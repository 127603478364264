import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageWrapper from "../../components/PageWrapper";

const SedmFaziCovidu = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Sedm fází Covidu'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockQuote quote="https://www.latimes.com/opinion/story/2021-08-26/pandemic-covid-19-stages-vaccination-intensive-care-respiratory-therapist" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jsem respirační terapeutka. Se čtvrtou vlnou pandemie v plném proudu, poháněnou vysoce nakažlivou variantou Delta, je trajektorie pacientů, které vídám od přijetí až po intenzivní péči, až příliš podobná. Pokud jsou očkováni, jejich COVID-19 infekce pravděpodobně skončí po 1. fázi. Kéž by to tak bylo u všech.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Nechte se očkovat. Pokud se rozhodnete, že ne, tak tady je příklad toho, co můžete očekávat, pokud budete hospitalizováni s těžkým případem COVID-19.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="Fáze 1" />
                    <PageParagraph content={
                        <Fragment>
                            Už několik dní trpíte vysilujícími symptomy, ale nyní se Vám už tak těžce dýchá, že se rozhodnete jít na pohotovost. Vaše hodnoty saturace kyslíkem nám okamžitě říkají, že potřebujete nutně pomoc — doplňkový tok 1 až 4 litrů kyslíku za minutu. Přijímáme Vás a začínáme Vám dávat antivirotika, steroidy, antikoagulanty nebo monoklonální protilátky. Strávíte několik dnů v nemocnici s pocitem vyčerpání, ale pokud Vás dokážeme odstavit od kyslíku, budete propuštěni. Přežili jste.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="Fáze 2" />
                    <PageParagraph content={
                        <Fragment>
                            Dýchání je pro Vás stále těžší a těžší. Mnoho pacientů tento pocit popisuje &#8220;jako tonutí.&#8221; Bronchodilatační kůry, které Vám poskytujeme, přinášejí pouze malou úlevu. Vaše potřeba kyslíku se výrazně zvýší ze 4 litrů na 15 litrů a poté i na 40 litrů za minutu. Drobnosti, jako vykonání potřeby nebo sezení na posteli, se stanou příliš náročnými na to, abyste je zvládli sami. Když se pohybujete, Vaše saturace kyslíkem rychle klesá. Převezeme Vás na jednotku intenzivní péče.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="Fáze 3" />
                    <PageParagraph content={
                        <Fragment>
                            Jste vyčerpáni z hyperventilace, kterou se Vaše tělo snaží uspokojit poptávku po vzduchu. Dali jsme Vás na neinvazivní ventilaci &#8220;přetlakem&#8221; — velkou, objemnou masku na obličej, která musí být kolem tváře pevně připevněna suchým zipem, aby stroj mohl efektivně vyvíjet tlak do Vašich plic, aby je otevřel a díky tomu jste získali dostatek kyslíku.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Fáze 4" />
                    <PageParagraph content={
                        <Fragment>
                            Dýchání je pro Vás ještě namáhavější. Je nám zcela očividné, že jste velice unavení. Odběr arteriální krve potvrzuje, že obsah kyslíku ve Vaší krvi je kriticky nízký. Připravujeme Vás na intubaci. Pokud můžete a pokud je čas, navrhneme Vám zavolat svým blízkým. Možná to bude naposledy, kdy uslyší Váš hlas.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Připojíme Vás k ventilátoru. Jste pod sedativy a paralyzováni, krmení skrz sondu zavedenou do žaludku, připojení k močovému katetru a rektální trubici. Vaše ochablé tělo pravidelně otáčíme, aby se Vám nevytvořily proleženiny. Koupeme Vás a udržujeme Vás v čistotě. Převrátíme Vás na břicho, abychom zajistili lepší okysličení. Vyzkoušíme experimentální terapeutika. 
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="Fáze 5" />
                    <PageParagraph content={
                        <Fragment>
                            Někteří pacienti přežijí fázi 4. Bohužel pro Vás se Vaše hladina kyslíku a celkový stav po několika dnech na ventilátoru nezlepšil. Vaše plíce infikované Covidem potřebují pomoc a čas na uzdravení; něco, co Vám může poskytnout přístroj ECMO, který obchází Vaše plíce a okyslišuje Vaši krev. Ale bohužel naše komunitní nemocnice tuto možnost nemá.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Pokud jste dostatečně stabilní, budete převezeni do jiné nemocnice vybavené ECMO přístrojem. V opačném případě s Vámi budeme nadále zacházet tak, jak nejlépe umíme. Jsme poddimenzovaní a zahlcení, ale vždy Vám poskytneme tu nejlepší péči, jakou můžeme.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="Fáze 6" />
                    <PageParagraph content={
                        <Fragment>
                            Tlak potřebný k otevření Vašich plic je tak vysoký, že do hrudní dutiny může prosakovat vzduch, který odsáváme trubičkami, abychom udrželi Vaši hrudní dutinu čistou. Vaše ledviny přestávají filtrovat vedlejší produkty z léků, které Vám neustále podáváme. Navzdory dieuretikům Vaše celé tělo otéká kvůli zadržování tekutin a Vy potřebujete dialýzu, která Vám pomůže s funkcí ledvin.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Dlouhý pobyt v nemocnici a Váš oslabený imunitní systém Vás činí náchylnými k infekcím. Rentgen hrudníku ukazuje, že se ve Vašich plicních váčcích začíná hromadit tekutina. Může se také objevit krevní sraženina. V tuto chvíli těmto komplikacím nemůžeme zabránit; léčíme je postupně tak, jak se objevují.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Pokud Váš krevní tlak kriticky klesne, podáme Vám vazopresory, kterými jej zvýšíme zpět, ale Vaše srdce se i přesto může zastavit. Po několika kolech kardiopulmonální resuscitace obnovíme Váš puls a oběh. Vaše rodina ale bude muset brzy udělat těžké rozhodnutí.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="Fáze 7" />
                    <PageParagraph content={
                        <Fragment>
                            Po několika setkání s týmem paliativní péče se Vaše rodina rozhodne péči přerušit. Extubujeme Vás a vypínáme dýchací přístroje. Zapneme Vám poslední FaceTime hovor s Vašimi milovanými. Zatímco pracujeme ve Vaší místnosti, slyšíme pláč a srdceryvné loučení. Pláčeme také a držíme Vás za ruku až do posledního přirozeného nádechu.
                        </Fragment>
                    } />
                    
                    <PageBreak />
                    <PageParagraph content={
                        <Fragment>
                            Takhle pracuju už 17 měsíců. A pořád to není jednodušší a jednodušší. Moje pandemické příběhy málokdy končí dobře.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            <i>Karen Gallardo je respirační terapeutka v Community Memorial Hospital v krajském městě okresu Ventura v Kalifornii ve Spojených státech.</i>
                        </Fragment>
                    } />
                    
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default SedmFaziCovidu;