import React from 'react';
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageWrapper from "../../components/PageWrapper";

const Hardware = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'My hardware'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockHeaderTwo header="Home PC" />

                    <PageParagraph content={
                        <Fragment>
                            This is my main home machine which I use almost daily. I built it in December 2022 after I had enough of being stuck on MacBook Air, which used to be my daily driver. Having Mac as a daily driver is not ideal, because it limits you severely. I love Macs for work, but in my opinion, they are not great machines for having fun.<PageBreak/>
                            <PageBreak/>
                            I decided to go with these specs: <PageBreak />
                            <PageBreak />
                            <strong>CPU</strong>: Intel Core i5-12400 ~2.5 GHz, Turboboost ~4.4GHz <PageBreak/>
                            cooled with SilentiumPC Fera 5 <PageBreak/>
                            <strong>MB</strong>: GIGABYTE B660M GAMING DDR4 <PageBreak/>
                            <strong>RAM</strong>: Kingston FURY 32GB DDR4 3200MHz Beast Black <PageBreak/>
                            <strong>GPU</strong>: GIGABYTE GeForce RTX 3060 EAGLE 12G, 12 GB GDDR6 ~15000MHz <PageBreak/>
                            <strong>PSU</strong>: Seasonic Focus GX 550 Gold <PageBreak/>
                            <strong>SSD</strong>: WD Blue SN570 1TB. <PageBreak/>
                            <strong>Case</strong>: Fortron CST350 PLUS Black <PageBreak/>
                            <strong>OS:</strong> Windows 11 Pro <PageBreak/>
                            <PageBreak />
                            So far, I am very happy with my choice - while this is mid-end, it gives me enough power to play any game I want to, and makes my life much easier when I am developing videogames or creating assets into them.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Weekend PC" />

                    <PageParagraph content={
                        <Fragment>
                            I often visit my family on weekends, which means that I, of course, need a weekend PC there as well. It was build in 2015, so it is fairly old. However, it lets me play pretty much anything I want to still, so, I can't complain!<PageBreak/>
                            <PageBreak/>
                            Specs are as follows: <PageBreak />
                            <PageBreak />
                            <strong>CPU</strong>: Intel Core i5-4460 ~3.2 GHz, Turboboost ~3.4GHz<PageBreak />
                            cooled with Cooler Master Hyper 212+ EVO<PageBreak />
                            <strong>MB</strong>: ASUS H81-GAMER<PageBreak />
                            <strong>RAM</strong>: Kingston 16GB DDR3 1600MHz CL9 HyperX Savage<PageBreak />
                            <strong>GPU</strong>: MSI GTX 960 GAMING 4G, 4GB GDDR5 ~7010MHz<PageBreak />
                            <strong>PSU</strong>: Corsair CS550M<PageBreak />
                            <strong>Case</strong>: Zalman Z11<PageBreak />
                            <strong>OS:</strong> Windows 10 Pro<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Company MacBook Pro (2019)" />

                    <PageParagraph content={
                        <Fragment>
                            We are using MacBook Pro at work. What a beast!<PageBreak/>
                            <PageBreak/>
                            Specs are as follows: <PageBreak />
                            <PageBreak />
                            <strong>CPU</strong>: Intel Core i7 ~2.6 GHz, Turboboost ~4.5GHz<PageBreak />
                            <strong>RAM</strong>: 16 GB DDR4 ~2400MHz<PageBreak />
                            <strong>GPU</strong>: AMD Radeon Pro 5300M (4GB GDDR6)<PageBreak />
                            <strong>OS:</strong> MacOS Ventura<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="MacBook Air (2017)" />

                    <PageParagraph content={
                        <Fragment>
                            I also have a personal laptop, which is MacBook Air from 2017, which is definitely not a beast compared to the company Pro one. Nowadays replaced with my desktop PC and rarely used, mostly as a shared network drive.<PageBreak/>
                            <PageBreak/>
                            Specs are as follows: <PageBreak />
                            <PageBreak />
                            <strong>CPU</strong>: Intel Core i5 ~1.8 GHz, Turboboost ~2.9GHz<PageBreak />
                            <strong>RAM</strong>: 8 GB LPDDR3 ~1600MHz<PageBreak />
                            <strong>GPU</strong>: Intel HD Graphics 6000<PageBreak />
                            <strong>OS:</strong> MacOS Ventura<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="iPhone SE 2020" />

                    <PageParagraph content={
                        <Fragment>
                            My phone is iPhone SE 2020, which I bought right away when it launched as a replacement for my aging Nexus 5X. I used to be an Android fan, but I fell in love with iOS (especially great combination with other Apple hardware, like MacBook) and cannot really imagine going back. Considered to be a "budget" and "affordable" iPhone. Even though it's more than 3 years old now, I am not really planning to replace it any time soon — it still runs like a new one!<PageBreak/>
                            <PageBreak/>
                            Specs are as follows: <PageBreak />
                            <PageBreak />
                            <strong>SoC:</strong> Apple A13 Bionic APL1W85<PageBreak />
                            <strong>CPU</strong>: 2.65GHz hexa-core<PageBreak />
                            <strong>RAM</strong>: 3 GB<PageBreak />
                            <strong>OS:</strong> iOS<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Raspberry Pi 3B+" />

                    <PageParagraph content={
                        <Fragment>
                            I also have a Raspberry Pi 3B+, which is mostly used as a tiny server or a shared network drive. However, I didn't try to use it as a NAS, because Raspberry 4 would be more suitable for it. That's gonna happen in the future though!<PageBreak/>
                            <PageBreak/>
                            Specs are just laughable: <PageBreak />
                            <PageBreak />
                            <strong>CPU</strong>: 1.4GHz 64-bit quad-core procesor ARMv8<PageBreak />
                            <strong>RAM</strong>: 1 GB LPDDR3 ~1600MHz<PageBreak />
                            <strong>GPU</strong>: Broadcom VideoCore IV @ 400 MHz / 300 MHz<PageBreak />
                            <strong>OS:</strong> Raspbian<PageBreak />
                        </Fragment>
                    } />
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
};

export default Hardware;