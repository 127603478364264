import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageBulletListNested from "../../components/PageComponents/PageListComponents/page-bullet-list-nested";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import PageNumberedListNested from "../../components/PageComponents/PageListComponents/page-numbered-list-nested";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper";

const IssendaiDProcDetiOpousteji = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{ header: 'Issendai – Proč dospělé děti opouštějí svoje rodiče?' }} />

            <PageContent content={
                <Fragment>
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/reasons-for-estrangement.html" author="Originál v angličtině" />
                    
                    <PageBlockHeaderTwo header="Pohled rodičů" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jedna z těch více fascinujících věcí na fórech pro opuštěné rodiče je způsob, jakým řeší otázku toho, proč je jejich dítě opustilo. Je to jejich ultimátní otázka; předmět jejich zkoumání; vyskytuje se v úvodních příspěvcích každého nového člena a členové se k této otázce stále a stále vrací. A přesto zkušení veteráni z fóra odrazují od analýzy a rozbíjí diskuzi tím, že prohlásí něco jako: &#8220;Nejde pochopit něco, co je iracionální&#8221; a nebo &#8220;Je jedno, proč to děti udělaly, nejdůležitější je to, že jsi byla dobrá matka a ony tě odhodily pryč.&#8221;
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Vypsala jsi tu tři &#8220;důvody&#8221; k tomu, proč tě dcera opustila: to, že jsi nebyla perfektní rodič, pak její zdravotní stav a také její toxický přítel. Vyhoď tyhle důvody z okna. Je jenom jeden důvod, proč se na tebe dcera otočila zády: vybrala si to. Nemysli si ani jednu minutu, že to nedělá ze své svobodné vůle. Je to způsob, jak se lidi jako tvoje dcera vyrovnávají s těžkými situacemi: utíkají od nich pryč a snaží se jim vyhnout. Neexistuje nic, co bychom s tím ty nebo já, nebo kdokoliv na tomhle fóru, mohli udělat.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tohle se může stát, i když členka vypíše seznam zcela jasných a do očí bijících důvodů k tomu, proč ji dítě opustilo a stává se to velmi často při tom, když za to členka cítí alespoň částečnou zodpovědnost. Ostatní členové přispěchají na pomoc, aby členku rychle ukonejšili, řekli jí, že ať už udělala cokoliv, že se snažila udělat to nejlepší, co mohla a že si za to nezaslouží takovýhle trest. Naopak, byla skvělá matka a děti by za ní měly být rády. Je naprosto nepochopitelné, že si její děti neváží toho, co pro ně udělala a nevidí ji jako milujícího, dobrosrdečného rodiče, jakým je.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Ale takové vyhýbání se odpovědi neodpovídá na otázku &#8220;proč.&#8221; A brzy se v dalším diskuzním vláknu opět objeví: Proč nám děti dělají takové kruté, iracionální, neporozumitelné věci?
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Zlehčené důvody" />

                    <PageParagraph content={
                        <Fragment>
                            &#8230; A v tomhle případě někdo rychle nadhodí důvod: &#8220;Tahle generace je tak hrozně narcistická.&#8221; A všichni ihned souhlasí: Ano, ano, tahle generace je neuvěřitelně narcistická!
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Všichni členové ihned vyskočí na vlnu této výmluvy, včetně členů, kteří přišli na fórum pro pomoc s dětmi, které mají za sebou například už několik neúspěšných pokusů o to, zůstat na odvykačce a vyléčit se z drogové závislosti. Nebo které odmítají brát léky na jejich bipolární poruchu. Nebo děti, které mají od svého manžela viditelné podlitiny. I tihle členové si velmi rádi nejprve uklidí na svém písečku tím, že řeknou &#8220;odloučení je nepochopitelné&#8221; a původní pádný důvod nahradí svým, méně závažným důvodem.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            &#8220;Závažný&#8221; je důležité slovo k zapamatování, když vidíme důvody, kterých se členové zuby nehty drží. Důvody, kterých se drží, jsou většinou takové, které je zbavují jakékoliv zodpovědnosti, díky kterým necítí, že by někdy mohli udělat něco špatně &#8211; maximálně možná to, že byli příliš dobří rodiče a své dítě rozmazlili; všechnu zodpovědnost přehazují na dítě nebo na jeho okolí; a tedy neexistuje nic, co by vlastně rodič mohl dělat &#8211; kromě čekání.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tyto &#8220;zlehčené&#8221; důvody jsou obvykle jeden krok zpátky a vlečou se za důvodem skutečným. Například, pokud rodič tvrdí, že se s nimi dítě přestalo bavit kvůli tomu, že neumí správně řešit konflikty a řeší všechny problémy tím, že od nich uteče, tak jaký byl konflikt, před kterým uteklo? Pokud dítě rodiče &#8220;trestá&#8221; za věci, které si dítě vymyslelo, že mu rodič udělal, tak proč si takové věci vůbec vymýšlí? Je blázen? Lže? Pokud ano, tak co ho nutí k tomu, aby lhalo? Za každým zlehčeným důvodem je vážný důvod, kus psychologického jádra, ale členové se aktivně vyhýbají tomu, se k němu dokopat.
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Zlehčené důvody odloučení" />

                    <PageParagraph content={
                        <Fragment>
                            Toto je list nejčastějších důvodů, kterými členové fór pro opuštěné rodiče vysvětlují to, proč se s nimi jejich děti nestýkají:
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Byli jsme na ně moc hodní.</li>
                            <li>Jejich celá generace je narcistická.</li>
                            <PageBulletList content={
                                <Fragment>
                                    <li>Očekávají dokonalost a odmítají nás, protože jsme lidi.</li>
                                </Fragment>
                            } />
                            <li>Jsou nezralí.</li>
                            <li>Neumí řešit konflikty a komunikovat, proto od nich utíkají pryč.</li>
                            <li>Naučili jsme je, aby nás opustily díky tomu, že my jsme své rodiče také opustili.</li>
                            <li>Opouštění rodičů je v genech, máme to v rodině.</li>
                            <li>Stydí se za sebe.</li>
                            <PageBulletList content={
                                <Fragment>
                                    <li>Naše děti udělaly špatná rozhodnutí a stydí se nám pohledět do tváře.</li>
                                    <li>Dělají něco, o čem vědí, že s tím nesouhlasíme a nechtějí se tomu postavit čelem.</li>
                                </Fragment>
                            } />
                            <li>Je módní své rodiče opouštět./Dělají to kvůli pozornosti.</li>
                            <li>Lhali o nás a teďka nevědí, jak se z toho vykroutit.</li>
                            <li>Trestají nás za prohřešky, které si vymyslely.</li>
                            <li>Je to přesilovka./Chtějí nad námi mít kontrolu.</li>
                            <PageBulletList content={
                                <Fragment>
                                    <li>Chtějí, abychom je naháněli, protože nás to nutí k tomu, jim věnovat pozornost.</li>
                                </Fragment>
                            } />
                            <li>Jsme obětním beránkem pro všechno, co se jim v jejich životě nepovedlo./Mají psychologické problémy, které nedokážou vyřešit, tak místo toho útočí na nás.</li>
                            <li>Jejich manžel/manželka je chce mít jenom pro sebe.</li>
                            <PageBulletList content={
                                <Fragment>
                                    <li>Jejich manžel/manželka má špatné vztahy se svou rodinou, takže se nemůže smířit s tím, že moje dítě s námi má hezký vztah.</li>
                                </Fragment>
                            } />
                            <li>Jejich manžel/manželka má hodně peněz, takže se za nás naše dítě stydí.</li>
                            <li>Opouštějí nás, protože můžou.</li>
                            <li>Myslím si, že ani oni samotní nevědí, proč to dělají.</li>
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Vážné důvody" />

                    <PageParagraph content={
                        <Fragment>
                            Ne všechny diskuze o odloučení se zaměřují na zlehčené důvody. Členové fór také diskutují nad řadou &#8220;vážných důvodů,&#8221; důvodů, které jsou takřka kompletním vysvětlením toho, proč s nimi děti přerušily kontakt: duševní nemoci, poruchy osobnosti, rodičovské odloučení, závislosti na drogách, partnerské týrání.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nicméně, diskuze o těchto důvodech má stejně kašovitou, nesoustředěnou kvalitu jako diskuze členů o zlehčených důvodech. Členové odmítají své děti třídit to různých kategorií, takže si celkově jako komunita neuvědomují, že například k dětem, které jsou uvízlé ve spárech svých násilných manželů, se musí chovat jinak než třeba k dětem, které jsou závislé na kokainu. Bez kolektivního rozpoznávání problémů jim chybí kolektivní paměť &#8211; všechny nové poznatky, ke kterým se členové dopracují, ihned po skončení diskuze kolektivně zapomínají, takže každá další konverzace začíná opět znovu tam, kde začínaly všechny předchozí konverzace. A zároveň to vypadá, že se členové příliš nezajímají o fakta. Nechtějí doopravdy znát, co skutečně vede k rodičovskému odloučení, nebo třeba jak se asi teenager doopravdy cítí pod jejich nadvládou. Chtějí se pouze cítit lépe po tom, co je konfrontoval jejich bývalý partner nebo po tom, co byli odmítnuti jejich vlastními dětmi. Výsledkem je to, že každá konverzace o vážných důvodech se mění do diskuze o tom, jak se cítí jako rodiče dítěte, které je duševně nemocné, závislé na drogách, které má poruchu osobnosti, atd., které se poté změní do diskuze nad smutkem a vztekem, který členové cítí a který následně utěšují aplikací zlehčených důvodů.
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Závěr" />

                    <PageParagraph content={
                        <Fragment>
                            Členové fór pro opuštěné rodiče jsou posedlí zkoumáním toho, proč je jejich děti opustily a zároveň mají velký odpor k analýze skutečných důvodů svých dětí. Členové nejen odmítají důvody, které jim jejich děti řekly přímo do očí, ale také ignorují vážné důvody jako třeba závislost na drogách nebo duševní nemoci. Preferují vysvětlení, která jim pomáhají zmírnit jejich bolest: &#8220;Tahle generace je narcistická.&#8221; (Je to chyba naší kultury. Moje dítě se takhle chová navzdory tomu, že jsem se snažila, jak jsem mohla.) &#8220;Moje dítě očekává, že budu perfektní.&#8221; (Věci, z kterých mě obviňuje jsou drobné chybičky, které může udělat každý člověk.) &#8220;Opouštění rodičů je genetické&#8221; (A geny pravděpodobně pochází od druhého rodiče. A i kdyby byly ode mě, tak nemám zodpovědnost za to, jakou DNA jsem jí dala. Ona je zodpovědná za to, co s ní udělá.)
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Výsledkem je to, že komunity opuštěných rodičů nemají žádné kolektivní zkušenosti s tím, jak řešit problémy s dospělými dětmi, které jsou duševně nemocné, závislé na drogách, týrané jejich partnery, atd., přičemž mnoho členů tvrdí, že tyto důvody se velkou mírou podílí na tom, že se s nimi jejich děti nestýkají. Naproti tomu zkušenosti s utěšováním ostatních členů mají obrovské: jak zastavit posedlost svými opuštěnými dětmi, jak se cítit méně provinile, odmítnutě a bezcenně.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nechápejte mě špatně &#8211; je dobře, že takové věci opuštění rodiče vědí. Potřebují to jako čistou vodu a čistý vzduch. Ale jejich přístup spočívá ve špatném rozdělení celku do více samostatných částí: Buď se o sobě budeš cítit dobře NEBO budeš lépe rozumět tomu, proč tě dítě opustilo. Podkopávej kritiku svých dětí, aby ses s jejich kritikou nemusel vůbec zabývat NEBO buď přinucený k tomu, strpět nějakou bolest související s tím, že si uvědomíš, že děláš chyby a že to, co jsi udělal, někomu ublížilo, díky čemuž si ale také uvědomíš, jak to můžeš napravit a jak svůj vztah s dítětem vyléčit.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tato neschopnost rozdělit jednu věc na více nezávislých celků je jádrem celého odloučení. Pokud by chyba byla na rodičově straně, ale zároveň by byl ochotný naslouchal opravdovým důvodům, tak by nebyl opuštěný. Pokud je chyba na straně dítěte a rodič by byl schopen vidět situaci i z jiného úhlu než, &#8220;Jak se kvůli tomu cítím?&#8221;, tak by jeho porozumění problému bylo tak odlišné, že by ho ani nenapadlo hledat takový typ podpůrných skupin, jako jsou fóra pro opuštěné rodiče. A dokud rodič nebude ochotný změnit trochu svůj způsob myšlení, tak bude holt člen nadále trvat na tom, že s ním dítě nemluví, protože mu byla tak dobrou mámou, že ho rozmazlila a stal se z něj rozmazlený parchant, který si myslí, že se kolem něj točí svět, očekává absolutní dokonalost a lže o svých rodičích svému bohatému partnerovi.
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Děti vysvětlují" />
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/adult-childrens-reasons-for-estrangement.html" author="Originál v angličtině" />

                    <PageParagraph content={
                        <Fragment>
                            Jaké jsou opravdové důvody k tomu, přestat se stýkat se svými rodiči? Dospělé děti je vysvětlují vlastními slovy:
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Jako dcera, která se se svojí matkou nestýká, ti můžu říct, proč jsem to udělala. Pokaždé, když moje matka otevřela pusu, tak jenom kritizovala. Pokud ne mě, tak manželku mého bratra, její sousedy nebo kamarády. I úplně náhodný člověk na ulici neutekl před její kritikou. Vždycky k tomu jenom dodala: &#8220;Co je? Dyť jenom mluvím,&#8221; nebo &#8220;Když to nemůžu říct tobě, tak komu?&#8221; a potom začala kritizovat mě. Nedokázala pochválit nový účes nebo oblečení bez toho, aby k tomu nepřidala nějakou nemístnou poznámku. Už jsem tu její negativitu nedokázala snážet, tak jsem ji od sebe odřízla. Je mi smutno, že jsem nikdy neměla mámu, jakou bych si přála mít. Dlouho jsem si přála mít pozitivní mámu, která by mě podporovala, ale takovou jsem nikdy neměla. Nikdy mi nechyběla, protože ani nevím, co by mi na ní mělo chybět.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Mám matku, která by mě nejradši viděla samotnou, bez peněz, bez domova a podobně; je sama, žije velmi ubohý život a chce, abych tak žila taky. Bohužel pro ní to tak není&#8230;.mám úžasného muže, dům, kariéru, přátele a život. Po rocích poslouchání jejího neustálého stěžování si na všechno, po rocích neustálého kritizování a po letech poslouchání toho, ať jdu do ha..lu, mi to stačilo. Roky jsem se starala o to, aby se ona a můj otec (který před čtyřmi lety umřel) měli dobře a aby si na ně někdo o svátcích vůbec vzpomněl a podobně. Myslím, že ode mě očekávala, že po tom, co mi umřel můj otec, že přestanu žít svůj vlastní život a budu ji furt opečovávat.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Náš vztah s mojí mámou je opuštěný&#8230; nikdy nepřiznala žádnou zodpovědnost za bolest, kterou mi způsobila..potřebovala bych, aby to přiznala a aspoň mě poprosila o odpouštění..aby řekla &#8220;Omlouvám se&#8221;&#8230;opravdu bych to od ní chtěla slyšet.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Včera jsem se pohádala s mojí mámou. Není to konec světa, ale když si vezmu můj život celkově, tak mám pocit, jako kdyby to konec světa byl.
                            No každopádně, včera jsme si volaly a já věděla, že jsem podrážděná a proto jsem nechtěla moc dlouho volat. Vím, že to myslela dobře, ale dávala mi rady, nad kterými jsem už dávno popřemýšlela. Je dobrá v předpokládání určitých věcí a řekla jsem jí, že někdy je lepší nic nepředpokládat. Začala brečet a řekla mi &#8220;Všimla jsem si, že s tebou nejde mluvit vůbec o ničem. Jsi moc negativní!&#8221;<br></br>
                            Hned na to jsem jí dala důvody a řekla jí, že věci jsou složité, moc jsem se poslední dobou nevyspala, rozloučila jsem se s ní a řekla jí dobrou noc.<br></br>
                            Vím, že je stará, ale takováhle byla i před 20 lety. Je to dobrá žena, ale myslí si, že se svět točí jenom kolem ní a je hrozně kritická. Neustále má nějaké připomínky k mým vlasům, mé váze, mluví se mnou o intimních detailech jejího soukromého života s mým otcem (který je už dávno mrtvý), což je extrémně nevhodné. A s mými sourozenci by takhle NIKDY nemluvila.<br></br>
                            Nenávidí, když cestuju, takže pořád odkládám můj výlet do Asie. Má zvláštní představy o mém zdraví. Chce, abych byla v nejlepší formě a to vždy.<br></br>
                            Ale nemůžu a už to mě to všechno štve.<br></br>
                            Nemůžu jí dát něco, co nemám, takže cítím, že je čas na to, abych od ní byla trochu dál.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Já jsem své matce řekla, proč jsem ji opustila. Protože mě nebyla schopná ochránit a když jsem se snažila zachránit sama, tak všem řekla, že si kvůli mým lékům na astma vymýšlím nepravdivé příběhy. Dělala si srandu z mého strachu a z mé bolesti. Vždycky jsem jako dítě mluvila pravdu a pamatuju si, když mě obviňovali z toho, že lžu &#8211; cítila jsem se, jako kdyby mi to napsali na čelo.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Svoji matku jsem poprvé viděla v jasném světle až když mi bylo 35. Po všech těch letech, kdy jsem se snažila získat její lásku, jsem se musela smířit s tím, že ona v sobě prostě žádnou lásku nemá. Všechny ty ztracené roky jsem hodila za hlavu, přestala jsem na ni myslet a zaměřila veškerou moji sílu na to, abych byla mým dětem dobrou mámou.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Když byl můj syn ještě malý, tak se mi vrátil do života můj otec&#8230;teďka alkoholik, který ale tehdy ještě nepil. Trvalo mi rok, než jsem si uvědomila, že můj otec je pořád stejný tyran jako když jsem byla malá, že je furt stejný jako když jsem ho popisovala na terapii. Začala jsem si všímat, že se jeho špatné vlastnosti zase zhoršují a uvědomila si, že by mému synovi mohly uškodit a pořád jsem otcovi říkala, ať se na tom pokusí zapracovat. To odmítl, tak jsem mu řekla, ať TÁHNE pryč z mýho života&#8230;&#8230;&#8230;&#8230; opustila jsem vlastního otce&#8230;&#8230;&#8230;.byla to nejtěžší věc, kterou jsem kdy musela udělat, ale udělala jsem to kvůli bezpečí mého syna a kvůli mému emočnímu zdraví. Lituju té situace, ale nelituju toho, že se starám o sebe a svého syna. Udělala jsem správnou věc a je jedno, jak byla těžká.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Moje matka je a vždycky byla sobecká narcistka, která mě fyzicky týrala i po tom, co jsem se k ní zase vrátila.<br></br>
                            Ona sama se od své rodiny odloučila ještě před tím, než jsem se vůbec narodila. Zbytek její rodiny jsou dobří, tvrdě pracující lidé, kteří by pomohli každému, kdo to opravdu potřebuje. Matka říká, že se k ní nikdy nechovali dobře. Případy, které mi popisovala a o kterých si myslí, že se k ní nechovali dobře, mi nedávají žádný smysl.<br></br>
                            PŘÍKLAD: Když jí bylo 11, tak spálila kurník, protože jí její bratři provokovali a nakonec za to byla potrestaná ona. (Ty jo, opravdu? Fakt? NO NEKECEJ!!) Ale podle ní měli být potrestaní její bratři za to, že jí vzali panenku a utekli s ní, takže to byla celé jejich vina. Ona dokonce i PŘIZNÁ to, že ten kurník zapálila a doteď si opravdu skutečně myslí, že to nebyla její chyba! A vyprávěla mi hromadu dalších příběhů, které jsou podobně ujeté.<br></br>
                            Ozývá se mi jenom, když chce peníze. Například, jednou si koupila lístek na koncert 3200 kilometrů daleko, zavolala mi a TRVALA NA TOM, abych jí tam koupila letenku. HUH?<br></br>
                            Je mi jasný, že trpí narcistickou poruchou osobnosti (vygooglete si Narcistická Porucha Osobnosti a budete zírat).<br></br>
                            Opustila jsem jí kvůli mému pudu sebezáchovy. Můj drahý muž si myslel, že to jsou jenom &#8216;typické problémy mezi matkou a dcerou&#8217; dokud ji nepotkal sám a teď se s radostí vyhýbá tomu, aby ji musel vidět nebo s ní mluvit. Pokud čistou náhodou jedeme okolo místa, kde bydlí, tak se tam nestavujeme. Můj muž mi říká, že každý den děkuje Bohu za to, že se o mě v mém mladém věku starali především ostatní příbuzní.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Moji matku jsem opustila kvůli tomu, že mě týrala a taky kvůli tomu, že týrala všechny muže, se kterými kdy byla. Když mi bylo 11, tak mi řekla, že jsem byla chyba a že se mě pokoušela potratit pomocí ramínka na šaty&#8230;.. odešla jsem po tom, co jsem se vdala a nikdy jsem se neohlédla zpátky. Je to moc smutný a dlouhý příběh, ale když jsem jí k ničemu nebyla a nepomáhala jí ani finančně, tak všem řekla, že neexistuju. A to i všem jejím partnerům.<br></br>
                            7 let před její smrtí jsem se vrátila do Texasu, abych byla se svou sestrou a bratrem. Umírala. Ani jednou za 54 let mi neřekla, že mě miluje, jenom, že jsem byla nehoda a přítěž. Zajímala se o mě jenom posledních 6 měsíců jejího života a to většinou kvůli tomu, abych řekla mé sestře nebo mému bratrovi, aby se na ni přišli podívat, což se mi ne vždy podařilo. Byla jsem s ní její poslední tři dny, byla jsem tam, když umřela &#8230; nikdy jsem jí těch posledních 7 let nezapomněla říct, že ji miluju &#8230; a že jí odpouštím &#8230; její odpověď byla &#8230; kde je tvoje sestra, kde je tvůj bratr, řekni jim, ať příjdou&#8230;<br></br>
                            Pohřbila jsem ji, oni ne, všechno jsem zařídila, za všechno jsem zaplatila &#8230; a potom odešla truchlit za matku, kterou jsem nikdy neměla a mít nebudu a budu už asi až do konce života přemýšlet, proč mě vlastně tak nenáviděla.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Svoji matku jsem opustila. Nikdy neměla mít žádné děti. Vychovávala jsem svoji sestru, zatímco ona chodila na party. Zapomínala na to, že potřebujeme jídlo, zatímco ho ona měla hromadu, kupovala si &#8220;hezké&#8221; oblečení, zatímco my neměli ani bundu na zimu. Devadesát procent našeho oblečení bylo od charity, svůj první zubní kartáček jsem dostala díky tomu, že jsem začala ve dvanácti letech pomáhat uklízet byty. Když jsem byla dospělá, tak jsem jí finančně podporovala a starala se o ni pokaždé, když to potřebovala. Bydlela u mě 12 let, během kterých jsem se starala i o svojí rodinu. A ne, ani mi nepohlídala děti, nikdy by to neudělala a já ji s dětmi stejně nevěřila. Přestala jsem se s ní stýkat před osmi lety, když jsem si uvědomila, že se ke mě prostě nikdy hezky chovat nebude.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Nedávno jsem opustila svojí matku a všichni v rodině jsou na mě naštvaní kvůli tomu, že jsem &#8220;na ni hnusná.&#8221; Důvodem, proč si myslím, že je moje rozhodnutí oprávněné je to, že se ke mě celý život chovala jako k nějakému &#8220;nevlastnímu dítěti.&#8221; Nikdy jsem nebyla pozvaná na žádné rodinné oslavy nebo mi byl přístup na ně dokonce výslovně zamítnut a ostatní členové rodiny postupně uvěřili všemu, co jim o mě nakecala, věří tomu všemu a povídají to i ostatním lidem. Tohle léto moje matka na svou oslavu pozvala všechny členy rodiny, děti, synovce, vnuky, tety, přátele, sousedy, atd., kromě mě a mých dětí. To byla pro mě poslední kapka.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Svoji matku jsem už 21 let neviděla.<br></br>
                            Nemyslím si, že jsem kvůli tomu někdy vůbec šla přes nějaké etapy odloučení &#8211; nikdy jsem k ní necítila žádné pouto, protože se k nám chovala jako kdybychom byli jenom břímě. Zanedbávala nás a týrala.<br></br>
                            Občas cítím takovou vlezlou vinu, ale myslím si, že to je jenom touha po matce, kterou jsem nikdy neměla. Ale že bych trpěla kvůli ní, tak to tedy opravdu ne.<br></br>
                            Kéž by mě jako dítě sebrala sociálka a já měla šanci mít nějakou normální rodinu.
                        </Fragment>
                    } />

                    <Link to="/issendai-poznamky">Pokračování &#8211; Poznámky</Link>

                </Fragment>} />
            <Footer />
        </PageWrapper>
    )
}

export default IssendaiDProcDetiOpousteji;