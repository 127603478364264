import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import PageWrapper from "../../components/PageWrapper";

const NaKaloriichZalezi = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: '11 důvodů, proč si lidé myslí, že na kaloriích nezáleží – a proč se pletou'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockQuote quote="https://completehumanperformance.com/2014/01/20/calories-count/" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Vzhledem k tomu, že i v dnešní době stále existují stokrát omleté mýty ohledně kalorií (kterým ale stále mnoho lidí věří), jsem se rozhodl přeložit článek, který se tomuto tématu věnuje. Dále také doufám, že pomůže lidem, kteří se nemohou rozhodnout, jakým způsobem zhubnout a nebo mají různé pochybnosti.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Na kaloriích <strong>záleží</strong>.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jedna studie za druhou prokazují, že pokud jíte méně a více se hýbete, tak zhubnete [1-4].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nicméně, pořád lze slýchat protiargumenty, proč tomu tak není. Některé z těchto protiargumentů jsou vyloženě hloupé, ale některé z nich mohou znít legitimně. A mohou ve vás vyvolat určité pochyby ohledně toho, zda je tento celý &#8220;<em>kalorie přijímané versus kalorie spálené</em>&#8221; plán pro vás vůbec vhodný.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pojďme se podívat na nejčastější protiargumenty, které používají lidé tvrdící, že redukce kalorií nezpůsobuje redukci hmotnosti a hlavně na to, proč se mýlí.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #1: To, co jíte, je důležitější, než kolik toho jíte"/>

                    <PageParagraph content={
                        <Fragment>
                            Můžete mít suprové tělo, i kdybyste jedli jenom Skittles a pili výhradně Coca Colu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Je to samozřejmě absurdně extrémní příklad a moc byste si takové stravování nejspíše neužili. Nicméně, pokud jde <em>čistě</em> o hubnutí, tak vůbec nezáleží na tom, co jíte. Pokud jste v kalorickém deficitu, zhubnete.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Neexistuje žádný důkaz o tom, že &#8220;nezdravé jídlo&#8221; vás vykrmí více, než to &#8220;zdravé jídlo,&#8221; pokud mají obě jídla stejný počet kalorií [5].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tady jsou nejčastější obětní beránci:
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Toxiny jako bisfenol-A (BPA) [6].</li>
                            <li>Geneticky modifikované jídlo.</li>
                            <li>Cukr [7].</li>
                            <li>Fruktóza [8].</li>
                            <li>Lepek.</li>
                            <li>Mléčné výrobky. </li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Neexistuje žádný důkaz o tom, že konzumace čehokoliv výše zmíněného způsobí, že budete tloustnout rychleji a nebo že vám zpomalí hubnutí, pokud budete v kalorickém deficitu [5, 6, 9-11]. Zároveň také neexistuje žádný důkaz toho, že ostatní jídla vám pomůžou zhubnout více.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            To neznamená, že na kvalitě jídla nezáleží. Konzumace nezpracovaných, nutričně bohatých jídel, která vás zaplní, pomáhá k lepší kontrole nad pocitem hladu a pomůže vám s udržením dlouhodobě dobrého zdravotního stavu [12-15].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nicméně, konzumace rozumných množství &#8220;nečistých&#8221; jídel nebude mít žádný vliv na vaši schopnost zhubnout &#8211; pokud budete stále v kalorickém deficitu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Některá jídla vás zasytí více, než jiná a díky tomu je při jejich konzumaci snadnější kalorický deficit udržet. Nicméně, fakt je ten, že pokud budete v kalorickém deficitu, tak zhubnete vždy.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Různá &#8220;kvalita&#8221; jídla (ať už to znamená cokoliv) v tomto případě nehraje žádnou roli.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #2: Pokud jíte správnou kombinaci proteinů, tuků a cukrů, tak neztloustnete"/>

                    <PageParagraph content={
                        <Fragment>
                            Prakticky každá redukční dieta má za cíl udržet určitý poměr makronutrientů &#8211; tím je myšleno pevné procento kalorií pocházejících z proteinů, tuků a sacharidů, které máte dodržovat.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Většina z takových diet jsou naprosté nesmysly. Skoro 100 let různých výzkumů prokázalo, že neexistuje žádná makronutriční kombinace, která by způsobovala rychlejší hubnutí, než jiná [1, 3, 16-18]. Lidé ztrácejí stejnou hmotnost stejnou rychlostí bez ohledu na to, zda konzumují vysoko nebo nízkosacharidová jídla, pokud přitom konzumují stejný počet kalorií [1-4, 19].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            To nás přináší k výjimce &#8211; a to jsou vysoce proteinové diety.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Vysoce proteinové diety obecně pomáhají lidem ztratit méně svalů a shodit více tuků při stejném kalorickém příjmu [20-30]. Nicméně, fungují pouze do určitého bodu, při jehož překročení vám konzumace více proteinů nepomůže zhubnout více tuku na úkor svalů.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Je nezbytné pamatovat na to, že nikdo z těchto lidí v těchto studiích by nezhubnul, kdyby <strong>nebyl v kalorickém deficitu</strong>. Také platí to, že i tito lidé měli tendence ztratit <em>stejnou celkovou váhu</em> jako lidé na nízkoproteinových dieách.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Každý makronutrient může způsobit přibírání na váze, pokud zároveň přispívá ke kalorickému přebytku. Neexistuje žádný poměr makronutrientů, který by zabraňoval nebo urychloval hubnutí. Je to zkrátka o kaloriích.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #3: Lidé neztrácí přesně tolik hmotnosti, kolik by se očekávalo; takže na kaloriích nezáleží"/>

                    <PageParagraph content={
                        <Fragment>
                            Půl kila tuku má zhruba 3500 kalorií (jedno kilo tuku má tedy zhruba 7000 kalorií). Pokud jíte o 500 kalorií denně méně, tak byste teoreticky měli zhubnout půl kila za týden.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            To se prakticky nikdy nestává.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            I v kontrolovaných studiích lidé nezhubli přesně tolik, kolik by se čistě matematicky očekávalo [31, 32]. Někteří lidé tvrdí, že je to důkaz toho, že na kaloriích nezáleží.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Není to pravda.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Vědci už dlouhé roky vědí, že obě strany energetické balance (kolik toho zkonzumujete a kolik toho spálíte) jsou proměnné, které se měří složitě [1, 2].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tady jsou důvody, proč lidé nehubnou přesně tak, jak byste matematicky očekávali. Zároveň slouží i jako vysvětlení, proč nic z toho neznamená, že by na kaloriích nezáleželo:
                        </Fragment>
                    } />

                    <PageNumberedList content={
                        <Fragment>
                            <li>Lidé jsou velice nepřesní v odhadování jejich kalorických příjmů a výdajů [33-81]. Díky tomu anekdoty a studie provedené na lidech, kteří se během studie nenacházeli v kontrolovaném prostředí, nikdy nedokáží, že by na kaloriích nezáleželo.</li>
                            <li>V případě, že lidé hubnou, tak z mnoha důvodů také (postupně spolu se snižující se hmotností) spalují méně kalorií. Jejich kalorický deficit se tedy v případě stejného příjmu postupně zmenšuje a to je důvod toho, proč hubnou postupem času pomaleji, než na začátku [82-84].</li>
                            <li>Lidé také zpravidla zhubnou i nějaké svaly a to i v případech, kdy hubnou rozumně. Díky tomu se může úbytek hmotnosti v čase postupně měnit.</li>
                            <li>Je těžké spočítat naprosto přesný příjem kalorií, i když jsou lidé v kontrolovaném prostředí.</li>
                            <li>Když výzkumníci odhadují kalorické výdaje konkrétních lidí, tak se mohou u některých lidí lehce zmýlit. Pokud se tak stane, tak to znamená, že tito konkrétní lidé mohou být během celého výzkumu v menším kalorickém deficitu, než jaký u nich výzkumníci předpokládali.</li>
                            <li>Lidé ztrácí nebo zadržují různé množství vody, která může mít vliv na jejich celkovou hmotnost.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            V každé dobře kontrolované studii, ve které jsou lidé přinuceni k tomu, jíst méně, lidé hubnou. Ne vždy samozřejmě zhubnou stejnou hmotnost jako ostatní, ale zpravidla se jejich redukce hmotnosti blíží předpokládané redukci hmotnosti, kterou byste očekávali za předpokladu, že půl kila tuku je 3500 kalorií [32].
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #4: Lidé nepřibírají přesně tolik hmotnosti, kolik by se očekávalo; takže na kaloriích nezáleží"/>

                    <PageParagraph content={
                        <Fragment>
                            Ve studiích, ve kterých se lidé přejídají, lidé netloustnou přesně tolik, kolik byste čistě matematicky předpovídali [85, 86].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Opět je to důvod, proč někteří lidé tvrdí, že tedy na kaloriích nezáleží.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A opět se mýlí.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Stejně jako v případě, kdy lidé hubnou, má tělo tendence bránit se proti tloustnutí.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Někteří lidé přiberou skoro přesně tolik, kolik byste předpokládali. Pokud během týdne mají o 3500 kalorií navíc, tak přiberou půl kila [87-89]. To je ale zklamání, co?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Na druhou stranu jsou také lidé, u kterých to tak není. V některých případech přiberou jen málo, přestože se masivně přejídají [90].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Existují více důvodů k tomu, proč tomu tak může být:
                        </Fragment>
                    } />

                    <PageNumberedList content={
                        <Fragment>
                            <li>Lidé se podvědomně více hýbají, aby spálili přebytečné kalorie. Někteří lidé totiž podvědomně zvýší počet kalorií, který spalují. Začnou více a déle stát, méně sedět, více se hýbou a jsou celkově neposední a hyperaktivní. Tyto malé pohyby mohou v některých případech významně zvýšit počet kalorií spálených během dne [91, 92]. V extrémních případech mohou spálit někteří lidé i 1000 extra kalorií za den a díky tomu přibrat jen málo [90]. Něco málo stále přiberou, ale ne tolik, kolik byste čekali.</li>
                            <li>Lidé tloustnou, což je nutí hýbat větší masou těla &#8211; a to spaluje více kalorií. Pokud ztloustnete, musíte hýbat větším tělem. To logicky spaluje více kalorií, což může v některých případech dorovnat přebytečné kalorie, které sníte [93].</li>
                            <li>Pokud sníte více kalorií, tak spálíte více kalorií během trávení jídla. Většina lidí spálí trávením zhruba 10% jejich kalorického denního příjmu [94]. Pokud za den sníte 1000 extra kalorií, tak spálíte 100 kalorií jenom trávením.</li>
                            <li>Lidé nemají představu, kolik toho vlastně jedí.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            V nekontrolovaných podmínkách často hubení lidé tvrdí to, že &#8220;toho snědí tunu,&#8221; ale zpravidla to tak není. Hubení a podvyživení lidé mají často tendence výrazně nadhodnocovat množství jídla, které zkonzumují &#8211; obdobně jako lidé s nadváhou, kteří mají naopak tendence jejich kalorický příjem podhodnocovat.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Fakt, že ne vždy lidé přibírají lineárně ve vztahu k tomu, kolik toho zkonzumují, v žádném případě neprokazuje, že na kaloriích nezáleží. Jenom dokazuje to, že vaše kalorické výdaje se mění spolu s vaší váhou.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #5: Váš metabolismus se zpomalí, když budete konzumovat méně kalorií, takže nemá cenu je snižovat"/>

                    <PageParagraph content={
                        <Fragment>
                            Pokud by něco takového platilo, tak by nebylo možné hladovět.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pokud ztratíte hmotnost, tak také budete spalovat méně kalorií. Tohle platí pro každou dietu a pro všechny lidi na světě [82, 84, 95, 96]. Výjimkou jsou naprosto extrémní okolnosti, které ale prakticky nikdy nejsou dostatečné k tomu, aby bylo hubnutí zcela nemožné.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Ve většině případů je při hubnutí úprava metabolismu relativně malá a má jenom velice malý dopad na to, jak rychle hubnete [82].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Když byli obézní lidé donuceni k tomu, aby hodiny denně cvičili a aby zredukovali jejich kalorický příjem o 30 %, tak ztratili 61 kilogramů během 30 týdnů. Jejich klidové hodnoty bazálního metabolismu klesly pouze o 504 kalorií denně oproti normálnímu očekávanému poklesu [97].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Ve slavné studii <em>Minnesota Starvation Study</em> byli lidé donuceni k tomu, zredukovat jejich denní příjem kalorií o 50 % a během každého týdne ujít 22 mil (35 kilometrů). Po šesti měsících účastníci studie ztratili 25 % z jejich celkové původní hmotnosti. Jejich bazální metabolismus poklesnul pouze o 225 kalorií za den. V tomto případě se ale nejednalo o obézní lidi, ale o lidi, kteří byli na začátku studie štíhlí a v dobré fyzické kondici [98].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Také neexistuje žádný důkaz toho, že by snížení vašeho bazálního metabolismu během hubnutí způsobovalo přibývání na váze. Nikdy &#8211; ani jednou jedinkrát &#8211; se to během 100 let různých výživových studií nestalo. Pokud by tomu tak bylo, tak bychom o tom už dávno věděli.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Váš bazální metabolismus se během hubnutí sníží jenom nepatrně, ale to je zkrátka cena, kterou platíte za redukci vaší hmotnosti. Stává se to všem a je to něco, u čehož lze vliv správným chováním zminimalizovat. Na kaloriích stále záleží.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #6: Hubnutí je příliš komplexní na to, aby se dalo zvládnout něčím tak jednoduchým jako dietou a cvičením"/>

                    <PageParagraph content={
                        <Fragment>
                            Existují stovky faktorů, které mají vliv na kompozici vaší hmotnosti a vašeho celého těla.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Váš cíl, různé volby jídel, citlivost na různé složky jídel, intenzita cvičení, bazální metabolismus, sebekontrola, schopnost odhadnout celkový příjem jídla, úrovně hormonů a jejich citlivosti a stovky dalších proměnných. Všechno výše vyjmenované má vliv na vaši schopnost zhubnout.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Hubnutí je neuvěřitelně komplexní proces a je pravděpodobně nemožné definovat všechny procesy, které ovlivňují vaši schopnost zhubnout nebo ztloustnout.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Dobrá zpráva je ta, že to vůbec nemusíte řešit.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pokud jsou lidé v kalorickém deficitu, tak ztrácí hmotnost bez toho, aniž by se museli zabývat ostatními faktory. Jedí méně jídla, více se hýbají a hubnou [1-4].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nemůžete mít pod kontrolou vše, co má vliv na vaši schopnost zhubnout. Proto se musíte zaměřovat pouze na to, nad čím kontrolu máte. A to je váš příjem kalorií a celkový pohyb.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Fakt, že je váš kalorický příjem a výdej ovlivněný mnoha různými faktory, nemění nic na tom, že na kaloriích záleží a že nezhubnete, pokud nebudete v kalorickém deficitu.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #7: Kdy a jak často jíte je důležitější, než množství jídla"/>

                    <PageParagraph content={
                        <Fragment>
                            Přesné plánování jídel podle časového harmonogramu je čím dál tím populárnější a je to k smíchu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Existují názory, podle kterých je důležitější jíst podle určitého časového rozvrhu, než to, kolik toho celkově sníte:
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Mýlka #1</strong>: Pokud budete jíst 5 až 6 malých jídel denně, tak budete mít hlad pod kontrolou a zrychlíte si váš metabolismus.
                            <PageBreak />
                            <strong>Fakt</strong>: Neexistuje naprosto žádný důkaz o tom, že by častější jezení zrychlovalo váš metabolismus nebo vám pomáhalo zhubnout [99]. Zároveň je také jenom velice málo důkazů o tom, že by častější jezení pomáhalo kontrolovat váš apetit lépe, než normální frekvence okolo tří jídel denně [100-102].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Mýlka #2</strong>: Přerušovaný půst vám pomůže zhubnout více tuků a méně svalů. Také vám pomůže nabrat méně tuků a naopak více svalů v případě, kdy se přejíte.
                            <PageBreak />
                            <strong>Fakt</strong>: Je jenom velmi málo důkazů o tom, že by přerušovaný půst bylo něco jiného, než způsob, jakým si lidé zkoušejí vytvářet svou dietní strukturu [103].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Mýlka #3</strong>: Snídaně vám pomáhá hubnout rychleji a nastartovat metabolismus.
                            <PageBreak />
                            <strong>Fakt</strong>: Neexistuje žádný důkaz o tom, že by lidé, kteří snídají, hubnuli rychleji než lidé, kteří nesnídají za předpokladu, že obě skupiny lidí konzumují stejný počet kalorií za den.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Mýlka #4</strong>: Konzumace více kalorií nebo sacharidů před spaním vám pomůže výrazněji zhubnout.
                            <PageBreak />
                            <strong>Fakt</strong>: Přestože existují některé nekontrolované výzkumy [104, 105], tak neexistuje žádný <em>kontrolovaný</em> výzkum, který by toto tvrzení mohl potvrdit za předpokladu, že obě skupiny lidí konzumují stejný počet kalorií.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Mýlka #5</strong>: Nabydete více svalové hmoty a ztratíte více tuku, pokud budete jíst 30 minut po cvičení.
                            <PageBreak />
                            <strong>Fakt</strong>: Naprostá většina výzkumů ukazuje, že jezení krátce po tréningu nemá žádný vliv na růst svalů nebo spalování tuků [106, 107].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Na shrnutí je tedy třeba dodat, že frekvence jezení jídel nemá žádný vliv na ztrátu hmotnosti nebo rychlost spalování kalorií. Evidentně je špatný nápad týden hladovět a poté se týden přejídat, ale pokud máte alespoň trochu rozumný přístup ke stravování, tak vůbec nezáleží na tom, kolikrát denně jíte.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pokud zkonzumujete stejný počet kalorií, tak ztratíte nebo přiberete úplně stejně bez ohledu na to, zda jíte desetkrát denně a nebo pouze jednou.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #8: Hormony ovlivňují hmotnost těla, udržování správné hladiny hormonů je tedy důležitější, než kalorie"/>

                    <PageParagraph content={
                        <Fragment>
                            Hormony jako leptin, kortizol, hormony štítné žlázy, inzulín a testosteron mají všechny vliv na to, kolik kalorií za den spálíte a kolik tuku a svalů ztratíte během restriktivní diety [108-111]. Také mají kontrolu nad tím, kolik tuku a svalů přiberete, pokud se budete přejídat.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Někteří lidé tvrdí, že je toto důvod k tomu, proč byste se neměli starat o kalorický příjem, ale měli byste udržovat a &#8220;optimalizovat&#8221; hladinu vašich hormonů. Poté zpravidla doporučí něco z následujícího:
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Speciální dietu navrženou ke stimulaci produkce nějakého hormonu.</li>
                            <li>Vyhýbání se některým jídlům nebo makronutrientům, protože zvyšují produkci estrogenu nebo snižují produkci testosteronu, popřípadě ovlivňují nějaký jiný hormon.</li>
                            <li>A samozřejmě, doplňky stravy.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Samozřejmě na hormonech záleží, ale neexistuje prakticky žádný důkaz o tom, že je můžete regulovat způsobem, aby vám &#8220;optimalizované&#8221; hormony pomohly zhubnout bez toho, aniž byste byli v kalorickém deficitu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pokud snížíte váš denní kalorický příjem, tak hladiny hormonů jako leptin a hormonů štítné žlázy poklesnou, což způsobí, že budete spalovat o něco méně kalorií [112, 113].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nicméně, tyto změny jsou zpravidla velice malé a nedochází k nim, dokud nejste v kalorickém deficitu. Neexistuje zároveň žádný důkaz o tom, že člověk, který konzumuje stále stejný počet kalorií, zhubne více, pokud si &#8220;optimalizuje&#8221; své hormony.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Masivní redukce kalorií, přílišné cvičení, spánková deprivace, stresující životní styl a další nezdravé chování mohou zhoršovat vaši schopnost zhubnout. To ale nemění nic na faktu, že abyste zhubli, tak musíte být v kalorickém deficitu.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #9: Se správnou kombinací výživových doplňků není třeba jíst méně kalorií, abyste zhubli"/>

                    <PageParagraph content={
                        <Fragment>
                            Většina doplňků stravy na hubnutí jsou naprosto k ničemu a žádný z nich vám nepomůže zhubnout, pokud nebudete v kalorickém deficitu. A i ty doplňky, které fungují, vám pomohou zhubnout jenom nevýrazně rychleji.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            U doplňků stravy jako CLA (konjugovaná kyselina linolová) nebyl nikdy u lidí prokázán vliv na schopnost hubnout rychleji [114, 115]. Současná data prokazují, že takové doplňky stravy jsou zpravidla jenom vyhozené peníze.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            I ty nejefektivnější doplňky stravy na hubnutí jako efedriny a kofein mají pouze malý celkový vliv na hubnutí. V těch nejlepších případech vám mohou pomoci zhubnout přibližně 100-200 extra kalorií za den a jejich efekt se postupem času vytrácí [116-118].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jediné doplňky stravy, které vám mohou pomoci zhubnout, vám pomáhají zhubnout tím, že díky ním méně jíte a více se hýbete, což není nic jiného, než další důkaz toho, že na kaloriích záleží.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #10: Kvůli snižování kalorií budete mít neustále hlad; takže není dlouhodobě efektivní"/>

                    <PageParagraph content={
                        <Fragment>
                            Obecně platí, že pokud budete jíst méně, tak budete mít větší chuť na jídlo.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Na druhou stranu &#8211; pokud zvolíte jídla, která vás zaplní, tak nemusíte mít během hubnutí nutně hlad. Typicky platí například to, že pokud lidé začnou jíst více proteinů, tak zpravidla přes den zkonzumují o několik stovek kalorií méně bez toho, aniž by si toho vůbec všimli [24, 27, 28, 119-121]. To samé se zpravidla typicky děje v případech, kdy lidé konzumují více ovoce, zeleniny nebo vlákniny [122].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            V některých případech lidé pociťují hlad přestože konzumují více jídla, které zpravidla zaplní žaludek. Bohužel, někdy se s tím prostě musíte smířit. Neexistuje žádný zákon, který by nařizoval, že během redukční diety nesmíte mít pocit hladu. Obecně platí to, že postupem času si vaše tělo na vaši novou hmotnost zvykne a pocit hladu vymizí.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Další věc, na kterou je třeba pamatovat, je to, že denní příjem jídla je ovlivněn velkým množstvím věcí, které nejsou přímo spojené s pocitem hladu.
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Jíme více, pokud jídlo dobře chutná.</li>
                            <li>Jíme více, pokud jíme z větších talířů.</li>
                            <li>Jíme více, když si můžeme zvolit z více jídel.</li>
                            <li>Jíme více chutných jídel, než těch s nevýraznou chutí.</li>
                            <li>Jíme více, pokud je talíř široký a naopak jíme méně z těch vysokých.</li>
                            <li>Jíme více, pokud se nudíme.</li>
                            <li>Jíme více, pokud máme jídlo přímo před nosem.</li>
                            <li>Jíme více, pokud se lidé okolo nás přejídají.</li>
                            <li>Jíme více, protože jíme příliš rychle. </li>
                            <li>Jíme více, protože ztratíme přehled nad naším kalorickým příjmem.</li>
                            <li>Jíme více, protože jsme rozptýlení.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Možná překvapivý fakt je ten, že cvičení pomáhá lidem lépe kontrolovat jejich příjem jídla, přestože cvičení také zároveň pomáhá tvořit kalorický deficit [123, 124].
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pokud není cílem vašeho hubnutí extrémně nízká hodnota tělesného tuku, tak je zpravidla kontrola nad pocitem hladu při využití správných strategií snadná.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Toto je shrnutí pro vás:
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            To, že jíte méně kalorií, nemusí vždy znamenat, že budete mít hlad.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jenom kvůli tomu, že konzumace menšího množství kalorií u vás někdy vyvolává pocity hladu, neznamená, že můžete zhubnout bez toho, aniž byste denní příjem kalorií nezredukovali.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Mýtus #11: &#8220;Nezhubnul jsem a přitom jsem jedl méně a více cvičil!&#8221;"/>

                    <PageParagraph content={
                        <Fragment>
                            Tomu se říká <em>anekdota</em>.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Většinou to zní takto:
                        </Fragment>
                    } />

                    <PageBlockQuote quote="Nezhubnul jsem, i když jsem jedl méně a cvičil více; takže na mně to nefunguje. Místo toho jsem zkusil low-carb [nebo nějakou jinou dietu] a zhubnul jsem 10 kilo. Tak vidíš!"/>

                    <PageParagraph content={
                        <Fragment>
                            Tady jsou 4 důvody, proč jsou takové argumenty irelevantní:
                        </Fragment>
                    } />

                    <PageNumberedList content={
                        <Fragment>
                            <li>Většina lidí je velmi nepřesná ve správném odhadování jejich kalorického příjmu a výdajů. Neexistuje tak žádný pádný důvod k tomu, abychom věřili, že tomu tak není i v tomto případě.</li>
                            <li>Nelze ověřit, kolik kalorií tito lidé skutečně zkonzumovali a spálili předtím a poté, co zhubnuli, takže jejich tvrzení musíme věřit. Přečtěte si opět bod 1. k tomu, abyste zjistili, proč je něco takového špatný nápad.</li>
                            <li>Ať už tato osoba zvolila jakoukoliv dietu, tak s tou dietou začali kvůli tomu, protože věřili, že jim pomůže zhubnout. Chtěli, aby jejich dieta fungovala, takže byli ochotnější přehlédnout cokoliv, co by mohlo poukázat na to, že jejich dieta nefunguje (říká se tomu <em>konfirmační zkreslení</em>). A podobně &#8212; pokud začali počítání kalorií s tím, že to nefunguje, tak existovala větší pravděpodobnost, že je nebudou počítat správně a nebo že dokonce cíleně selžou.</li>
                            <li>I kdyby tito lidé provedli &#8220;kontrolovanou&#8221; studii sami na sobě, tak by to stále znamenalo, že znají důležité detaily studie, díky čemuž jsou jejich výsledky nevyhnutelně zaujaté.</li>
                    </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Poté, co tito lidé slyší protiargumenty zmíněné výše, tak většinou reagují takto:
                        </Fragment>
                    } />

                    <PageBlockQuote quote="Neprovedl jsi na mě žádnou studii. Nevíš tedy, jestli to, co říkáš, se na mě dá vůbec aplikovat."/>

                    <PageParagraph content={
                        <Fragment>
                            Odpověď: Jaký máš důkaz, který by dokázal, že jsi jiný, než ostatní?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Šance jsou takové, že výjimečný nejste. Během takřka 100 let výzkumů zaměřených na hubnutí jsme zatím nebyli schopní najít jednoho jediného člověka, který by při kalorickém deficitu nezhubnul.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            S množstvím výzkumů, které v současné době máme, je docela dost velká pravděpodobnost, že nějaká studie na někom velmi podobném vám už dávno proběhla.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Fakt: Na kaloriích záleží"/>

                    <PageParagraph content={
                        <Fragment>
                            Každá kontrolovaná studie, která proběhla v posledních 100 letech potvrdila, že lidé nehubnou, pokud nejsou v kalorickém deficitu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            To neznamená, že jste slaboši nebo jste selhali, pokud se vám nedaří zhubnout. Znamená to jenom to, že jste se zaměřovali na špatné cíle. A to je naprosto normální &#8211; to se stane jednou za čas úplně každému člověku. Zjednodušte si cíle; vytvořte si kalorický deficit.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Uslyšíte hromadu důvodů, proč to není pravda, ale všechny argumenty jsou snadno vyvratitelné, pokud se podíváte na opravdové, kontrolované studie. To neznamená, že kalorie jsou jediná věc, na kterou byste měli myslet. Pokud ale chcete zhubnout, tak musíte zkrátka konzumovat méně kalorií, než kolik jich spálíte. Tak to platí bez výjimky.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Použité zdroje"/>

                    <PageParagraph content={
                        <Fragment>
                            1. Buchholz AC, Schoeller DA. Is a calorie a calorie? Am J Clin Nutr. 2004;79(5):899S–906S. Available at: https://eutils.ncbi.nlm.nih.gov/entrez/eutils/elink.fcgi?dbfrom=pubmed&amp;id=15113737&amp;retmode=ref&amp;cmd=prlinks.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            2. Schoeller DA. The energy balance equation: looking back and looking forward are two very different views. Nutr Rev. 2009;67(5):249–254. doi:10.1111/j.1753-4887.2009.00197.x.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            3. Schoeller DA, Buchholz AC. Energetics of obesity and weight control: does diet composition matter? J Am Diet Assoc. 2005;105(5 Suppl 1):S24–8. doi:10.1016/j.jada.2005.02.025.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            4. Westerterp KR. Physical activity, food intake, and body weight regulation: insights from doubly labeled water studies. Nutr Rev. 2010;68(3):148–154. doi:10.1111/j.1753-4887.2010.00270.x.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            5. Surwit RS, Feinglos MN, McCaskill CC, et al. Metabolic and behavioral effects of a high-sucrose diet during weight loss. Am J Clin Nutr. 1997;65(4):908–915. Available at: https://ajcn.nutrition.org/content/65/4/908.full.pdf.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            6. Hengstler JG, Foth H, Gebel T, et al. Critical evaluation of key evidence on the human health hazards of exposure to bisphenol A. Crit Rev Toxicol. 2011;41(4):263–291. doi:10.3109/10408444.2011.558487.&lt; 7. Lustig RH, Schmidt LA, Brindis CD. Public health: The toxic truth about sugar. Nature. 2012;482(7383):27–29. doi:10.1038/482027a.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            8. Lustig RH. Fructose: it’s “alcohol without the buzz”. Adv Nutr. 2013;4(2):226–235. doi:10.3945/an.112.002998.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            9. Bizzaro N, Tozzoli R, Villalta D, Fabris M, Tonutti E. Cutting-edge issues in celiac disease and in gluten intolerance. Clin Rev Allergy Immunol. 2012;42(3):279–287. doi:10.1007/s12016-010-8223-1.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            10. White JS. Straight talk about high-fructose corn syrup: what it is and what it ain’t. Am J Clin Nutr. 2008;88(6):1716S–1721S. doi:10.3945/ajcn.2008.25825B.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            11. Louie JCY, Flood VM, Hector DJ, Rangan AM, Gill TP. Dairy consumption and overweight and obesity: a systematic review of prospective cohort studies. Obes Rev. 2011;12(7):e582–92. doi:10.1111/j.1467-789X.2011.00881.x.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            12. Rebello CJ, Liu AG, Greenway FL, Dhurandhar NV. Dietary strategies to increase satiety. Adv Food Nutr Res. 2013;69:105–182. doi:10.1016/B978-0-12-410540-9.00003-X.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            13. Slavin JL. Position of the American Dietetic Association: health implications of dietary fiber. J Am Diet Assoc. 2008;108(10):1716–1731.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            14. Aune D, Chan DSM, Vieira AR, et al. Fruits, vegetables and breast cancer risk: a systematic review and meta-analysis of prospective studies. Breast Cancer Res Treat. 2012;134(2):479–493. doi:10.1007/s10549-012-2118-1.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            15. Slavin JL, Lloyd B. Health benefits of fruits and vegetables. Adv Nutr. 2012;3(4):506–516. doi:10.3945/an.112.002154.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            16. Freedman MR, King J, Kennedy E. Popular diets: a scientific review. Obes Res. 2001;9 Suppl 1:1S–40S. doi:10.1038/oby.2001.113.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            17. Hu T, Mills KT, Yao L, et al. Effects of low-carbohydrate diets versus low-fat diets on metabolic risk factors: a meta-analysis of randomized controlled clinical trials. Am J Epidemiol. 2012;176 Suppl 7:S44–54. doi:10.1093/aje/kws264.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            18. Bradley U, Spence M, Courtney CH, et al. Low-fat versus low-carbohydrate weight reduction diets: effects on weight loss, insulin resistance, and cardiovascular risk: a randomized control trial. Diabetes. 2009;58(12):2741–2748. doi:10.2337/db09-0098.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            19. Soenen S, Bonomi AG, Lemmens SGT, et al. Relatively high-protein or “low-carb” energy-restricted diets for body weight loss and body weight maintenance? Physiol Behav. 2012;107(3):374–380. doi:10.1016/j.physbeh.2012.08.004.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            20. Layman DK, Boileau RA, Erickson DJ, et al. A reduced ratio of dietary carbohydrate to protein improves body composition and blood lipid profiles during weight loss in adult women. J Nutr. 2003;133(2):411–417. Available at: https://jn.nutrition.org/content/133/2/411.full.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            21. Layman DK, Evans E, Baum JI, Seyler J, Erickson DJ, Boileau RA. Dietary protein and exercise have additive effects on body composition during weight loss in adult women. J Nutr. 2005;135(8):1903–1910. Available at: https://jn.nutrition.org/content/135/8/1903.long.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            22. Leidy HJ, Carnell NS, Mattes RD, Campbell WW. Higher protein intake preserves lean mass and satiety with weight loss in pre-obese and obese women. Obesity (Silver Spring). 2007;15(2):421–429. doi:10.1038/oby.2007.531.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            23. Layman DK. Protein quantity and quality at levels above the RDA improves adult weight loss. J Am Coll Nutr. 2004;23(6 Suppl):631S–636S. Available at: https://pmid.us/15640518.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            24. Wycherley TP, Moran LJ, Clifton PM, Noakes M, Brinkworth GD. Effects of energy-restricted high-protein, low-fat compared with standard-protein, low-fat diets: a meta-analysis of randomized controlled trials. Am J Clin Nutr. 2012;96(6):1281–1298. doi:10.3945/ajcn.112.044321.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            25. Wycherley TP, Noakes M, Clifton PM, Cleanthous X, Keogh JB, Brinkworth GD. A high-protein diet with resistance exercise training improves weight loss and body composition in overweight and obese patients with type 2 diabetes. Diabetes Care. 2010;33(5):969–976. doi:10.2337/dc09-1974.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            26. Kerksick C, Thomas A, Campbell B, et al. Effects of a popular exercise and weight loss program on weight loss, body composition, energy expenditure and health in obese women. Nutr Metab (Lond). 2009;6:23. doi:10.1186/1743-7075-6-23.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            27. Westerterp-Plantenga MS, Lemmens SG, Westerterp KR. Dietary protein – its role in satiety, energetics, weight loss and health. Br J Nutr. 2012;108 Suppl 2:S105–12. doi:10.1017/S0007114512002589.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            28. Paddon-Jones D, Westman E, Mattes RD, Wolfe RR, Astrup A, Westerterp-Plantenga M. Protein, weight management, and satiety. Am J Clin Nutr. 2008;87(5):1558S–1561S. Available at: https://ajcn.nutrition.org/content/87/5/1558S.long.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            29. Demling RH, DeSanti L. Effect of a hypocaloric diet, increased protein intake and resistance training on lean mass gains and fat mass loss in overweight police officers. Ann Nutr Metab. 2000;44(1):21–29.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            30. Helms ER, Zinn C, Rowlands DS, Brown SR. A Systematic Review of Dietary Protein During Caloric Restriction in Resistance Trained Lean Athletes: A Case for Higher Intakes. Int J Sport Nutr Exerc Metab. 2013.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            31. Hall KD, Sacks G, Chandramohan D, et al. Quantification of the effect of energy imbalance on bodyweight. Lancet. 2011;378(9793):826–837. doi:10.1016/S0140-6736(11)60812-X.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            32. Hall KD. What is the required energy deficit per unit weight loss? International Journal of Obesity (2005). 2008;32(3):573–576. doi:10.1038/sj.ijo.0803720.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            33. Yanetz R, Kipnis V, Carroll RJ, et al. Using biomarker data to adjust estimates of the distribution of usual intakes for misreporting: application to energy intake in the US population. J Am Diet Assoc. 2008;108(3):455–64– discussion 464. doi:10.1016/j.jada.2007.12.004.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            34. Millen AE, Tooze JA, Subar AF, Kahle LL, Schatzkin A, Krebs-Smith SM. Differences between food group reports of low-energy reporters and non-low-energy reporters on a food frequency questionnaire. J Am Diet Assoc. 2009;109(7):1194–1203. doi:10.1016/j.jada.2009.04.004.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            35. Tooze JA, Vitolins MZ, Smith SL, et al. High levels of low energy reporting on 24-hour recalls and three questionnaires in an elderly low-socioeconomic status population. J Nutr. 2007;137(5):1286–1293. Available at: https://jn.nutrition.org/content/137/5/1286.long.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            36. Lichtman SW, Pisarska K, Berman ER, et al. Discrepancy between self-reported and actual caloric intake and exercise in obese subjects. N Engl J Med. 1992;327(27):1893–1898. doi:10.1056/NEJM199212313272701.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            37. Price GM, Paul AA, Cole TJ, Wadsworth ME. Characteristics of the low-energy reporters in a longitudinal national dietary survey. Br J Nutr. 1997;77(6):833–851.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            38. Pryer JA, Vrijheid M, Nichols R, Kiggins M, Elliott P. Who are the “low energy reporters” in the dietary and nutritional survey of British adults? Int J Epidemiol. 1997;26(1):146–154.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            39. Brehm BJ, Spang SE, Lattin BL, Seeley RJ, Daniels SR, D’Alessio DA. The role of energy expenditure in the differential weight loss in obese women on low-fat and low-carbohydrate diets. J Clin Endocrinol Metab. 2005;90(3):1475–1482. doi:10.1210/jc.2004-1540.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            40. Burrows TL, Martin RJ, Collins CE. A systematic review of the validity of dietary assessment methods in children when compared with the method of doubly labeled water. J Am Diet Assoc. 2010;110(10):1501–1510. doi:10.1016/j.jada.2010.07.008.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            41. Cook A, Pryer J, Shetty P. The problem of accuracy in dietary surveys. Analysis of the over 65 UK National Diet and Nutrition Survey. J Epidemiol Community Health. 2000;54(8):611–616.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            42. Maurer J, Taren DL, Teixeira PJ, et al. The psychosocial and behavioral characteristics related to energy misreporting. Nutr Rev. 2006;64(2 Pt 1):53–66.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            43. Rennie MJ, Bohe J, Smith K, Wackerhage H, Greenhaff P. Branched-chain amino acids as fuels and anabolic signals in human muscle. J Nutr. 2006;136(1 Suppl):264S–8S. Available at: https://pmid.us/16365095.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            44. Johansson L, Solvoll K, Bjorneboe GE, Drevon CA. Under- and overreporting of energy intake related to weight status and lifestyle in a nationwide sample. Am J Clin Nutr. 1998;68(2):266–274.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            45. Poslusna K, Ruprich J, de Vries JHM, Jakubikova M, van’t Veer P. Misreporting of energy and micronutrient intake estimated by food records and 24 hour recalls, control and adjustment methods in practice. Br J Nutr. 2009;101 Suppl 2:S73–85. doi:10.1017/S0007114509990602.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            46. Livingstone MBE, Black AE. Markers of the validity of reported energy intake. J Nutr. 2003;133 Suppl 3:895S–920S.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            47. Pietilaninen KH, Korkeila M, Bogl LH, et al. Inaccuracies in food and physical activity diaries of obese subjects: complementary evidence from doubly labeled water and co-twin assessments. International Journal of Obesity (2010). 2010;34:37–445.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            48. Ferrari P, Slimani N, Ciampi A, et al. Evaluation of under- and overreporting of energy intake in the 24-hour diet recalls in the European Prospective Investigation into Cancer and Nutrition (EPIC). Public Health Nutr. 2002;5(6B):1329–1345. doi:10.1079/PHN2002409.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            49. Azizi F, Esmaillzadeh A, Mirmiran P. Correlates of under- and over-reporting of energy intake in Tehranians: body mass index and lifestyle-related factors. Asia Pac J Clin Nutr. 2005;14(1):54–59.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            50. Buhl KM, Gallagher D, Hoy K, Matthews DE, Heymsfield SB. Unexplained disturbance in body weight regulation: diagnostic outcome assessed by doubly labeled water and body composition analyses in obese patients reporting low energy intakes. J Am Diet Assoc. 1995;95(12):1393–400– quiz 1401–2. doi:10.1016/S0002-8223(95)00367-3.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            51. Samaras K, Kelly PJ, Campbell LV. Dietary underreporting is prevalent in middle-aged British women and is not related to adiposity (percentage body fat). International Journal of Obesity (2005). 1999;23(8):881–888.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            52. Lafay L, Mennen L, Basdevant A, et al. Does energy intake underreporting involve all kinds of food or only specific food items? Results from the Fleurbaix Laventie Ville Sante (FLVS) study. International Journal of Obesity (2005). 2000;24(11):1500–1506.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            53. Lafay L, Basdevant A, Charles MA, et al. Determinants and nature of dietary underreporting in a free-living population: the Fleurbaix Laventie Ville Sante (FLVS) Study. International Journal of Obesity (2005). 1997;21(7):567–573.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            54. Garriguet D. Under-reporting of energy intake in the Canadian Community Health Survey. Health Rep. 2008;19(4):37–45.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            55. Shahar DR, Yu B, Houston DK, et al. Misreporting of energy intake in the elderly using doubly labeled water to measure total energy expenditure and weight change. J Am Coll Nutr. 2010;29(1):14–24.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            56. Krebs-Smith SM, Graubard BI, Kahle LL, Subar AF, Cleveland LE, Ballard-Barbash R. Low energy reporters vs others: a comparison of reported food intakes. Eur J Clin Nutr. 2000;54(4):281–287.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            57. Bratteby LE, Sandhagen B, Fan H, Enghardt H, Samuelson G. Total energy expenditure and physical activity as assessed by the doubly labeled water method in Swedish adolescents in whom energy intake was underestimated by 7-d diet records. Am J Clin Nutr. 1998;67(5):905–911.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            58. Tooze JA, Subar AF, Thompson FE, Troiano R, Schatzkin A, Kipnis V. Psychosocial predictors of energy underreporting in a large doubly labeled water study. Am J Clin Nutr. 2004;79(5):795–804.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            59. Rennie KL, Siervo M, Jebb SA. Can self-reported dieting and dietary restraint identify underreporters of energy intake in dietary surveys? J Am Diet Assoc. 2006;106(10):1667–1672. doi:10.1016/j.jada.2006.07.014.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            60. Macdiarmid J, Blundell J. Assessing dietary intake: Who, what and why of under-reporting. Nutr Res Rev. 1998;11(2):231–253. doi:10.1079/NRR19980017.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            61. Bathalon GP, Tucker KL, Hays NP, et al. Psychological measures of eating behavior and the accuracy of 3 common dietary assessment methods in healthy postmenopausal women. Am J Clin Nutr. 2000;71(3):739–745.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            62. Ventura AK, Loken E, Mitchell DC, Smiciklas-Wright H, Birch LL. Understanding reporting bias in the dietary recall data of 11-year-old girls. Obesity (Silver Spring). 2006;14(6):1073–1084. doi:10.1038/oby.2006.123.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            63. Champagne CM, Bray GA, Kurtz AA, et al. Energy intake and energy expenditure: a controlled study comparing dietitians and non-dietitians. J Am Diet Assoc. 2002;102(10):1428–1432.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            64. Bedard D, Shatenstein B, Nadon S. Underreporting of energy intake from a self-administered food-frequency questionnaire completed by adults in Montreal. Public Health Nutr. 2004;7(5):675–681.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            65. Hendrickson S, Mattes R. Financial incentive for diet recall accuracy does not affect reported energy intake or number of underreporters in a sample of overweight females. J Am Diet Assoc. 2007;107(1):118–121. doi:10.1016/j.jada.2006.10.003.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            66. Muhlheim LS, Allison DB, Heshka S, Heymsfield SB. Do unsuccessful dieters intentionally underreport food intake? Int J Eat Disord. 1998;24(3):259–266. doi:10.1002/(SICI)1098-108X(199811)24:3&lt;259::AID-EAT3&gt;3.0.CO;2-L.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            67. Black AE, Goldberg GR, Jebb SA, Livingstone MB, Cole TJ, Prentice AM. Critical evaluation of energy intake data using fundamental principles of energy physiology: 2. Evaluating the results of published surveys. Eur J Clin Nutr. 1991;45(12):583–599.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            68. Singh R, Martin BR, Hickey Y, et al. Comparison of self-reported, measured, metabolizable energy intake with total energy expenditure in overweight teens. Am J Clin Nutr. 2009;89(6):1744–1750. doi:10.3945/ajcn.2008.26752.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            69. Bingham SA, Day NE. Using biochemical markers to assess the validity of prospective dietary assessment methods and the effect of energy adjustment. Am J Clin Nutr. 1997;65(4 Suppl):1130S–1137S.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            70. Black AE, Bingham SA, Johansson G, Coward WA. Validation of dietary intakes of protein and energy against 24 hour urinary N and DLW energy expenditure in middle-aged women, retired men and post-obese subjects: comparisons with validation against presumed energy requirements. Eur J Clin Nutr. 1997;51(6):405–413.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            71. Novotny JA, Rumpler WV, Riddick H, et al. Personality characteristics as predictors of underreporting of energy intake on 24-hour dietary recall interviews. J Am Diet Assoc. 2003;103(9):1146–1151.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            72. Heerstrass DW, Ocke MC, Bueno-de-Mesquita HB, Peeters PH, Seidell JC. Underreporting of energy, protein and potassium intake in relation to body mass index. Int J Epidemiol. 1998;27(2):186–193.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            73. Zhang J, Temme EH, Sasaki S, Kesteloot H. Under- and overreporting of energy intake using urinary cations as biomarkers: relation to body mass index. Am J Epidemiol. 2000;152(5):453–462.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            74. Scagliusi FB, Ferriolli E, Pfrimer K, et al. Underreporting of energy intake in Brazilian women varies according to dietary assessment: a cross-sectional study using doubly labeled water. J Am Diet Assoc. 2008;108(12):2031–2040. doi:10.1016/j.jada.2008.09.012.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            75. Heitmann BL. The influence of fatness, weight change, slimming history and other lifestyle variables on diet reporting in Danish men and women aged 35-65 years. International Journal of Obesity (2005). 1993;17(6):329–336.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            76. Scagliusi FB, Polacow VO, Artioli GG, Benatti FB, Lancha AHJ. Selective underreporting of energy intake in women: magnitude, determinants, and effect of training. J Am Diet Assoc. 2003;103(10):1306–1313.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            77. Heitmann BL, Lissner L. Dietary underreporting by obese individuals–is it specific or non-specific? BMJ. 1995;311(7011):986–989. doi:10.1136/bmj.311.7011.986.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            78. Hebert JR, Peterson KE, Hurley TG, et al. The effect of social desirability trait on self-reported dietary measures among multi-ethnic female health center employees. Ann Epidemiol. 2001;11(6):417–427.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            79. Johnson RK, Soultanakis RP, Matthews DE. Literacy and body fatness are associated with underreporting of energy intake in US low-income women using the multiple-pass 24-hour recall: a doubly labeled water study. J Am Diet Assoc. 1998;98(10):1136–1140. doi:10.1016/S0002-8223(98)00263-6.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            80. Taren DL, Tobar M, Hill A, et al. The association of energy intake bias with psychological scores of women. Eur J Clin Nutr. 1999;53(7):570–578.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            81. Horner NK, Patterson RE, Neuhouser ML, Lampe JW, Beresford SA, Prentice RL. Participant characteristics associated with errors in self-reported energy intake from the Women’s Health Initiative food-frequency questionnaire. Am J Clin Nutr. 2002;76(4):766–773.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            82. Dulloo AG, Jacquet J, Montani J-P, Schutz Y. Adaptive thermogenesis in human body weight regulation: more of a concept than a measurable entity? Obes Rev. 2012;13 Suppl 2:105–121. doi:10.1111/j.1467-789X.2012.01041.x.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            83. Tremblay A, Royer M-M, Chaput J-P, Doucet E. Adaptive thermogenesis can make a difference in the ability of obese individuals to lose body weight. International Journal of Obesity (2005). 2012. doi:10.1038/ijo.2012.124.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            84. Muller MJ, Bosy-Westphal A. Adaptive thermogenesis with weight loss in humans. Obesity (Silver Spring). 2013;21(2):218–228. doi:10.1002/oby.20027.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            85. Joosen AMCP, Westerterp KR. Energy expenditure during overfeeding. Nutr Metab (Lond). 2006;3:25. doi:10.1186/1743-7075-3-25.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            86. Bray GA, Smith SR, de Jonge L, et al. Effect of dietary protein content on weight gain, energy expenditure, and body composition during overeating: a randomized controlled trial. JAMA. 2012;307(1):47–55. doi:10.1001/jama.2011.1918.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            87. Klein S, Goran M. Energy metabolism in response to overfeeding in young adult men. Metab Clin Exp. 1993;42(9):1201–1205.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            88. Diaz EO, Prentice AM, Goldberg GR, Murgatroyd PR, Coward WA. Metabolic response to experimental overfeeding in lean and overweight healthy volunteers. Am J Clin Nutr. 1992;56(4):641–655. Available at: https://ajcn.nutrition.org/content/56/4/641.long.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            89. McDevitt RM, Poppitt SD, Murgatroyd PR, Prentice AM. Macronutrient disposal during controlled overfeeding with glucose, fructose, sucrose, or fat in lean and obese women. Am J Clin Nutr. 2000;72(2):369–377. Available at: https://ajcn.nutrition.org/content/72/2/369.long.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            90. Levine JA, Eberhardt NL, Jensen MD. Role of nonexercise activity thermogenesis in resistance to fat gain in humans. Science. 1999;283(5399):212–214. Available at: https://www.sciencemag.org/content/283/5399/212.long.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            91. Levine JA, Schleusner SJ, Jensen MD. Energy expenditure of nonexercise activity. Am J Clin Nutr. 2000;72(6):1451–1454. Available at: https://ajcn.nutrition.org/content/72/6/1451.long.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            92. Levine JA. Nonexercise activity thermogenesis (NEAT): environment and biology. Am J Physiol Endocrinol Metab. 2004;286(5):E675–85. Available at: https://ajpendo.physiology.org/content/286/5/E675.long.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            93. Casazza K, Fontaine KR, Astrup A, et al. Myths, presumptions, and facts about obesity. N Engl J Med. 2013;368(5):446–454. doi:10.1056/NEJMsa1208051.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            94. Westerterp KR. Diet induced thermogenesis. Nutr Metab (Lond). 2004;1(1):5. doi:10.1186/1743-7075-1-5.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            95. Rosenbaum M, Leibel RL. Adaptive thermogenesis in humans. International Journal of Obesity (2005). 2010;34 Suppl 1:S47–55. doi:10.1038/ijo.2010.184.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            96. Major GC, Doucet E, Trayhurn P, Astrup A, Tremblay A. Clinical significance of adaptive thermogenesis. International Journal of Obesity (2005). 2006;31(2):204–212. doi:10.1038/sj.ijo.0803523.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            97. Johannsen DL, Knuth ND, Huizenga R, Rood JC, Ravussin E, Hall KD. Metabolic slowing with massive weight loss despite preservation of fat-free mass. J Clin Endocrinol Metab. 2012;97(7):2489–2496. doi:10.1210/jc.2012-1444.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            98. Kalm LM, Semba RD. They starved so that others be better fed: remembering Ancel Keys and the Minnesota experiment. J Nutr. 2005;135(6):1347–1352. Available at: https://jn.nutrition.org/content/135/6/1347.full.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            99. Bellisle F, McDevitt R, Prentice AM. Meal frequency and energy balance. Br J Nutr. 1997;77 Suppl 1:S57–70.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            100. Leidy HJ, Tang M, Armstrong CLH, Martin CB, Campbell WW. The effects of consuming frequent, higher protein meals on appetite and satiety during weight loss in overweight/obese men. Obesity (Silver Spring). 2011;19(4):818–824. doi:10.1038/oby.2010.203.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            101. Leidy HJ, Armstrong CLH, Tang M, Mattes RD, Campbell WW. The influence of higher protein intake and greater eating frequency on appetite control in overweight and obese men. Obesity (Silver Spring). 2010;18(9):1725–1732. doi:10.1038/oby.2010.45.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            102. Ohkawara K, Cornier M-A, Kohrt WM, Melanson EL. Effects of increased meal frequency on fat oxidation and perceived hunger. Obesity (Silver Spring). 2013;21(2):336–343. doi:10.1002/oby.20032.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            103. Brown AW, Bohan Brown MM, Allison DB. Belief beyond the evidence: using the proposed effect of breakfast on obesity to show 2 practices that distort scientific evidence. Am J Clin Nutr. 2013;98(5):1298–1308. doi:10.3945/ajcn.113.064410.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            104. Sofer S, Eliraz A, Kaplan S, et al. Greater weight loss and hormonal changes after 6 months diet with carbohydrates eaten mostly at dinner. Obesity (Silver Spring). 2011;19(10):2006–2014. doi:10.1038/oby.2011.48.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            105. Keim NL, Van Loan MD, Horn WF, Barbieri TF, Mayclin PL. Weight loss is greater with consumption of large morning meals and fat-free mass is preserved with large evening meals in women on a controlled weight reduction regimen. J Nutr. 1997;127(1):75–82.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            106. Aragon AA, Schoenfeld BJ. Nutrient timing revisited: is there a post-exercise anabolic window? J Int Soc Sports Nutr. 2013;10(1):5. doi:10.1186/1550-2783-10-5.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            107. Schoenfeld BJ, Aragon AA, Krieger JW. The effect of protein timing on muscle strength and hypertrophy: a meta-analysis. J Int Soc Sports Nutr. 2013;10(1):53. doi:10.1186/1550-2783-10-53.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            108. Klok MD, Jakobsdottir S, Drent ML. The role of leptin and ghrelin in the regulation of food intake and body weight in humans: a review. Obes Rev. 2007;8(1):21–34. doi:10.1111/j.1467-789X.2006.00270.x.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            109. Meier U, Gressner AM. Endocrine regulation of energy metabolism: review of pathobiochemical and clinical chemical aspects of leptin, ghrelin, adiponectin, and resistin. Clin Chem. 2004;50(9):1511–1525.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            110. Pearce EN. Thyroid hormone and obesity. Curr Opin Endocrinol Diabetes Obes. 2012;19(5):408–413.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            111. Strohacker K, McCaffery JM, Maclean PS, Wing RR. Adaptations of leptin, ghrelin or insulin during weight loss as predictors of weight regain: a review of current literature. International Journal of Obesity (2005). 2013. doi:10.1038/ijo.2013.118.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            112. Li M-D. Leptin and beyond: an odyssey to the central control of body weight. Yale J Biol Med. 2011;84(1):1–7. Available at: https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3064240/.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            113. Hukshorn CJ, Saris WHM. Leptin and energy expenditure. Curr Opin Clin Nutr Metab Care. 2004;7(6):629–633.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            114. Whigham LD, Watras AC, Schoeller DA. Efficacy of conjugated linoleic acid for reducing fat mass: a meta-analysis in humans. Am J Clin Nutr. 2007;85(5):1203–1211.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            115. Onakpoya IJ, Posadzki PP, Watson LK, Davies LA, Ernst E. The efficacy of long-term conjugated linoleic acid (CLA) supplementation on body composition in overweight and obese individuals: a systematic review and meta-analysis of randomized clinical trials. Eur J Nutr. 2012;51(2):127–134. doi:10.1007/s00394-011-0253-9.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            116. Hursel R, Viechtbauer W, Dulloo AG, et al. The effects of catechin rich teas and caffeine on energy expenditure and fat oxidation: a meta-analysis. Obes Rev. 2011;12(7):e573–81. doi:10.1111/j.1467-789X.2011.00862.x.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            117. Hursel R, Viechtbauer W, Westerterp-Plantenga MS. The effects of green tea on weight loss and weight maintenance: a meta-analysis. International Journal of Obesity (2005). 2009;33(9):956–961. doi:10.1038/ijo.2009.135.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            118. Phung OJ, Baker WL, Matthews LJ, Lanosa M, Thorne A, Coleman CI. Effect of green tea catechins with or without caffeine on anthropometric measures: a systematic review and meta-analysis. Am J Clin Nutr. 2010;91(1):73–81. doi:10.3945/ajcn.2009.28157.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            119. Westerterp-Plantenga MS, Nieuwenhuizen A, Tome D, Soenen S, Westerterp KR. Dietary protein, weight loss, and weight maintenance. Annu Rev Nutr. 2009;29:21–41. doi:10.1146/annurev-nutr-080508-141056.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            120. Halton TL, Hu FB. The effects of high protein diets on thermogenesis, satiety and weight loss: a critical review. J Am Coll Nutr. 2004;23(5):373–385. Available at: https://www.ysonut.fr/pdf/Ysodoc/C0302.pdf.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            121. Soenen S, Westerterp-Plantenga MS. Proteins and satiety: implications for weight management. Curr Opin Clin Nutr Metab Care. 2008;11(6):747–751.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            122. Champagne CM, Broyles ST, Moran LD, et al. Dietary intakes associated with successful weight loss and maintenance during the Weight Loss Maintenance trial. J Am Diet Assoc. 2011;111(12):1826–1835. doi:10.1016/j.jada.2011.09.014.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            123. King NA, Caudwell PP, Hopkins M, Stubbs JR, Naslund E, Blundell JE. Dual-process action of exercise on appetite control: increase in orexigenic drive but improvement in meal-induced satiety. Am J Clin Nutr. 2009;90(4):921–927. doi:10.3945/ajcn.2009.27706.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            124. King NA, Hopkins M, Caudwell P, Stubbs RJ, Blundell JE. Individual variability following 12 weeks of supervised exercise: identification and characterization of compensation for exercise-induced weight loss. International Journal of Obesity (2005). 2008;32(1):177–184. doi:10.1038/sj.ijo.0803712.
                        </Fragment>
                    } />
                    
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default NaKaloriichZalezi;