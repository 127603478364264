import React from 'react';
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageWrapper from "../../components/PageWrapper";

const Projects = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'My projects'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageParagraph content={
                        <Fragment>
                            I am interested in quite a few things.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Videogames"/>
                    <PageParagraph content={
                        <Fragment>
                            Videogames go here.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Music"/>
                    <PageParagraph content={
                        <Fragment>
                            Music goes here.
                        </Fragment>
                    } />
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
};

export default Projects;