import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import PageWrapper from "../../components/PageWrapper";

const IssendaiCCoVasDrzi = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Issendai – Co vás drží v nezdravém systému'}}/>
            
            <PageContent content={
                <Fragment>

                    <PageBlockQuote quote="http://www.issendai.com/psychology/sick-systems-qualities-that-keep-you-stuck.html" author="Originál v angličtině" />

                    <PageParagraph content={
                        <Fragment>
                            Při psaní článku <a href="http://web.dzejpi.cz/issendai-nezdrave-systemy/issendai-jak-nekoho-donutit-aby-s-vami-zustal-navzdy/">Jak někoho donutit, aby s vámi zůstal navždy</a>, byly předkládány situace, ve kterých se Issendai sama ocitla a nebo se v nich ocitli její přátelé, takže předpokládala, že nezdravé systémy jsou vzácné a hluboce patologické. Ale z komentářů a ostatních konverzací na internetu lze vidět, že skoro každý člověk někdy uvíznul s nezdravém systému a že nezdravé systémy jsou nedílnou součástí života v některých částech světa.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Něco je <i>špatně</i>.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Toto je část, ve které se ode mě očekává, že napíšu článek &#8220;<i>Co Lze Dělat, Abychom Spravili Celý Svět</i>,&#8221; ale odpověď je: nic. Nemůžete opravit nemocný systém zevnitř, pokud tedy neoplýváte nějakou speciální mocí a je nemožné opravit systém zvenku. Naprosto nemožné. Nemůžete ostatní donutit k tomu, aby odešli. Můžete je přesvědčovat k tomu, aby odešli, ale moment, který je donutí k tomu, aby odešli, je čistě individuální. Asi jako když mnicha náhle prozře, protože jeho mistr řekl něco inspirujícího. A pokud se člověk rozhodne z nemocného systému odejít, je to zpravidla mnohem, mnohem později, než to měl pro své dobro udělat. Místo toho vám nabízím tento list.     
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="Charakterové vlastnosti, které vás drží v nezdravém systému" />
                   
                    <PageBulletList content={
                        <Fragment>
                            <li>Věrnost</li>
                            <li>Trpělivost</li>
                            <li>Dobrá pracovní morálka</li>
                            <li>Optimismus</li>
                            <li>Sebeobětování</li>
                            <li>Potřeba být užitečný pro ostatní</li>
                            <li>Schopnost odpouštět</li>
                            <li>Schopnost předvídat</li>
                            <li>Důvěra</li>
                            <li>Naděje</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nemusíte se těchto svých charakterových vlastností vzdávat. Ale pokud zjistíte, že jste uvíznuli v nezdravém systému, tak pamatujte na to, že v nich lidé jen výjimečně uvíznou kvůli svým negativním vlastnostem. Většinou se do nezdravého systému lidé chytí právě díky svým pozitivním vlastnostem.     
                        </Fragment>
                    } />

                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default IssendaiCCoVasDrzi;