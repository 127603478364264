import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper";

const IssendaiBNaCestePryc = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Issendai – Na cestě pryč'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockQuote quote="http://www.issendai.com/psychology/sick-systems-whittling-yourself-away.html" author="Originál v angličtině" />

                    <PageParagraph content={
                        <Fragment>
                            Při psaní článku <a href="http://web.dzejpi.cz/issendai-nezdrave-systemy/issendai-jak-nekoho-donutit-aby-s-vami-zustal-navzdy/">Jak někoho donutit, aby s vámi zůstal navždy</a>, byly předkládány situace, ve kterých se Issendai sama ocitla a nebo se v nich ocitli její přátelé, takže předpokládala, že nezdravé systémy jsou vzácné a hluboce patologické. Ale z komentářů a ostatních konverzací na internetu lze vidět, že skoro každý člověk někdy uvíznul s nezdravém systému a že nezdravé systémy jsou nedílnou součástí života v některých částech světa.     
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jedna z otázek, která se často opakovala po článku <a href="http://web.dzejpi.cz/issendai-nezdrave-systemy/issendai-jak-nekoho-donutit-aby-s-vami-zustal-navzdy/">Jak někoho donutit, aby s vámi zůstal navždy</a>, byla: <em>&#8220;A nemusí člověk sám sobě ubližovat tak, jako ubližuje tomu druhému, aby nemocný systém udržel v chodu?&#8221; A odpověď je: ANO. K tomu, abyste mohli být srdcem nezdravého systému, tak ze sebe musíte postupně ořezávat kousky sama sebe až na dřeň.</em>     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nemocný systém je poháněný strachem z vás. Je to tak a díky tomu, že víte, že se vás někdo bojí, se můžete dokonce cítit velmi sebevědomí a mocní. Ale většina lidí není schopná toho, držet u sebe lidi jenom pomocí strachu samotného. Potřebujete, aby ostatní lidé věřili tomu, že je ke svému životu bezpodmínečně potřebujete, že byste se bez nich zcela zhroutili. Postupem času jste ale nuceni používat čím dál tím větších extrémů k tomu, aby vám ostatní věnovali stejné množství péče jako původně. Občasné finančně zodpovědné nakupování se postupně změní v závislost na nakupování a ta vás postupně finančně zruinuje. Neochota k braní léků se postupně změní v pravidelné návštěvy pohotovosti a poté v pravidelné hospitalizace.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A během toho musíte bedlivě sledovat, jakým způsobem ořezáváte lidi, kteří vás podporují. Není dobré, aby byli znatelně zdravější, než vy &#8211; protože jim dojde, co se ve skutečnosti děje a odejdou od vás. Ale zase není dobré, aby kolabovali vyčerpáním, protože by to znamenalo, že byste se <em>o ně</em> museli starat <em>vy</em>. Nejlepší je, když je držíte ve zlatém bodě, ve kterém jsou nuceni vynakládat trochu více zdrojů, než kolik jich mají reálně k dispozici. To samozřejmě znamená, že se časem myšlenkově dostanou do negativní spirály a pokud nemáte více lidí, kteří by vás podporovali ve vašem chování a kteří by mohli tohoto člověka nahradit v případě, kdy nevyhnutelně selže, tak budete muset z vašich požadavků trošku slevit.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Možná si myslíte, že máte určité standardy. Nemáte. Když spolu začínáte, tak začínáte na 4 000 Kč týdně pro dvě osoby a odmítáte jíst mražený steak. Pokud máte jenom 1 000 Kč týdně, budete jíst mražený steak, ale nebudete jíst konzervovanou zeleninu. Pokud máte 500 Kč na týden, budete jíst i konzervovanou zeleninu, ale nepůjdete do potravinové banky. Pokud máte 350 Kč na týden, necháte svého partnera, aby šel do potravinové banky, ale nepůjdete tam vy sami a budete odmítat jít se najíst do výdejny obědů pro bezdomovce. Pokud máte 200 Kč na týden, pořád se neukážete ve výdejně obědů pro bezdomovce, ale necháte svého partnera, aby domů přinesl jídlo nasbírané z popelnic a budete ochotni předstírat, že je z potravinové banky. Adaptujete se stejně tak, jako se adaptuje váš partner &#8211; pomalu a tak, aby se vaše spirála vešla do té jejich.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Postupně se ale spirála vašeho partnera stane tak malou, že se do ní už nevejde a spirála praskne. Je pryč. Jste sami. Začínáte panikařit. Všichni lidé, u kterých jste spoléhali na to, že vás ve vašem chování budou věčně podporovat, jsou pryč a léta zanedbávání sama sebe se začnou projevovat; následně dorazí poslední výbuch závislosti na ostatních lidech před tím, než si uvědomíte, že vaše staré taktiky na ně už nefungují. Je tu velmi vysoká šance, že skončíte v nemocnici, na psychiatrii nebo ve vězení.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pokud si uvědomíte, že jste srdcem nemocného systému, nezoufejte. Existují cesty, jak neskončit nedobrovolně v nějakém nehezkém zařízení. Budete ale muset udělat něco opravdu těžkého &#8211; zbavit se toho, čím jste systém vybudovali. Bude to pro vás to nejtěžší, co jste kdy museli udělat. Ale je to jednodušší, než kompletně zničit sám sebe a skončit někde nedobrovolně zavřený.     
                        </Fragment>
                    } />

                    <PageBreak />
                    <Link to="/issendai-co-vas-drzi">Pokračování &#8211; Co vás drží v nezdravém systému</Link>

                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default IssendaiBNaCestePryc;