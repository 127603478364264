import React from "react";
import logo from "../../assets/images/dzejpi_logo_white.png"
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from 'react-router-dom';
import MenuMainButton from "./menu-main-button";
import MenuIndentedButton from "./menu-indented-button";
import MenuTextButton from "./menu-text-button";

const LeftMenu = () => {

    const current_location = useLocation(); 

    return(
        <nav class="w-2/5 lg:w-[30%] min-w-96 flex flex-col overflow-y-auto py-4 text-center text-white">
            
            <div className="center mx-12 md:mx-12 lg:mx-16 xl:mx-24">
                <Link to="/"><img src={logo} class="" alt="Logo" /></Link>
            </div>
            
            <div className="flex-1 scroll-smooth overflow-y-auto lg:px-8 xl:px-8 xl:mr-4">
                <MenuMainButton linkTo="/" currentPage={current_location.pathname} buttonText="About me"/>
                <MenuMainButton linkTo="/cv" currentPage={current_location.pathname} buttonText="My CV"/>
                <MenuMainButton linkTo="/projects" currentPage={current_location.pathname} buttonText="Projects" isHidden="true"/>
                <MenuMainButton linkTo="/hardware" currentPage={current_location.pathname} buttonText="Hardware"/>
                <MenuMainButton linkTo="/software" currentPage={current_location.pathname} buttonText="Software"/>
                <MenuMainButton linkTo="/movies" currentPage={current_location.pathname} buttonText="1001 movies"/>
                <MenuMainButton linkTo="/game-ideas" currentPage={current_location.pathname} buttonText="Game idea generator"/>
                
                <MenuTextButton linkTo="/issendai-opusteni-rodice" childPages={["/issendai-opusteni-rodice", "/issendai-kultura-for", "/issendai-domnenky", "/issendai-proc-deti-opousteji", "/issendai-poznamky"]} buttonLabel="česky" currentPage={current_location.pathname} buttonText="Opuštění rodiče"/>
                <MenuIndentedButton linkTo="/issendai-opusteni-rodice" currentPage={current_location.pathname} buttonText="Opuštění rodiče" siblingPages={["/issendai-opusteni-rodice", "/issendai-kultura-for", "/issendai-domnenky", "/issendai-proc-deti-opousteji", "/issendai-poznamky"]}/>
                <MenuIndentedButton linkTo="/issendai-kultura-for" currentPage={current_location.pathname} buttonText="Kultura fór" siblingPages={["/issendai-opusteni-rodice", "/issendai-kultura-for", "/issendai-domnenky", "/issendai-proc-deti-opousteji", "/issendai-poznamky"]}/>
                <MenuIndentedButton linkTo="/issendai-domnenky" currentPage={current_location.pathname} buttonText="Domněnky" siblingPages={["/issendai-opusteni-rodice", "/issendai-kultura-for", "/issendai-domnenky", "/issendai-proc-deti-opousteji", "/issendai-poznamky"]}/>
                <MenuIndentedButton linkTo="/issendai-proc-deti-opousteji" currentPage={current_location.pathname} buttonText="Proč děti opouští své rodiče?" siblingPages={["/issendai-opusteni-rodice", "/issendai-kultura-for", "/issendai-domnenky", "/issendai-proc-deti-opousteji", "/issendai-poznamky"]}/>
                <MenuIndentedButton linkTo="/issendai-poznamky" currentPage={current_location.pathname} buttonText="Poznámky" siblingPages={["/issendai-opusteni-rodice", "/issendai-kultura-for", "/issendai-domnenky", "/issendai-proc-deti-opousteji", "/issendai-poznamky"]}/>

                <MenuTextButton linkTo="/issendai-jak-donutit" childPages={["/issendai-jak-donutit", "/issendai-na-ceste-pryc", "/issendai-co-vas-drzi"]} buttonLabel="česky" currentPage={current_location.pathname} buttonText="Nezdravé systémy"/>
                <MenuIndentedButton linkTo="/issendai-jak-donutit" currentPage={current_location.pathname} buttonText="Jak někoho donutit, aby s Vámi zůstal" siblingPages={["/issendai-jak-donutit", "/issendai-na-ceste-pryc", "/issendai-co-vas-drzi"]}/>
                <MenuIndentedButton linkTo="/issendai-na-ceste-pryc" currentPage={current_location.pathname} buttonText="Na cestě pryč" siblingPages={["/issendai-jak-donutit", "/issendai-na-ceste-pryc", "/issendai-co-vas-drzi"]}/>
                <MenuIndentedButton linkTo="/issendai-co-vas-drzi" currentPage={current_location.pathname} buttonText="Co vás drží v nezdravém systému" siblingPages={["/issendai-jak-donutit", "/issendai-na-ceste-pryc", "/issendai-co-vas-drzi"]}/>

                <MenuTextButton linkTo="/issendai-jak-byt-perfektni-manazer" childPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]} buttonLabel="česky" currentPage={current_location.pathname}  buttonText="Překlady"/>
                <MenuIndentedButton linkTo="/issendai-jak-byt-perfektni-manazer" currentPage={current_location.pathname} buttonText="Jak být perfektní manažer" siblingPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]}/>
                <MenuIndentedButton linkTo="/navod-na-zhubnuti" currentPage={current_location.pathname} buttonText="Návod pro zhubnutí pro líné" siblingPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]}/>
                <MenuIndentedButton linkTo="/na-kaloriich-zalezi" currentPage={current_location.pathname} buttonText="Proč na kaloriích záleží" siblingPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]}/>
                <MenuIndentedButton linkTo="/stojim-za-nic" currentPage={current_location.pathname} buttonText="Stojím za nic! - Já taky, nevadí" siblingPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]}/>
                <MenuIndentedButton linkTo="/priony" currentPage={current_location.pathname} buttonText="Priony" siblingPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]}/>
                <MenuIndentedButton linkTo="/koureni-je-nejlepsi-vec-na-svete" currentPage={current_location.pathname} buttonText="Kouření? Nejlepší věc na světě" siblingPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]}/>
                <MenuIndentedButton linkTo="/sedm-fazi-covidu" currentPage={current_location.pathname} buttonText="Sedm fází Covidu" siblingPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]}/>
                <MenuIndentedButton linkTo="/jak-se-lisi-ockovani-od-holokaustu" currentPage={current_location.pathname} buttonText="Jak se očkování liší od holokaustu" siblingPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]}/>
                <MenuIndentedButton linkTo="/nemate-pravo-na-svuj-nazor" currentPage={current_location.pathname} buttonText="Nemáte právo na vlastní názor" siblingPages={["/issendai-jak-byt-perfektni-manazer", "/navod-na-zhubnuti", "/na-kaloriich-zalezi", "/stojim-za-nic", "/priony", "/koureni-je-nejlepsi-vec-na-svete", "/sedm-fazi-covidu", "/jak-se-lisi-ockovani-od-holokaustu", "/nemate-pravo-na-svuj-nazor"]}/>
            </div>
        </nav>
    )
}

export default LeftMenu