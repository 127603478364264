import React, { useState } from "react";

const gameIdeas = [
    "Violent Relaxation",
    "Nature vs. Nurture",
    "You Control The Weather And The Time Of The Day",
    "Oogaa Booga Fire Bad",
    "Alone Among The Stars",
    "Opposite Day",
    "Elephant In The Room",
    "Black Hole",
    "The Game Plays You",
    "After The End",
    "To The Void",
    "Down The Rabbit Hole",
    "Procrastination",
    "Must Be A Full Moon",
    "Forlorn Hope",
    "Caught In Simulations",
    "-1 Lives",
    "Unsatisfying Outcomes",
    "Textless",
    "Inaccurate Simulation",
    "Metamorphosis",
    "Freedom Is Slavery",
    "Dark And Light",
    "Inputs Are Discouraged",
    "The Conclusion Is The Beginning",
    "Goal Is Regression",
    "My Deadline",
    "I Can See Clearly Now",
    "Kill It",
    "The Game Cheats",
    "Falling Apart",
    "We Need To Go Deeper",
    "Escape From Reality",
    "You're Already Dead",
    "Your Life, Your Time",
    "Connected Worlds",
    "Dying Is Fun",
    "House Of Four Doors",
    "It's Forbidden!",
    "Dying Is Absolutely Safe",
    "When Cats Ruled The World",
    "Fun With Beans",
    "Moldova",
    "Light In The Dark",
    "Alien Monolith",
    "The Other Side Of The Story",
    "Nobody Has A Backbone",
    "Fever Dream",
    "The Game Knows It's A Game",
    "Make A Mistake",
    "Collective Loneliness",
    "Everest",
    "Working With Randomness",
    "Empty",
    "Accidental Violence",
    "You Have To Go Back",
    "Anonymity",
    "Congruent",
    "Nostalgia Goggles",
    "City Of Forgotten Toys",
    "Matter Of Dreams",
    "Inverted Weight",
    "Like, Comment, Subscribe",
    "Back To The Basics",
    "Unavoidable Destiny",
    "Beautiful Nightmares",
    "Unhelpful Superpower",
    "One Day In Office",
    "Seven Deadly Sins",
    "Not What It Looks",
    "Time Is Key",
    "Relentless",
    "Your Days Are Numbered",
    "You Must Collect Tax From All The Wizards",
    "Parallel Universes",
    "Your Dream Day",
    "Monochrome Rainbow",
    "The Sleeper Awakens",
    "Leave The City",
    "Loneliness",
    "Black Canvas",
    "Fight Game Without Violence",
    "Bipolar",
    "Ludum",
    "Medicine For The Soul",
    "Work With What You Got",
    "Think Fast",
    "The Sun Is Going To Explode!",
    "On Your Own",
    "Words Paradise",
    "Together But Alone",
    "The Intimacy Of Being Understood",
    "Fox With A Cup Of Tea",
    "Asymmetry",
    "Projection",
    "Crying Works",
    "Requirement For Failure",
    "Bugs Are Your Friends",
    "There Is A Secret To Success",
    "Solitude",
    "Do Not Return To Where You Started",
    "Backwards Progress",
    "All Characters Are Bunnies",
    "Burn It All",
    "Diamond Hands",
    "Let's Party!",
    "The End Is The Beginning",
    "Everything Is Dangerous",
    "Don't Leave The Menu",
    "Opposites Attract",
    "I Can't Tell If The Game Is Broken",
    "Gather Everything",
    "Holiday",
    "You Have 48",
    "We Have Been Waiting",
    "Endless",
    "Crohn's Disease",
    "Behind Blue Eyes",
    "You Did What?",
    "Survive The Night",
    "Life Is Death",
    "Are You Okay?",
    "Be The NPC",
    "Only Mouse Moving",
    "Playable Only Once",
    "Mortal Items",
    "Evergreen",
    "Untwisting",
    "Back To The Earth",
    "Parallel Lines",
    "Robots Vs. Covid",
    "Reach It, Inefficiently",
    "Antimatter",
    "Decay",
    "Artificial Intelligence",
    "Can't Handle The Pressure",
    "Don't Own, Borrow Instead",
    "Everything Is Over",
    "Nothing Else Matters",
    "The World Ends In A Day",
    "What Is Both Sacred And Profane",
    "Equilibrium",
    "A Weird Feeling Of Déjá Vu",
    "01011001",
    "A Winter Night",
    "Sleep On It",
    "Multiple Games Happening At Once",
    "Questionable Ethics",
    "The Level Is A Pizza",
    "A World Without Time",
    "Microscopic",
    "Stuck In Parallel World",
    "Dystopia",
    "Hill Of The King",
    "Retro Computers",
    "Circle Of Life",
    "Die To Progress",
    "Everything Is Cats",
    "The Walls Have Ears",
    "(Not) Everything Is Replaceable",
    "Constant Decay",
    "Operating System",
    "Aaand... It's Gone",
    "The World Is Too Small",
    "React To The Unexpected",
    "Destructive Emptiness",
    "The Walls Have Ears",
    "I Need A Coffee",
    "Close The Distance",
    "Health Is Everything",
    "Rain",
    "I Forgot How To Do That",
    "Big Fish In A Little Pond",
    "Clean Up The Game",
    "Acceptance",
    "Simple Systems, Complex Result",
    "Huge, But Tiny",
    "Taking Back The Holy Land",
    "Feeding The Monster",
    "Temperature Change",
    "Your Life Is Currency",
    "A Single Central Resource",
    "Braindance In Wonderland",
    "Try This At Home",
    "Dealing With The Aftermath",
    "Water & Light",
    "Wild Goose Chase",
    "It's Growing",
    "Your Mouse Is The Player",
    "Farfetched Reality",
    "Anger Management",
    "From Quarks To Galaxies",
    "Memento Mori / Memento Vivere",
    "I'm Coming",
    "Are We The Bad Guys?",
    "Game From Perspective Of Non-Playable Game Object",
    "Warning! Contains Small Parts!",
    "Fear The Light",
    "Everything Is For Sale",
    "Flooding",
    "Save Earth",
    "Controlled By Algorithm",
    "Corporate Lifestyle",
    "Stages Of Grief",
    "Lose Yourself",
    "Is It Real?",
    "Sky",
    "Glitches Are Required",
    "Inconvenient Superpowers",
    "No Collisions",
    "Lose Yourself",
    "Is It Real?",
    "You Really Shouldn't Mix Those",
    "No Flight Allowed",
    "Other Side",
    "48 in 48",
    "Disoriented",
    "Color Changes Everything",
    "Stay Out Of Sight",
    "Mail From The Future",
    "Who Needs Sleep?",
    "Shadows Disappeared",
    "Breathing Is Boring",
    "Ancient Runes",
    "Don't Be A Jerk",
    "Inhospitable Environment Made Friendly",
    "The Calm Before The Storm",
    "Breathing Is Boring",
    "Sound Of Silence",
    "Lighthouse",
    "RGB",
    "Beginner's Luck",
    "Stuck In Limbo",
    "Death Is Optional",
    "Planet-Scale Screw-Up",
    "Death Means Nothing",
    "Under The Sun",
    "Violence Isn't A Solution, It's THE Solution!",
    "Delve Into The Mind Of Frogs",
    "Secret Disorganization",
    "Prehistoric",
    "Colour Is Everything",
    "Avoid Being Alive",
    "Hostile Lands",
    "Never Again",
    "Victory Or Defeat View",
    "Forgiveness",
    "It's Not A Bug, It's A Feature",
    "Self-Improvement",
    "Inside Is Outside",
    "Harvest Happiness",
    "One Tool, Many Uses",
    "Player Is Blind",
    "It's Not That Bad Being Last",
    "Knights Aren't Always Noble",
    "Oxygen Is Worth Gold",
    "You Have 2 Days",
    "To The South",
    "Macroworld",
    "Help It Grow",
    "An Enormous Flood",
    "Management Increases Your Changes",
    "Defend The Weak",
    "Frogs",
    "Nonviolent Noncompliance",
    "Perform Mental Gymnastics",
    "Must Play Twice",
    "Grammar Matters",
    "Zoo",
    "Frogs",
    "Watch Your Tone",
    "I Had No Choice",
    "Only One Screen",
    "Meaningful Death",
    "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",
    "Old",
    "The World Is Fragile",
    "Deconstruction",
    "Reverend Travel",
    "Sanctuary",
    "Carbon",
    "Unity",
    "Let's Not Meet",
    "Bright Black",
    "A Bit Too Much",
    "Light And Shadows",
    "Memory",
    "The Last Is The Best",
    "Rebirth",
    "Responsibility",
    "Space Climate Changes",
    "Lose By Moving",
    "Starve The Beast",
    "Only You Can See It",
    "Fibonacci Sequence",
    "Archeology",
    "Forgotten",
    "Away From Keyboard",
    "Entropy",
    "Survive The End",
    "Barriers",
    "A Bit Too Much",
    "Tourniquet",
    "Dark And Darker",
    "Illegal Eggs",
    "Before Apocalypse",
    "It's A Forecast",
    "Non-Binary",
    "Enemy Inside",
    "Isolation",
    "Bloodlines",
    "Mental Degradation",
    "Hoa",
    "Everything Is A Lie",
    "Beyond The Horizon",
    "Cracking The Shell",
    "Except For One",
    "Take A Hike",
    "How To Grow A Plant",
    "A Walk On The Beach",
    "I'm Hiring",
    "Perspective Matters",
    "Plan Ahead",
    "Real World Time",
    "Retro Game, New Gameplay Features",
    "Full Of Emptiness",
    "5 Minutes",
    "Sponsored By",
    "Delay The Inevitable",
    "A Failing Relationship",
    "Breaking Point",
    "Children Drawing",
    "As The Darkness Settles In",
    "Medieval Admin",
    "A Life In Darkness",
    "Overgrowth",
    "Dare To Explore",
    "Subverted Expectations",
    "Take Shelter",
    "Three Choices",
    "Connection Lost",
    "Get Your Deposit Back",
    "Slowly Sinking",
    "Supply & Demand",
    "Something Not Scary Is Terrifying",
    "Hello World",
    "Devil Is In The Details",
    "An Unseen Adversary",
    "MaGUIficient",
    "Build And Release",
    "Home-Made Is Better",
    "Magic, Kittens And Persistence",
    "Binary",
    "4 Qualities And 9 Defects",
    "Along For The Ride",
    "Artist's Palette",
    "In The Shadows",
    "10 Seconds",
    "Blue Moon",
    "24/7",
    "Mystic Garden",
    "Shai-Hulud",
    "Public Transport",
    "Life Of An Artist",
    "Phone Call",
    "Stay The Night",
    "Round The Campfire",
    "f(x) = x^2",
    "Break The Simulation",
    "Nature Generosity",
    "Waves Of Thoughts",
    "Far Away Trip",
    "Perfect Square",
    "Random Influences",
    "A Strange Duality",
    "Before The Dawn",
    "Little Fluffy Deaths",
    "Fire Fades",
    "Antiquity",
    "Magical Dystopia",
    "Unexpected Delivery",
    "Don't Kill Anything",
    "Dying Sun",
    "Unreliable Storytelling",
    "Three Attempts",
    "Shrinking World",
    "Alien Frogs",
    "Chilling Influence",
    "No Money? No Problem!",
    "Follow The Star",
    "Missing Parts",
    "Dying Earth",
    "Inescapable Collapse",
    "Only One Click",
    "One Button Game",
    "Koala And Friends",
    "Self-Awareness",
    "Sometimes They Come Back",
    "The Journey",
    "Party Gone Wrong",
    "Do Not Click",
    "Bureaucratic Hell",
    "Ancient Relic",
    "Torn Between Worlds",
    "Fixing Makes Things Worse",
    "Beyond The Wall",
    "Alone Together",
    "Local Folklore",
    "Infinity And Beyond",
    "Self Defence",
    "True And False",
    "Into The Wild",
    "First Day On The Job",
    "Lost In Translation",
    "Dumpster Fire",
    "Colliding Realities",
    "Butterfly Effect",
    "Under Pressure",
    "Mike Is Hiring",
    "Mistakes Were Made",
    "Pink Hippopotamus",
    "Disappoint Your Dad",
    "Void",
    "Inevitable Demise",
    "Something Old, Something New",
    "No Place But Home",
    "Point And Click",
    "Recursive Nightmare",
    "Cognitive Dissonance",
    "Wizard Tax Evasion",
    "Improvise. Adapt. Overcome.",
    "Only One Button",
    "Burst Your Bubble",
    "No Way Home",
    "Clear Your Memory",
    "Micro Transactions",
    "Tomorrow Never Comes",
    "If You Build It, They Will Come",
    "The Same Day",
    "Monke",
    "Flat Line",
    "Knight Night",
    "Forbidden Land",
    "Ancient Runes",
    "Animal Simulation",
    "God Is On Vacation",
    "Rabbits",
    "Lost In Library",
    "Stuck In Hell",
    "1 Second",
    "49 Years Ago",
    "Shoo Fly! Don't Bother Me!",
    "Being Watched",
    "Simulation Hypothesis",
    "Under Watchful Eye",
    "You Saved The World, Now What?",
    "The Moment You Stop, You Die",
    "We're Hiring",
    "A Quiet Place",
    "Approaching Perfection",
    "Race Against Time",
    "Exponential Growth",
    "Self Destructive",
    "Nature Meets Technology",
    "Bathing In It",
    "A Nice Trip",
    "Sleep Deprived",
    "Touch The Edge",
    "Last Day On Earth",
    "Darker And Darker",
    "Small World",
    "Feel The Darkness",
    "Borrowed Time",
    "The Middle Of Nowhere",
    "Blood On The Snow",
    "Nature Recovery",
    "No Theme",
    "Tears In Rain",
    "Apes Together Strong",
    "Inverted Gameplay",
    "Darkness Is Your Friend",
    "Into The Woods",
    "Among The Stars",
    "Everything is Purchasable",
    "Try Not to Move",
    "Who Is Playing",
    "Start By The End",
    "Psychedelic",
    "Leave a Trail",
    "Paths",
    "Hell",
    "Downward Spiral",
    "Incomplete",
    "Growing And Growing",
    "When You Wake Up At 5AM At Go Back To Sleep",
    "Echoes",
    "Lost In Chaos",
    "2002",
    "Go Brrrt",
    "Terms of Service",
    "Road Trip",
    "You've Got Mail",
    "Generation",
    "Random()",
    "Extreme Tea Time",
    "Collapsing Environment",
    "Death Waltz",
    "You Get 50",
    "Behind The Storm",
    "Beyond Our World",
    "On The Horizon",
    "Historical Inaccuracy",
    "Delegate The Work",
    "Onwards And Upwards",
    "Constant Speed",
    "Diplomatic Immunity",
    "Only Text Please",
    "Rebirth",
    "Be Resourceful",
    "Not Allowed",
    "Lone Wolf",
    "Toxic World",
    "Broken Connection",
    "Slice of Life",
    "Color Changes Everything",
    "Halfway Point",
    "Feeling Lonely",
    "Every Day The Same",
    "The Night Shift",
    "Share The Load",
    "20 Years Ago",
    "In Short Supply",
    "Visiting Dimensions",
    "Raise Animal Without Eating It",
    "Movement Is A Luxury",
    "My Darkness, My Domain",
    "Control The Chaos",
    "Beautifully Inefficient",
    "Tower Offence",
    "Procedural Anxiety",
    "Girls Gone Mild",
    "Tiny Open World",
    "The Pain Of Pressing N",
    "Unlockdown",
    "Unconventional farming",
    "After the game ends",
    "It works on my device",
    "It all adds up",
    "It's not normally like that",
    "Broken down",
    "Would you please?",
    "Press in case of emergency",
    "F-ing islands",
    "Thalassophobia",
    "Less and less",
    "As expected",
    "Mixed signals",
    "Game of life",
    "The other side",
    "Dimensions",
    "Noise",
    "Wave",
    "Contemplation",
    "Baby's first",
    "STOP",
    "Meta",
    "A point of critical mass",
    "Hope",
    "Beyond reality",
    "Moist",
    "Elevation",
    "Life outside the wall",
    "One life",
    "Sick",
    "Changing times",
    "One more",
    "Communication breakdown",
    "The rain never stops",
    "Interconnected",
    "Nothing",
    "Stuck in character creation",
    "The fifth",
    "A boring dystopia",
    "Dead on arrival",
    "Shadows of yesterday",
    "Thin crust",
    "Dark",
    "Fifty shades of array",
    "Under pressure",
    "Some 15 to 20 seconds",
    "Wet and dry",
    "Dance of death",
    "Tradition",
    "Beach relaxation",
    "It lasts for generations",
    "Only two times",
    "Guardian",
    "They are watching",
    "Disconnect",
    "Sentience",
    "Life on line",
    "The darkest hour",
    "Shattered",
    "Larger than life",
    "Submechanophobia",
    "Let it go",
    "Alone",
    "Learned helplessness",
    "Prolong the inevitable",
    "Exposure",
    "Cute girls doing cute things",
    "Death is your ally",
    "Mushroom tea",
    "Reminiscence",
    "The source of life",
    "Life of an NPC",
    "The ontology of shadows",
    "Ocean locomotion",
    "Hyper minimalism",
    "Expanding space",
    "Polarity",
    "Regulation",
    "Fix the skies",
    "Superhero helpline",
    "Lighthouse saves the day",
    "Exploring linearity",
    "Safety in numbers",
    "Into the unknown",
    "A new world",
    "Night and Day",
    "Deprivation",
    "You inherit 100$",
    "Increasing speed",
    "If only there was more time",
    "Crack in reality",
    "Around the world",
    "Remote access",
    "Congratulations! Now try again",
    "Mimicry",
    "Extreme environment",
    "Lost in space",
    "Last gas station",
    "Push the tempo",
    "Crossroad",
    "Gorilla marketing",
    "Slap of God",
    "Extreme climate",
    "Medieval dystopia",
    "Falling apart",
    "Left behind",
    "Disintegration",
    "Voices",
    "Hopelessly lost",
    "Barren",
    "The meaning of life",
    "Unavailable",
    "Mysterious origins",
    "Road to nowhere",
    "The road ahead",
    "Historically inaccurate",
    "Black hole",
    "Dust in the wind",
    "Solitude",
    "One last fight",
    "Buried secrets",
    "Rotation",
    "Hollow",
    "Equivalency",
    "Borders",
    "Labour",
    "Boundaries",
    "Lost signal",
    "Summer vacation",
    "Every minute",
    "Drained",
    "Flow",
    "Beyond",
    "Sold out",
    "Cluster of buildings engulfed by the deep abyss of the ocean",
    "Embrace the end",
    "Bonds",
    "Other worlds",
    "Yesterday was the end of the world",
    "Corruption",
    "Dopamine",
    "The long and winding road",
    "Lost to time",
    "Capture the frag",
    "Whispers from the past",
    "Real of elementals",
    "Tremors",
    "A new world",
    "After the end",
    "Neightborhood",
    "Subliminal",
    "New beginning",
    "Replenish",
    "Field of the dreams",
    "Journey into the darkness",
  ];
  
  const getRandomGameIdea = () => {
    return gameIdeas[Math.floor(Math.random() * gameIdeas.length)];
  };
  
  function GameIdeaGenerator() {
    const [gameIdea, setGameIdea] = useState(getRandomGameIdea());
    const handleClick = () => {

      // Shuffle array and pick random
      const randomGameIdea = getRandomGameIdea();
      setGameIdea(randomGameIdea);
    };
  
    return (
      <div>
        <h1 class="text-xl p-8 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800 bg-opacity-20"><b>{gameIdea}</b></h1>
        <br></br>
        <button class="hover:bg-white hover:bg-opacity-20 text-white border-white border-transparent border-2 py-2 px-4 text-sm font-medium rounded-lg focus:outline-none " onClick={handleClick}><b>New game idea</b></button>
      </div>
    );
  }

export default GameIdeaGenerator