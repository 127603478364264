import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageBulletListNested from "../../components/PageComponents/PageListComponents/page-bullet-list-nested";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import PageNumberedListNested from "../../components/PageComponents/PageListComponents/page-numbered-list-nested";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper";

const IssendaiCDomnenky = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Issendai – Domněnky na fórech pro opuštěné rodiče'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-of-estranged-parents-forums.html" author="Originál v angličtině" />
                    
                    <PageBlockHeaderTwo header="Domněnky na fórech pro opuštěné rodiče"/>

                    <PageBlockQuote quote={
                        <Fragment>
                            Jen přemýšlej o všech těch věcech, které máme a ke kterým nám přibalili návod. A přesto jsme jednou dostali malého človíčka bez jakýchkoliv instrukcí. Myslím, že jsme všichni udělali dost dobrou práci, jinak bychom nebyli na tomto fóru a nepřemýšleli o tom, co jsme udělali špatně. Měli bychom na ně koukat jako na dospělé, kteří dělají ohromnou chybu, když od sebe odhazují lidi, kteří nechtěli nic jiného než to, co je pro ně nejlepší. Pokud jsem udělala něco špatně, tak to bylo to, že jsem je milovala až příliš moc.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Existuje velký počet domněnek, které se na fórech neustále a neustále opakují dokola. Dokonce se opakují i v příspěvcích opuštěných rodičů, kteří píšou na své vlastní blogy a fóra pro opuštěné rodiče nenavštěvují. Toto je výběr těch nejčastějších. Tučně psaná si rozebereme podrobněji.
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment> 
                            <li><strong>Naše děti nám chtějí ublížit.</strong></li>
                            <li><strong>Chci mu nařezat na prdel.</strong></li>
                            <li><strong>Nejsme dokonalí, oni ale taky ne.</strong></li>
                            <li><strong>Kontaktuju své opuštěné dítě kvůli sobě, ne kvůli němu.</strong></li>
                            <li><strong>Nechtěný kontakt není pronásledováním.</strong></li>
                            <li><strong>Týrání je velmi vzácné.</strong></li>
                            <li><strong>Opravdovým tyranům to je jedno.</strong></li>
                            <PageBulletList content={
                                <Fragment> 
                                    <li>Opravdoví tyrani vědí, že jsou tyrani.</li>
                                </Fragment>
                            } />
                            <li><strong>Naše děti si ve skutečnosti nemyslí, že jsme je někdy týrali.</strong></li>
                            <li>Dělali jsme to nejlepší, co jsme mohli.</li>
                            <PageBulletList content={
                                <Fragment> 
                                    <li>K dětem nedávají návod.</li>
                                </Fragment>
                            } />
                            <li>Kdyby slyšely moji stranu, tak by rozumněly.</li>
                            <li>Odcizení je hra o moc.</li>
                            <li>Odcizení je týrání.</li>
                            <PageBulletList content={
                                <Fragment> 
                                    <li>Odcizení je ten nejhorší způsob týrání, jaký může jeden člověk druhému udělat.</li>
                                    <li>Nemožnost navštívit vnuky je multigenerační týrání &#8211; týrání jak nás, tak vnoučat.</li>
                                </Fragment>
                            } />
                            <li>Hrajou s námi hru na tichou domácnost.</li>
                            <li><strong>Chtějí, abychom je naháněli.</strong></li>
                            <li>Sledují naše životy a nenávidí, když jsme šťastní.</li>
                            <li>Jejich příspěvky na Facebooku jsou mířené na nás.</li>
                            <li><strong>Smějí se nám.</strong></li>
                            <li>Používají vnuky jako figurky ve hře.</li>
                            <li>Učí mé vnuky tomu, aby mě nikdy nechtěli poznat.</li>
                            <li>Bojím se, že moje děti si tím, že se mnou nemají kontakt, ubližují sami sobě.</li>
                            <li>O tolik věcí přicházejí, když s námi nemají kontakt.</li>
                            <li>Byli jsme skvělí rodiče.</li>
                            <li>Byli jsme si tak blízcí.</li>
                            <li>Děti se chovají, jako kdyby zapomněly na všechny dobré časy.</li>
                            <li>Po tom všem, co jsme pro ně udělali!</li>
                            <li>Rodiče si zaslouží respekt automaticky.</li>
                            <PageBulletList content={
                                <Fragment>
                                    <li>Cti otce a matku svou.</li>
                                    <li>Moji rodiče byli hrozní tyrani, ale starala jsem se o ně, dokud neumřeli.</li>
                                </Fragment>
                            } />
                            <li>Zbytek rodiny by se měl postavit za mě.</li>
                            <li>Jak to můžeme vyřešit, kdy o tom nemluvíme?</li>
                            <li>My jsme si odcizení nevybrali, to oni.</li>
                            <li>Nevíš, jaké to je, pokud jsi to nezažil sám.</li>
                            <li>Doufám, že se ti to taky stane.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Historické okénko: V letech 1873-1884, Samuel Butler napsal &#8220;<em>The Way of All Flesh</em>,&#8221; částečně autobiografickou novelu o jeho vztazích s jeho rodiči. Jeho popis jeho otce byl následující:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            V těchto temných dnech, měl velmi ponurý pohled na vše, co jej obklopovalo a říkal si sám sobě, že navzdory vší své laskavosti, kterou věnoval svým dětem, ho jeho děti nemilovaly. [&#8230;] Jakou podlostí, vykřikoval sám pro sebe, je taková nevděčnost! Byl na sebe obvzláště tvrdý, poněvadž byl příkladným synem, jenž za všech okolností ctil a poslouchal své rodiče, ačkoliv na něm neutratili ani setinu peněz, jenž on utratil na dětech svých. &#8220;Je to vždy jeden a ten stejný příběh,&#8221; říkal si, &#8220;čím toho mají dnešní mladí lidé více, tím méně vděčnosti se za ně člověku dostane; udělal jsem ohromnou chybu; vždy jsem k dětem přistupoval příliš zlehka; nevadí &#8211; já svou povinnost vůči nim splnil a dokonce jsem splnil mnohem více, než jen mou povinnost; pokud ony selhají v tom, splnit jejich povinnosti vůči mě, je to věcí mezi Bohem a mými dětmi. Já, v každém případě, jsem nevinný.&#8221;
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Některé věci jsou zkrátka věčné.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="&#8220;Naše děti nám chtějí ublížit&#8221;" />
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-our-children-want-to-hurt-us.html" author="Originál v angličtině" />
                   
                    <PageBlockQuote quote={
                        <Fragment>
                            Přesně. Je tu jedna věc, kterou rodiče nechápou, dokud nejsou opuštění několik let. Oni nám CHTĚJÍ ublížit. Něco musí bejt špatně s jejich empatií. V jejich chování vidím něco děsivýho a nemocnýho, v čem si libují. Vím, že to neplatí pro všechny, ale myslím, že pro většinu z nich. Potřebují moc. Je to všechno o kontrole a o tom, ubližovat nám, ať už je jejich důvod jakýkoliv. Potřebujou obětního beránka. Potřebujou nenávidět. Oni VĚDÍ, co dělají. Odlidšťují nás, aby to, co dělají, měli ještě snadnější. Naše prosby je jenom více opíjejí mocí. Židovský člověk v plynové komoře by nebyl propuštěn jenom pokřikem: &#8220;Jsem člověk!&#8221; Nikdo v popravčí četě by nezmáčknul kohoutek, kdyby viděli své terče jako lidské bytosti. Naše děti nás tak taky nevidí. Zašlo to příliš daleko. Už nejsme lidské bytosti s vlastními city.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Postupem času začne mnoho opuštěných rodičů věřit tomu, že se jejich děti rozhodly s nimi přerušit kontakt jenom kvůli tomu, aby je viděly trpět. Někteří rodiče, jako Sharon A. Wildey, autorka <em>Opuštění Rodiče: Ďáblovo Dilema</em>, trpí tak intenzivně, že dochází k takovému závěru, že ji její děti chtějí zabít:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Na závěr, jakékoliv přetrhávání kontaktů [&#8230;] stojí na základní motivaci s vražedným úmyslem. [&#8230;]<br></br>
                            Víme, že konečným cílem týrání je ukončit život týraného člověka a nezáleží na tom, zda jeho zabití je spirituální, emoční nebo fyzické. Čím více je týrání záměrné ve smyslu, že je plánováno a navrženo tak, aby způsobovalo co nejvíce bolesti, tím více je toto chování motivováno touhou po tom, ukončit život rodiče. Neexistují žádné vyjímky.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Domněnka, že děti své rodiče opouštějí kvůli tomu, aby jim ublížily, je založena na následujícím řetězci myšlenek:
                        </Fragment>
                    } />
                    
                    <PageNumberedList content={
                        <Fragment> 
                            <li><strong>Rodič nedokáže akceptovat motivy svých dětí</strong>. Je pro něho příliš bolestivé si sám sobě přiznat, že jejich dětem je jejich přítomnost nepříjemná. Rodiče proto okolo sebe nadhazují jiné možné teoretické motivy svých dětí, které na ně pokud možno vrhají lepší světlo.</li>
                            <li><strong>Rodiče věří, že z odcizení mají oni sami více výhod, než jejich děti</strong>. Rodiče rozumí tomu, že například při rozvodech nebo rozchodech existuje jedna strana, která odchází šťastná a efekt na druhou stranu je pro ní vedlejší. Když je opustí jejich vlastní dítě, jejich bolest je oslepuje. Věří tomu, že efekty, které na ně odcizení má, jsou předem úmyslně promyšlené jejich dítětem, ale že kromě radosti z bolesti, kterou jim dítě způsobuje, z toho dítěti žádné další výhody neplynou.</li>
                            <li><strong>Pokud jim něco nějak připadá, tak to tak je</strong>. Pokud se cítí, že je někdo zradil, tak je někdo zradil. Pokud se cítí, že jsou někomu ukradení, tak mu jsou ukradení. Pokud se cítí, že jim někdo chce ublížit, tak jim ten člověk chce ublížit.</li>
                        </Fragment>
                    } />
                   
                    <PageParagraph content={
                        <Fragment>
                            Velkou část tu také hraje takzvaná psychologická projekce. Rodič, který chce svému dítěti podvědomě (třeba i něvedomky) ubližovat, přemýšlí stylem: &#8220;Chci ti ublížit, takže ty chceš ublížit i mě.&#8221; Nicméně, když se toto myšlení převede z rodiče na dítě, tak se často dějí kuriózní věci. Rodič chce svému dítěti ublížit z bolesti a vzteku, popřípadě to bere jako odplatu za to, že ho dítě opustilo &#8211; tyto emoce jsou v rodičovské pozici pochopitelné. Někteří rodiče toto myšlení ale převádí na děti a domýšlí si například to, že je jejich dítě chce potrestat čistě pro radost, či pro temné uspokojení nad tím, že ví, že jejich rodič trpí. Někteří rodiče dokonce tvrdí, že se jejich děti určitě smějí, když ví, že jejich rodiče trpí. Proč rodiče ořezávají z domnělých motivů svých dětí emoce jako vztek a utrpení?
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jedno z vysvětlení je takové, že někteří rodiče si tiše užívají, když vidí ostatní lidi trpět, takže to promítají i na své děti. Je možné a dokonce i pravděpodobné, že někteří opuštění rodiče si opravdu užívají, když vidí ostatní lidi trpět. Sadismus je častou komponentou takzvaných &#8220;<em>Cluster B&#8221;</em> poruch osobnosti (hraniční porucha osobnosti, narcistická porucha osobnosti, psychopatie, sociopatie) a tyrani se těmito poruchami přímo vyznačují.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Ale myslím si, že většina rodičů, kteří si myslí, že jejich děti si užívají, když je vidí, jak trpí, si to myslí kvůli tomu, že nejsou schopni unést bolest nebo vztek svých dětí. Pokud je jejich dítě naštvané nebo trpí kvůli něčemu, co rodič udělal, tak je reakce dítěte oprávněná a rodič nedokáže unést myšlenku toho, že by mohl udělat něco špatně.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Druhé vysvětlení je to, že rodiče nevěří tomu, že jsou na ně děti naštvané. To vychází z klasické dysfunkční logiky:
                        </Fragment>
                    } />
                    
                    <PageNumberedList content={
                        <Fragment> 
                            <li>Abych byl na někoho naštvaný, tak mi k tomu musí dát pádný důvod.</li>
                            <li>Já jsem svým dětem žádný pádný důvod k tomu, aby na mě byly naštvané, nedal.</li>
                            <li>Takže moje děti na mě naštvané nejsou.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Takže pokud na rodiče děti naštvané nejsou, ale ubližují mu jako kdyby na něj naštvané byly, tak proč mu vlastně ubližují? Fóra pro opuštěné rodiče jsou plná rodičů, kteří se neustále snaží přijít na opravdový důvod k tomu, proč s nimi jejich děti přerušily kontakt, zoufale se snaží najít jakýkoliv důvod jiný než &#8220;protože jsem jim dal důvod k tomu, aby se mi vyhýbaly.&#8221; Nakonec mnoho z nich dojde k závěru, že jim jejich dítě ubližuje zkrátka a jednoduše proto, že ho to baví.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Rodičům tato myšlenka svým způsobem příjde utěšující. Mluví o tom, jak je těžké se s tím smířit, ale pomáhá jim to k tomu, oddělit se od svých dětí bez toho, aby se cítili provinile. Některé z nich to dokonce povzbudí a díky tomu si vezmou zpět všechnu moc, o které si mysleli, že dali svému dítěti. Už nemusí žít svůj život v pohotovosti, nemusí čekat na to, zda se magicky obnoví a spraví vztah s jejich dítětem, které o ně nemá zájem.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Takže by se dalo říct, že to je vlastně prospěšné. Rodiče se cítí lépe; přestanou se snažit kontaktovat své odcizené děti; odcizené děti se díky tomu také cítí lépe, protože je rodiče nekontaktují. Nedostatek perspektivy nezbytné k tomu, aby rodič opravdu &#8230; věřil, že mu děti chtějí ublížit, mu proniká celým jejich vzájemným vztahem, takže většina takových rodičů si myslí, že to vztah s jejich dětmi nijak nepoškozuje; vztah byl totiž poškozený už dávno.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Je tu ale problém &#8211; &#8220;tvoje děti ti chtějí ublížit&#8221; je standardní rada, kterou veteráni fóra dávají nováčkům. Noví členové, kteří cítí úlevu díky tomu, že konečně našli lidi, kteří pro ně mají pochopení, budou takové lidi brát jako důvěryhodné. A nadšeně tyto jejich rady zhltnou a budou se podle nich řídit. A tyto rady jim posléze zničí jakékoliv zbývající naděje na usmíření s jejich dětmi.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Citáty opuštěných rodičů" />
                    
                    <PageBulletList content={
                        <Fragment>
                            <li>Věřím, že většina našich dětí nám chce ublížit co nejvíc, co to jenom jde.</li>
                            <li>Chtějí nám jenom ublížit. Věřím, že to dělají schválně.</li>
                            <li>Vypadá to, že moje děti mají potěšení z mého utrpení.</li>
                            <li>Je pro mě těžké příjmout, že nás chtějí ranit, ale hádám, že to tak prostě bude.</li>
                            <li>[&#8230;] a chtějí nám ublížit, způsobit nám bolest a jsou tak vnitřně nešťastné, že nás chtějí vidět stejně nešťastné, proto nás viní z jejich vlastního neštěstí.</li>
                            <li>Tuším, že to je možná důvod k tomu, proč je to s nima tak těžké &#8211; dělají to schválně, protože si ubližují samy, tak chtějí ublížit i nám a tímhle způsobem se pojistit, že jim nemůžeme ublížit my.</li>
                            <li>Moje dcera ví, že mi ubližuje. Řekla jsem jí natvrdo, že myslí jenom na své potřeby a přání na úkor těch mých jenom proto, aby mi ublížila.</li>
                            <li>Někdy nám chtějí ublížit tím, že nám dávají najevo, že nás nepotřebují, i když to není pravda.</li>
                            <li>Myslím, že tak touží po tom, nám ublížit, že si neuvědomují, že nás tím vytlačují z jejich života&#8230; přesný opak toho, co si myslím, že ve skutečnosti chtějí.</li>
                            <li>Nerozumím, proč nám naše děti chtějí tolik ublížit a používají k tomu naše slabá místa. Jejich hrůzná, pomstychtivá povaha je zkrátka nad moje chápání.</li>
                            <li>Souhlasím s tím, že [&#8230;] se cítím, jako kdyby chtěli, abychom trpěli za naše zločiny, které &#8220;ani nestojí za řeč.&#8221;</li>
                            <li>Omlouvala jsem svou dceru. Nechtěla jsem věřit tomu, že by chtěla někomu schválně ubližovat. Zvlášť ne mě, její mámě, která ji tak milovala. Ale po mnoha letech v tomto pekle odcizení už věřím tomu, že nám naše děti neubližují jen tak omylem.</li>
                            <li>Moje dcera řekla&#8230; říká&#8230; nenávistné věci o mě jenom kvůli tomu, aby mi ublížila. To, že se mnou přerušila kontakt, by mohl být způsob, jakým chce získat nezávislost, ale úmyslné vylučování mě z jejího života je o tom, zranit MĚ.</li>
                            <li>Teď už vím, že to byla jenom metoda použitá k tomu, aby ublížila především mě &#8211; a dodnes nevím, proč má takovou radost, když trpím.</li>
                            <li>Ano, odmítání kontaktu s rodičem je týrání. Věřím, že moje dcery ví, že mi ubližují, ale bohužel, je jim to jedno.</li>
                            <li>Zdá se, že z toho mají potěšení, připadá mi, že MILUJOU, když jejich rodiče trpí. Čím víc rodič trpí, tím větší uspokojení maj. Ze všech ostatních příspěvků, co vidím, mi připadá, že to je celej jejich cíl, i když třeba souhlasí s tím, že se se svými rodiči přecijenom setkají. Zajistí jídlo, domluví se na návštěvě vnoučat a&#8230; neukážou se, nezavolají (schválně) a když už se ukážou, tak se chovaj odporně. A připadá mi to všechno záměrný. Rodiče jsou z toho naštvaní, smutní, v depresi a chtějí svým dětem vytrhat vlasy z hlavy. To je to, v co doufají, že uděláte a z toho, co čtu, tak opravdu věří tomu, že vám tím ničí život, což znamená, že jejich cíl je splněn.</li>
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="&#8220;Chci mu nařezat na prdel&#8221;" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-fantasizing-about-violence.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Mnoho členů s radostí vzpomíná na dny, kdy mohli svým zlobivým dětem jednu vrazit a přejí si, aby tyto dny bývaly nikdy neskončily.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Nový slogan pro DEN PRARODIČŮ&#8230;&#8230;&#8230;pořádně proplesknout dceru nebo syna a vmlátit do těch hnusných kreatur nějakej rozum&#8230;..a ano pořád miluju svojí dceru, což je neštěstí, ale nikdy není moc pozdě na to ukáznit svoje děcko! Bohužel o tom můžu jenom snít.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Nebyl by to krásný pocit dát svýmu dospělýmu dítěti ránu pěstí přímo do ksichtu?
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Jsem tak naštvaná, že bych tomu hajzlovi nejradši jednu vrazila, štěstí, že žije tak daleko.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Zavolala jsem svému muži, protože jsem brečela a byla jsem naštvaná a cítila další miliardu emocí, ale převládající byla chuť na to zbít ji jak psa!!!!!<br></br>
                            Mám už toho fakt dost, mám chuť mu ztřískat prdel.<br></br>
                            Jsem tak naštvaná a znechucená a opravdu ji nechci vidět, protože kdybych ji viděla, tak bych ji rovnou jednu vrazila a upřímně, myslím, že bych ji tím vším naštváním zabila.<br></br>
                            Nemůžu si pomoct, ale příjde mi, že pořádná facka by byla skvělá&#8211;ale nahrálo by jí to do jejich karet s tím, že by mohla říct: &#8220;vidíš, jak strašní jsou moji rodiče.&#8221; Vím, že nemluvíš o opravdové facce&#8211;ale někdy je těžké nepředstavovat si, jak ji fackuju. Dalo by jim to ale jenom další zbraň proti nám.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            A už jsou tady další svátky!! Ty jo, jak ráda bych svýho syna praštila do nosu!!!! Pamatuju si jedny Velikonoce, kdy byl malej, kdy jeho otec a já jsme spolu schovali vajíčka na zahradě přes noc po tom, co šel spát, aby je mohl jít hledat hned po tom, co se vzbudil&#8212;asi to už všechno zapomněl&#8211;jo, chci ho pořádně ztřískat a řvát u toho&#8211;&#8220;Prober se z toho&#8221; tak moc, ale nemám na to postavu jako Cher!!!!
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Jsem tak naštvaná, že mám chuť vlítnout do jejího domu, fláknout ji přímo tam, potom se otočit na místě a odejít. Jak si vůbec může dovolit takhle se ke mě chovat.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Nemyslím si, že jsme sebemrskači-bili jsme naše dítě docela důkladně!! Ale asi jsme měli ještě přitvrdit!!
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Mám opravdu co dělat, abych potlačila nutkání pořádně je profackovat, ale nedělá to dobrotu, když se chováte impulzivně. Věřte mi, každý z nás má občas takové nutkání.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Malý monstrum, který si myslí, že okolo něj obíhá svět, to je docela dobrý shrnutí mojí dcery&#8230;říkám jí Verruka Salt&#8230;&#8230;Nemám účet na Faceboku a vím, že kdyby se mi dostala do rukou, tak bych jí uškrtila na místě.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            ZAPOMEŇTE NA STARÉ SVÁTKY!! &#8211; UDĚLAL JSEM PRO NÁS NOVÉ!!!!<br></br>
                            <br></br>
                            Ahoj všichni,<br></br>
                            Už jsem si skoro zvykl na to NENÁVIDĚT (nemám to slovo rád, ale tady zrovna sedí) Vánoce, Valentýna, Velikonoce, Den matek, Den otců, narozeniny, výročí, svatby a ano, dokonce i pohřby, protože to jsou všechno RODINNÉ DNY!! &#8230;..Naše děti, které nás opustily neuznaly žádný z nich a to už několik let! Takže měním všechny ty &#8220;rodinné oooy gooy úžasné a sladké speciální příležitosti a SVÁTKY!<br></br>
                            Vzal jsem si svůj kalendář, který jsem dostal zadarmo v drogérii a zakroužkoval jsem v něm NOVÉ speciální dny, které budu oslavovat [&#8230;]<br></br>
                            &#8211; Růstávám pěkně od daleko od toho Dne m&#8230;k, místo něj slavím Den Ozbrojených Složek. Řekl jsem si, že bych ten den mohl trénovat na mém dvorku&#8230;.víte, jako Sarah Palin trénovala střílení na medvěda? No, hádejte, kdo bude MŮJ &#8220;cíl&#8221;? HA! Taková zábava! [&#8230;]<br></br>
                            &#8211; V listopadu jdu lovit na Aljašku se Sarah P. Budeme střílet na krocany a střílet na hliněné terče. Ooops! Zapomněl jsem na Volební Den v listopadu. Co se dá dělat, asi budu muset střílet krocany na mém dvorku a předstírat, že jsou vy-víte-kdo. (Jinak je na dvorku opravdu máme) [&#8230;]<br></br>
                            &#8211; V prosinci je můj nový oblíbený den Boxovací Den. Ale, to je další kanadský svátek. Asi vám ani nemusím říkat, co budu TENHLE den dělat! LOL. Jdu do obchodu koupit boxerské rukavice. Na tohle se opravdu potřebuju připravit předem. Musím být v kondičce a chci mít nějaké svaly! Tohle kolo neprohraju!
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Přesně tak, z 12 nových svátků se tři z nich skládaly ze střílení a mlácení něčeho, místo čeho si představuje své děti.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jedna z věcí, která vyčnívá &#8211; pokud tedy ignorujeme pomstychtivost a kontrolu &#8211; je to, že pro tyto rodiče, jejich děti nikdy nevyrostly. Tvrdí, že pokud by byl svět spravedlivý, byli by schopni zfackovat a zbít své děti tak, jak to dělali, když jejich děti byly malé a bezbranné. A nejenom, že by si to děti zasloužily, ale dokonce by se díky tomu mohly &#8220;konečně probrat.&#8221; Vtipkují o tom, že je jejich děti obviňují z týrání, což je dle nich nesmysl, ale zároveň tvrdí, že problém je v tom, že své rozmazlené děti nebili dostatečně, že je měli bít víc, jako členka, která řekla, že &#8220;je měla bít víc a častěji, když byly mladší!!&#8221;
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Tito rodiče poté přísahají, že své děti nikdy netýrali a už vůbec ne fyzicky.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Upřímně, nemyslím si, že většina z nich své děti fyzicky týrala. Ale problémy s touhou ovládat své děti, odmítání toho, uznat jejich zralost a autonomii, to není něco, co by se u rodičů magicky objevilo po tom, co jejich děti dosáhly 30 let a rozhodly se zablokovat si jejich telefonní čísla.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="&#8220;Nejsme dokonalí, oni ale taky ne&#8221;" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-perfection.html" author="Originál v angličtině" />

                    <PageParagraph content={
                        <Fragment>
                            Chuťovka. Jeden z věčných, nekonečných, neměnných výkřiků opuštěného rodiče: &#8220;Naše děti od nás očekávají dokonalost, ale my nejsme dokonalí, ale naše děti taky ne!&#8221;
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Nebyli jsme dokonalí (ale naše děti taky ne).
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Samozřejmě jsem nebyla dokonalá, ale oni taky ne.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Nejsme dokonalí, to je jasné. Jsme lidi. Nikdo není dokonalý, ani ostatní dospělí, kteří nás odsuzují.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Vypadá to, že si myslíš, že jsem tě zklamala, protože jsem neodpovídala tvé představě toho, jaká by měla být perfektní matka. Ale žádný člověk nemůže být dokonalý &#8211; všichni jsme svým unikátním způsobem nedokonalí.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Tahle myšlenka, kterou se furt zabýváme a kterou si furt vyčítáme pořád a pořád dokola, protože jsme nebyli absolutně PERFEKTNÍ rodiče a kvůli tomu se s námi naše děti rozhodly přerušit kontakt je absurdní. Jsou naše dospělé děti perfektní rodiče? Nejsou. A řekla bych, že mnoho z nich je horšími rodiči než jsme byli my.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Tato domněnka se objevuje neustále a neustále dokola se slovosledem tak podobným, že příspěvky vypadají skoro jako přes kopírák. Jen velmi výjimečně člen, který podrobně líčí svůj příběh, nepoužije nějakou verzi věty &#8220;Nebyla jsem perfektní matka, ale&#8230;&#8221;
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Základní logika je tato:
                        </Fragment>
                    } />
                    
                    <PageNumberedList content={
                        <Fragment> 
                            <li>Změny v mém chování, které po mě požaduješ, jsou nepřiměřené. Vyžaduješ dokonalost.</li>
                            <li>Nemáš právo vyžadovat dokonalost, pokud sám nejsi dokonalý.</li>
                            <li>Takže &#8211; pokud nejsi dokonalý, nemáš nárok na to, žádat mě, abych se v něčem měnil.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Je to perfektní způsob, jak přinést diskuzi do mrtvého bodu. Přání dítěte jsou zamítnuta, rodič si oddechne, že na sobě nebude muset nic měnit, dítěti je připomenuto, že je minimálně nedokonalé jako rodič a rodič si připisuje drobné morální vítězství. Není divu, že se opuštění rodiče v této domněnce rochní jako prasata v žitě.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Citáty opuštěných rodičů" />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Ošklivá skutečnost je taková, že naše opuštěné děti nechaly své rodiče HNÍT bez toho, aniž by se s nimi alespoň rozloučily. To je ta nejvíc sobecká, krutá, opovrženíhodná a ohavná věc, kterou může lidská bytost druhé způsobit, zvláště když ta druhá osoba neměla v srdci nic jiného, než lásku pro ně. Nikdo není perfektní, naše děti nejsou perfektní, také mají chyby, museli jsme řešit jejich špatné chování, když vyrůstaly, museli jsme řešit jejich nemoce, jejich dramata. Kdyby oni byli perfektní lidé, tak by si zasloužili perfektní rodiče, ale nic jako perfektní člověk na této planetě neexistuje. Cítí se nad námi nadřazené, i když ještě před nimi stojí hodně vyrůstání. Jejich životy nebudou perfektní, jejich přátelé nebudou perfektní, jejich děti nebudou perfektní, na jejich cestě života je ještě čekají velká překvapení. Každopádně, v mém případě, jsem byla ponechána k tomu HNÍT jako nějaké přejeté zvíře u cesty.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Jak si ty děti vůbec můžou myslet, že byly tak speciální, aby si zasloužily super-perfektní rodiče, to jde mimo moje chápání. Snaha o perfektnost je jenom cvičením v marnosti a často přemýšlím, jak se s tím smíří, až jejich děti vyrostou a budou se k nim třeba chovat stejně jako ony k nám.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Existuje jenom jedna bytost, která kráčela na téhle zeměkouli a která nikdy neudělala nic špatného. Všichni jsme udělali něco špatného. Neexistuje ŽÁDNÝ perfektní rodič. Z nějakého šíleného důvodu od nás naše děti očekávají dokonalost a k tomu všemu říkám tohle: pojďme si počkat, než budou mít svoje vlastní děti, které budou v pubertě a které je potom v dospělosti taky opustí. To se pravděpodobně stane! Potom uvidí, kdo jsou perfektní rodiče &#8212; lol!
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Můj názor je takový, že my, rodiče &#8211; kterým bylo tolik ublíženo &#8211; si vytyčujeme příliš velké mantinely a naše opuštěné děti to vědí &#8211; a využívají toho tak&#8230;.. že nám dávají vědět, že nejsme perfektní lidé, jací si myslíme, že bychom měli být &#8211; perfektními lidmi, kteří ale neexistují.<br></br>
                            Myslím si, že kdybychom odstranili ten &#8220;terč&#8221; perfektnosti z našich zad, tak by se hněv našich dětí přesměroval na lidi, kteří si to zaslouží &#8211; jako třeba, v mém případě, na mojeho exmanžela 🙂<br></br>
                            Viděla jsem tu mnoho příspěvků o dětech, u kterých se mi zdá, že jsou přitahovány k nedokonalému (a to říkám jen velmi mírně) rodiči nebo prarodiči, ale zároveň nenávidí nás, kteří se k nim chovali co nejlépe. Myslím, že když se na to podíváme blíže, tak zjistíme, že ti nedokonalí lidé nemají na zádech žádný &#8220;terč&#8221; a díky tomu se nestávají terčem nenávistných útoků jako my.<br></br>
                            Neexistuje logický důvod, aby byl někdo naštvaný na dalšího člověka kvůli tomu, že není dokonalý.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Neexistují perfektní rodiče ani perfektní děti. Pokud dospělé dítě schválně opustí a přeruší kontakt se svým rodičem nebo sourozencem, tak si myslím, že je na dospělém dítěti (vím, že je to oxymoron, ale žádný jiný termín mě nenapadá), aby vysvětlilo, proč. A ne aby místo toho přidávalo do situace další bolest tím, že mlčí. Jak se může rodič omluvit, když neví, co udělal špatně a proč ho jeho dítě opustilo? Kdo rozhoduje o tom, že chování dospělého dítěte JE absolutně oprávněné, zatímco rodič je vinen z NĚČEHO a musí něco napravovat? Co se stalo shovívavosti a Zlatému pravidlu?
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="&#8220;Kontaktuju své opuštěné dítě kvůli sobě, ne kvůli němu&#8221;" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-contacting-for-me.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Opuštění rodiče často prohlašují, že se nesnaží kontaktovat své děti pro dobro svých dětí, ale pro své vlastní dobro. Někdy se cítí, že se o kontakt musí snažit, aby tím udrželi svou naději naživu; někdy mají pocit, že úplné přerušení kontaktu by znamenalo, že jejich dítě &#8220;vyhrálo.&#8221;
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Samozřejmě jsem pro ni podle ní nikdy neudělala nic dobrého, takže teď udělám něco, co je dobré pro mě. Jsme matky a babičky a jak si někdo vůbec dovoluje si myslet, že bude o takových věcech rozhodovat? Můžou chtít, abychom my, matky a babičky, zmizely z jejich životů a abychom pro ně byly považovány za mrtvé&#8230;&#8230;ale my s tím nemusíme souhlasit!!
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Rodiče mohou být výřeční v případě, kdy se snaží ukázat svůj hlad po vztahu se svými dětmi, po potřebě toho, aby jim někdo potvrdil, že jsou dobří rodiče a po potřebě ventilovat svoji frustraci ohledně omezení, která byla vytyčena jejich dětmi. O čem až tak výřeční nejsou &#8211; a co otevřeně odmítají řešit &#8211; je reakce jejich dětí na nežádoucí kontakt. Tato konkrétní domněnka se objevuje, když rodič už ani nechce předstírat, že mu jde o blaho svého dítěte a chce s ním mít vztah výhradně kvůli výhodám, které mu z takového vztahu plynou.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="&#8220;Nechtěný kontakt není pronásledováním&#8221;" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-not-stalking.html" author="Originál v angličtině" />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Když jsem byla vytrvalá ve snaze dovolat se svému synovi, tak jsem to opravdu nebrala jako pronásledování. Pronásledování? Snažila jsem se jenom opravit něco, co jsem brala jako TRHLINKU.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Opuštění rodiče, kteří se stanou pravidelnými členy fór pro opuštěné rodiče, věří tomu, že se musí snažit zkontaktovat své děti za každou cenu, aby jim dali najevo, že je milují. A nezáleží přitom na tom, že jim jejich dítě už několikrát řeklo, že si jakýkoliv kontakt nepřeje.  Posílají svým dětem textové zprávy a emaily, dokud si je jejich děti nezablokují; posílají jim pohlednice a přání na narozeniny a svátky, i když ví, že je jejich dítě rovnou vyhazuje do popelnice nebo je nechá posílat zpátky; někdy se je rozhodnou navštívit doma nebo v práci, aby svoje dítě donutili ke konverzaci. Rodiče věří, že pokud se nebudou snažit, tak budou jejich děti tvrdit, že už je jejich rodiče nemilují. Když takovým rodičům starší a zkušenější členové fóra radí, že mají kontakt utlumit, tak to odmítají s tím, že se přece nebudou vzdávat svých dětí a nebudou ke svým dětem tak nenávistné jako jsou ony k nim.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Mezitím se jejich děti cítí terorizované. Pokaždé sebou trhnou, když zase vidí jméno svých rodičů na jejich telefonu nebo ve schránce. Děsí se toho, že se jejich rodiče objeví u jejich dveří. Myšlenka toho, že dostanou nevyhnutelný sváteční telefonát jim celé svátky kazí. Instalují bezpečnostní kamery, říkají sousedům, ať nedávají lidem, kteří se na ně ptají, žádné informace, dávají ochrance ve svém zaměstnání fotky svých rodičů, blokují své rodiče na každém komunikačním kanálu a přemítají o tom, zda mají zavolat policii, když jejich rodiče klepou na dveře a odmítají odejít. Teror, který tyto dospělé děti cítí a z kterého jim je zle od žaludku, se nijak výrazně neliší od toho, jak se cítí lidé, kteří jsou například pronásledováni bývalým partnerem.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Nic z toho si rodiče neuvědomují.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Opuštěným rodičům chybí porozumění &#8211; nedokáží si představit, jak se děti ohledně nechtěného kontaktu cítí. Samotné vyhýbání se jim překládají jako vinu, odmítnuté dárky jako záměrné pokusy o to, pokusit se jim ublížit, zavolání policie si překládají jako snahu o to, je veřejně ponížit. Chování svých dětí nálepkují hromadou slov &#8211; sobecké, pomstychtivé, kontrolující, nezralé &#8211; ale už se nepokusí o to, situaci porozumět z perspektivy jejich dítěte. Jak to jeden z takových rodičů říká:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Bohužel, některé děti vidí naše pokusy o to, ukázat jim lásku, jako pronásledování. Jsou jako puberťáci, kteří se snaží ukončit vztah s přítelem nebo přítelkyní. Vidí to jako: &#8220;běž ode mě pryč&#8221; místo toho, aby viděli, že se jedná o jejich rodiče a že by se měli chovat jako dospělí.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Když musí konfrontovat důkazy, že se s nimi jejich dítě opravdu nechce setkat, tak je rodiče minimalizují (&#8220;Co jsme mu kdy udělali? Nic, kromě toho, že jsme mu říkali, že ho milujeme!&#8221;), odmítají (&#8220;Říká to jenom proto, že jí to její manžel nařídil!&#8221;), dožadují se vysvětlení (&#8220;Proč by se mě měla bát? Jsem slabá stará žena, která by neublížila ani mouše!&#8221;), prosazují si názor, že dělají správnou věc (&#8220;Jak může toto odloučení skončit, když si o tom ani nepromluvíme?&#8221;) a zamítají emoce svých děti. Sledovat takové chování je ta nejtěžší část. Častý přístup rodičů v takových případech je takový, že dospělé děti by se takto cítit neměly, takže jejich nesouhlasné emoce mohou být ignorovány. I když dostanou opuštění rodiče přímé, tvrdé a ničím neobalené vysvětlení toho, jaké má jejich chování vliv na jejich děti, tak odmítají uvěřit čemukoliv, co je proti jejich představě toho, jak má vztah vypadat.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Postupem času někteří rodiče pošlou tolik dopisů bez jediné odpovědi nebo dostanou tolik negativních odpovědí, že si uvědomí, že takový nechtěný kontakt jim nepřinese výsledky, které by si přáli. Přestanou tedy zkoušet kontaktovat své děti? Um&#8230; ne. V kontaktování svých dětí nepoleví, protože to je něco, co oni, rodiče, potřebují. Takže třeba člen řekne: &#8220;Neočekávám, že dostanu nějakou odpověď, ale neposílám mu ty dopisy kvůli němu. Posílám je kvůli sobě.&#8221; &#8220;Dělej, jak myslíš,&#8221; řeknou mu ostatní členové. Takže kontakt pokračuje. Jejich dítě je stále terorizováno. A rodič je stále opuštěný. Nic se nemění až na to, že má dítě pravidelné přípomínky, že rodič nerespektuje jeho bariéry. Rodiče zároveň cítí, že nějaký kontakt s dětmi přecijenom mají, takže v tom pokračují, což má za následek to, že se jejich dítě ve svém rozhodnutí o tom, se s nimi nestýkat, ještě utuží.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="&#8220;Týrání je velmi vzácné&#8221;" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-abuse-is-rare.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Členové fór pro opuštěné rodiče se shodují na tom, že týrání je akceptovatelným důvodem k tomu, aby někdo své rodiče opustil. Ale mají zároveň velmi vysoké standardy pro to, co berou jako týrání.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Tohle je můj list:
                            <PageNumberedList content={
                                <Fragment> 
                                    <li>Kdyby je rodiče nechali několik dnů bez jídla.</li>
                                    <li>Kdyby je rodiče nutili k sexu s cizími lidmi nebo by prodávali jejich těla k sexu nebo k natáčení porna.</li>
                                    <li>Kdyby je bili do krve nebo bezvědomí.</li>
                                    <li>Kdyby je pálili cigaretama.</li>
                                    <li>Kdyby je nutili brát tvrdé drogy.</li>
                                </Fragment>
                            } />
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Týrání dle nich musí být opravdu extrémní, aby bylo dostatečným důvodem k opuštění svých rodičů. Mírné fyzické násilí nestačí; musíte zbít své děti do krve nebo je pálit cigaretami. Legální drogy jako alkohol se nepočítají. Mírné zanedbávání se nepočítá, jenom zanedbávání tak těžké, že by děti musely mít velké štěstí, aby ho vůbec přežily.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Emoční týrání není legitimní důvod &#8211; je příliš snadné z něj někoho obvinit, je velmi těžké dokázat a je také velmi pravděpodobné, že z takového týrání byl člen sám obviněn. Mnoho členů těchto fór se rozvádělo s partnery, kteří je týrali a volně popisují své bývalé partnery jako emoční tyrany, ale všechna tvrzení o emočním týrání, z kterého je obviňují jejich děti, jim příjdou vysoce podezřelá:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Kdo prohlásil, že tě tvoji rodiče ostatní emočně týrají &#8212; ty? Nebo to byla nějaká pop psychologická knížka, kterou sis pořídil, aby ses cítil dobře o sobě a o tvém chování, abys mohl obviňovat svoje rodiče ze všech svých problémů, abys nemusel brát zodpovědnost za nic? A co vůbec udělali, aby si zasloužili takovou nálepku? Nedovolili ti, abys nosil do školy jeany s dírama? Opozdili se dvakrát za sedm let o pár minut, když tě vyzvedávali z tvých lekcí piana? Cítil ses kvůli něčemu provinile, aby tě donutili k tomu, respektovat domácí pravidla? Božínku, ty si asi myslíš, že bys nikdy ve svým životě neměl zažít negativní emoce.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            S termínem &#8220;emoční týrání&#8221; se tu poslední dobou nějak roztrhnul pytel. Ti, kterým bylo &#8220;ublíženo,&#8221; popřípadě ti, kteří byli &#8220;poškozeni&#8221; nebo ti, kteří byli &#8220;obětmi&#8221;, kteří si stěžují kvůli tomu, že se museli srovnat s nějakými negativními pocity o sobě nebo je jejich nedokonalí rodiče ukáznili, používají pocity viny nebo hanby k tomu, aby je tu ostatní lidi litovali a tím znehodnocují samotné slovo &#8220;týrání,&#8221; zahazují zde svou vlastní důvěryhodnost a také snižují zážitky dětí, které zažily opravdové fyzické, sexuální nebo emoční týrání. Děti, které byly opravdu týrány, by se smály do obličeje té hromadě &#8220;ublížených dětí,&#8221; jejichž největším problémem v životě bylo poprat se s několika problémy souvisejících s jejich malým sebevědomím. Je tu vůbec někdo, kdo nikdy neměl potíže se sebevědomím za celej jejich život?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Opuštění rodiče považují sami sebe za rozhodčího o tom, co je skutečné týrání. Koneckonců, většina z nic týrána byla:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Moji sourozenci a já jsme museli třináct let trpět pod páskem, kterým nás bil náš nevlastní otec. Naše matka nás nikdy nebila, ale nikdy ho také nezastavila a zůstala s ním celé ty roky. Matka zemřela v jedné maličké vesničce v Arizoně&#8230;přišlo se s ní rozloučit přes 300 lidí&#8230;Všichni ji tam měli rádi. Nemohl jsem se přinutit k tomu, abych na jejím pohřbu mluvil u mikrofonu. Bál jsem se, že bych to nevydržel a řekl to. Také týrala děti&#8230;&#8230;&#8230;&#8230;.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Když se mnou měla moje matka problém a chtěla ho se mnou rychle vyřešit, tak si počkala, než můj otec přišel domů, donutila se k hysterickému breku a už jsem slyšel, jak na mě jde můj otec, samozřejmě s páskem.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Můj otec mě bil páskem úplně za všechno a myslím, že to jeho i moji mámu vzrušovalo. Nikdo se nám, dětem, nesnažil pomoct a dokonce vím, že o tom sousedi věděli. Můj otec byl ale místní velký zvíře, tak si na něj nikdo netroufnul.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            K takovým příběhům většinou členové doplní: &#8220;ale ať už to bylo sebehorší, nikdy jsem svoje rodiče nebo svoji rodinu neopustil.&#8221;
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Takže, když děti, které své rodiče opustily, tvrdí, že byly týrané, členové fór pro opuštěné rodiče k nim jsou velmi skeptičtí. Jedno dospělé dítě napsalo:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Napíšu ti něco o sobě. Je mi 38, mám extrémně malé sebevědomí, každý den myslím o sebevraždě, nikdy jsem neměl dlouhodobý vztah a nedokážu v noci ani pořádně spát.<br></br>
                            Tohle všechno jenom kvůli neustálému týrání ze strany ženský, která si říkala moje matka a kvůli otci, který to celé sledoval. Denodenně mě trápili, tvrdili mi, že jsem debilní (i když jsem měl skoro samé jedničky a občas nějakou dvojku), říkali mi, že nejsem dobrej v ničem (což není pravda), trestali mě i za to, když jsem rozlil mlíko. Za cokoliv horšího automaticky bití páskem. Říkali mi, že nikdy v životě neuspěju, že si nikdy nenajdu nikoho, kdo by si mě chtěl vzít (i když si většina lidí myslí, že vypadám jako model). Slova mohou bolet navždy, každý den brečím, protože vidím ostatní lidi, kteří mají normální život a když si uvědomím, že to je něco, co nikdy mít nebudu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jeden člen na to odpověděl:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Myslíš si, že si to &#8220;užívala,&#8221; když ti ubližovala? Pořád se o tebe starala&#8211;proč asi? A proč mluvíš o všech lidech na celém světě?<br></br>
                            <br></br>
                            Evidentně byla dostatečně dobrá na to, aby měla fungující manželství&#8211;to beru z toho, že jsi řekl, že byli celou dobu spolu&#8211;je evidentně &#8220;vítaná,&#8221; protože ji lidé zvou na různé akce a ona se tam ukazuje. Takže VÍ, jak pracovat s různými typy vztahů, je to tak?<br></br>
                            Takže když vidíš, jaké měla role v rodině &#8211; a srovnám to s tím, co tvrdíš ty, tak jsem velmi zmatený z toho, že by někdo takový vůbec viděl nějaké hodnoty v rodinných rolích&#8211;opravdu říkáš, že byla zaměřená jenom na tebe&#8211;a nebo týrala úplně všechny okolo? Nebyly v tvém dětství žádné &#8220;dobré dny,&#8221; ani jeden? Nemáš žádné dobré vzpomínky? Nepodporovala tě ani v jednom procentu věcí, co jsi dělal, je to tak?<br></br>
                            Jenom se snažím dostat tu opravdový obrázek a nedává mi moc smysl, takže bys mi to možná mohl objasnit většími detaily. Rozumím pásku&#8211;ale v tvém věku byly fyzické tresty běžné&#8211;a nebylo to bráno jako týrání&#8211;a je možné, že jsi byl prostě jenom vychováván páskem, ne opravdu bit?<br></br>
                            A ty jsi jí dal vysvědčení, na kterém byly samé jedničky a pár dvojek a ona ti opravdu řekla, že jsi hloupý?<br></br>
                            Ještě dodám to, že se nesnažím tě z čehokoliv vinit, jen se snažím porozumět tomu, odkud bereš svoje domněnky.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Fakt, že člověk, kterému odpovídá, se rozhodl kontakt se svými rodiči přerušit, je dost k tomu, aby tomuto členovi přišel podezřelý. Přecijen, opuštění rodiče si vybavují hromadu případů, kdy děti obviňují své rodiče z něčeho, o čem rodiče vědí, že není pravda. Proč by další dítě v řadě mělo být jiné?
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            A konec konců, většina členů byla sama týrána jejich rodiči, takže ví, že skutečné příběhy týrání jsou velmi vzácné.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Opravdu týrané dítě odpovídá" />
                    <PageParagraph content={
                        <Fragment>
                            Členové fór pro opuštěné rodiče tvrdí, že dospělé děti tyranů, které byly opravdu týrané, souhlasí s jejich posouzením toho, co oni berou jako týrání:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Ti, kterým bylo &#8220;ublíženo,&#8221; popřípadě ti, kteří byli &#8220;poškozeni&#8221; nebo ti, kteří byli &#8220;obětmi&#8221;, kteří si stěžují kvůli tomu, že se museli srovnat s nějakými negativními pocity o sobě nebo je jejich nedokonalí rodiče ukáznili, používají pocity viny nebo hanby k tomu, aby je tu ostatní lidi litovali a tím znehodnocují samotné slovo &#8220;týrání,&#8221; zahazují zde svou vlastní důvěryhodnost a také snižují zážitky dětí, které zažily opravdové fyzické, sexuální nebo emoční týrání. <em>Děti, které byly opravdu týrány, by se smály do obličeje té hromadě &#8220;ublížených dětí,&#8221; jejichž největším problémem v životě bylo poprat se s několika problémy souvisejících s jejich malým sebevědomím.</em>
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Na fóru pro dospělé děti s narcistickými rodiči, si člen s přezdívkou &#8220;fucktheseshitheads&#8221; vzpomněl na epizodu s jeho rodiči, která otřásla i členy tohoto fóra, které jen tak něco nerozhodí a následně dodal:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            V mém posledním příspěvku jsem dostal mnoho komentářů typu &#8220;No, moje situace není tak hrozná jako tvoje, ale&#8230;&#8221; a můj názor je, že takový způsob přemýšlení není dobrý. Myslím, že v místě plném podpory jako je toto místo, není místo k tomu, porovnávat, koho rodiče byli horší. Všichni jsme to měli těžké. Všichni si zasloužíme něco lepšího. <strong>Naše zkušenosti jsou opravdové a nejsou o nic víc nebo o nic míň opravdovější než zkušenosti jiných.</strong> Moji rodiče milují ospravedlňování jejich týrání tím, že mi říkají, že JEJICH otec by mě zbil jak psa za to, co říkám/dělám (jenom když jsem si stál za tím svým.) ONI by byli na ulici pro X, Y, takže bych měl být šťastný za MOJI matku, protože aspoň není alkoholička jako ta JEJÍ, blablabla.<br></br>
                            <br></br>
                            Jako jasně &#8211; mohl bych mít rodiče, kteří by byli totální narcističtí psychopati, kteří by mě doslova drželi ve sklepě a mučili mě jako v seriálu Zákon a Pořádek. To samozřejmě nedělali, ale moji rodiče byli i tak otřesní a způsobili mi mnoho bolesti. V jádru my všichni &#8211; MY VŠICHNI &#8211; ať už se jedná o ty, na které je matka hrubá kvůli jejich tloušťce a neustále o ní mluví až po ty, co musí prožívat můj hypotetický scénář ze Zákona a Pořádku &#8211; všichni trpíme, protože jsme byli zrazeni. Lidi, kteří nás měli ochraňovat, dělali přesný opak. Udělali nám to vědomě. Myslím, že nezáleží na tom, jak se to zranění na nás projevilo, ale na tom, že tam je. Naši rodiče nám občas chtěli ublížit a ublížili.<br></br>
                            Tyrani chtějí, abyste o sobě měli pochybnosti a říkali si, že na tom nejste tak špatně. Moji rodiče jsou tak dobří v ospravedlňování jejich chování, že jsem před posláním mého původního příspěvku ani neměl tušení, že oni byli jedni z těch nejhorších.<br></br>
                            Nenechte je.<br></br>
                            Seru na kontext.<br></br>
                            Ať už byla vaše zkušenosti jakákoliv, tak vás o něco obohatila.<br></br>
                            Ať už byla vaše bolest jakákoliv, byla dostatečná.<br></br>
                            Nepotřebujete žádné povolení k tomu, abyste vyprávěli váš příběh.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="&#8220;Opravdovým tyranům to je jedno&#8221;" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-real-abusers-dont-care.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Členové fór pro opuštěné rodiče tvrdí, že rodiče, kteří opravdu týrali své děti vědí o tom, že je týrali. Tyranům by prý na dětech nezáleželo a bylo by jim jedno, že se s nimi jejich dítě nestýká, takže by ho nikdy nehledali a nepřišli by do těchto komunit, aby se pokusili vztah s dětmi vyléčit. To, že se snaží uzdravit vztah je samo o sobě důkaz, že své dítě nikdy týrat nemohli.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Tyrani milují sami sebe nejvíc a nikdy by necítili potřebu obracet se na fóra jako tohle.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Hlavní pointa je ta, že podle mě, opravdoví tyrani by netrpěli tolik, jako trpíme my a nepoužívali by tuhle stránku jako my.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            My jsme nikdy děti netýrali. Záleží nám na nich, proto jsme tu.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Pokud bych ji bila a nadávala ji, tak bych tomu rozuměla. Ale, víš, lidi, kteří svým dětem udělali hrozné věci tu určitě nejsou, protože těm je to fuk. Tyrani dělají to, co dělají a nelitují toho.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Co je ironické, je to, že většina členů fór svoje rodiče popisuje jako tyrany. Vědí, jak vypadá týrání a jak tyrani přemýšlí. Nicméně, tyto zkušenosti škatulkují. Vytváří si nějakou vlastní představu o tom, jací tyrani doopravdy jsou:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Tyranům jsou ukradené narozeniny jejich dětí, nebo Vánoce, nebo to, kdy jejich děti dokončily školu. Takže pochybuju, že bychom byli tyrani.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            A jak vypadají oběti:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Lidi, kteří byli opravdu týráni, o tom chtějí mluvit, chtějí omluvu a chtějí od tyrana vysvětlení toho, proč. A mají potíže, se přes to &#8220;proč&#8221; dostat.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Připadá mi, že dospělé &#8220;děti,&#8221; které byly opravdu týrané, na takové stránky nechodí a netouží po validaci od ostatních lidí. Opravdové oběti by hledaly profesionální pomoc a nebo by žily kompletně osamoceně.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            A jsou si absolutně, ale naprosto jisti tím, že jejich děti nikdy týrány nebyly:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Vím, že můj syn nebyl týraný. Myslím si, že to je první věc, která lidi napadne, když se dozvěděj, že se s tebou dítě už nestýká.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            A ne, nebyla týraná. Rozmazlila jsem ji. A ona mě nenávidí. Jak se to proboha stalo?
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Můj příspěvek byl o dětech, které si nechaly nakukat do hlavy nesmysly&#8230; ne o dětech, které byly skutečně týrány. A týráním nemyslím to, že si vymyslely příběhy o týrání jenom protože se jim nelíbila nějaká domácí pravidla. To není týrání&#8230; to je přepsaná historie&#8230; předělaná tak, aby podporovala jejich tvrzení, že je rodiče týrali. A stejně jako se vším v životě tu jsou taky různé stupně týrání a za týrání můžou většinou lidi, kteří se dostatečně neptali nebo se třeba neptali přesně a domnělá oběť, ať už s vysvětlením nebo ne, se cítí být oprávněná k tomu, aby dělala cokoliv, co &#8220;chce&#8221; &#8211; jako třeba odřezávání svých rodičů ze života a způsobování multigeneračních dysfunkcí a to jenom na základě svých vymyšlených příběhů. Mějte na pamětí, že tu nemluvím o skutečně týraných dětech, jen o těch&#8230; co si to vymýšlí.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="&#8220;Naše děti si ve skutečnosti nemyslí, že jsme je někdy týrali&#8221;" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-our-children-dont-think-were-abusive.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jednoho dne, jedna členka poslala na fórum otevřený dopis své opuštěné dceři a zeptala se ostatních členů na to, jestli by k němu něco nepřidali. Dopis začal následovně:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Dopis Odcizené<br></br>
                            <br></br>
                            Pro odcizenou:<br></br>
                            Pokud tě tvoji rodiče netýrali a milovali tě, jak ospravedlňuješ to, že jsi je od sebe odřízla do konce života? Pokud se snažili napravit svoje &#8220;hříchy&#8221; a ty jejich nápravu neakceptuješ, nehraješ si tu náhodou na samozvaného soudce a nesnažíš se na nich vybít svůj vztek? Jak ti tohle tohle pomáhá? A tvým rodičům? A našim vnoučatům? Nerozumím tomu a nikdy nebudu rozumět. Všichni jsme bytosti, které se někdy mýlí a dělají chyby. Nechápu, jak můžeš něco takového udělat svému rodiči, který tě tak miluje a tolikrát se snažil všechno napravit, jak to můžeš udělat? Já osobně bych nikdy nebyla schopná toho, udělat něco takového svým rodičům a ani jeden z nich není dokonalý. Dokonalost neexistuje. Máš vůbec tušení, jakou ohromnou bolest nám to způsobuje? Myslím, že si to ani nedokážeš představit.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <em>Pokud tě tvoji rodiče netýrali a milovali tě, jak ospravedlňuješ to, že jsi je od sebe odřízla do konce života?</em>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tato otázka je pilířem skoro každého odloučení. Je to velmi temná příčina toho, proč se mnoho členů se svými dětmi nikdy neusmíří. &#8220;Pokud tě tvůj rodič nikdy netýral-&#8221; členové říkají a jejich děti je v tu ránu přeruší a řeknou: &#8220;Ale dyť on mě týral.&#8221;
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            &#8220;To jsi to jen špatně pochopil,&#8221; odpoví členové. &#8220;Jenom jsi špatně pochopil normální výchovné metody a vyložil sis je jako týrání. Jsi rozmazlený; očekáváš od svých rodičů dokonalost. Vymýšlíš si příběhy o týrání, protože to je dneska v módě, protože si potřebuješ nějak omluvit svoje vlastní chování a nebo ti to do hlavy nakukal tvůj partner. Nevěříš doopravdy tomu, že jsi byl týraný.&#8221;
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Členové to jen velmi vyjímečně napíší takto přímo, ale tenhle typ myšlení je u nich zcela zřejmý. Například, když tvrdí, že si jejich děti vymýšlí a lžou o skutečném důvodu opuštění:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Naše děti předstírají, že jsme špatní lidé, aby se necítily špatně o věcech, které udělaly. Bůh chraň, aby za něco nesly zodpovědnost!
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Mnoho z našich opuštěných dětí lhalo o tom, že jsme je fyzicky nebo emočně týrali, aby tím ospravedlnily svoje hrozné chování. Jak jinak by mohly ospravedlnit takové zneuctění jejich rodičů?
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            &#8220;Týrání&#8221; je použito jenom jako výmluva, protože naše děti nemají žádný SKUTEČNÝ důvod k tomu, proč nás opustily. A vědí, že kdyby to řekly skutečně tak jak to bylo, tak by to nikdo nebral vážně a lidi by jim neprojevili žádné sympatie, pokud by řekly: &#8220;Dostal jsem každý týden od mých tyranů jenom stovku kapesného!!&#8221; nebo &#8220;Moji tyrani trvali na tom, že musím dělat otrockou práci tím, že vynesu odpadky! Wahhhhhhhhhh.&#8221; A ano, mnoho z nich si užívá hraní na obětí.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Takže si rodiče vytvoří vlastní &#8220;odlehčené&#8221; důvody k tomu, proč je děti opustily:
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment> 
                            <li>Naše děti nás opustily, protože to je dneska v módě.</li>
                            <li>Rády si hrají na oběť.</li>
                            <li>Stydí se za sebe.</li>
                            <li>Známe je příliš dobře a nechtějí, abychom o nich ostatním lidem řekli pravdu.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A můj nejoblíbenější:
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment> 
                            <li>Jsme příliš dobří a naše děti vědí, se nám nemůžou nikdy vyrovnat.</li>
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                             Možná&#8230; a říkám, že jenom možná&#8230; možná se naše děti cítěj provinile, protože věděj, že se nám nikdy ve slušnosti nevyrovnaj, takže na nás házej špínu místo toho, aby se jejich chybám a nedokonalostem postavily čelem a pracovaly na tom, aby se z nich také stali slušní lidé.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jsou tu dvě možné příčiny. První je něco jako cucání si vlastního palce &#8211; &#8220;Moje děti se mnou nemohly přerušit kontakt kvůli něčemu, co jsem udělala. Lžou.&#8221; Je to velmi slabá obrana, kterou většina členů nepoužívá. Dokonce i kus citace výše byla pouhá jednorázovka od členky, která to, že se s ní dcera nestýká, vysvětluje rodičovským odloučením.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Druhá příčina je trochu hlubší: a tou příčinou je tak velký nedostatek empatie, že si člen nejenom myslí, že byl skvělý rodič, ale nedokáže si ani představit to, že někdo může přemýšlet jinak, než on. Teoreticky to tito lidé chápou. A pokud nejsou ve stresu, tak se možná i dokáží přenést do pocitů někoho jiného, ale pokud se cítí ublížení nebo ohrožení, tak nevědomky myslí pouze na sebe. Ti normálnější členové říkají věci jako &#8220;Nerozumím, proč se vzdávají veškeré lásky a laskavosti, kterou jsme jim dali,&#8221; když je každému normálnímu člověku jasné, že děti na jejich lásku a laskavost mají velmi odlišný názor než oni. Nebo &#8220;Je ohavné, že svoje vnuky ochuzují o naši lásku jenom kvůli tomu, aby nám ublížili,&#8221; když jim jejich děti řekly, proč jim nedůvěřují s jejich vnuky. Členové fór neustále žasnou nad tím, že si jejich děti nevykládají pohledy, dárky a nečekané návštěvy jako projevy lásky a cítí zmatek a hněv, když jejich děti berou jejich přirozenou mateřskou lásku jako pronásledování. Ti nejhorší z rodičů kompletně ignorují jakákoliv přání a pocity dítěte a nahrazují je vlastními pocity: Nezáleží na tom, co ty říkáš, oba víme, jak to doopravdy je a pravda je taková, že jsem byl skvělý rodič.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="&#8220;Chtějí, abychom je naháněli&#8221;" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-they-want-us-to-chase-them.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jedna ze zvláštních domněnek, která se občas vyskytne na fórech pro opuštěné rodiče je domněnka, že dospělé děti se svými rodiči přerušují kontakt kvůli tomu, že <strong>chtějí</strong>, aby je rodič naháněl.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Přítelkyně mi řekla, ať svému synovi už přestanu psát emaily, protože z toho má on a jeho manželka potěšení, protože určitě milují pocit toho, že je někdo nahání. A díky tomu cítí, že nade mnou mají moc.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Je to musí nějakým způsobem uspokojovat, když se před nimi někdo plazí, prosí je a stále se snaží. Připadají si jako někdo, koho nelze ignorovat. Určitě se jim to líbí.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tato domněnka pramení z pocitu bezmoci, který rodiče cítí. Cítí se jako kdyby žebrali, když se snaží navázat opětovný vztah se svými dětmi, i kdyby byl třeba jenom vzdálený. Když se za něco omlouvají, tak mají pocit jako kdyby se někomu plazili u nohou. Bojí se, že pokud se omluví, tak to jejich dětem potvrdí myšlenku, že rodiče jsou špatní a děti dobré &#8211; a tím svým dětem nad sebou dají určitou moc. A když se jim podaří obnovit kontakt, i když třeba probíhá jenom textově, tak rodiče vědí, že jejich děti budou chtít, aby se dodržovala určitá pravidla a hranice, které jejich děti určitě používají k tomu, aby nad nimi měly kontrolu a kvůli kterým budou muset chodit kolem svých dětí po špičkách. Předpokládají, že jejich děti mají radost z takového pocitu moci a smějí se, když se jejich rodiče poníží k tomu, na takové podmínky přistoupit. Takže mnoho z těchto členů si myslí, že jejich děti je odřízly jenom kvůli tomu, aby nad nimi měly moc.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Výsledek je takový, že mnoho členů si vykládá své rozhodnutí nechat své děti na pokoji, jako způsob, jak dostat svou moc zpátky. Přisahají, že se už přestanou plazit svým dětem u nohou, přestanou je poníženě prosit, přestanou jim posílat přáníčka a dárky a díky tomu svému dítěti přestanou dávat potěšení z toho, že se kvůli nim někdo takto zostuzuje. &#8220;Schválně, jak se jim bude líbit to, že je pro změnu taky někdo ignoruje,&#8221; říkají někteří rodiče.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Zkrátka, rodiče mají pocit, že vztah se svými dětmi stále mají. Jejich pocit sounáležitosti s jejich opuštěným dítětem je tak silný, tak procítěný, že si nedokážou představit, že na jejich vztah má dítě třeba jiný názor. Nebo se vyhýbají bolestivé myšlence, že se s odloučením už jejich dospělé děti smířily a už na ně nemyslí. Každopádně, rodiče se chovají, jako kdyby se svými dětmi stále měli aktivní vztah a to, že o něj děti zájem nemají, si vysvětlují jako nějaký &#8220;tah ve hře.&#8221;
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jaký na to mají názor dospělé děti, to rodiče nezajímá. Viděla jsem členy, kteří si mysleli, že si s nimi děti jenom &#8220;hrají,&#8221; když na ně křičely, neodpověděly jim už několik let na žádné zprávy a dopisy, když jim dítě občas o svátcích zavolá, když děti volají svým rodiče jenom když potřebují peníze, když děti složitě vymýšlejí, jak by se mohli jejich rodiče setkat s vnoučaty. Jedna členka říkala, že na ní dcera &#8220;nastražila past&#8221; tím, že s ní přerušila kontakt, odešla bez toho, aby jí řekla kam a poté čekala, až ji najde, jenom aby na ni mohla zavolat policii a tak ji přede všemi ztrapnit. Ať už dítě udělá cokoliv, rodiče si to vysvětlí jako snahu dítěte o to, aby ho naháněli. Jediným rozhodujícím faktorem toho, jestli své dítě budou nahánět nebo ne je to, zda se rodiče cítí naháněním svého dítěte poníženi nebo ne a také to, zda rodiče promítají své vlastní potřeby na své děti nebo ne.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="&#8220;Smějí se nám&#8221;" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/themes-theyre-laughing-at-us.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jedna babička na fóru pro opuštěné rodiče se nacházela v nekonečném kolotoči s její dcerou a její vnučkou (která chodila na střední školu). Kolotočem se myslí to, že se babička s její dcerou neustále dokola odcizovala a poté sbližovala. Součástí kolotoče byla samozřejmě i vnučka, ke které babička opakovaně na chvíli měla přístup a na chvíli zase ne. Nedávno však babička své vnučce napsala to, že s ní musí zůstat v dobrém a navštěvovat se s ní, nebo jí nedá peníze, které jí ušetřila na vysokou školu; potom si vnučka vybrala školu, se kterou babička nesouhlasila, takže jí babička kategoricky odmítla dát jakékoliv peníze. Takže se věci zase&#8230; napjaly.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Chvilku po tom babička poslala,
                        </Fragment>
                    } />

                    <PageBlockQuote quote={
                        <Fragment>
                            Vnučka dneska přinesla zpátky Norton disk, který jsem jí půjčila. Dala ho do poštovní schránky s papírkem, na kterém bylo napsáno: &#8220;Promiň babi, musela jsem spěchat a neměla jsem bohužel dneska čas si s tebou popovídat.&#8221; Její máma a její druhá vnučka ji tam asi přivezly, řekly jí, ať to tam rychle hodí a utíká zpátky. Můj manžel mi řekl, že by se vsadil, že se u toho smály. Jsem ráda, že mi to vrátila, ale no tak&#8230; tohle je holka, které hrozí, že nedostane peníze na vysokou školu a ani se neobtěžuje zazvonit, aby mi řekla &#8220;ahoj.&#8221;
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Babička a její manžel berou vhození disku do schránky místo zazvonění a předání disku osobně jako mstu. A nejenom, že je tím chtěla vnučka ranit, ale pravděpodobně se tomu vnučka s její mámou smály, jako kdyby jim prováděly nějaký kanadský žertík.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Nikoho na fóru ani nenapadlo říct babičce, aby se zamyslela nad interpretací toho, co se stalo. Ostatním členům na tom nic divného nepřišlo; mnoho z nich věří, že se jejich dospělým dětem líbí, když svým rodičům ubližují a že se jejich bolesti smějí. Píšou si o tom, jak se děti musely smát, když jejich rodiče z jejich pozemku odvážela policie, jak jejich děti milují, když se mohou &#8220;smát jejich utrpení.&#8221; &#8220;Já kvůli svým dětem brečela, ony se mi smály,&#8221; rodiče opakují. Jiná máma dostala ke Dni matek jenom velmi krátký, extrémně strohý dopis a stěžovala si, &#8220;Mám pocit jako kdyby se mi vysmívala.&#8221; Členové si často komentáře dětí na jejich fórech interpretují jako &#8220;vysmívání se jejich bolesti.&#8221;
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Když je možné vidět, na co přesně rodiče reagují, tak je kontrast zcela očividný. Pojďme si ukázat příklad &#8211; jedno nejmenované fórum mělo více diskuzních vláken založených opuštěnými rodiči v sekci &#8220;rodiče&#8221; a jedno vlákno založené dítětem, které bylo v sekci &#8220;odcizené děti.&#8221; Dvě dospělé děti ve vláknu ze sekce &#8220;odcizené děti&#8221; se při diskuzi o svých vlastních rodičích dostaly k tomu, že si čtou diskuzní vlákna rodičů. Místo toho, abych z komentářů dělala výňatky, tak se budu snažit napodobit celou výměnu názorů, abyste názorně viděli, jak taková konverzace probíhá.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jména členů jsou jejich zkrácená opravdová jména.
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Dave, Sage, tomu úplně rozumím. Je zajímavý vidět, co si rodiče v sekci &#8220;Rodiče&#8221; o nás povídají, protože je tam jedno vlákno specificky zaměřené na to, aby v něm probírali odloužení (nebo těch vláken je pět, deset) a je to zaměřené na rodiče. Dalo mi to dobrou perspektivu k tomu, abych si uvědomila, jak se ohledně toho rodiče cítí. Já sama jsem už máma a se svou matkou jsem přerušila kontakt. Dostala jsem hodně komentářů o tom, že musím být &#8216;rozmazlená&#8217; nebo &#8216;neodpouštějící&#8217; nebo &#8216;krutá&#8217; (projekce?).<br></br>
                            Sage, taky se bojím toho, co se stane, až moje máma umře. Jsem tím vším (mým životem) neskutečně vystresovaná, že někdy ani nevím, jak si odpočinout a relaxovat. Cítím se za ní zodpovědná. Miluju jí. Ale prostě její chování už nemůžu tolerovat. Kdyby byla dítě, tak to pochopím. A odpustila bych jí toho mnohem víc. Ale dyť jí je přes 60 let.<br></br>
                            Myslím, že až tvoji rodiče umřou, tak budeš vědět, co máš dělat. Muset dělat věci, nad kterými nemáš kontrolu, ale udělat je musíš, to není nikdy příjemné. Pro ostatní lidi bych to neudělala. Měj svůj vlastní obřad tam, kde to máš ráda a se svými dobrými přáteli. Přines něco, co můžeš spálit. Nech to spálit a odvát do větru, odpusť a nech to už být. Tou dobou už nebude nic jiného, než vzpomínky. A truchlení. A teďka brečím. MILUJU svoji mámu. A nemůžu být její přítel. Nevím, jak být její dcera. Nevím, jak žít v takovém vztahu.<br></br>
                            Jak Dave řekl, je jen velmi málo lidí, kteří mají pochopení pro dospělé lidi, kteří prostě a jednoduše nechtějí pokračovat v tak bolestivém vztahu s jejich rodiči. Přeju vám jen to nejlepší,
                        </Fragment>
                    } author="Silver" />

                    <PageBlockQuote quote={
                        <Fragment>
                            Díky za zpětnou vazbu, Silver. Moc mi to pomohlo. Vím, že dělat věci, ke kterým tě život přinutí, není nikdy příjemné a to v žádné situaci &#8211; už jsem za svůj život udělala kvůli rodičům hodně věcí, se kterými jsem nesouhlasila a cítila jsem se kvůli tomu jenom hůř. Někdy se ale cítím, že je musím udělat ještě jednou. Máš pravdu &#8211; až se to stane, tak budu vědět, co je správné. Alespoň budu mít o čem přemýšlet &#8211; a budu na tuhle tvoji radu myslet, až se budu zase strachovat z toho, co život přinese. Moc děkuju.<br></br>
                            Snažila jsem se přečíst několik příspěvků, které píšou rodiče, abych to také viděla z jejich perspektivy, protože mi připadá, že je hodně zdrojů pro opuštěné rodiče, ale není tu moc zdrojů pro děti, které své rodiče opustily. Byla jsem vlastními rodiči obviněna z toho, že jsem rozmazlená, že neodpouštím, že věčně dělám z komára velblouda, že jsem moc citlivá, nevděčná nebo sobecká. Říkali mi to pořád a pořád dokola. Příjde mi, že tenhle &#8220;tón konverzace&#8221; je na rodičovských fórech úplně stejný.<br></br>
                            Ale díky tomu jsem už tak nějak schopná vidět, že je to pro ně způsob, jak si udržet čisté svědomí a jak tím sami sebe přesvědčují, že pro děti udělali &#8216;jen to nejlepší, co mohli&#8217;. (Tahle věta mě neskutečně rozčiluje pokaždé, když ji vidím). Moji rodiče mi také říkali, že &#8216;to mohlo být mnohem horší, ve zprávách jsou strašlivé příběhy o týraných dětech, dětech, které někdo držel ve sklepě a mučil je&#8217;. A že bych &#8216;měla být vděčná za to, že to nebylo horší&#8217;. Myslím, že je otřesné, že prostě v žádném případě nepřiznají, že to, co dělali, bylo špatné, že se nepřiznají ke svým proviněním a že to takto celé překroutí. Nechutné.<br></br>
                            Necítím ke svým rodičům žádnou lásku, cítím se kvůli tomu provinile a asi se tak vždycky cítit budu. Není to prostě &#8216;přirozené&#8217;, pokud chápete, co se tím snažím říct. Děti by měly cítit hluboké spojení se svými rodiči, milovat je a zároveň cítit i lásku z jejich strany. Dali mi sice život a možná už jen za to bych je měla milovat. Ale nemiluju.<br></br>
                            S uvolňováním se a relaxací mám také problémy. Ale učím se. Jako dítě jsem byla pod neustálou lavinou stresu. Takže jsem se nenaučila, jak správně reagovat na určité situace a svou naštvanost jsem ventilovala sebedestrukčním způsobem &#8211; přejídáním se, náhodným sexem, drogami, izolací &#8211; nedokázala jsem s nikým udržet přátelství. Už jen díky tomu, že jsem si uvědomila, že to je problém (při terapii), jsem si oddechla. A učení se, jak relaxovat, mi přineslo hodně klidu.<br></br>
                            Silver, jednou se budeš muset smířit s tím, že vztahy s tvými rodiči jsou takové, jaké jsou. Neobviňuj se z toho &#8211; není to tvoje vina. Pamatuj na to, že nemůžeš kontrolovat to, co si tvoje máma myslí a co dělá. Ach jo, přála bych si, abych měla lepší radu. Nevím prostě, co říct, kromě toho, že &#8216;rozumím, jak se cítíš&#8217;. Plakání je v pořádku, je to dobrý způsob, jak ventilovat stres. Taky vám přeju jen to nejlepší a to, aby se vám povedlo se s vaší situací smířit.<br></br>
                            Díky všem za poslouchání/čtení.
                        </Fragment>
                    } author="Sage" />

                    <PageBlockQuote quote={
                        <Fragment>
                            Vím, co tím myslíš, Sage. Opravdu neexistuje lepší rada, co? Upřímného &#8216;rozumím, jak se cítíš&#8217; si velmi vážím.<br></br>
                            Co mě fascinuje je jejich neustálé tvrzení toho, že byli &#8220;dobří rodiče&#8221;, a že jejich děti to udělaly &#8220;pro nic za nic&#8221;. Museli je &#8220;milovat příliš moc&#8221; a &#8220;dát jim příliš mnoho&#8221;. Vím, že to je hromada&#8230; no ty víš, čeho.<br></br>
                            Myslím, že je zajímavé, že mluvíš o lásce. Eskymáci mají 500 slov pro sníh. My máme jenom jedno slovo pro lásku. Přitom existuje mnoho typů lásky&#8230; rodičovská láska, dětská láska, přátelská láska, láska k jídlu, láska k sexu, &#8230; Myslím, že jsi na sebe moc tvrdá. Nemáš přece povinnost někoho milovat. A možná je láska sama o sobě špatným slovem, protože je tak úzce definované slovo. Pokud jsi vděčná za to, že žiješ, a že ti rodiče dali život, když přemýšlíš stylem: &#8220;Cítím se provinile, protože k nim necítím lásku&#8221; tak z toho možná udělej &#8220;Vážím si dnešního dne a toho, že tu můžu být. Moji rodiče mi to umožnili.&#8221; Vím, že je to hloupé! Ale někdy musím sama sebe odradit od toho, abych si na sebe kladla příliš tvrdé nároky.<br></br>
                            Zní to jako kdybys měla hodně složité dětství. To moje bylo emočně zrádné. Nikdy jsem nevěděla, kde vlastně stojím. A lidi ode mě čekali, že budu silná. Snažím se o to, abych svou rozmrzelost hodila za sebe a abych šla dopředu, ale je to velmi těžké, když ti to někdo furt připomíná. Vím, co myslíš tím, že jsi nikdy nechtěla, aby za tebou kamarádi chodili domů. Vždycky jsem chtěla žít v jiné rodině. Hlavně kvůli stabilitě, o které jsem si myslela, že v nich panuje.
                        </Fragment>
                    } author="Silver" />

                    <PageBlockQuote quote={
                        <Fragment>
                            Ahoj Silver,<br></br>
                            Jsem ráda, že tu jsi! Čím víc se blíží svátky, tím víc o těhle věcech přemýšlím. Přemýšlím o nich opravdu moc a jsem z toho celá špatná. Je fajn tu ventilovat aspoň trochu nahromaděné úzkosti tím, že tu o tom píšu a tím, že to řeším s lidmi, kteří mi doopravdy rozumí.<br></br>
                            Díky za tvoje povzbuzující slova. Myslím, že rodiče, kteří stále dokola tvrdí, že byli &#8220;dobří rodiče&#8221; i když zcela evidentně nebyli, tak jsou kompletně mimo. Moji rodiče jsou takoví. Myslím, že nejsou schopní jakýmkoliv způsobem řešit jejich vlastní pocity viny, tak si prostě řeknou: &#8220;Byl jsem OK, snažil jsem se, jak to jenom šlo.&#8221; A potom se snaží ignorovat všechny ostatní emoce. Utíkají před zodpovědností. Nic mě tolik neštve, jako moji rodiče, kteří se urputně snaží ignorovat naprosto všechno, co udělali špatně. Kdyby to přiznali a omluvili se, tak by to možná hodně pomohlo. Ale za všechny ty roky, co jsem se snažila náš vztah napravit, nikdy nepřipustili, že by udělali cokoliv špatně. Dyť je to směšné.<br></br>
                            Vím co myslíš pozitivním &#8220;mluvením k sobě&#8221; &#8211; je to důležitý a často to dělám! 🙂 Vzpomínám si a mám úplně před očima to, jak jsem byla jako dítě neuvěřitelně vystresovaná myšlenkou, že by ke mě někdy přišel nějaký kamarád na návštěvu a viděl to, co se u nás děje. Vždycky jsem záviděla kamarádům, cítila jsem se u nich doma tak klidně a bez stresu &#8211; a nikdy jsem nechtěla jít zpátky domů.<br></br>
                            Když jsem začala chodit k terapeutovi, tak jsem si uvědomila, jak moc mi moje dětství vytvarovalo způsoby, jakými pracuju s emocemi jako dospělá. A nejvíc to vidím, když mě někdo odmítne. Nikdy jsem se necítila milovaná nebo jako část rodiny, takže jakákoliv odmítnutí v mém dospělém životě mě vždycky poslala do sebezničující spirály. Byla to úleva, když jsem si to uvědomila. Nikdy předtím jsem si ty dvě věci nespojila dohromady, dokud jsem někde neseděla a nemluvila o tom s někým jiným. Neviním svoje rodiče ze všechno špatného, co jsem kdy udělala, na to si dávám pozor. Ale vidím, jaké následky způsobují nedostatek péče a týrání a vidím, že moje dětství má souvislost s tím, jak řeším věci teďka jako dospělá.<br></br>
                            Teď už mám pěkný život. Mám všechno, co chci. Je mi 40 a je čas na to, hodit rozmrzelost za hlavu. Ale je to neuvěřitelně těžké. Ale je to lepší a lepší.
                        </Fragment>
                    } author="Sage" />

                    <PageParagraph content={
                        <Fragment>
                            Do jednoho vlákna na rodičovském fóru se nějakým způsobem připletlo dospělé dítě, které hledalo nějakou podpůrnou skupinu. Silver ji pozvala do vlákna v sekci pro odcizené děti. Jedna z opuštěných matek, Straycat, se do tohoto vlákna také podívala, přečetla ho a poté se vrátila do rodičovského vlákna, aby tam napsala:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            WOW- chcete slyšet, co si o nás &#8220;druhá strana&#8221; v jejich vlákně povídá? Je neuvěřitelné, jak někdo, kdo říká, že bychom měli poslouchat jejich rady (i když nejsou a nikdy nebyli ve stejné situaci jako my), o nás mluví v jejich vláknech&#8230;<br></br>
                            V sekci pro &#8220;odcizené děti&#8221; je místo, kde tam dospělé děti, které se nestýkají se svými rodiči, mají někoho, kdo tam posílá příspěvky, ve kterých si aktivně dělá srandu z našich emocí. Byla jsem jak opařená. Jeden člověk řekl: &#8220;rodiče říkají, že nám nikdy nic špatného neprovedli, maximálně to, že nás příliš milovali&#8221; a pokračuje s tím, jak jsme směšní, že si &#8220;to myslíme.&#8221;<br></br>
                            Opravdu nerozumím, jak mohou lidi schválně psát taková zraňující slova, která jsou naprosto vytržena z kontextu a pak čekat, že lidi budou poslouchat jejich &#8220;rady.&#8221; Nebo berou naše vlastní příspěvky, vytrhnou je z kontextu a smějí se jim&#8230;<br></br>
                            Tohle přece není střední škola &#8211; soutěž &#8211; nebo nějaký způsob, jak obnažit něco, co je pro ostatní tak drahé &#8211; aby k tomu pak někdo mohl psát nenávistné komentáře.<br></br>
                            Mnoho z nás jsou ženy, kterým bylo ublíženo a osobně si neumím představit, že by někdo posílal naše příspěvky takovým způsobem, aby nám způsobil ještě víc bolesti&#8230;Wow- myslím, že to mi stačí-<br></br>
                            To je na mě prostě příliš moc.<br></br>
                            Doufám, že budete mít všichni krásné Vánoce- starejte se o všechny a děkujte za všechnu podporu, kterou tu zdarma dostáváte. Cením si vašich laskavých slov
                        </Fragment>
                    } author="Straycat" />

                    <PageParagraph content={
                        <Fragment>
                            Silver odpověděla:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Straycat, předpokládám, že mluvíš o mně, když říkáš &#8220;&#8230; někdo, kdo tu posílá a dělá si z našich procítěných emocí srandu&#8221;.<br></br>
                            Nedělám si srandu. Nesměju se. A neposílám příspěvky, abych někomu způsobila bolest.<br></br>
                            Je to otevřený dialog.
                        </Fragment>
                    } author="Silver" />

                    <PageBlockQuote quote={
                        <Fragment>
                            Proč si myslíš, že mluvím o tobě?<br></br>
                            Myslela jsem, že jsme si rozuměly?
                        </Fragment>
                    } author="Straycat" />

                    <PageBlockQuote quote={
                        <Fragment>
                            Protože:
                            <PageNumberedList content={
                                <Fragment> 
                                    <li>Byla jsem to já, kdo poslal ten link</li>
                                    <li>Protože jsem jediná, která &#8220;píše příspěvky tady i tam&#8221;</li>
                                </Fragment>
                            } />
                        </Fragment>
                    } author="Silver" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Straycat šest dní neodpověděla, poté napsala,
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            Připadá mi, že se naše příspěvky nějak změnily&#8230;a že hodně lidí, kteří sem předtím přispívali už moc nepřispívají a nebo přestali přispívat úplně. Tušíte někdo, jak by se něco takového mohlo stát nebo jestli se to opravdu děje?
                        </Fragment>
                    } author="Straycat" />

                    <PageParagraph content={
                        <Fragment>
                            &#8230; což je typický pokus o to, odklonit konverzaci a přepsat historii.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Takže proč mnoha členům fór pro opuštěné rodiče připadá, že se jim jejich děti smějí za zády?
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Protože ani členové fór pro opuštěné rodiče ani členové fór pro oběti takových rodičů nejsou dobří ve čtení tónu toho druhého. Rodičům připadají příspěvky týraných dětí jako plné zlosti a prázdných obvinění. Dětem zase příspěvky rodičů připadají nechutné a pomstychtivé. Dětem připadají pokusy jejich rodičů o kontakt jako touha rodičů po moci, přičemž rodiče se své děti snaží kontaktovat, aby udrželi naživu iluzi toho, že spolu nějaký vztah stále mají. Rodiče si přerušení kontaktu dětmi vysvětlují jako touhu po kontrole, přičemž dospělé dítě vztah přerušuje kvůli tomu, aby se ochránilo před chováním svých rodičů. Obě strany si akce té druhé strany vysvětlují podle toho, jak se kvůli nim cítí.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Takže když opuštění rodiče tvrdí, že se jim jejich děti vysmívají, tak si myslím, že to je jen další aspekt dynamiky popsané v &#8220;Chtějí, abychom je naháněli.&#8221; Rodič se cítí ponížený a znevážený, protože v jejich očích škemrají své děti o vztah, na který by měli mít automaticky právo. Cítí se poníženě, a nejen to &#8211; cítí se, že je jejich děti chtějí ponížit a že to je celým smyslem jejich chování. Děti s nimi nepřerušily kontakt kvůli tomu, že je rodiče týrali; tvrzení, že byly týrané, jsou lži, kterými se snaží schovat to, že odloučení je pro ně jenom hra. A když vyhrají kolo ve hře, tak je jasné, že mají radost.
                        </Fragment>
                    } />
                    
                    <Link to="/issendai-proc-deti-opousteji">Pokračování &#8211; Proč dospělé děti opouštějí svoje rodiče?</Link>

                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default IssendaiCDomnenky;