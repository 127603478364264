import React from 'react';
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageWrapper from '../../components/PageWrapper';

const Home = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'About me'}}/>
            
            <PageContent content={
                <Fragment>

                    <PageBlockHeaderTwo header="Welcome, friend!" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Welcome to my new personal website! I decided to ditch the old Wordpress and just write my own website from scratch using React and Tailwind. Why React, you might ask. Because why not, it's not illegal! That said, if you're a reasonable person, React will probably not be the platform of choice for static websites for you. It's like taking a flamethrower for a mosquito. But very fun-to-write-in flamethrower. 
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            What can I tell you about myself? Well, I'm just a typical little boy. I like dancin' and ponies and getting my... oh wait, what? Joking asides, I currently work as a QA Test Lead, and I like to say that I am QA in the streets and hobby game dev in the sheets. But more about that later.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            So, is there even anything interesting on this website? Should you care? There are interesting things here, actually. Let's take a look! 
                        </Fragment>
                    } />  

                    <PageParagraph content={
                        <Fragment>
                            First of all, if you want to see where I work, what do I do, or what did I study, check out my <Link to="/cv">CV page</Link>.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Since I am a typical antisocial IT guy, I have couple of computers and laptops, and some extra things. If you want to see what kind of hardware I have, check out the <Link to="/hardware">Hardware page</Link>. <PageBreak />
                            <PageBreak />
                            When you're doing game dev as your hobby, you need to use many different programs and need to be a Jack of all trades, unless you want to pay someone. Which means that I use many different software tools. If you want to see what I use to make my games, check out my <Link to="/software">Software page</Link>.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            If you're really into movies and like some life-long challenge, check out <Link to="/movies">1001 movies you must see before you die</Link> and you can compare with me, I take very good care of this list and cross everything right after seeing it.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            If you're a game dev, you can sometimes struggle with coming up with some ideas for your games. I have a page which might be interesting to you, which is called <Link to="/game-ideas">Game idea "generator</Link>." It's just a massive list of interesting ideas from Ludum Dare "slaughter" rounds. Have fun!
                        </Fragment>
                    } />  

                    <PageParagraph content={
                        <Fragment>
                            I also have a little bit of Czech content, which consists of translations of interesting articles or researches originally written in English. All pages include the links to their originals, if you are interested in those.<PageBreak /> 
                            <PageBreak /> 
                            If you're interested in a weird world of estranged parents, you can start <Link to="/issendai-opusteni-rodice">here</Link>. <PageBreak />
                            Another coherent piece is about "sick systems" which are "systems" designed to trap you into the feel of helplessness. Read about them <Link to="/issendai-jak-donutit">here</Link>. <PageBreak />
                            The last part are just random translations of articles or interesting comments which I want to make accessible to Czech readers too. Start <Link to="/issendai-jak-byt-perfektni-manazer">here</Link>.
                        </Fragment>
                    } />  
                    
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
};

export default Home;