import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import TextCentered from "../../components/PageComponents/TextComponents/text-centered";
import PageWrapper from "../../components/PageWrapper";

const KoureniNejlepsiVec = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Kouření je nejlepší věc na světě'}}/>
            
            <PageContent content={
                <Fragment>
                    
                    <PageBlockQuote quote="https://www.reddit.com/r/changemyview/comments/1l39f3/comment/cbvf2wq/" author="Originál od uživatele /u/Exis007" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Tohle je jedna z věcí, kterou vám nikdy neřeknou o kouření:     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Kouření je ta nejlepší věc na světě.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nekecám. Je to to NEJ. Vytváří to problém a hned ho zároveň řeší. Je to jako kdybyste vyhráli patnáctkrát za den v loterii.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Věděl jsem o heroinu. Věděl jsem o kokainu. Věděl jsem, že heroin by byl jako mít ten nejlepší orgasmus, který jsem kdy měl, ale ještě tisíckrát lepší. Věděl jsem, že díky kokainu bych se cítil víc sexy, než sám bůh, ale taky to, že kdybych se dostal do boje s bohem, tak bych vyhrál. Věděl jsem, že X mi hnedka zlepší víkend. Držel jsem se HODNĚ DALEKO od těhle svinstev. Dobré drogy jsou dobré, dokud vám najednou nedojdou a potom se z vašeho života stane peklo. Tomu jsem naprosto rozuměl. Ale to jediné, co mi všichni o cigaretách vždycky říkali, bylo akorát to, že stojí za nic. Líbat se s kuřákem bylo jako olizovat popelník. Udělají ti díru do peněženky. Zemřeš příliš brzy. Budeš smrdět. Budeš nakonec mluvit s dírou v krku. Viděl jsem, jak mého otce cigarety pomalu přivedly do hrobu. Tak nekuř&#8230; fajn, na tom přece nic není.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A tady je problém: pokud jsou cigarety tak nechutné a hrozné, tak se vlastně nemáte čeho bát. Tak si jednu dejte. A ještě další. Nikdy byste přeci nebyli tak hloupí, abyste do téhle pasti spadli. Můžete být tím magickým jednorožcem, který si dá jenom jednu cigaretu ve společnosti vašich přátel a jinak ne. A tak či onak, většina lidí, co kouří, začne kouřit mnohem dříve, než jim je 18 let. A vám už je 19, takže vás se to netýká. Navíc jste příliš chytří! Nikdy byste nedopustili, abyste byli závislí na té nejhloupější droze, kterou můžete koupit, na droze ztroskotanců.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            To nikdy nebudete. Protože kouření vypadá relativně neškodně. Víte, že třetina lidí, co zkusí heroin, je na něm závislá od první dávky, ale co může být na kouření, něčem tak stupidním, tak nebezpečné? Je to jenom blbá cigareta&#8230; a to je poprvé pravda. První cigareta vám nechutná a je hrozná asi tak, jak jste předpokládali. Ale i díky tomu ztratíte vůči kouření jakoukoliv ostražitost.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pak přijde dlouhý víkend plný alkoholu na vysoké škole a vaši přátelé kouří, takže chodí často ven za dveře, aby si zakouřili. Tak vás to zase zaujme, nebo chcete být &#8220;in&#8221; a ta první cigareta vám NIC neudělala, tak si dáte druhou. Potom osmou. Potom je to jenom o víkendech. Potom jenom když jste s přáteli v hospodě nebo v klubu. Pak si řeknete, že trochu &#8220;zvolníte&#8221; a dáte si cigaretu jen když si ji dávají vaši přátelé (a vaši přátelé ji mají VŽDYCKY po ruce). Někde vzadu ve vaší hlavě víte, že si chcete zapálit další, ale to byste museli být hloupí (pamatujete?), abyste tomu propadli. Jste extrémně inteligentní, vše v životě se vám daří, všechno o kouření víte a nikdy byste neskočili jako pravidelní kuřáci.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A tady to začíná být vážné: stane se něco hrozného. Váš partner se s vámi rozejde, vašeho psa srazí auto, nezvládnete udělat předmět ve škole, něco zkrátka půjde do kytek. Tak se odměníte touhle sebedestrukční věcí. Je to jenom výjimečně a protože se vám stala tahle hrozná věc. Tak jdete do obchodu, koupíte si krabičku a cítíte se provinile, ale cítit se provinile je tak či onak součástí toho, co se vám stalo, takže to nevadí. A konečně se cítíte jako dospělí. A máte pocit, že tu situaci zvládáte, i když to tak vůbec není.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Cigarety jsou nejlepší součástí vašeho života. Ten pocit, když jednu chcete a dopřejete si ji, to je čistá euforie. V životě plném zmatku a zoufalství se stanou vaším oblíbeným záchranným člunem. Všechno ihned zlepší a všechno, co stojí za nic, najednou není tak strašné. A díky tomu je skoro NEMOŽNÉ s nimi přestat. Víte, že se potápíte a stejně nic neuděláte. Najednou si uvědomíte, že jste kuřák (jak se to jen sakra stalo?), ale bude vám to stejně jedno. Protože nejlepší část vašeho dne je tahle sračkoidní, drahá a vysilující věc. Je to jako když jste zamilovaní do někoho, kdo vás nechce a nebo když máte velice arogantního nejlepšího přítele. Milujete je o to víc, o co je situace s nimi horší.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jednou si uvědomíte, že musíte přestat, ale nějak prostě nemůžete. Špatné věci se nepřestávají dít. Vaše práce stojí za nic, váš vztah stojí za nic, škola stojí za nic. Přichází závěrečné zkoušky, musíte odevzdat daně, váš přítel vám imploduje přímo do vašeho obličeje. Jakýkoliv stres, jakákoliv sebemenší drobnost, se stane okamžitě nesnesitelnou a potřebujete to <em>řešení + problém</em> v jednom, aby to všechno vypadalo zvládnutelně. Budete se cítit, že se před vámi propadá život, ale když si jednu zapálíte, tak je najednou všechno v pohodě. Na dvacet minut. A dvacet minut vám úplně stačí&#8230; alespoň prozatím. Dokud těch dvacet minut neuběhne a nepotřebujete zase další. A v hlavě sami sobě NADÁVÁTE, jak jste neuvěřitelně hloupí a jak nenávidíte to, čím jste se stali, ale to potěšení a úleva při zapálení další cigarety to vytlačí zase zpět.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nerozumíte tomu. Ani tomu rozumět nemůžete. Pokud jste se do toho nezapletli, tak tomu nebudete rozumět. Pokud jste se do toho zapletli, tak tomu taky nebudete rozumět. Ale uvědomíte si, proč to děláte, alespoň do určité míry. Všichni jsme se rozhodli, že kouřit nebudeme. Někomu z nás se to jenom prostě nepovedlo. Nikdy to nebylo ze dne na den nebo tak, že bychom chtěli být závislí na kouření. Prostě se to stalo. A žádná fakta o kouření a ani všechny strašidelné historky na světě to nezmění. Jediné, co to změní, je informovat lidi o tom, že to je ta nejlepší věc na světě. To by bylo to, co by na mě tehdy fungovalo. Už nekouřím dva roky a to je dobře, ale pořád s tím bojuji. Pořád se mě to týká. Pokud tomu nerozumíte, nebuďte arogantní, ale buďte RÁDI. Mohli jste tak skončit i vy.     
                        </Fragment>
                    } />

                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default KoureniNejlepsiVec;