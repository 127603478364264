import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import PageWrapper from "../../components/PageWrapper";

const StojimZaNic = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: '“Béé, stojím za nic!” “Já taky, ale to je OK, jsme perfektní!”'}}/>
            
            <PageContent content={
                <Fragment>
                    
                    <PageBlockQuote quote="https://www.reddit.com/r/socialskills/comments/gab3sz/wah_i_suck_omg_i_suck_too_but_its_okay_were/" author="Originál v angličtině" />
                    
                    <PageBlockHeaderTwo header="Argument" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Někdo to musel říct.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Začne to blábolením, poté srovnávním s realitou a skončí to zdroji. Bude to otevřené, tvrdé a nehezké. Ale je mi to u prdele. Někdo už to musí říct tak, jak to je. Většina stěžování vypadá asi takhle:
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li><strong>Stížnost</strong>: Jsem tak smutný, že moje sociální dovednosti jsou tak špatné. Teď vám popíšu přesně do detailu, jak přesně jsou špatné a v jaké jsem situaci. Poté se budu točit v kruhu ohledně toho, jak jsou moje sociální dovednosti špatné.</li>
                            <li><strong>Nejčastější odpověď</strong>: Já jsem na tom úplně stejně, ale naučil jsem se s tím nic nedělat a smířit se s podprůměrným životem, takže ty bys měl taky. Nikdy nezkoušej dělat něco nového a náročného; to, co by z tebe mohlo udělat někoho lepšího! Jsi perfektní takový, jaký jsi, kašli na ostatní, nikdy se kvůli ostatním neměň. A teď ještě řeknu pár prázdných frází, abych ti trochu pohonil ego a ty jsi byl furt ve stejné prdeli.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Přísahám bohu, že chci křičet z plných plic.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Proč mi to není jedno?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Protože jsem takovým člověkem byl celý život.</strong>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A v ničem mi to nepomohlo.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tohle naštve hodně lidí, ale upřímně &#8211; mělo by. Naštvěte se. Naštvěte se na mě. Naštvěte se na všechno. A konečně&#8230; naštvěte se sami na sebe. Naštvěte se na fakt, že se vám to všechno tak moc zarývá pod kůži. Naštvěte se na to, že necháváte svět, aby vyhrál tím, že vás drží dole. Naštvěte se na to, že máte tak stupidně křehké ego. Naštvěte se na to, že jste nikdy nic pořádného nepředstavovali. A potom, když ten vztek konečně dostanete ze svého systému, tak si otřete své dětské slzy a začněte kurva pracovat na tom, abyste byli lepší.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tohle je většinou to, co vždycky radím (samozřejmě s použitím hezčího slovníku, abych se nikoho nedotknul):
                        </Fragment>
                    } />

                    <PageNumberedList content={
                        <Fragment>
                            <li>
                                Jediný člověk, který vás tak obsesivně sleduje, jste <strong>vy sami</strong>. Jste jediný člověk, který dělá to, co děláte, který si myslí to, co si myslíte a který cítí to, co cítíte. Nikdo další tyto věci nezná do takového
                                detailu, jako vy. A čím více času s nimi strávíte, tím větší bude vaše ego, které vyroste ve velký hrad ochraňující veškerý myšlenkový prostor ve vaší hlavě. Ale pamatujte, že jenom <strong>vy</strong> máte tento konkrétní hrad v
                                hlavě &#8211; nikdo jiný. Je celkem jedno, jestli sedíte na zadku a říkáte si, že stojíte za nic a nebo naopak sedíte na zadku a říkáte si, jak jste úžasní &#8211; pokud místo ve vašem veškerém myšlenkovém prostoru vyplníte
                                obsedantními pozorováními o sobě, jen nafouknete své vlastní ego a je to kurva bezcenné.
                            </li>
                            <li>
                                Většina lidí netuší, že existujete. A ještě bych dodal, že jste většině lidí naprosto ukradení. Pokud je vám po přečtení tohohle smutno, tak si trochu zkroťte ego. Tohle je pravda ohledně prakticky každého člověka na téhle planetě.
                                Včetně celebrit &#8211; myslíte si, že všichni ti chudáci, co žijí ve zbídačených zemích třetího světa a nemají ani televizi, mají představu o tom, kdo je Will Smith? Ne. I o takové superhvězdě jako je on, ví možná tak 10 % celého
                                světa. Tak si vystrčte hlavu z prdele. Nikoho kurva nezajímáte, možná tak s výjimkou vaší rodiny.
                            </li>
                            <li>
                                Bod číslo 2 by pro vás měl být osvobozující. Pokud není a pokud vás rozesmutněl, tak si běžte vylízat své vlastní rány a potom se zase vyvztekejte, vy jedno malé dítě.
                                <a href="https://www.sciencedirect.com/science/article/pii/S0378873316301095">Studie</a> prokázaly, že většina lidí má bez ohledu na časová a životní období zhruba pět blízkých přátel. Tohle je opravdová věda a jednoduchá evoluční
                                biologie. Nejste kurva speciální. A to je moc dobře. Proč?
                            </li>
                            <li>
                                Bod číslo 2 vám dává možnost dělat cokoliv jenom chcete. Tak se zlepšete v sociálních dovednostech, zlepšete se ve sportu, zlepšete se v čemkoliv chcete. Těžce selhávejte a selhávejte často. Lidé, kteří vás uvidí při tom, jak se
                                učíte, rostete a klopýtáte, jsou pouze dočasní a nikdy jim na vás nezáleželo. Tak se dejte kurva do práce.
                            </li>
                            <li>
                                Bez ohledu na to, kdo sklízí výhody, někdo k tomu musí vynaložit nějaké úsilí. Proto rada typu: &#8220;To vůbec neřeš, ono to přijde&#8221; je naprosto k ničemu. Nic se nikdy &#8220;jen tak nestane.&#8221; NĚKDO do toho musí vložit
                                nějaké úsilí. Jasně, můžete sedět na zadku a čekat na to, až někdo do něčeho vloží úsilí místo vás a ovoce sklidíte vy. Ale jaká je šance, že se něco takového stane? Tak kurva začnete vkládat úsilí do věcí i vy.
                            </li>
                            <li>
                                Štěstí se usmívá na ty, co se nebojí. Stateční lidé selhávají. Sakra často. Většina zámožných podnikatelů mnohokrát selhala před tím, než dosáhli úspěchu. A představte si to &#8211; nikdo nevěděl, kdo jsou, dokud se nestali
                                úspěšnými. Tak buďte kurva stateční. Vystrčte hlavu ven. Riskujte to, že vám ji někdo usekne. Dyť vám doroste zase další. Ne tedy doslova &#8211; ale pokud to berete doslova, tak máte větší problém než to, o čem mluvím teď. Musíte
                                být ochotní zaútočit na vaše křehké, malé ego, abyste v životě něčeho dosáhli.
                            </li>
                            <li>
                                Selžete. Mnohokrát. A bude to bolet. Ale to neznamená, že byste měli přestávat. Jasně, můžete přestat, aby se vašemu egu nic nestalo, ale co tím získáte? To je přece kurevsky ubohé. Takže byste si na selhávání měli zvykat. Ti
                                nejúspěšnější jsou ti, co se nebojí selhat nejvíc. Oni jsou ti nejvíce znecitlivělí. Dělají to, protože jim jsou jejich ega ukradená. Pokud chcete být také úspěšní, tak pokračujte i potom, co jste selhali. Učte se dál. Pokračujte v
                                růstu. Zlepšujte se i když selháváte.
                            </li>
                            <li>
                                Poučte se ze svých chyb. Pokud v něčem selžete, tak se zastavte a zjistěte, PROČ jste selhali. Pokud to neuděláte, tak to je všechno k ničemu. Dělání jedné a té samé věci dokola a očekávání jiných výsledků je šílenství. Změňte
                                strategii. Naučte se, jak se zlepšit. Neseďte a nepouslouchejte lidi, co vám říkají fráze jako: &#8220;<em>Dyť už jsi perfektní malá sněhová vločka, nemusíš na sobě nic měnit</em>.&#8221;
                            </li>
                            <li>
                                Ne vše ohledně učení se je politicky korektní. Někdy musíte rozumnět tomu, že něco z toho se nemusí líbit všem. A to je v pořádku. Vyhledejte si prostě to, co je potřeba k tomu, se něco naučit a vyrůst. Pokud používáte tyto
                                vědomosti eticky, tak je to v pořádku. Ale pro dobro všech &#8211; neseďte jen tak na prdeli a neignorujte to.
                            </li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            To je všechno, co mě tak nějak napadá. Všechny mé odpovědi vždycky vychází z těchto bodů, takže mě napadlo to dát dohromady do jednoho nasraného příspěvku.
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Zdroje" />

                    <PageBulletList content={
                        <Fragment>
                            <li>The Charisma Myth.</li>
                            <li>Charisma On Command na YouTube.</li>
                            <li>How to Win Friends and Influence People.</li>
                            <li>The Subtle Art of Not Giving a F*ck.</li>
                            <li>The 7 Habits of Highly Effective People.</li>
                            <li>The Power of Habit.</li>    
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Protiargument" />
                    
                    <PageParagraph content={
                        <Fragment>
                            S hodně argumenty souhlasím. Ale myslím, že konverzace může být produktivnější. Rada typu: &#8220;Dej si ego dokupy a přestaň bejt takovej zbabělec&#8221; může být často spíše odrazující.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Myslím, že na konci dne se to vždy vrací k tématu všímavosti.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Povzbuzujte ostatní, aby spíše pozorovali, než aby o všem přemýšleli ve zbytečných detailech a vše analyzovali. Vaše obsesivní myšlenky jsou jenom to &#8211; myšlenky. Lidé se rádi nechají myšlenkami zkonzumovat. Ale my přece nejsme jenom myšlenky.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Ale spíše, než říkat lidem, aby byli ostřejší, tak je lepší je povzbudit v tom, aby byli introspektivní a všímaví, než aby se snažili oslovit někoho jiného, který by jim pomohl se zlepšit. To také podporuje odpovědnost. Například &#8211; co se mi honilo hlavou během téhle sociální interakce? Možná jsem si myslel: &#8220;Wow, teď jsem řekl něco opravdu stupidního, všichni mě teď určitě nenávidí.&#8221; Je pravděpodobné, že to tak je? No, skoro určitě ne. A i kdyby to tak bylo, pomohl by mi jakýmkoliv způsobem takový vnitřní dialog? To je jasné, že ne. Takže vlastně souhlasím&#8230; je to problém vašeho ega. Tak se ho naučte rozpoznávat a oddělit ho od svého pravého já. Vyžaduje to ale čas a silné uvědomění sama sebe.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Zkuste nahradit své obsesivní negativní myšlenky s pozitivními nebo motivačními myšlenkami. Například: &#8220;Myslím, že to, co jsem během konverzace řekl, bylo velice zajímavé a validní. Myslím, že lidé si mou společnost užívají.&#8221; I když tomu třeba nevěříte, tak si to stejně kurva aspoň řekněte! Po nějakém čase se pozitivní myšlení stane vaším novým zvykem. To znamená, že už se nebudete bát toho, jestli vás má každý rád. Prostě budete PŘEDPOKLÁDAT, že vás mají rádi. A tak se nebudete tolik bát.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Síla pozitivního myšlení je skutečná.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Stručně řečeno - říkat lidem, kteří sami sebe považují za méněcenné, aby s tím hned přestali, obvykle není produktivní. Ale můžete je povzbudit v tom, přijmout odpovědnost a připomenout jim, že jen oni samotní mají kontrolu nad svým životem.
                        </Fragment>
                    } />
                    
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default StojimZaNic;