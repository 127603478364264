import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageBulletListNested from "../../components/PageComponents/PageListComponents/page-bullet-list-nested";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import PageNumberedListNested from "../../components/PageComponents/PageListComponents/page-numbered-list-nested";
import PageTable from "../../components/PageComponents/PageTable";
import PageTableHeader from "../../components/PageComponents/PageTable/page-table-header";
import PageTableContent from "../../components/PageComponents/PageTable/page-table-content";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper";

const IssendaiBKulturaFor = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Issendai – Kultura Fór'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/average-member-of-estranged-parent-forums.html" author="Originál v angličtině" />
                    
                    <PageBlockHeaderTwo header="Kultura Fór"/>

                    <PageParagraph content={
                        <Fragment>
                            Toto se netýká všech fór, jenom fór pro opuštěné rodiče.
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Průměrný člen fór pro opuštěné rodiče" />

                    <PageParagraph content={
                        <Fragment>
                            Na fórech pro opuštěné rodiče je průměrný člen:
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Žena. Mnoho fór se skládá výhradně z ženských členů a fóra, která mají i mužské členy, jich jen velmi vyjímečně mají více než jednoho nebo dva. Dohromady.</li>
                            <li>Věk kolem 55-70. Existuje početnější menšina uživatelů nad 70 let. Starší členové se většinou zaregistrují, zeptají se na otázku a poté odchází místo toho, aby se stali pravidelnými členy. Je tu také významnější aktivní menšina ve věku 45-55 let.</li>
                            <li>Dělnická až střední třída.</li>
                            <li>1-3 děti, typicky ve věku 18-45 s výraznější menšinou pod 18 let a zbytek 45-55 let.</li>
                            <li>Rozvedený, často dokonce vícekrát. Rekord je zatím šest sňatků.</li>
                            <li>Jeden z bývalých partnerů je týral, ale aktuální partner ne.</li>
                            <li>Mohla strávit mnoho let jako matka samoživitelka.</li>
                            <li>Rodiče je týrali, typicky matka.</li>
                            <li>Je možné, že se svou matkou dříve přerušila kontakt, ale teď se s ní normálně stýká &#8211; popřípadě se s ní stýkala před tím, než její matka zemřela.</li>
                            <li>Má nebo měla velmi blízký vztah se svou matkou.</li>
                            <li>Nikdy nebyla na žádné terapii a terapeutům nevěří.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Je tu silný důkaz existence &#8220;řetězu bolesti.&#8221; Týrajícím rodičům se narodily týrané dcery, které byly silně vázané na matku. V dospělosti zůstaly po jejím boku i když je matky stále týraly. Dcery obvykle byly přitahovány k násilným mužům, čímž svým vlastním dětem vytvářely podobné prostředí jako z jejich vlastního dětství. Nicméně, přísahaly sami sobě, že budou lepšími rodiči než jejich vlastní rodiči. A i když si myslely, že se jim to povedlo, jejich synové a dcery je nyní obviňují z týrání. A žena se jménem Rosa, komentátor na blogu <em>Láska, Radost, Feminismus</em> k tomu řekla:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                             Občas tak přemýšlím, jestli tohle není klíčem k tomu, proč je mnoho rodičů překvapených a zděšených tím, že se jejich děti cítí ukřivděně. Když byli sami týráni a neviděli z toho cestu ven, a díky tomu se chovali lépe ke svým dětem &#8211; použili méně násilí, více lásky a podpory &#8211; tak možná skončili s dětmi, které už byly schopny vytyčit nějaké bariéry a v dospělosti vyžadovat respekt &#8211; a to mnohem lépe, než by jejich rodiče kdy mohli.<br></br>
                            A díky tomu se možná rodiče cítí, že to není fér &#8211; nebili jsme tě páskem, jenom rukou! A chtěli jsme, abys byl dobrý ve škole místo toho, abychom chtěli, abys se školou přestal a vydělával na rodinu. A ty mi chceš říkat, že jsem i tak byla násilná a dirigující?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Zaměření na matky a dcery je nevyhnutelné vzhledem k tomu, z jakých lidí se taková fóra skládají. Nesnažím se z toho všeho vinit jenom matky; pokud něco, tak těch několik málo mužů z některých fór vypadalo ještě méně stabilněji a ještě náročněji než ženy. Ale mužský vzorek je maličký a ženský vzorek obrovský, takže je nemožné se nezaměřit na matky.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Rodičovská fóra vs. Fóra pro děti, které opustily rodiče" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/differences-between-forums.html" author="Originál v angličtině" />

                    <PageParagraph content={
                        <Fragment>
                            Fóra pro opuštěné rodiče a fóra pro děti, které s těmito rodiči přetrhaly kontakty, mají radikálně odlišné kultury na fórech.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Fóra pro opuštěné rodiče typicky obsahují jak fóra pro opuštěné rodiče, tak pro prarodiče, kteří se snaží o to, navštívit svá vnoučata. Fóra dětí typicky obsahují fóra pro děti všech typů tyranů, zejména narcisů, a fóra pro diskutování problémů s nevlastními členy rodiny.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Rozdíly v kulturách fór" />

                    <PageTable content={
                        <Fragment>
                            <tbody>
                                <tr>
                                    <PageTableHeader content={<Fragment>Fóra pro opuštěné rodiče</Fragment>}/>
                                    <PageTableHeader content={<Fragment>Fóra pro děti, které své rodiče opustily</Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>Silný náklon k emoční podpoře. Asistování členům s opravdovým řešením jejich problémů je až druhořadé.</Fragment>}/>
                                    <PageTableContent content={<Fragment>Balance mezi radami a emoční podporou, obvykle se silnými tendencem k tomu, řešit problémy i když třeba někteří členové posílají příspěvky čistě jenom kvůli emoční podpoře.</Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>Členové poskytují emoční podporu nezávisle na tom, zda s chováním druhého člena souhlasí nebo ne.</Fragment>}/>
                                    <PageTableContent content={<Fragment>Pokud členové s chováním druhého člena nesouhlasí, tak mu žádnou emoční podporu neposkytují.</Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>
                                        Většina členů má problém s dospělými dětmi, které se od nich vzdálily nebo je kompletně opustily, popřípadě nemají žádný kontakt ani s jejich vnuky. Jejich problémy mohou být způsobené mentálními a fyzickými problémy, chudobou nebo jinými okolnostmi, se kterými by se ale daleko něco dělat, pokud by jim jejich dospělé děti pomohly.<br></br>
                                        Malá, ale významná část členů má problém s dospělými dětmi, které je finančně zneužívají, jsou násilné, závislé na nějakých drogách a podobně; a které své rodiče nenechají na pokoji.
                                    </Fragment>}/>
                                    <PageTableContent content={<Fragment>
                                        Většina členů má problém s dotěrnými, panovačnými nebo kontrolujícími rodiči a chtějí, aby je rodiče nechali na pokoji nebo si je přejí opustit kompletně. Jejich problémy mohou být způsobené závislostí na problémových členech rodiny, ať už to jsou důvody finanční, emoční nebo praktické. Například, jejich panovačná matka hlídá jejich děti.<br></br>
                                        Jen velmi malá část členů má problémy opačného rázu, tj. že jejich rodiny s nimi nekomunikují nebo je zanedbávají.
                                    </Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>
                                        Členové si nejsou jisti tím, jak vyřešit svoje problémy. Jejich problém je ten, že chtějí mít vztah s někým, kdo s nimi vztah mít nechce, což je prakticky neřešitelný problém. Mají pro to ale takový dvoufázový proces.<br></br>
                                        V první fázi se snaží zaměřit na řešení toho, že s nimi děti nekomunikují. Členové se opakovaně snaží kontaktovat svoje děti, žalují své děti o to, aby jim soud dal právo na návštěvu vnuků a tak podobně. Nicméně, uvědomují si, že tyto akce problém pravděpodobně nevyřeší.<br></br>
                                        Druhá fáze se snaží o to, se nějakým způsobem smířit s důsledky opuštění. Členové si najdou nové koníčky, přidávají se do různých klubů, hledají nové přátele, aby vyplnili emoční prázdnotu, která jim zbyla po tom, co je děti opustily. Také často odstraňují své děti ze závětí, snaží se vyřešit finanční problémy a podobně.	
                                    </Fragment>}/>
                                    <PageTableContent content={<Fragment>
                                        Členové věří tomu, že jejich problémy mohou být vyřešeny kombinací terapie, manželských poraden, vytyčením bariér a podobnými typy chování, popřípadě prakticky jako například nalezením práce a odstěhování se. Navíc jsou ochotní k tomu, zredukovat nebo kompletně odříznout vztahy s toxickými lidmi. Plně očekávají, že tyto metody budou efektivní, pokud je správně aplikují.
                                        Členové očekávají, že emoční problémy budou mít složitá řešení. Řeší to pomocí terapií, knih zaměřených na osobní růst a také spoléhají na vzájemnou podporu zkrz příspěvky, kde diskutují s ostatními lidmi o svých problémech.
                                    </Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>Členové očekávají, že proces vyrovnání se s opuštěním bude dlouhý a bude mít tendence se opakovat. Je jenom velmi málo, co by člen mohl udělat; je to primárně emoční cesta, a dlouhá cesta často vyžaduje, aby cestovatel dělal stále ty stejně věci dokola - dokud nebude nejen vědět, ale i dokonce věřit tomu, že všechny akce jsou zbytečné. Členové ostatní členy bezmezně podporují i přes všechny chyby, kroky zpět a i když se "zaseknou".	</Fragment>}/>
                                    <PageTableContent content={<Fragment>
                                        I když je cílem členů emoční vyléčení, věří také tomu, že je nezbytné k němu dojít vlastními silami. Členové věří, že není možné se vyléčit, pokud jsou stále v kontaktu s toxickými lidmi. Také si uvědomují, že častým důsledkem týrání je jistá "setrvačnost," kdy se za tyranem člověk pravidelně vrací a podporují ostatní členy v tom, aby udělali přímá, konkrétní rozhodnutí i přesto, že se bojí a slibují jim, že emoční léčení začne poté, co tato rozhodnutí provedou.
                                        Členové, kteří na fórum opakovaně posílají stejné dotazy stále dokola jsou kritizováni. Část kritiky je složena z frustrace, ale častěji je kritika odůvodněna tím, že členové chtějí povzbudit dalšího člena, aby se konečně rozhoupal k těžkému rozhodnutí a pomohl si tím.
                                    </Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>Členové nikdy neřeší detaily příběhu, který je jim právě předložen. Někteří opakují své příběhy pořád dokola. Možná se někdy zeptají jeden druhého na více detailů, ale nikdy se neptají na důležité otázky nebo nevyjadřují pochybnosti. Pokud si přesto uvědomí, že příběh je nekonzistentní, tak to omlouvají jako pouhé nedorozumění. Není to tak, že by jim nevadila nekonzistentnost, ale jednoduše je ani nenapadne, že by příběhy ostatních členů mohly být nekonzistentní.</Fragment>}/>
                                    <PageTableContent content={<Fragment>Konzistence je oceňována: Když člen pošle nějaký příběh, tak ho komentátoři analyzují před tím, než na něj odpoví. V některých fórech členové při zakládání nových příspěvků dávají odkazy i na své předchozí příspěvky. Komentátoři se často ptají na důležité otázky, vyžadují další detaily a pokud vidí nějaké nesrovnalosti, okamžitě o nich diskutují. Komentátoři sice dávají volnost členům, kteří se spletli nebo něco vysvětlili špatně, ale jsou zároveň schopni rychle rozpoznat, že se jiný člen snaží otázkám vyhýbat, odmítá je nebo je neustále "zmatený".</Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>Členové berou podněty z reakce ostatních. Pokud je některý člen z nějakého plánu nadšený, tak budou ostatní členové nadšení také. Pokud má nějaký člen o něčem pochybnosti, tak o tom budou ostatní členové pochybovat také. Členové klidně podpoří jeden týden člena v něčem a budou mu další týden tvrdit, že to je špatný nápad bez toho, aby viděli zjevný rozpor.</Fragment>}/>
                                    <PageTableContent content={<Fragment>Členové berou své podněty z ověřených a efektivních řešení. Členové, kteří mají podobné problémy dostanou podobnou radu a ostatní členové mění své rady pouze tehdy, když jim člen, kterému radí, předloží důkazy o tom, že je situace velmi odlišná od toho, jak si ji ostatní členové původně představovali.</Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>Členové nevyzývají ostatní k vysvětlení některých věcí a všechny rady, které by i jen zdánlivě mohly připomínat kritiku, se snaží zminimalizovat, jak to jenom jde.	</Fragment>}/>
                                    <PageTableContent content={<Fragment>Členové vyzývají ostatní k vysvětlení nejasností a pokud věří, že se někdo chová špatně, tak mu to upřímně řeknou.</Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>
                                        Členům dlouho trvá, než rozpoznají nějaké typické chování a takto rozpoznaná chování jsou často nedetailní a obecná. Například, často vysvětlují určité chování tím, že z toho obviňují "tuto generaci dětí," ale "generace" v tomto případě pokrývá lidi od 15 do 50 let - což jsou dvě oddělené generace, přičemž nejstarší z nich je starší než mnoho členů samotných. Ustanovené koncepty jako "porucha osobnosti" používají jen velmi limitovaně. Často používané slovíčko je "narcis", popřípadě "tichá domácnost". Nicméně, členové těmto termínům detailně nerozumí a často si poruchy pletou.<br></br>
                                        Znalosti určitého typického chování jsou velmi krátké. Členové neustále dokola znovuobjevují psychologické koncepty.<br></br>
                                        Předpověď chování jejich dospělých dětí je typicky nejasná a je založená na zkušenostech s konkrétní osobou, není založená na znalosti různých typických chování.
                                    </Fragment>}/>
                                    <PageTableContent content={<Fragment>
                                        Členové rychle poznají určité typické chování a je pro ně jednoduché aplikovat správná řešení i na nové situace. Jejich chápání určitého chování je detailní a přesné. Například diskutují o rozdílech mezi narcistickou poruchou osobnosti a hraniční poruchou osobnosti, učí se jména psychologických obranných mechanismů a učí jeden druhého o fázích cyklu týrání.<br></br>
                                        Členové používají znalosti některých typických vzorců chování k předpovídání toho, jak se budou toxičtí lidé chovat a jsou v tom poměrně dost přesní. Noví členové jsou často ohromeni tím, kolik toho ostatní členové o jejich rodičích už ví a to bez toho, aniž by jim o nich ještě něco vůbec řekli.
                                    </Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>
                                        Členové obecně nedoporučují žádné webové stránky ohledně rodičovského odcizení, s vyjímkou jiných podobně zaměřených fór. Přečtou si maximálně pár knih určených ke zlepšení sama sebe, ale nemají žádné zdroje, které by považovali za "povinnou četbu".<br></br>
                                        Jedna knížka, která se věnuje opuštěných rodičům, "Když Jsou Rodiče Zranění" od doktora Joshua Colemana, je často těžce kritizována.
                                    </Fragment>}/>
                                    <PageTableContent content={<Fragment>
                                        Členové odkazují na velký počet webových stránek a knih o tom, jak řešit toxické příbuzné a těmto zdrojům přikládají velkou váhu a věří jim. Některé zdroje jsou považovány za "povinnou četbu".
                                    </Fragment>}/>
                                </tr>
                            </tbody>
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Rozdíly ve stylech psaní" />

                    <PageTable content={
                        <Fragment>
                            <tbody>
                                <tr>
                                    <PageTableHeader content={<Fragment>Fóra pro opuštěné rodiče</Fragment>}/>
                                    <PageTableHeader content={<Fragment>Fóra pro děti, které své rodiče opustily</Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>Členové na fórum posílají dopisy a texty, které hodlají poslat jejich dětem, ale jen velmi vyjímečně posílají také odpovědi jejich dětí. Jen velmi výjimečně člen pošle kompletní konverzaci.</Fragment>}/>
                                    <PageTableContent content={<Fragment>Členové neposílají jenom dopisy a texty, které poslali svým toxickým příbuzným, ale i jejich odpovědi. Často posílají kompletní emailové nebo textové konverzace.</Fragment>}/>
                                </tr>
                                <tr>
                                    <PageTableContent content={<Fragment>Členové prakticky nikdy nepopisují konverzace, které měli. Někdy ani dokonce neopakují ani celé věty, které jim jejich dítě řeklo. Preferují silné parafrázování.	</Fragment>}/>
                                    <PageTableContent content={<Fragment>Členové detailně popisují konverzace, které měli. Parafrázování je v pořádku, ale když mluví o verbální komunikaci, snaží se opakovat přesná slova, která zazněla.</Fragment>}/>
                                </tr>
                            </tbody>
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Proč mají fóra tak radikálně odlišné členy?" />
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/summary.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Proč jsou členové fór pro opuštěné rodiče tak odlišní od ostatních opuštěných rodičů? Proč už se svými dětmi neměli žádný kontakt 5, 15, ale dokonce i 30 let? Proč jsou usmíření tak neobvyklá a proč jsou většinou pouze dočasná? A proč je kultura fóra tak neobvykle toxická? Mají spolu něco společného kultura fór a důvody, proč jsou rodiče opuštění?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Absolutně.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jsou tu tři faktory, které přispívají k dysfunkční kultuře fór pro opuštěné rodiče a k tomu, že nejsou schopni se s dětmi usmířit.
                        </Fragment>
                    } />
                    
                    <PageBulletList content={
                        <Fragment> 
                            <li>&#8220;<strong>Řetěz bolesti</strong>&#8221; &#8211; generační cyklus týrání.</li>
                            <li><strong>Osobnost následovníka autorit</strong>, ze které vyplývá naléhavý pocit úcty k rodičovské autoritě, dvojité metry pro rodiče a děti a velmi silné nutkání potrestat lidi, kteří se vzpírají.</li>
                            <li><strong>Snaha vyhýbat se kritice</strong>, pokřivování reality, minimalizování dopadů, odmítání a někdy dokonce i zavírání očí před čímkoliv, co jen vzdáleně připomíná kritiku.</li>
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Řetěz bolesti" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Ohromující část členů povídá příběhy multigeneračního týrání. Jejich prarodiče týrali jejich rodiče. Když už byli dospělí, našli si partnery se sklony k týrání, někdy dokonce členové vidí své děti, jak týrají své vlastní děti. Rozvodovost členů je extrémně vysoká &#8211; mnoho členů se dokonce rozvedlo už několikrát a poté si vzalo dalšího tyranského manžela. Mnoho členů povídá příběhy o tom, že v jejich rodinách bylo už předtím několik případů, kdy dítě opustilo své rodiče, ať už mluví o sobě, nebo o rozhádaných tetách a strýcích, popřípadě o tom, jak jejich vlastní rodiče odřízli na nějaký čas zbytek rodiny. Členové stále mluví o &#8220;kultuře odcizování&#8221; a s lítostí mluví o tom, že naučili své děti tomu, aby je byly schopné opustit, ale myslím si, že vysvětlení je jednodušší: týrání zkrátka rozvrací vztahy.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Všichni členové říkají, že se snažili být lepšími rodiči než jejich vlastní rodiče a že se jim to povedlo. Nerozumí tomu, proč jejich vlastní děti tvrdí, že byly týrané, když jim členové neudělali nic z toho, co jejich rodiče dělali jim. Dokonce kolem sebe vidí opravdové tyrany &#8211; například rodiče, kteří bijí své děti nebo drogově závislé rodiče, přičemž tito rodiče jsou často obklopeni dětmi, které jim jsou oddané. Co se stalo špatně? Proč je vlastní děti odmítají, zatímco děti, které prožily skutečné peklo pořád milují svoje rodiče? Mnoho členek přemýšlelo nad tím, jestli se ke svým dětem neměly chovat hůře.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Takže co se vlastně stalo? Dvě věci. Zaprvé &#8211; členové správně identifikovali nejhorší chování svých rodičů a rozhodli se, že se tak chovat nebudou; nicméně, pořád si od svých rodičů vzali dysfunkční vzorce chování, nikdy nezpochybněné špatné domněnky a špatné způsoby toho, jak některé věci řešit. (Všechny děti a manželé tyranů od nich získávají tendence k týrání. Říká se jim <em>blechy</em>.) Tyto blechy se schovávají ve slepé skvrně rodičů. Otec, který je hrdý na to, že své děti nebije, si neuvědomuje, že když je vůči svým dětem pohrdavý a dirigující, že jim to způsobuje skoro stejné příkoří jako jemu způsobovalo bití. Když ho někdo upozorní na to, že je pohrdavý a diriguje své děti, tak na svém chování nevidí nic špatného, protože od svého otce převzal domněnky toho, co je normální, jak se má chovat hlava rodiny a jeho výchova mu nedala nástroje k tomu, aby si uvědomil, že svým dětem ubližuje, i když &#8220;pouze&#8221; nepřímo. Je možná mnohem lepší otec než byl ten jeho, ale to neznamená, že své děti netýrá.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Většina členů byla pokousaná svými vlastními blechami. To je první věc. Druhá věc je méně častá, ale i tak významně přispívá k velkému množství rozbitých vzahů: Členové opravdu byli lepší rodiče než ti jejich. Vychovali děti, které mají trochu více sebevědomí, jsou na nich méně závislé, děti, které si byly schopné vytvořit lepší obranné mechanismy, díky kterým si našly lepší přátele a viděly, že existuje lepší, širší, laskavější svět. Toto, zkombinované s lepším porozuměním týrání a větší podporou obětí, znamenalo, že děti byly dostatečně zdravé na to, aby byly schopné rozpoznat to, byly týrané a byly schopné opustit oběžnou dráhu svých rodičů.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Takže někteří členové mají pravdu, když tvrdí, že byli lepší rodiče a že děti je opustily kvůli tomu, že nebyli tak hrozní jako jejich vlastní rodiče. Ale neznamená to ještě, že své děti netýrali.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Osobnost následovníka autorit" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Členové fór pro opuštěné rodiče by měli vysoké skóre na tom, čemu Bob Altemeyer říká &#8220;<em>Pravicové Autoritářské Měřítko</em>.&#8221; Člověk s takovou osobností má typické rysy, které mu tvarují celý jeho život a hlavní myšlenka je ta, že autority by měly být automaticky respektovány a měla by jim být automaticky projevována úcta, protože to jsou autority. Člověk s takovou osobností takové myšlenky podporuje používáním dvojitých měřítek, která omluví cokoliv špatného, co by kdy autorita mohla udělat, takže autority v jejich vidění světa nikdy nemůžou nic udělat špatně. Je to silně hierarchistické vidění světa, díky kterému si tito lidé moc nevyskakují a je to pro ně zároveň i silným důvorem k tomu, aby nikdy autority nezpochybňovali. Takže tito lidé mají v tomto ohledu černobílé chápání světa a mají myšlení typu &#8220;<em>my vs. oni</em>&#8221; a cítí silnou potřebu potrestat hříšníky, kteří se autoritám vzpouzí.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tento styl myšlení je v těchto fórech velmi zřejný. Jejich vidění světa je pevně hierarchistické, kdy existuje pouze jedna skupina &#8220;<em>opuštění rodiče</em>&#8221; (nebo například &#8220;<em>prarodiče</em>&#8220;) a druhá skupina &#8220;<em>děti, které své rodiče opustily</em>,&#8221; nebo například prostě &#8220;<em>děti</em>.&#8221; Od každé skupiny jsou očekávány radikálně odlišné věci, kdy je akceptováno a přijímáno naprosto vše, co &#8220;<em>jeden z nás</em>&#8221; říká a kdy je odmítáno cokoliv, co říká &#8220;<em>někdo od nich</em>.&#8221; Separace mezi těmito skupinami je neodlučitelná. Opuštění rodiče jsou zkrátka opuštění rodiče i když třeba popisují, že oni sami někdy v minulosti (i třeba jen dočasně) přerušili kontakt se svými rodiči; přesto pro ně neplatí stejná pravidla, jako pro děti, které své rodiče opustily.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A je jim zcela jasné, že je potřeba potrestat děti, které se svými rodiči nekomunikují. Samozřejmě, jenom &#8220;opravdové&#8221; děti. Neplatí to pro ně, i když svou rodinu třeba také někdy předtím opustili. Primární funkce fóra je k tomu, aby působilo jako bezpečné místo pro všechny rodiče, aby tam mohli ventilovat své touhy po tom, ponižovat své děti, bít je, zostuzovat je, zkrátka, způsobit jim stejnou bolest jako ony způsobují svým rodičům. Po bolestí způsobené odmítnutím, je druhou nejčastější emocí, kterou členové ventilují, vztek.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Následovníci autority mají také zvláštní uvažování. Škatulkují, odmítají koukat na nepříznivé důkazy, které svědčí o opaku, mají tendence k tomu, myslet si, že odůvodnění jsou správná, pokud vedou k výsledku, který se jim líbí. Fakta uchopují velmi volně. Jak už psycholog, který takové lidi zkoumal, řekl:
                        </Fragment>
                    } />
                    
                    <PageBlockQuote quote={
                        <Fragment>
                             Nebyli si schopni vzpomenout na některé kousky důkazů, vymýšleli si důkazy, které neexistovaly a neustále dělali chybné závěry ze správných materiálů.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Tento popis by mohl být aplikován na všechny členy fór pro opuštěné rodiče. Členové si na události vzpomínají velmi nepřesně, detaily si pečlivě vybírají a často o jedné události napíší více verzí, které si vzájemně odporují. Ostatní členové tomu, že si tvrzení vzájemně odporují, nevěnují žádnou pozornost a akceptují poslední verzi jako celou pravdu. V namíchaných fórech (fóra jak pro rodiče tak i pro děti) je větší pravděpodobnost toho, že se budou ostatní členové na rozpory v příbězích ptát. V takových případech je pro opuštěné rodiče složité pamatovat si všechny relevantní detaily, zapomínají na fakta, která se jim nehodí, přidávají nová fakta a cítí se dostatečně sebejistě k tomu, aby si udělali své závěry, i když si situaci nepamatují plně a chybí jim kontext. Pokud je někdo konfrontuje, jsou schopni přiznat, že se v některých věcech mýlili, ovšem urputně argumentují pro svůj původní závěr, který učinili na faktech, o kterých právě přiznali, že jsou mylná. V namíchaných fórech to vede k nekonečnému dohadování se o tom, že jim ostatní jenom špatně porozuměli a opuštění rodiče se poté nimrají v drobných chybkách ostatních členů. Ve fórech, která jsou určená pouze pro opuštěné rodiče, to vede ke kultuře, kdy členové jeden den s nějakou věcí nadšeně souhlasí a druhý den jsou silně proti. Dlouhodobě to jejich kulturu ochuzuje, protože členové si nejsou schopní shrnout dohromady jejich kolektivní zkušenosti a nepříjdou se souvislým porozuměním toho, proč jsou vlastně opuštění. Pokud něco, tak členové prohlašují, že dát cokoliv do souvislostí je nemožné, protože opuštění rodiče samo o sobě není možné pochopit.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Domněnky, které tyto členové drží, učí jeden ostatního a snaží se přenést na své děti, jsou silně autoritářské:
                        </Fragment>
                    } />
                    
                    <PageBulletList content={
                        <Fragment>
                            <li>Rodiče by měli být respektováni, protože to jsou rodiče. Tečka.</li>
                            <li>Rodiče, kteří své děti týrají, by měli být respektováni tak jako tak. (Rodiče členů je týrali, ale nikdy by se k nim členové nechovali tak, jak se jejich děti chovají k nim.)</li>
                            <li>Rodič rozhoduje o tom, co si představuje pod pojmem &#8220;respekt&#8221; a co to slovo vlastně znamená.</li>
                            <li>Rodič rozhoduje o podmínkách vztahu. Veškeré pokusy dítěte o to, vymezit nějaké podmínky vztahu, jsou chápány jako pokus o to, mít kontrolu nad rodičem.</li>
                            <li>Rodiče by měli ovládat své děti. Dětem nesmí být povoleno ovládat své rodiče.</li>
                            <li>Rozhodnutí pro něco, s čím rodič nesouhlasí, je známka nezralosti. Dělání toho, co rodič říká, je známka zralosti.</li>
                            <li>Názory ostatních lidí neplatí, dokud s nimi člen nesouhlasí. Neplatné názory jsou neexistující názory.</li>
                            <li>Děti nemají právo na to, ukončit vztah se svými rodiči.</li>
                            <li>Odmítání toho, mít s rodičem vztah, je týrání.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Členové tráví mnoho času fňukáním nad tím, že s výše vyjmenovanými domněnkami jejich děti nesouhlasí a že lidi, kteří je obklopují, jim vůbec nepomáhají s tím, vnutit jejich dětem tyto domněnky. A že společnost jako celek s těmito domněnkami nesouhlasí. Pro mnoho z nich je magickým řešením rozvráceného vztahu hledat způsob, jak zahnat své děti do úzkých a tyto domněnky jim vnutit. Nikoho asi nepřekvapí, že šance toho, napravit vztah takovýmto způsobem, je velmi nepravděpodobná. Méně autoritářští rodiče se postupně se svými dětmi usmíří a nebo odejdou na fóra, která jsou méně autoritářská, což má za důsledek to, že tato fóra si drží vysokou koncentraci velmi autoritářských členů.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Vyhýbání se kritice" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Kultura fór pro opuštěné rodiče je postavena na silném odporu členů ke kritice. Členové se dotazují na podrobnosti jeden druhého s neuvěřitelnou křehkostí a nebo raději vůbec; věří čemukoliv, co jim ostatní členové říkají; a tráví mnoho času utěšováním jeden druhého, když si &#8220;svět venku&#8221; na ně dovolí být méně laskavý. Jedna z nejdůležitějších funkcí fór je nabídka pomoci lidem, kteří se cítí být nepochopení, pod útokem, očerňováni vnějším světem kvůli věcem, nad kterými si myslí, že nemají kontrolu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Některé z mechanismů, které členové používají k tomu, aby se vyhnuli kritice, jsou následující:
                        </Fragment>
                    } />
                    
                    <PageBulletList content={
                        <Fragment>
                            <li><strong>Nemožnost porozumět kritice</strong>. &#8220;Křičela na mě a křičela,&#8221; tvrdí jedna členka, ale už si nemůže vzpomenout, co na ní dcera vlastně křičela.</li>
                            <li><strong>Minimalizace kritiky, kterou si pamatují</strong>. Seznam všech příkoří, které jim dítě vyjmenuje, zredukují na: &#8220;Obvinil mě z toho, že jsem mu jeden Halloween snědla bonbony&#8221; nebo &#8220;Je na mě naštvaný, protože měl na střední škole domácí povinnosti.&#8221; Tato tvrzení jsou tak směšná a nesmyslná, že je nikdo normální nemůže brát vážně.</li>
                            <li><strong>Neochota opakovat kritiku</strong>. Členové nevkládají do svých příspěvků &#8220;dopisy plné nadávek,&#8221; které jim jejich děti poslaly, jen velmi zřídka posílají textové konverzace a často ani neřeknou, co bylo vůbec podstatou konfliktu.</li>
                            <li><strong>Odmítnutí přijmout kritiku, se kterou nesouhlasí</strong>. Dcera třeba tvrdí, že se nemůže smířit s tím, že jí matka neodsouhlasila jejího přítele. Matka si myslí, že její nesouhlas s přítelem dcery je perfektně legitimní, protože její dcera má na víc. Z toho matka dojde k závěru, že s ní dcera nepřerušila kontakt kvůli nesouladu s jejím přítelem. Dojde k závěru, že jí dcera neřekla opravdový důvod, takže jí vlastně neřekla žádný důvod a moc si přeje, aby jí opravdový důvod řekla, aby se spolu mohly zase usmířit.</li>
                            <li><strong>Přecitlivělost k negativním emocím, které míří na ně</strong>. Jakákoliv kritika je interpretována jako útok, smutek je interpretován jako vztek, klidnou mluvu si pamatují jako křik. Jejich děti nejsou zraněné nebo naštvané, ale jsou &#8220;plné nenávisti.&#8221; Reakce ostatních lidí jsou vnímány jako natolik silné, že je nic nemůže ospravedlnit, a jsou interpretovány jako tak bolestné, že by je nikdo normální nebyl schopný ustát.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Vyhýbání se kritice spolu s osobností následovníka autorit, kterou nezajímají fakta, spolu vytváří velmi zvláštní přístup k realitě. Členové jen obtížně skládají detaily do nějakého uceleného celku. Jejich příběhy jsou obecně mlhavé, nepřesné a zaměřené na emoce, které při nich členové cítili, přičemž zcela chybí informace o tom, co ke konfliktu vlastně vedlo. Navíc k detailům často přimíchají časovou osu tak rozsekanou, že vypadá, jako kdyby prošla mixérem. Opuštění přišlo jako rána z čistého nebe: Jejich syn byl šťastný a den po svatbě je zničeho nic kompletně odříznul, piknik byl úžasný, dokud k nim nepřišla jejich snacha a nezačala na ně křičet. Ostatní členové při čtení takových příběhů nevidí nic divného nebo jim nepřipadá, že v nich evidentně něco chybí. Přecijenom, mnoho z nich zažilo přesně to samé!
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Pokud příjde opuštěný rodič vyprávět svůj příběh do namíchaného fóra, ostatní členové se nevyhnutelně začnou ptát na podrobnosti. V takovém případě se stane jedna z dvou věcí. Buď rodič vybuchne s tím, že tam přišel pro podporu a pro pomoc, ne k tomu, aby byl vyslýchán a kdo vůbec jsi, že si ji dovoluješ vyslýchat? Nevěříš mu? Jsi rodič nebo dítě? Chci mluvit jenom s rodiči, protože jenom ti tomu rozumí, takže se seber spolu se svými urážkami a jdi pryč.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            &#8230; A nebo rodič názorně předvede, že i když tvrdí opak, tak všechny chybějící kousky skládačky už má v ruce. Z původního: &#8220;Manželka mého syna byla hrubá, sobecká a nenechala nikoho, aby při křtu držel její dítě&#8221; se stane &#8220;Dítě se narodilo předčasně, existovalo zde velké riziko, že od někoho chytne smrtelný respirační syncyciální vir a byla zrovna chřipková sezóna.&#8221;
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Schopnost členů držet se faktů je závislá na tom, jak se zrovna cítí. Například členka, která navštěvuje svého vnuka, často tvrdí, že snacha její návštěvy nemá ráda, ale navštěvovat svého vnuka bude i nadále, protože ví, že její syn by jí nikdy neodmítnul. Po šesti měsících ta stejná členka tvrdí, že se snachou měla dobrý vztah, ale že se její snacha najednou rozhodla návštěvy zrušit a členka nemá ponětí, proč a co se stalo.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Vyhýbání se kritice garantuje to, že tito lidé budou mít napjaté vztahy. Nikdo nechce být s lidmi, kteří nejsou schopni mluvit o svých problémech, kteří si nepamatují, co jim lidé vytkli, kteří chodí a všem okolo říkají, že netuší, proč jsou na ně lidé tak oškliví. Takže to garantuje, že pokud řešení problému je: &#8220;Zjisti, co děláš špatně a naprav to,&#8221; že problém vyřešený nikdy nebude. Není žádným překvapením, že lidé, kteří se vyhýbají kritice, končí rozvedení a opuštění mnohem častěji než normální lidé.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Takže když už všechno toto víte, tak není žádné překvapení, že lidé, kteří se vyhýbají kritice, si stěžují, že je jejich děti odřízly jen tak zničeho nic a bezdůvodně. Podle jejich chápání světa jim jejich dítě žádný důvod nedalo. (Aspoň tedy žádný důvod, který si pamatují &#8211; kromě všech těch urážek, které na ně děti křičely a kromě nesmyslů, že jednou snědli jejich bonbony.) Chtěli by se tak moc usmířit s jejich dětmi, ale když se jich ptají, co můžou udělat, tak na ně děti znovu jenom křičí, říkají jim, ať nehrají hloupé, že už důvody znají, nebo jim dají další směšné důvody, kterým nikdo nerozumí&#8230; takže rodiče dětem nabízejí, že spolu půjdou na společná sezení, ale tento nápad jim nepomůže, protože ho dítě odmítne a řekne rodiči, ať si tam zajde sám a ať nejdřív pracuje na sobě. Ale rodič na sobě nemůže pracovat, když neví, co je s ním špatně, takže musí čekat, než se jejich dítě uklidní a doufat, že někdy, možná někdy, jim jejich dítě řekne, jaký je skutečný problém.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Interakce" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Mezigenerační týrání, osobnost následovníka autorit a vyhýbání se kritice jsou velmi úzce propletené věci. Autoritativní rodičský styl neznamená nutně týrání, ale je to typická volba tyranů. Autoritářská výchova má sklony k tomu, vychovat děti s velmi špatnými rodičovskými dovednostmi, takže lidi, kteří byli při vyrůstání týráni a jejich rodiče byli autoritativní, mají sklony k tomu, převzít si alespoň kousky autoritátorského chování svých rodičů a aplikovat je na své děti. Tyrani se co nejvíce vyhýbají kritice, takže své děti nenaučí, jak kritiku zdravě snášet a jejich tvrdé tresty naplňují děti takovým studem a bezmocí, že některé z těchto dětí vyrostou a nejsou poté schopny připustit udělání čehokoliv špatně bez toho, aby je znovu nepolévaly stejné pocity studu a bezmoci jako cítily v dětství. Takže v jádru, autoritářské týrání je často skrytá snaha o to, vyhnout se kritice.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Ne všichni členové fór pro opuštěné rodiče jsou takoví. Poměrně značná menšina z nich nebyla týrána jako děti a zároveň je zde menšina, která není autoritářská, ale i přesto s názory fór souhlasí. Nicméně, úplně všichni dlouhodobí členové se vyhýbají kritice. Je nemožné být opuštěn &#8220;bez žádného dobrého důvodu&#8221; bez toho, aby se člověk nevyhýbal kritice; a členové, kteří ví, jaký jejich problém a jsou schopni ho řešit, jdou někam jinam &#8211; na fóra, která jsou zaměřená na řešení konkrétních problémů.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderThree header="Shrnutí" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Většina členů fór pro opuštěné rodiče patří do skupiny lidí, u kterých je zvýšené riziko špatných vztahů: Lidi, kteří byli týráni jako děti, kteří si podvědomě osvojili některá toxická chování svých rodičů a kteří, i když se snažili, přenesli týrání i na své vlastní děti. Vyhýbají se kritice tak silně, že to narušuje jejich vnímání světa a díky tomu je pro ně nemožné akceptovat a řešit problémy, které s nimi jejich děti mají. Podvědomě je pro ně snadnější nést bolest toho, že je děti opustily &#8211; bolest tak silnou, že někteří opuštění rodiče raději spáchají sebevraždu, než aby museli snést bolest toho, že se mýlí.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Co se s tím dá dělat? Nevím. Je těžké rozbít celoživotní vzorce chování i když si je člověk uvědomuje a chce je opravdu rozbít. Když je někdo ani nevidí, když rozbíjení těchto vzorců mu připadá jako rána obouručním kladivem přímo do jeho duše, tak se zkrátka nic nezmění.
                        </Fragment>
                    } />
                    <PageParagraph content={
                        <Fragment>
                            Ale co vím, je toto: Změna je možná, zvláště, pokud jste v dolní části řetězu. Především, pokud jste dospělé dítě, které opustilo své rodiče a byl jste vychován tyranským autoritativním rodičem. Vím, že se snažíte být co nejlepšími rodiči. Ale bohužel, i vy jste převzali některá špatná chování od svých rodičů, i když třeba pouze podvědomně. Existuje vyšší riziko toho, že vás děti opustí, ale ne kvůli tomu, že &#8220;je učíte, aby vás byly schopné opustit&#8221; nebo kvůli podobným nemyslům, které vám vštěpují do hlavy vaši rodiče, ale protože je zkrátka přirozené dostat blechy. Nezáleží na tom, jak dobrý rodič jste, najděte si terapeuta a udělejte si pořádně tvrdou a důkladnou &#8220;generálku.&#8221; Soustřeďte se na své slepé skvrny a i na vaše rozbité, zubaté, obrněné části, které si přejete, aby neexistovaly. Protože pokud je nevyřešíte vy, možná je jednou budou muset řešit vaše děti.
                        </Fragment>
                    } />
                    
                    <Link to="/issendai-domnenky">Pokračování &#8211; Domněnky na fórech pro opuštěné rodiče</Link>

                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default IssendaiBKulturaFor;