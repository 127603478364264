import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import TextCentered from "../../components/PageComponents/TextComponents/text-centered";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper";

const IssendaiAJakDonutit = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Issendai – Jak někoho donutit, aby s vámi zůstal navždy?'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockQuote quote="http://www.issendai.com/psychology/sick-systems.html" author="Originál v angličtině" />

                    <PageParagraph content={
                        <Fragment>
                            Při psaní článku <a href="http://web.dzejpi.cz/issendai-nezdrave-systemy/issendai-jak-nekoho-donutit-aby-s-vami-zustal-navzdy/">Jak někoho donutit, aby s vámi zůstal navždy</a>, byly předkládány situace, ve kterých se Issendai sama ocitla a nebo se v nich ocitli její přátelé, takže předpokládala, že nezdravé systémy jsou vzácné a hluboce patologické. Ale z komentářů a ostatních konverzací na internetu lze vidět, že skoro každý člověk někdy uvíznul s nezdravém systému a že nezdravé systémy jsou nedílnou součástí života v některých částech světa.     
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Vytvoříte nezdravý systém.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nezdravý systém má čtyři základní pravidla:     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Pravidlo 1: Udržujte je příliš zaneprázdněné na to, aby mohli přemýšlet</strong>. Přemýšlení je nebezpečné. Pokud se lidé zastaví a začnou o své situaci přemýšlet logicky, mohou si uvědomit, do jakého šíleného kolotoče věcí se to vlastně dostali.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Pravidlo 2: Udržujte je unavené</strong>. Vyčerpání je perfektní obranou proti jakýmkoliv nebezpečným myšlenkám, které by mohly proklouznout skrz. Oprava systému vyžaduje změnu a změna vyžaduje úsilí. A úsilí vyžaduje energii, která zkrátka chybí. Zařiďte, aby neměli žádnou energii a jakákoliv možnost převratného prozření a uvědomění si situace se přemění jen do několika nudných hádek.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            To je také důvodem, proč je musíte udržovat příliš zaneprázdněné na to, aby mohli přemýšlet. Samozřejmě nemůžete vypnout jejich myšlenkový proces kompletně, ale můžete je držet příliš unavené na to, aby je napadlo něco vlastního. Centrum rozhodování v mozku se dá unavit podobně jako svaly. A pokud je centrum rozhodování unavené, začnou lidé dělat určité předvídatelné typy logických chyb. Najděte systém založený na těchto chybách a máte vyhráno.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Pravidlo 3: Udržujte je emočně zapojené</strong>. Zařiďte, aby vás milovali, pokud to je možné; pokud jste zaměstnavatel, vybudujte firemní kulturu extrémní loajality. V opačném případě spojte jejich úspěch s tím vaším tak, že když se daří vám, tak se daří i jim a pokud selžete, tak selžou také. Pokud pracujete někde, kde nelze selhat v klasickém slova smyslu (úřady, služby), vytvořte systém, kde vaši zaměstnanci pracují dobře nebo špatně podle toho, jak dlouho jsou u vás zaměstnaní. (Toto také funguje, pokud jste v nezdravém poly vztahu.)     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nezapomeňte na to, že pokud vytvoříte systém, kde je loajalita a oddanost důkazem hodnoty vašeho partnera, můžete ho donutit k tomu, aby vás miloval. A nebo si to alespoň myslel. Ve skutečnosti jakákoliv kombinace občasných odměn zkombinovaných s vyčerpáním (díky kterému chybí energie na to, přemýšlet o jiných alternativách), v lidech vyvolá pocit toho, že vás milují, i když vás zároveň nenávidí.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Pravidlo 4: Odměňujte je jen občas</strong>. Občasné uspokojení je jeden z nejnávykovějších druhů uspokojení vůbec. Pokud víte, že při zatáhnutí za páčku vám vždycky vypadne jídlo, budete za ni tahat jenom tehdy, když budete mít hlad. Pokud víte, že jídlo nevypadne nikdy, nebudete za páčku tahat vůbec. Ale pokud někdy jídlo vypadne a někdy zase ne, budete tahat za páčku do konce života i když už máte jídla dostatek, protože co kdyby jídlo došlo a žádné další jídlo by už nikdy nevypadlo? Je to motivace stojící za chronickým hráčstvím, sběratelskými kartičkami, většinou počítačových her, internetem samotným a vztahy s bláznivými lidmi.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jak tohle všechno ale uděláte? Je to neuvěřitelně snadné:     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Zařiďte, aby neustále nastávaly nějaké krize</strong>. Nekompetentnost je skvělým způsobem, jak na to: Pokud systém v kanceláři pravidelně funguje špatně nebo vámi posedlý partner pravidelně dělá závažné chyby, máte přímo garantováno, že nějaká krize bude vždy na obzoru. Výborně také funguje špatné hospodaření s penězi. Také funguje, pokud pracujete v oboru, kde jsou klienti prchaví a nestabilní, nebo máte přátele, kteří jsou sami v neustálých krizích. Můžete také pravidelné krize <span class="tlid-translation translation"><span class="" title="">institucionalizovat</span></span>: Pracovníci v <em>Sea Org</em>, elitním křídle Scientologie, musí každý týden překonat produkci z předchozího týdne nebo čelit vážným postihům. Protože je nemožné takovou podmínku dlouhodobě plnit, garantuje to před konečnými termíny pravidelné krize.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pravidelné krize plní dvě funkce: Zařídí, aby byli lidé příliš zaneprázdnění na to, aby přemýšleli a zároveň jim přináší občasné uspokojení. Konec konců, někdy přecijenom vyhrajete &#8211; a pokud většinou prohráváte, je chuť úspěchu návyková.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Ale proč by si lidé postupem času neuvědomili, že krize jsou permanentním stavem věcí a nikdy neskončí? Protože krize omlouváte způsobem, který jim dává naději.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Věci se zlepší, až&#8230;</strong> si najdu novou práci. Jsem k tobě teď nepříjemý, protože jsem příliš vystresovaný, ale to se změní, až nebudu pracovat v téhle hrozné práci.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Produkční cyklus je šílený, protože je klient hrozný pitomec. Musíme tenhle cyklus nějak dokončit, poté už budeme mít nového klienta, který bude mnohem lepší.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Má nepříjemnou náladu, protože zrovna začala chodit k novému terapeutovi. Bude to mnohem lepší, až si to celé sedne a až si na sebe zvyknou.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Věci se ale mají tak, že první člověk si vůbec žádnou práci nehledá. Je příliš vystresovaný na to, aby začal vyplňovat přihlášky na nové pozice a domlouvat si pracovní pohovory. Produkční cyklus v druhém příkladu bude vždy šílený, protože pitomci jsou všichni klienti; popřípadě společnost nastavila vnitřní procesy tak, aby co nejvíce pracovní postupy pro všechny znepříjemnila, díky čemuž nakonec klient vypadá jako pitomec bez ohledu na to, jestli tím pitomcem skutečně je a nebo ne. Třetí osoba už navštěvuje jejího &#8220;nového&#8221; terapeuta rok. Ale ne tři roky! Nebo pět! Všechny tyto výmluvy zní přijatelně. A navíc, jednou za čas mají tito lidé dobrý den a nebo je produkční cyklus normální. Občasné odměňování + naděje = &#8220;Jednou se to uklidní a bude to pak už navždy takové, jaké to je zrovna dneska.&#8221; Neustálé krize znamenají, že je člověk příliš unavený na to, aby si všiml, že věci nikdy příliš dlouho v pořádku nevydrží.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Držte opravdové odměny daleko</strong>. Odměny v odstavci &#8220;<em>Věci se zlepší, až&#8230;</em>&#8221; většinou vůbec žádnými odměnami nejsou &#8211; věci se vrátí zpátky do normálu, až se stane nějaká magická věc. Opravdové odměny &#8211; spokojenost, prosperita, posun v kariérním žebříčku, nový dům, děti &#8211; jsou až někde v dáli. Vypadají tak, že každou chvíli příjdou, ale neexistují pro ně žádné plány. Například &#8211; <em>všechno bude lepší, až se přestěhujeme do vlastního domu na venkově&#8230;</em> ale není na něj nic našetřeno, není vlastně vůbec v plánu na něj šetřit, žádný dům není vybraný, dokonce není vybraný ani kraj nebo země. Nebo <em>se to všechno zlepší, až si najde lepší práci</em>, ale nikam nerozesílá své životopisy, nehledá volná místa a nabídky práce, nemá žádné dovednosti, které by jí pomohly k tomu, najít si novou práci, nemá žádné plány na to, se vzdělávat a ani neví, jaký typ práce by vlastně chtěla dělat. Firmy to mají trošku složitější, ale dobře funguje neustále oddalované zvyšování platů a oddalovaná slibovaná povýšení, popřípadě zlepšení pracovních podmínek, o kterém se neustále mluví, ale nikdy se na něj nenajde dostatek finančních prostředů a nebo také pravidelně slibovaná školení, která jsou nakonec vždy zrušena kvůli nedostatku financí.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Dovolte jim mít jednou za čas nějaký úspěch</strong>. Měl by to být každodenní úkol s proměnlivou šancí na úspěch. Například &#8211; potřebujete si vzít prášky v naprosto přesný čas. Pokud si je vezmete příliš brzy, tak další den nebudete schopni vstát do práce. Ale pokud si je naopak vezmete příliš pozdě, budete trpět nespavostí a držet svého partnera vzhůru, dokud neusnete. Příliš mnoho něčeho jiného a objeví se u vás nechutenství, které rozruší váš pravidelný jídelníček a rozhoupe tím váš bedlivě sledovaný cukr v krvi, což způsobí nekontrolovatelné záchvaty vzteku nebo hysterie. Je na vašem partnerovi, aby zjistil, kdy bude perfektní čas na to, abyste si vzali prášek. A pokaždé, kdy váš partner na tento perfektní čas přijde, je vždy s něčím v rozporu &#8211; zrovna tenhle čas toho vždy máte příliš mnoho, nebo nejste doma, popřípadě jíte příliš brzy a nebo příliš pozdě, takže se ideální čas každý den posouvá a nebo ho není možné stanovit vůbec. Ale jednou za čas si prášky vezmete v perfektní čas a všechno funguje skvěle, váš partner tím pádem okusí drobky úspěchu a bude si myslet, že se konečně blíží bod zlomu, po kterém to už bude všechno jenom dobré.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Osekávejte jejich čas</strong>. Neustále je vyrušujte meetingy nebo návštěvami vedoucích a dejte jim šibeniční termíny na splnění úkolů. Pokud jste partneři, buďte na toho druhého neustále přilepení, vyžadujte od nich pozornost v krátkých intervalech po celý den (a dejte jim najevo, že oni to samé po vás vyžadovat nemohou), oznamte, že určité nutné povinnosti dělat nebudete a vyžadujte, aby je dělal místo vás váš partner. Oznamte, že určité povinnosti váš partner dělat nesmí a vyžadujte po něm, aby byl ohledně splnění těchto povinností závislý na vás (a povinnosti plňte pomalu, špatně a nebo zcela náhodile). Ujistěte se, že mají sotva dostatek času na to, aby byli schopni řešit zároveň aktuální krizi a své povinnosti; a pokud je nedokážete unavit fyzicky, vysajte je emočně.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Spojte váš úspěch s jejich úspěchem</strong>. Firemní městečka jsou v tomto ideální. Všechno, počínaje sociálním postavením zaměstnance až po výběr věcí v obchodě závisí na tom, jak dobře zaměstnanec dělá svou práci a jak dobře si společnost jako celek vede. Společnosti, které své zaměstnance tolik nesvazují, se snaží spojit zaměstnancovo vnímání sebe sama s obecným vnímáním jejich značky. Lidé v partnerských vztazích to dělají tak, že si spojí své úspěchy a selhání s tím, jak si vede jejich partner, i když tyto úspěchy a selhání spolu nemají naprosto nic společného. Dospělý člověk by měl být schopen vzít si prášky sám bez pomoci jeho partnera a zároveň existuje jen několik málo omezených možností, jak donutit dospělého člověka jíst ve správný čas a polykat prášky. Zodpovědnost za to ale i přesto spočívá na jeho partnerovi. Klasickým manévrem je obviňovat vašeho partnera z toho, že je zodpovědný za vaši náladu: Kdyby jen nebyl tak _____ nebo udělal _____ správně, nebyli byste tak vystresovaní/naštvaní.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Držte všechno na hraně</strong>. Postarejte se o to, aby nikdy nebyl dostatek peněz, času, věcí, statusu nebo čehokoliv jiného, po čem lidé touží. Nedostatek působí jako pohon nezdravého systému, protože nikdy není dostatek _____ k tomu, aby se systém dal opravit. A nikdy není dostatek času na to, vymyslet lepší řešení problému &#8211; místo toho se musí pracovat na plné otáčky, aby se systém udržel od totálního kolapsu.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Všechny tyto výše zmíněné věci spolu fungují tak, že se díky nim stává špatné pracovní místo nebo vztah návykovým. Už jste se strhali, abyste uhasili všechny ohně a zajistili, aby všechno fungovalo. Váš svět se rozpadne, pokud teď zastavíte. A občas i nachvilku uspějete. Věci se brzy zlepší. Nemůžete tomu přestat věřit. Pokud tomu věřit přestanete, nebudete moci pokračovat a vy si nemůžete dovolit nepokračovat, protože všechno to, co máte a všechno, co jste, je svázané se snahou dát tohle všechno do pořádku. Nevidíte jakoukoliv cestu ven, protože je kolem vás tolik věcí, co vás zastavují. A mohli byste zkusit třeba i něco jiného, ale to vyžaduje čas a peníze a vy nemáte ani jedno. Bylo vám ale řečeno, že obojí jednou mít budete až se stane určitá magická věc a tedy snaha o to, posunout to dále manuálně, nic neurychlí, takže je nejlepší prostě sklopit hlavu a čekat. Po nějaké chvíli vám už stres a panika připadají zcela normální, takže pokud je zrovna klid, cítíte se i přesto nervózně, protože víte, že dočasný klid neznamená, že by se věci zlepšily, ale znamená to, že ještě zatím nevíte, co dalšího se pokazilo. A systém nebo váš partner vám vždy další novou krizi velmi rádi zařídí.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Postupem času jste už ze všeho takový blázen, že už ani nedokážete mluvit s kýmkoliv, kdo na tom není podobně jako vy. Normální lidé od vás buď už utekli a nebo vám řekli už příliš mnohokrát, že jste hloupí a měli byste z práce/vztahu okamžitě odejít. Takže jste ztratili všechny, kteří by vám mohli pomoci k tomu, se konečně probrat a uvědomit si svou situaci. Nyní jste obklopeni lidmi, kteří žijí podobně šílený život jako vy a nevidí z něj žádnou cestu ven. Trávíte svůj čas tím, že jeden druhému říkáte, že to je fakt hrozné, ale nedá se nic dělat a všechno se zlepší, až se stane _____. Pokud si někdo z vás situaci uvědomí a řekne nahlas: &#8220;Hele lidi, dyť je to úplně celé postavené na hlavu a vůbec se toho přeci nemusíme účastnit,&#8221; tak se z něj stane porouchané ozubené kolečko v motoru nezdravého systému. Rychle si uvědomí, že s tím stejně nemůže nic udělat a odejde, čímž vás nechá samotného s vašimi šílenými přáteli.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            No a vy si myslíte, že to je úplně normální.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Začnete fantazírovat o tom, jaké by to asi tak bylo, kdybyste měli dostatečně silné sebevražedné myšlenky, které by vám dodaly potřebnou kuráž k tomu, spáchat sebevraždu. Ale není to vlastně taková hrůza, protože takovým způsobem nepřemýšlíte celý den od rána do večera a přece se vlastně vůbec zabít ani nechcete. Jenom si přejete, aby se něco magického objevilo a bezbolestně vás usmrtilo.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jednoho dne se dostanete až na dno. Možná chcete zemřít tak moc, že odchod z nezdravého systému vypadá jako dobrý způsob, jak spáchat sebevraždu. Nebo jste v takové depresi, že je vám to už jedno. Možná si to uvědomíte, až budete stát s pilulkou v ruce u vašeho partnera, který zrovna hraje World of Warcraft a nemá čas na to, si ji vzít, že tohle je šílenější než šílenství samotné a je čas na to, to konečně zastavit. Možná systém udělá chybu a vy budete mít příležitost podívat se na typ lidí, kteří byli povýšení a uvědomíte si, že nikdy povýšení nebudete.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A nebo se dveře samy otevřou a něco magického se stane. Pozice, o které jste vždy snili, se otevře. Škola, na kterou jste vždy chtěli jít, vám nabídne stipendium, které je určené přesně pro lidi, jako jste vy a osoba, která má stipendia na starosti, vám řekne, že je pro vás jako ušité. Váš prastrýc umře a odkáže vám 5 000 000 Kč. Můžete mít cokoliv, co chcete &#8211; pokud odejdete ze systému, do kterého jste zapletení.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pokud odejdete, stanou se dvě věci, jedna po druhé:     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            PANIKA! HOROR! NEBE PADÁ NA ZEM! ZTRATIL JSEM VŠECHNO, CO JSEM KDY MĚL A UŽ NIKDY TO NEDOSTANU ZPÁTKY! Necítím dostatek stresu, něco je špatně, něco hrozného se děje a nejsem tam, abych to zastavil. Bože, co můj ex-partner zrovna dělá a jak ho můžu takhle z dálky zachránit? Jsem za něj zodpovědný! Musím zavolat do jeho práce a ujistit se, že je v pořádku! Musím se ujistit, že všechno, co jsem opustil, je v pořádku, protože by se to beze mě všechno sesypalo a teď, když tam nejsem, tak se to určitě všechno sype, všichni ti nevinní lidé to odnáší a musím to zastavit!     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            &#8230; cítím se mnohem lépe.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Všechno je pryč, jako kdyby mě někdo přestal mlátit kladivem do hlavy. Ani jsem nevěděl, že nějaké kladivo existovalo. Proč jsem vůbec nechal někoho, aby mě mlátil kladivem do hlavy? Co se mi to jenom prohánělo hlavou? Jak něco z toho mohlo vůbec dávat smysl?     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Poté, co odejdete ze systému, vám to nedává vůbec žádný smysl. Všechny vábničky, které se vám houpaly před obličejem, už pro vás nic neznamenají a začnete si uvědomovat, pod jak obrovským stresem jste celou dobu byli. Začnete vidět věci, kterým byste odmítali věřit, pokud byste v systému ještě stále byli. A úleva je větší, než jste si vůbec kdy v systému představovali.     
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Ale &#8220;vy,&#8221; o kterých v těchto posledních odstavcích mluvím, už dávno nejste &#8220;vy,&#8221; o kterých byla řeč na začátku tohoto článku. Všem těm &#8220;vám&#8221; ze začátku &#8211; ať už jste partner nebo zaměstnavatel, který touží po tom, vytvořit nezdravý systém, aby držel lidi blízko sobě proti jejich vůli, vzkazuji: Nebojte se. Není to konec světa. Možná vám to tak připadá, když jste teď sami a váš bývalý partner je stovky kilometrů daleko; nebo když máte nedostatek zaměstnanců a vaši nejlepší zaměstnanci vás opustili, aby mohli pracovat pro konkurenci. Teď, když znáte tajemství toho, jak nezdravý systém nastavit, tak znáte i následující pravdy:     
                        </Fragment>
                    } />
                    
                    <TextCentered text="Kdokoliv se do takového systému dá chytit, pokud začnete dostatečně pomalu." />
                    <TextCentered text="Kdokoliv se do vašeho systému vejde, pokud máte dostatečně malé standardy." />
                    <TextCentered text="Jakýkoliv nezdravý systém vám může pomoci k vašim cílům, pokud jsou dostatečně malé." />

                    <PageParagraph content={
                        <Fragment>
                            Na volné místo ve vašem systému si zase najdete někoho nového. A sami se budete divit tomu, jak rychle. Běžte a najděte si nějakou čerstvou krev a pamatujte: Nepřizpůsobujte systém člověku, ale přizpůsobujte člověka systému.     
                        </Fragment>
                    } />

                    <PageBreak />
                    <Link to="/issendai-na-ceste-pryc">Pokračování &#8211; Na cestě pryč</Link>
                    
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default IssendaiAJakDonutit;