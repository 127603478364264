import React from 'react';
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageWrapper from "../../components/PageWrapper";

const Software = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'My favorite software'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockHeaderTwo header="Game engine — Godot" />
                    <PageParagraph content={
                        <Fragment>
                            My game engine of choice is <strong>Godot</strong>. My previous engine of choice was Game Maker. However, while Game Maker is certainly very easy to use, there are several disadvantages. It's primarily focusing on 2D, which means that you are stuck only with two dimensions. 3D is pretty much impossible, unless you want to write the entire rendering yourself (and if you can do that, why would you use Game Maker in the first place?). The UI can get confusing very quickly, because your whole project is displayed on one huge canvas, which can slow you down afterwards. Also, exporting to HTML5 is usually tricky and pretty much every single time, I had to apply some workarounds. My favorite issue was finding out that the whole UI rendered only in 25 % of its size on HTML5... two hours before the game jam was ending. Nice!<PageBreak />
                            <PageBreak />
                            The final nail into the coffin for me was the day when the Game Maker turned from paid permanent licence to the subscription model. I knew that I had to find something else. I have a permanent licence, which didn't change, so the subscription changes technically didn't touch me at all, but I am not a fan of subscription models.<PageBreak />
                            <PageBreak />
                            I have worked with Unity 3D and Unreal Engine in the past, but they have their quirks too. Unreal Enginer is a very specific beast, and Unity 3D is unable to have "real" 2D - all 2D games in it are actually still 3D ones, just with locked axis and some tricks applied in order to avoid camera stretching. So after some thinking, I decided to go for Godot. There are many advantages to using Godot.<PageBreak />
                            <PageBreak />
                            Firstly, it's <strong>completely free & open source</strong>! And it supports both native 3D and native 2D, which means that you can create proper 2D and 3D games at the same time, or even both at the same time! Community support is great, it's developing very nicely and includes a very reliable HTML5 exporter, which makes it perfect for game jams.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="3D modeling — Blender" />
                    <PageParagraph content={
                        <Fragment>
                            The main tool I use for 3D modeling is nothing else but <strong>Blender</strong>. Surpring, huh? But seriously, I don't think you can find any other free (or affordable for hobby use) modeling software which comes even close. Blender is a true labor of love and great example how in some cases, free and open source software can match even the biggest industry giants.<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Flat 3D — Crocotile 3D" />
                    <PageParagraph content={
                        <Fragment>
                            Back when I didn't know how to UV properly (and more importantly that "UV Reset" option which does what I needed existed), I bought the <strong>Crocotile 3D</strong>. It's a small adorable tool for creating 3D models tile by tile, which makes it great for flat 3D look. However, since I learned how to do this properly in Blender, and that it's also pretty easy there, there is no reason for me to use Crocotile 3D for now. At least I supported the independent developer who is passionate about it, so, that makes me happy!<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Pixel art — Aseprite" />
                    <PageParagraph content={
                        <Fragment>
                            When creating pixel art, my tool of choice is <strong>Aseprite</strong>, which is probably not surprising as it's pretty much a standard tool for pixel art. Incredibly lightweight, but at the same time, packed with everything you need for your pixel art, including the animation creator. The only thing I dislike about Aseprite is the way how it works with texts, which I think is just straight up silly and I have no idea why it's this bad. For everything else, you cannot get anything better.<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Image editor — Krita" />
                    <PageParagraph content={
                        <Fragment>
                            If I need to edit some image or draw something, I use <strong>Krita</strong>. Pretty nice and powerful alternative to Photoshop in my eyes. There are surely some areas where Photoshop is better, but Krita gives me everything I need, and it is a bang for the buck, which in this case, is zero. Krita was a pleasant surprise for me when it appeared, because the other alternative to Photoshop at the time was GIMP, which I DESPISE!<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Vectors — Inkscape" />
                    <PageParagraph content={
                        <Fragment>
                            Sometimes you need to draw vectors. I use <strong>Inkscape</strong> for this. Version 1.2 was a massive improvement in pretty much everything, and it is not hiding layers from you anymore. It still has some tiny quirks here and there, but it is not a frustrating experience as previous versions.<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Music production — Ableton Live" />
                    <PageParagraph content={
                        <Fragment>
                            When the planets are aligned just right and I have some time to produce music, my DAW of choice is <strong>Ableton Live Standard</strong> edition. It's more pricey (349 €) than the FL Studio (200 €), but no matter how hard I tried to like FL Studio, I just couldn't get into it, its workflow feels extremely unintuitive.<PageBreak />
                            <PageBreak />
                            Another DAW which I really liked to use was Bitwig, but it's even pricier with its cost of 399 €, so Ableton is a way to go for me.<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Sound editing — Audacity" />
                    <PageParagraph content={
                        <Fragment>
                            If I need to just make some small audio edits, I fire up <strong>Audacity</strong>. Gets the job done very nicely, it's packed with great features and effects, and it's completely free and open source!<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="IDE — VS Code" />
                    <PageParagraph content={
                        <Fragment>
                            My main IDE which I use is <strong>VS Code</strong>. I liked how lightweight and incredibly flexible it is. I don't think you can get anything better than it, especially for its price, which is free! I wrote this web using VS Code. When it comes to Godot, then I use the IDE which is built-in.<PageBreak />
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="GUI git — Sourcetree" />
                    <PageParagraph content={
                        <Fragment>
                            I use git, obviously, and while I usually love using terminal, I feel much safer using GUI git client. I use <strong>Sourcetree</strong> for this. You can use GitHub Desktop too, but I prefer Sourcetree.<PageBreak />
                        </Fragment>
                    } />

                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
};

export default Software;