import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageBulletListNested from "../../components/PageComponents/PageListComponents/page-bullet-list-nested";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import PageNumberedListNested from "../../components/PageComponents/PageListComponents/page-numbered-list-nested";
import PageWrapper from "../../components/PageWrapper";

const IssendaiEPoznamky = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Issendai – Poznámky'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/notes-all-or-nothing.html/" author="Originál v angličtině" />
                    
                    <PageBlockHeaderTwo header="Vztahy &#8220;všechno nebo nic&#8221;" />

                    <PageParagraph content={
                        <Fragment>
                            Mám nějaké myšlenky a pozorování, které nejsou dostatečně ucelené pro to, abych z nich udělala celý článek, ale možná by se hodily jiným lidem, i když třeba jenom v této &#8220;nestrávené&#8221; formě. Tady jsou některé poznámky ohledně přístupu &#8220;všechno nebo nic,&#8221; kterým členové fór pro opuštěné rodiče přistupují k usmíření.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Rodiče, kteří se snaží usmířit se svými opuštěnými dětmi si často stěžují, že kolem svých dětí musí &#8220;chodit po špičkách.&#8221; Jejich děti tvoří všechna pravidla a zničeho nic na své rodiče vybuchují. Neakceptují dostatek jejich pozvánek k návštěvě, nezvou dostatečně často své rodiče k sobě, nevolají dostatečně často, nepíšou dostatečně často. Rodiče mají pocit, že &#8220;žebrají o zbytky.&#8221; Postupem času &#8211; za několik let, měsíců, návštěv a někdy i po pouhé jedné návštěvě, se to rodičům přestane líbit a řeknou svým dětem, že jsou ochotni k tomu se usmířit, ale musí při tom jejich děti přistoupit na jejich podmínky. Jak řekla jedna matka, &#8220;Začínám si uvědomovat, že naše děti s námi nechtějí mít normální rodinný vztah. Chtějí, abychom si moc nevyskakovali a aby to celé fungovalo přesně podle jejich pravidel. [&#8230;] Takže jsem se rozhodla, že ty jejich hry hrát nebudu. Buď se s námi chtějí usmířit nebo ne.&#8221;
                        </Fragment>
                    }/>
                    
                    <PageBulletList content={
                        <Fragment>
                            <li>Vztah je popsaný jako nějaká hra, ve které se hráč pokouší o to, získat kontrolu nad protivníkem.</li>
                            <li>Očekávají, že se vše rychle vrátí do předchozího stavu. Pokud na to jde nějaký rodič pomalu, tak ho ostatní neupřímně chválí.</li>
                            <PageBulletList content={
                                <Fragment>
                                    <li>Některým rodičům se podaří jít na usmíření pomalu a hlásí díky tomu úspěchy s urovnáním sporů se svými dětmi. Ostatní členové jim pogratulují a hned k tomu napíšou, proč by ty samé metody v jejich případě nefungovaly. Mají velký odpor k tomu, zobecňovat řešení.</li>
                                </Fragment>
                            }/>
                            <li>Černobílé myšlení: &#8220;Buď se s námi chtějí usmířit [= chci usmíření, ve kterém kontroluju všechno, které funguje podle mých pravidel a které by nyní znamenalo, že bychom se plně vrátili k předchozímu &#8220;normálnímu&#8221; vztahu] nebo ne.&#8221;</li>
                            <li>Rodiče neuznávají změny, které po nich jejich děti chtějí. Je to pro ně &#8220;chození na špičkách&#8221; a odmítají své chování jakkoliv změnit.</li>
                            <li>Chození na špičkách naznačuje nejistotu. Rodič neví, co dělá špatně = nerozumí tomu, jaký je problém.</li>
                            <li>Bariéry, které se dítě rozhodne vztyčit pro svou vlastní ochranu = hry, pravidla, kontrola. Rodič nerozumí, proč dítě cítí potřebu něco takového vůbec dělat.</li>
                            <li>Rodiče reagují, jako kdyby po nich někdo chtěl, aby v poušti nosili pláštěnku a deštník.</li>
                            <li>Nerozumět problému nebo jeho řešení = smíření nebude fungovat.</li>
                            <li>Fascinující je to, že pokus o smíření je často ukončen rodičem, zatímco dítě, které má zpočátku k usmíření vlažný přístup, se o něj stále pokouší. Rodič interpretuje pomalý, promyšlený přístup dítěte &#8220;počkej a uvidíš&#8221; jako permanentní status quo.</li>
                            <PageBulletList content={
                                <Fragment>
                                    <li>Rodič přestane plnit podmínky svého dítěte (&#8220;přestane chodit po špičkách&#8221;), vrátí se ke svému starému chování, začne po dítěti chtít, aby přistoupilo na jeho požadavky a tím také potvrdí strach svého dítěte, že se jeho rodič vůbec nezměnil. Dítě se rozhodne rodiče opět opustit. Rodič to interpretuje jako chybu dítěte nebo nějakým jiným způsobem, který na něj vrhá co nejlepší světlo.</li>
                                    <li>Popřípadě rodič ztratí veškerou sebekontrolu, protože mu připadá, že se podřizuje dítěti a akceptuje neuctivé chování. (Neúcta = méně kontaktu, než si rodič představuje). Rozhodne se vztah přímo ukončit sám, protože je pro něj jednodušší nemít žádný vztah než částečný vztah.</li>
                                </Fragment>
                            }/>
                            <li>Pokud rodič popisuje odloučení jako zničující, nebo jako nejhorší bolest, kterou kdy zažil, tak se obvykle k odloučení vrací, pokud mu vztah po usmíření nepříjde dostatečně blízký.</li>
                        </Fragment>
                    }/>
                    
                    <PageParagraph content={
                        <Fragment>
                            Opuštění rodiče od svých dětí občas dostávají nabídky k tomu, setkat se s jejich vnoučaty. Samozřejmě obvykle s nějakým omezením: Potkat se jenom na veřejném místě, musí tam být oba rodiče, setkání bude krátké a jen jednou za čas, atd. Rodiče tyto nabídky interpretují tak, že se jejich děti určitě pokoušejí o to, donutit je začít ke svým vnukům cítit pouto, aby pak vnuky mohli použít proti nim. Opuštění rodiče, kteří začali ke svým vnukům cítit jisté pouto a poté s nimi ztratili kontakt, varují, že ztratit kontakt s vnoučatem pro ně bylo strašlivě bolestivé. Někteří rodiče se proto rozhodnou se svými vnuky vůbec nesetkat, aby sami sebe ochránili.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Dospělé děti nabízejí kontakt s vnuky, protože to berou jako krok ke smíření, protože jim je svých rodičů líto nebo to dělají kvůli tomu, že to berou jako nepsanou povinnost &#8211; například třeba věří, že děti by měly mít prarodiče i když jsou jejich prarodiče tak strašní, že s nimi uřízli kontakt. Omezení tu jsou tedy částečně kvůli bezpečí a částečně k tomu, aby se redukovala šance, že prarodič si s vnukem vytvoří silné pouto. Dospělé děti nechtějí, aby jejich rodiče měli se svými vnuky silné pouto. Dospělé děti si neužívají organizování takových setkání a často to dělají čistě kvůli svým rodičům. Očekávání jsou taková, že pokud se budou jejich rodiče pod dohledem chovat normálně, tak by třeba bylo možné jim povolit trávit s nimi více času, s menším dohledem a podobně.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Když prarodiče takovou podmínku odmítnou, tak si to dospělé dítě interpretuje jako: &#8220;Rodiče chtějí mít vztah buď podle jejich podmínek nebo podle žádných podmínek.&#8221;
                        </Fragment>
                    }/>
                    
                    <PageBulletList content={
                        <Fragment> 
                            <li>Opuštěnému rodičovi opět připadá, že je vztah pouze o kontrole.</li>
                            <li>Očekávají velmi rychlé usmíření; počáteční odstup vnímají jako permanentní.</li>
                            <li>Odvrací se od vztahu i když se jejich dospělé dítě stále snaží o usmíření.</li>
                            <li>Černobílé myšlení: Opuštění rodiče opravdu říkají, že: &#8220;Chtějí, aby jim bylo dovoleno být prarodiči&#8221; (ale podle jejich definice prarodiče = podle jejich podmínek), nebo nechtějí vůbec žádný vztah.</li>
                            <li>Opuštění rodiče si myslí, že mohou odmítnout nabídku bez toho, aby utrpěl vztah s jejich dospělým dítětem a nebo si myslí, že nabídka znamená, že se s nimi jejich dítě snaží manipulovat a že je to známka toho, že by se toho neměli účastnit.</li>
                            <li><strong>Katastrofické selhání vzájemného porozumění</strong>: Dospělé děti neví, že se jejich rodiče bojí toho, že budou cítit pouto ke svým vnukům a poté je ztratí; rodiče zase neví, že jejich dospělé dítě vidí setkání jako krok k usmíření.</li>
                            <PageBulletListNested content={
                                <Fragment> 
                                    <li>Řešení? Dát druhé stránce chybějící kousek skládačky?</li>
                                    <PageBulletList content={
                                        <Fragment> 
                                            <li>Dospělé dítě by odpovědělo: &#8220;Nechceme ti bránit setkat se s našimi dětmi. Jen respektuj naše podmínky a všechno bude fajn.&#8221; Opuštěný rodič nerozumí, že podmínky tu jsou od toho, aby něco vyřešily a interpretuje podmínky jako: &#8220;Poslouchej nás, dělej všechno, co ti říkáme, dej nám nad tebou kontrolu&#8221;; smíření je odsouzeno k neúspěchu, dospělé děti se rozhodnou vztah opět přerušit a spolu s nimi i vnuci.</li>
                                            <li>Nebo dospělé dítě slíbí: &#8220;Nikdy bychom tě neodřízli od tvých vnoučat.&#8221; To se často stává, když dospělé dítě situaci příliš nerozumí nebo používají nevhodný přístup ke svým problematickým členům rodiny. Sliby dokáží prodloužit vztah, který je odsouzen k zániku, ale nezabrání tomu, aby vztah opět zaniknul.</li>
                                            <li>Jednoduché uklidnění opuštěného rodiče nepomůže. Utěšení nic neznamená, pokud není podpořeno nějakými sliby, plus utěšení může povzbudit rodiče k tomu, aby se cítil více ve své kůži a vrátil se ke svému obvyklému chování.</li>
                                            <li>Opuštění rodiče by se naštvali kvůli tomu, že je jejich dospělé děti &#8220;testují&#8221;: &#8220;Buď se prostě chtějí usmířit nebo ne.&#8221; Dospělé dítě vypadá jako ten, u kterého je problém, takže rodiče nemají co dokazovat.</li>
                                            <li>Opuštění rodiče, kteří si myslí, že je jejich dítě chce lstí donutit k tomu, aby ke svým vnukům cítili pouto a dělají to pouze jako prostředek kontroly, jsou příliš narušení a zcela postrádají perspektivu, naznačuje to neschopnost empatie (ve formálním smyslu = být schopen chápat pocity toho druhého).</li>
                                        </Fragment>
                                    }/>
                                    <li><strong>Závěr</strong>: Dospělé děti nemohou své opuštěné rodiče dostatečně ujistit v tom, že na nezkouší žádné žádné triky. Jsou ochotné vztah obnovit, ale pouze pokud rodič změní svoje chování a protože rodič nerozumí tomu, co má změnit a proč, tak ke změně možná dojde jenom silným lpěním na pravidlech, která rodič vidí jako zbytečná.</li>
                                    <li><strong>Závěr</strong>: Rodiče nevěří, že jsou jejich děti upřímné, dokud je něčím nepřesvědčí; pouhá slova s jejich paranoiou nic neudělají. A přesvědčit je lze pouze úspěšným vztaheh s nimi a jejich vnoučaty. Paranoia nabádá rodiče k tomu, aby ze vztahu ze strachu odešli a nebo k tomu, aby chápali nabídky svých dětí jako pokus o sabotáž.</li>
                                </Fragment>
                            }/>
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Obě situace vyvstávají ze stejného jádra:
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Dospělé dítě je ochotné obnovit vztah POKUD se jejich opuštěný rodič změní. Dítě očekává, že změna bude chvíli trvat a chce důkaz, že se rodič opravdu změnil. Dospělé dítě si ideální budoucí vztah představuje jako velmi odlišný od původního, neuspokojivého vztahu.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Opuštěnému rodiči připadají změny nepotřebné, je uražený tím, že po něm dítě požaduje změnu, cítí se být kontrolován dítětem. Rodič cítí odpor k tomu, že ho někdo bude soudit a testovat, vůbec nerozumí problému a kvůli tomu také netuší, jak dlouho bude dítěti trvat, než jim bude zase věřit. Rodičova představa ideálního budoucího vztahu je stejná jako originální, pro ně uspokojivý vztah.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Dítě čeká na to, až se rodič změní.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Rodič čeká, až jeho dítě přestane s hloupostmi.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Žádná změna rodiče = žádná změna dítěte = usmíření selže.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Ne všechny pokusy o usmíření selžou. Někteří rodiče tvrdí, že si nejdřív museli projít jedním nebo více pokusy o usmíření, než se jim to povedlo a úspěšné usmíření nakonec bylo typu &#8220;dávej a ber.&#8221;
                        </Fragment>
                    }/>
                    
                    <PageBulletList content={
                        <Fragment>   
                            <li>Lidé chtějí ve vztazích dávat a brát. Nikdo nechce být ve vztahu, ve kterém se cítí, že jsou kontrolováni, ať už je důvod jakýkoliv.</li>
                            <li>Lidé, kteří jsou od sebe odloučení, jsou skeptičtí o stupni upřímého dávání a braní v jejich předchozím vztahu.</li>
                            <PageBulletListNested content={
                                <Fragment>   
                                    <li>Lidé s poruchami osobnosti jsou velmi nepřesní při popisování sebe samých a jsou velmi špatní pozorovatelé ostatních lidí.</li>
                                    <li>Počátečný nedostatek dávání a braní ve vztahu rodič-dítě má za úkol vyvážit rodičovu psychologickou moc nad jeho dítětem. Opuštění rodiče takovou moc volně používají a stejně volně také popírají existenci takové moci.</li>
                                    <li>Lidé, kteří jsou zvyklí na nerovné rozdělení moci, si interpretují rovnováhu jako nerovnost.</li>
                                </Fragment>
                            }/>
                            <li>Alternativní možnost: Předchozí nepovedé pokusy o usmíření přecijen donutily rodiče k tomu, aby se nad sebou zamysleli? Rodiče se dostatečně změnili na to, aby to alespoň částečně stačilo jejim dospělým dětem?</li>
                            <li>Alternativní možnost 2: Předchozí nepovedé pokusy o usmíření přesvědčily děti o tom, že se rodiče nezmění a kvůli tomu dospělé děti snížily své nároky?</li>
                            <li>Stejný mechanismus funguje i pozpátku, pokud je problém u dítěte. Pro tento případ platí už ale obvyklá poznámka, že rodiče takových dysfunkčních dětí chodí obvykle někam jinam a nekončí ve fórech pro opuštěné rodiče. Nicméně, dysfunkčních rodičů s dysfunkčními dětmi je poměrně dost.</li>
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Pokud jste dítě, které opustilo svého rodiče a právě čtete tohle, usmíření není možné. Protože vidím populaci rodičů, kteří jsou předurčeni k tomu, mít velmi silné, trvalé odloučení od svých dětí a velmi malou empatii. Pokud přemýšlíte o tom, pokusit se o smíření, moje rada je:
                        </Fragment>
                    }/>
                    
                    <PageBulletList content={
                        <Fragment>   
                            <li>Zeptejte se sami sebe, jestli váš rodič vykazuje jakékoliv známky toho, že se doopravdy změnil. Doufání a přání, že se změní, žádnými známkami změny nejsou.</li>
                            <li>Počítejte s tím, že to budou chtít řešit mnohem rychleji než vy. Buďte připraveni na to, mírnit jejich očekávání.</li>
                            <PageBulletList content={
                                <Fragment>   
                                    <li>Buďte připraveni na to, že vaše pravidla mohou ignorovat.</li>
                                </Fragment>
                            }/>
                            <li>Počítejte s tím, že se změní mnohem pomaleji, než to čekáte. Pokud nerozumí tomu, co je problém, budete je muset naučit veškerému správnému chování sami &#8211; a to nějaký čas potrvá. Očekávejte, že jakoukoliv změnu v okolnostech použijí jako výmluvu k tomu, aby se měnit nemuseli. Počítejte s tím, že se budou vymlouvat, pokud nebudete konzistentní a vytrvalí s následky jejich špatného chování.</li>
                            <PageBulletList content={
                                <Fragment>   
                                    <li>Popřemýšlejte o tom, jestli množství výchovy a udržování dobrého chování, které bude potřeba, budete schopni zvládnout. A přemýšlejte o tom pravidelně.</li>
                                    <li>Držte se mantinelů, i když třeba nastaly speciální okolnosti. (&#8220;Řekli jsme, že naše dítě ještě vidět nemůže, ale jsou přece Vánoce&#8230;&#8221;)</li>
                                    <li>Netahejte děti do této situace, dokud se váš rodič skutečně nechová normálně a vhodně, a to po nějakou delší &#8220;zkušební&#8221; dobu.</li>
                                    <li>Akceptujte to, že budete možná muset své rodiče několikrát opustit a několikrát se pokusit o smíření před tím, než se opravdu smíříte. Stejně jako váš rodič možná nevěřil tomu, že ve vztahu byl problém on, dokud jste je neodstřihli, tak stejně nebude věřit tomu, že ty změny po nich opravdu chcete, dokud jim nepředvedete, že vám nedělá problém je znovu odstřihnout.</li>
                                </Fragment>
                            }/>
                            <li>Pokud je bezpečné být k nim emočně otevřený, tak s nimi mluvte o jejich potřebách a očekáváních. Buďte otevření k některým jejich návrhům. (Toto je chůze na opravdu tenkém ledě mezi tím, být k nim otevřený, zároveň to obalit tak, aby si nemysleli, že mají větší vliv než ve skutečnosti mají a zároveň jim nedávat prostor k tomu, aby vás mohli podkopávat.)</li>
                            <li>Pokud není bezpečné s nimi být emočně otevřený, tak se neusmiřujte.</li>
                            <li>Pokud jste četli předposlední bod a říkali jste si, že to není bezpečné, poté jste si přečetli poslední bod a rozhodli se, že ne, je to vlastně bezpečné je, tak to bezpečné není. Nepokoušejte se s nimi usmířit, dokud budete cítit potřebu lhát sami sobě.</li>
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            A to nejdůležitější:
                        </Fragment>
                    }/>
                    
                    <PageBulletList content={
                        <Fragment>
                            <li>V žádném případě nesmí nést zodpovědnost za učení vašeho rodiče nebo za efekty jeho špatného chování spadnout na vašeho partnera nebo děti. Tohle není &#8220;dělejte&#8221; nebo &#8220;nedělejte.&#8221; Tohle není žádost. Tohle není návrh. VY budete svého rodiče vychovávat, VY se budete vypořádat s jeho toxicitou. Neberte své děti nebo partnera na střelnici, aby tam dělali terče, stejně jako byste je nevystrčili z úkrytu, kdyby se venku pohyboval šílený střelec.</li>
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Protichůdné myšlenky, dvojité metry" />

                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/contradictory-beliefs.html/" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Toto jsou některé protichůdné myšlenky a dvojitá měřítka, které lze často vidět na fórech pro opuštěné rodiče. Členové skákají z jedné myšlenky na druhou podle toho, jakým směrem diskuze směřuje.
                        </Fragment>
                    }/>
                    
                    <PageBulletList content={
                        <Fragment>
                            <li>Chci mít se svým dítětem dospělý vztah, se vzájemným vyjadřováním našich pocitů a zdvořilou diskuzí o našich problémech.</li>
                            <li>Já jsem rodič a moje děti mi musí projevovat úctu.</li>
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Být opuštěn od svého dítěte je krutá a nepřirozená situace. Pouto mezi rodičem a dítětem nikdy nemůže být přerušeno a dítě, které se snaží ho přerušit, tak se bezcitně pokouší o to, způsobit svým rodičům doživotní trauma.</li>
                            <li>Zříci se vzpurného dítěte je občas nezbytné a kompletně pochopitelné.</li>
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>I když si se svým dospělým dítětem nerozumím, tak odepření toho, mít vztah se svými vnoučaty, je zároveň týráním dětí a týráním prarodičů.</li>
                            <li>Pokud moji rodiče nebo příbuzní mají vztah s mým opuštěným dítětem nebo mými vnoučaty, tak jsou neloajální a zradili mě.</li>
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Pokud se dítě snaží mít vztah s mým partnerem ale ne se mnou, tak se snaží o to, vést boj pomocí metody &#8220;rozděl a panuj.&#8221;</li>
                            <li>Pokud se snažím mít dobrý vztah se svým dítětem bez toho, abych ho měl i s jeho partnerem, tak se prostě a jednoduše snažím udělat dobré rozhodnutí ve špatné situaci.</li>
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Když mluvím o našem odloučení s partnerem mého dítěte nebo partnerovými rodiči, tak se od nich snažím získat pomoc k tomu, aby nám pomohli vyřešit problémy a ukončit toto nepřirozené odloučení.</li>
                            <li>Pokud moje dospělé dítě mluví o našem odloučení se svým partnerem, jeho rodiči, mými rodiči nebo mým partnerem, tak jim cpe do hlavy lži a snaží se je poštvat proti mě.</li>
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Bariéry jsou zdravé a vytyčování bariér je nezbytné k tomu, abych se vypořádal s odloučením.</li>
                            <li>Jakékoliv pokusy dítěte o to, vytyčit bariéry a podmínky našeho vztahu, jsou snahou o to, mít nade mnou kontrolu.</li>
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>Pokud jsem já (opuštěný rodič) někdy své rodiče taky opustil, tak jsem to udělal pro moje mentální zdraví. Miloval jsem je, ale prostě jsem s nimi nemohl být.</li>
                            <li>To, že mě moje dítě opustilo, je jeho nechutná snaha o získání moci. A moje dítě se v tom vyžívá, protože mě nenávidí a chce, abych trpěl.</li>
                        </Fragment>
                    } />

                    <PageBulletList content={
                        <Fragment>
                            <li>My jsme rodiče. My jsme ti, kteří je znají nejlépe a milují nejvíce, protože jsme jejich rodiče.</li>
                            <li>Moji rodiče mě týrali. Milovali mě svým zvláštním způsobem, ale jejich láska mi ubližovala a byla podmíněná. Jsem rád, že mám svého partnera, který mě doopravdy zná a miluje mě takového, jaký jsem.</li>
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Absence kategorií" />

                    <PageBlockQuote quote="http://www.issendai.com/psychology/estrangement/lack-of-categorization.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Je tu hromada myšlenek, které mám furt v hlavě, okolo kterých furt kroužím, šťouchám do nich, ale cítím se jako slepec, který chce popsat slona. Hlavní myšlenka není úplně rozvinutá, ale nějakým způsobem, všechny tyto koncepty a pozorování jsou zároveň propojené.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Tato sekce je mimořádně roztříštěná. Mějte se mnou strpení.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Fóra pro lidi, kteří se musí stýkat s lidmi s toxickými osobnostmi, si vyvinou systémy kategorizování. Toxický vs. normální, pohlcující vs. zanedbávající, narcista nebo hraniční sociopat, alkoholik, feťák, zkuřka, polykač prášků. Kategorie definují minulost (&#8220;Říkáš, že drogy používá jenom ve společnosti, ale zlato, pokud od tebe krade, aby si je koupil, tak je určitě závislák&#8221;) a předpovídají budoucnost (&#8220;Bacha, šílená matka tvého ex-feťáka ho při rozvodu finančně podpoří, aby dostala přístup k tvým dětem&#8221;). Pomáhají členům rozumět, proč se některé věci dějí, proč jejich problematický šéf/přítel/učitel/rodič/dítě dělá to, co dělá, jak je zastavit, aby v tom nemohl pokračoval a jak se před ním chránit. Díky tomu se členové cítí lépe: méně bláznivě (&#8220;Nemůžeš za to ty, ale on&#8221;), méně provinile (&#8220;Je to parchant a je jedno, jak moc dobrá přítelkyně jsi byla&#8221;), dávají jim pocit větší kontroly (&#8220;Pokud uděláš X, tak udělá Y. Takže s tím můžeš udělat tohle&#8221;). Radí členům, jak situaci zlepšit, radí jim, jestli se vůbec něco dá dělat. Utěšují členy a říkají jim, jak se dostat ze situací, ve kterých jsou. Ve zkratce &#8211; kategorizování dělá všechno, co by členové fór pro opuštěné rodiče potřebovali.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Tak proč si členové fór pro opuštěné rodiče nevytvoří různé kategorie pro své opuštěné děti?
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            No&#8230; sice kolem sebe nadhazují slova jako &#8220;narcistický&#8221; nebo &#8220;hraniční,&#8221; ale jejich porozumění těmto poruchám osobnosti je velmi povrchní, odvozené především z vyprávění ostatních členů, kteří někde něco četli a jen velmi vyjímečně člen takového fóra cítí potřebu udělat si nějaký průzkum na vlastní pěst. Jejich používání konceptů je také povrchní. Na fórech lidí, kteří se vypořádávají s lidmi s poruchami osobnostmi, členové posílají popisy chování toxických lidí a ptají se, &#8220;Myslíte si, že je tenhle člověk narcistický?&#8221; &#8220;Je to chování někoho, kdo má hraniční poruchu osobnosti?&#8221; Často jsou ještě více specifičtí: &#8220;Je typické, že člověk s hraniční poruchou osobnosti příliš rychle probíhá různými vztahy a zasypává vás otázkami?&#8221; Na fórech pro normální opuštěné rodiče (kteří mají například drogově závislé děti, popřípadě mají nějakou poruchu osobnosti nebo duševní nemoc) můžou být takové konverzace zjednodušeny do pravidla: snažit se zlepšit věci, pokud se zlepšit dají. Utěšují ostatní členy a říkají jim, jak se dostat ze situace, ve které jsou. Ve zkratce, kategorie dělají všechno, co členové fór pro opuštěné rodiče potřebují. U těch ale diskuze probíhá zhruba následovně:
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            &#8220;Moje dcera je zlá a neuctivá. Je narcistka?&#8221;
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            &#8220;Ano, je narcistka, stejně jako moje zlá snacha.&#8221;
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            &#8220;Všechny děti, které opustily rodiče, jsou narcistické. Je to kvůli tomu, že jsme je vychovali k tomu, aby měly příliš velké sebevědomí.&#8221;
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            &#8220;Je jedno, jakou nálepkou ji označíš, důležité je to, že je zlá a neuctivá.&#8221;
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Měsíc později ta stejná členka přemýšlí o tom, zda její dcera nemá hraniční poruchu osobnosti, jestli není závislá na prášcích, jestli nepromítá své problémy se svým kontrolujícím přítelem na ni, stejně jako její zlý otec.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Což nám zvýrazňuje další komplikaci: Členové necítí, že by potřebovali nějakou klasifikaci. Jen velmi zřídka diagnostikují své dítě a pokud se o to snaží, nezůstanou u jedné diagnózy. Preferují něco, čemu říkám &#8220;zlehčené diagnózy,&#8221; takový soubor univerzálních důvodů pro opuštění, které lze použít na všechny dospělé děti:
                        </Fragment>
                    }/>
                    
                    <PageBulletList content={
                        <Fragment>
                            <li>Rozmazlili jsme je.</li>
                            <li>Jejich generace je narcistická.</li>
                            <li>Trestají nás za vymyšlené &#8220;zločiny.&#8221;</li>
                            <li>Chtějí nad námi mít kontrolu.</li>
                            <li>Používají nás jako obětní beránky pro jejich osobní problémy.</li>
                            <li>Je v módě své rodiče opouštět.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tyto důvody zprošťují rodiče jakýchkoliv pochybení, kromě tedy toho, že byli až příliš dobří rodiče a také je zprošťují od toho, muset tyto problémy řešit; neexistuje nic, co by mohli dělat, kromě čekání. Také díky nim nezkoumají problémy do hloubky. Napříkad, pokud dítě trestá rodiče za vymyšlené týrání, proč to dítě dělá? Proč si vymýšlí to, že ho rodič týral? Trpí kognitivním zkreslení kvůli hraniční poruše osobnosti nebo nekontrolované bipolární poruše? Pokud to nedělají kvůli pocitu kontroly, proč se cítí být ohrožovány svými rodiči? K tomu se už členové nedostanou, nikdy nejdou pod povrch problému.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            A ani necítí &#8220;věrnost&#8221; žádné konkrétní diagnóze. Často se stává, že rodiče souhlasí s jakoukoliv diagnózou, s kterou ostatní členové příjdou. Diskuzní vlákno o narcistických mileniálech dostane hromadu komentářů od rodičů, kteří tvrdí, že jsou opuštění kvůli jejich narcistickým mileniálům; vlákno o dětech, které používají své rodiče jako obětní beránky, dostane hromadu souhlasných komentářů od stejných rodičů jako v předchozím případě.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Co je zvláštní je to, že součástí této skupiny jsou i členové, jejichž děti jsou doopravdy diagnostikované: formálně diagnostikované poruchy osobnosti a duševní choroby, závislosti, atd. Poměrně výrazná menšina členů fór pro opuštěné rodiče příjde s příběhy dětí, jejichž poruchy jsou kompletním vysvětlením odloučení, ale zdá se, že tito členové to vůbec netuší, stejně jako ti ostatní. Občas se do toho namáčí i několikrát: I když píšou o jasné diagnóze (&#8220;Můj syn je bipolární a odmítá brát léky&#8221;), tak k tomu ještě přidají další, své diagnózy (&#8220;&#8230; a dala jsem mu toho příliš moc, když byl kluk, takže je rozmazlený. Jo a taky je závislý na pocitu moci a užívá si, když mě může trestat za vymyšlené prohřešky&#8221;). Zmíní se o věcech, které by na normálních fórech byly okamžitou diagnózou (&#8220;Můj syn je alkoholik&#8221;), ale pro ně a pro ostatní členy fóra pro opuštěné rodiče to je jen další nepodstatným detailem v nepřehledném guláši nechutností, kterým je mozek jejich dětí.
                        </Fragment>
                    }/>
                    
                    <PageBlockHeaderThree header="Kategorizace v jiných fórech" />

                    <PageParagraph content={
                        <Fragment>
                            Ve fórech, která se čistě věnují drogově závislým, duševně nemocným, lidem s poruchami osobnosti a podobně, si členové:
                        </Fragment>
                    }/>
                    
                    <PageBulletList content={
                        <Fragment>
                            <li>Vytvoří podrobná schémata chování, která se dají očekávat od lidí s konkrétním problémem.</li>
                            <li>Dělají rozdíly mezi chováním, které se nedá změnit, chováním, které se dá změnit a chováním, které není součástí problému.</li>
                            <li>Učí se tomu, aby si nebrali problémy osobně. (Drogově závislí se nechovají egoisticky, protože si myslí, že jim rodiče něco dluží, ale chovají se tak kvůli své drogové závislosti.) Je to důležitý krok k tomu, jak se naučit zvládnout takového člověka.</li>
                            <li>Používají schémata očekávaných chování, aby předpověděli budoucí chování takového člověka a vytvořili si plán toho, jak na takové chování reagovat.</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Některé problémy jsou pro ostatní členy &#8220;stopkami.&#8221; Přepíšou všechny ostatní problémy problémového člověka. Pokud je někdo závislý na drogách, není si schopný udržet práci a má napjatý vztah s babičkou, členové pravděpodobně doporučí babičce, aby s ním na chvíli omezila kontakt a rodičům, aby se přestali starat o to, že si není schopný udržet práci, protože dokud nevyřeší svoji závislost, tak se nic nezmění. Závislost na drogách je stopka.
                        </Fragment>
                    }/>

                    <PageParagraph content={
                        <Fragment>
                            Členové fór pro opuštěné rodiče nic takového nedělají. Člen řekne, že je jejich syn bipolární a odmítá brát léky, poté popíše chování, které se od neléčeného bipolárního člověka dá očekávat, ale napíše to jako kdyby to syn dělal z vlastní vůle a jenom kvůli tomu, aby členovi ublížil. Možná člena v koutku duše napadne, že nebude možné mít dobrý vztah se svým synem, dokud nebude brát prášky, ale bude k tomu spolu s ostatními členy přistupovat jako kdyby se rady pro lidi s duševně nemocnými dětmi daly aplikovat i na jejího neléčeného bipolárního syna. I lidi, kteří mají vzdělání, díky kterému ví, jak se chovat k závislákům, nejsou o moc lepší v tom, vidět hranici mezi nemocí a osobností.
                        </Fragment>
                    }/>
                    
                    <PageBlockHeaderThree header="Nejdůležitější věc o tobě jsem já" />

                    <PageParagraph content={
                        <Fragment>
                            Když čtu, jakým způsobem se rodiče snaží porozumět jejich dítěti, nemůžu se zbavit pocitu, že členové fór pro opuštěné rodiče definují věci podle toho, jaký na ně mají vliv. Snacha, kvůli které se o sobě cítí provinile, je snacha, která se o to cíleně snaží. Ptát se jich, proč to snacha dělá, je jako kdybyste přišli do obchodu s oblečením a chtěli od zaměstnanců, ať vám rozdělí trička podle toho, zda do obchodu přišla náklaďákem, lodí nebo letadlem. Jak to mají vědět? Proč by je to mělo zajímat? Možná se podívají na informace na stránkách společnosti a podle nich odhadnou, jak to tričko do obchodu mohlo přijít, ale nebude to pro ně dostatečně specifická a důležitá informace, aby si ji pamatovali příště, až někdo zase příjde do obchodu a začne se jich na to ptát.
                        </Fragment>
                    }/>

                    <PageBulletList content={
                        <Fragment>
                            <li>Tento styl myšlení je reflexní, nevědomý; lidi, kteří takto přemýšlejí, nevědí, že takto přemýšlejí.</li>
                            <li>Tento styl myšlení je zbavený jakéhokoliv náhledu na věc, ale je bohatým zdrojem pseunáhledu na věc. Členové usilovně přemítají nad stavem mysli svých dětí a zmiňují se o tom jako o důkazu toho, jak hluboce o myšlenkách a pocitech svých dětí přemýšlí, ale je rozdíl mezi tím, brát něco jako téma a udělat z toho předmět. Abych to popsala jinak &#8211; můžu být celý život posedlá slávou Benedicta Cumberbatche bez toho, aniž bych pochopila, jaký doopravdy byl.</li>
                            <li>Tento styl myšlení je aktivně povzbuzován ostatními členy. Zkušení členové zcela rutinně usekávají myšlenky ostatních členů větami jako, &#8220;Je jedno, proč to tvoje děti udělaly, důležitá věc je ta, že jsi byla dobrá matka a ony tě odhodily.&#8221;</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <em>Narcistická Rodina: Diagnóza a Léčba</em> diskutuje o této mentalitě. Autorka, Stephania Donaldson-Pressman, nepoužívá termín &#8220;narcistický&#8221; k tomu, aby s ním popisovala narcistickou poruchu osobnosti, ale popisuje s ním rodinu, ve které &#8220;potřeby rodičů mají přednost před potřebami dětí.&#8221; Potřeby dětí nejsou pouze druhotné, ale jsou &#8220;často pro rodiče velmi problematické.&#8221;
                        </Fragment>
                    }/>
                    
                    <PageBlockQuote quote={
                        <Fragment>
                            V narcistické rodině se chování dítěte posuzuje ne podle toho, co dítě cítí nebo čím si prochází, ale podle toho, jaký má dopad na rodiče. Například &#8211; pokud dítě v normální rodině dostane ve škole pětku, tak to rodiče upozorní na to, že je někde problém. A tato situace je zkoumána s ohledem na potřeby a vývoj dítěte: je práce moc těžká, je dítě pod stresem, potřebuje pomoc, doučování, nebo něco takového? V narcistické rodině je problém vyhodnocován podle toho, jaký to má dopad na rodiče: je dítě zlobivé, líné, dělá nám ostudu a nebo jenom hledá pozornost?<br></br>
                            V tomto případě by normální rodina reagovala tak, že by vyjádřila znepokojení nad tím, jestli se dítě cítí dobře, zda ho netrápí nějaký problém a prezentovala by mu pětku ne jako osobní selhání, ale jako problém, který se musí vyřešit. V narcistické rodině ale reakce rodičů indikují dítěti, že jeho pocity mají jen velmi malou nebo žádnou důležitost. Dítě nemá problém, dítě je problém.
                        </Fragment>
                    } author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Donaldson-Pressman říká, že rodiče v narcistických rodinách raději své dítě onálepkují místo toho, aby se mu snažili porozumět. Z mých pozorování, jak děti postupně vyrůstají, tak &#8220;líný, hloupý, klaun třídy,&#8221; které autorka zmiňuje, se změní na &#8220;nevděčný, rozmazlený, sobecký,&#8221; a &#8220;selhává, protože chce pozornost&#8221; se změní na &#8220;nevolá mi, protože mě chce kontrolovat.&#8221; Nálepky, ano, ale jsou k ničemu &#8211; jsou příliš obecné; vysvětlení, ano, ale vysvětlení s žádným konceptem věci, kterou se snaží vysvětlit.
                        </Fragment>
                    }/>
                    
                    <PageBlockHeaderThree header="Poznámka ohledně kategorizování na fórech pro opuštěné rodiče" />
                    
                    <PageBulletList content={
                        <Fragment>
                        <li>Absence jakýchkoliv očekávaných kategorií, včetně základních jako duševně nemocný/závislý/týraný partnerem.</li>
                        <li>Absence kategorií jako násilný/manipulativní, chování, jak se chová dítě k rodiči.</li>
                        <li>Rozhodující faktor je, &#8220;<strong>Jak se rodič kvůli dítěti cítí?</strong>&#8220;</li>
                        <li>Jedna jediná odpověď: &#8220;Odmítnutý, opuštěný, odhozený stranou, truchlící, uražený, naštvaný.&#8221; Jedna odpověď = jedna kategorie. Další kategorizace není třeba.</li>
                            <PageBulletListNested content={
                                <Fragment>
                                    <li>Jakákoliv další kategorizace riskuje tvorbu různých táborů v podpůrné skupině, která je hluboce citlivá na jakýkoliv typ konfliktu.</li>
                                </Fragment>
                            } />
                        <li><strong>První typ kategorie</strong> je irelevantní, protože popisuje vlastnosti, které dítě zdědilo (duševní nemoc, alkoholismus).</li>
                            <PageBulletListNested content={
                                <Fragment>
                                    <li>
                                        Členové fór pro opuštěné rodiče o svých dětech mluví, jako kdyby to byly černé skříňky. (&#8220;Nelze porozumět neporozumitelnému.&#8221;) Jakékoliv jejich porozumění je kolísavé, neustále se mění podle nálady rodiče a je lehce přepsáno jakýmikoliv nápady, které na fóru zrovna frčí. Mlhavý a neucelený koncept myšlení dítěte = znaky, které dítě zdědilo, jsou rodičem snadno přehlédnuté a i kdyby je rodič viděl, nebude mu připadat, že by měly nějaký důležitý vliv na jejich vztah.</li>
                                        <PageBulletList content={
                                            <Fragment>
                                            <li>I když se rodiče sami sebe ptají &#8220;Proč moje dítě dělá to, co dělá?&#8221; a opravdu se upřímně snaží analyzovat jejich chování, tak analýza ztroskotá na nedostatku dat; kvůli tomu, že rodiče nemají správné nástroje pro analýzu; a kvůli pokusům dalších členů odchýlit diskuzi od &#8220;zbytečných&#8221; témat zpět k tomu, jak se rodič cítí.</li>
                                            <li>Když se rodiče dostanou k nějakému závěru o duševním stavu svých dětí, jejich bludy jsou tak divné, paranoidní, úplně mimo normální realitu (&#8220;Moje dítě se odstěhovalo bez toho, aniž by mi řeklo novou adresu a potom čekalo, aby mělo potěšení toho, na nás zavolat policii, až ho najdeme a pojedeme ho navštívit.&#8221;), že nejsou schopni předpovědět budoucí chování dítěte. Pokud typ analýzy neprodukuje dobré výsledky, tak s ní přestanete.</li>
                                                <PageBulletListNested content={
                                                     <Fragment>
                                                        <li>A zároveň: neschopnost kvalitní dedukce člena ho nezastavuje od toho, radit dalším členům k tomu, aby dělali závažná rozhodnutí, která budou mít celoživotní vliv.</li>
                                                    </Fragment>
                                                } />
                                            </Fragment>
                                            } />
                                </Fragment>
                                } />
                        
                        <li><strong>Druhý typ kategorie</strong> (násilný/manipulativní) je irelevantní, protože&#8230; proč vlastně? Protože popisuje chování vycházející z dítěte?</li>
                        <PageBulletListNested content={
                                <Fragment>
                                    <li>Počkej, co?</li>
                                    <li>Chování dítěte má vliv na rodiče, takže by se tím měli znepokojovat i ti nejsobečtější rodiče. Co se děje?</li>
                                    <li>Teorie: Různá chování mohou způsobit úplně stejné pocity rodiče, takže specifická chování dítěte jsou irelevantní.</li>
                                    <li>Teorie: Pro rodiče je tak složité rozeznat chování, které pochází od nich, že nedokáží rozeznat ani kategorie jako &#8220;děti, které bijí své rodiče.&#8221;</li>
                                    <li>Teorie: Rodiče jsou tak sebestřední, že ani neztrácí čas srovnáváním jejich násilného dítěte s násilnými dětmi jiných lidí. Pokud se o tom baví v nějakém diskuzním vláknu, tak své děti sice porovnají, ale poté to ihned vypustí z hlavy.</li>
                                    <li>Když si to tak vezmu, tak nevím zcela jistě, proč členové fór pro opuštěné děti tuto kategorii nepoužívají.</li>
                                </Fragment>
                            } />
                    </Fragment>
                    } />

                    <PageBlockHeaderThree header="Extrémně předběžné závěry" />
                    <PageParagraph content={
                        <Fragment>
                            Nálepky mohou být způsobem, jak omezit člena a odmítnout jeho individualitu, ale také nám mohou pomoci rozpoznat důležité rozdíly. Například člověk s neléčenou hraniční poruchou osobnosti vidí svět skrz velmi pokřivené sklo, které se mu ještě více pokřiví, když je ve stresu. Má problém ovládat své emoce, takže jeho kognitivní zkreslení a z toho vyplývající strach se vzájemně vyživují a vedou k sebedestrukčním myšlenkám. Jeho tendence vidět věci černobíle znamená, že lidi okolo nich se mrknutím oka mění z andělů na démony. Ve zkratce, jeho nepředvídatelnost je&#8230; předvídatelná. Pokud chcete porozumět vztahu s takovým človekem, musíte rozumět jemu. A k tomu, abyste mu mohli rozumět jemu, musíte rozumět této poruše. Pokud jí dáte nálepku &#8220;hraniční porucha osobnosti,&#8221; tak sice odříznete kus individuality této osoby, ale uvidíte zároveň kosti, ze kterých se její porucha skládá, kostru, která její osobnost tvaruje.
                        </Fragment>
                    }/>
                    <PageParagraph content={
                        <Fragment>
                            Členové fór pro opuštěné rodiče takovou kostru nehledají. Preferují vysvětlení, která se opírají o jejich zkušenosti s dítětem místo toho, aby se zaměřili na dítě samotné. To platí i když jejich dítě má diagnostikovanou poruchu, která kompletně vysvětluje jeho chování. Preferují &#8220;zlehčené&#8221; důvody, které na ně nekladou nároky k tomu, mít pro něco pochopení: Moje dítě mě opustilo, protože je to v módě. Moje dítě mě opustilo, protože jsem mu nedávala dost na zadek a nenaučila ho tak respektovat mě. (A 11. září se stalo, protože teroristi nenávidí svobodu.)
                        </Fragment>
                    }/>
                    <PageParagraph content={
                        <Fragment>
                            Tyto zlehčené důvody nevedou k hlubšímu porozumění toho, proč je dítě opustilo a ještě zhoršuje šanci usmíření. Tak proč je mají rodiče tak rádi? Je tu určitý počet možností, jako:
                        </Fragment>
                    }/>
                    
                    <PageBulletList content={
                        <Fragment>
                            <li>Rodič skutečně <strong>není schopen toho, vidět problém hlouběji</strong>.</li>
                            <li>Rodič si <strong>nemůže dovolit vidět problém hlouběji</strong>, protože kdyby to tak udělal, mohl by se cítit provinile a to není schopen unést.</li>
                            <li>Rodič se cítí být <strong>pod útokem svého dítěte</strong>, a empatie s dítětem by oslabila jeho obranu. (Důsledek pokřiveného myšlení, že pokud něčemu rozumí, tak s tím i souhlasí.) Někteří z rodičů jsou opravdu pod útokem; někteří jsou přecitlivělí ke konfliktům a to tak, že úplně normální klidnou námitku svého dítěte berou jako záchvat křiku; a někteří jsou do toho tak zamotaní, že normální individualita dítěte jim způsobuje pocity, jako kdyby je jejich vlastní dítě trhalo na kusy.</li>
                            <li>Autoritářští členové cítí silnou potřebu <strong>potrestat lidi, kteří nerespektují autoritu</strong>. Fóra pro plná následovníků autorit, kteří hojně a rádi ukazují, jak moc chtějí potrestat jejich rozmazlené malé parchanty. Když jste posedlí touhou někoho potrestat, tak se nezastavíte k tomu, abyste je blíže poznali. A až se trochu zklidníte, tak se začnete cítit provinile a &#8211; opravdový problém pro členy fóra &#8211; spravedlivý vztek může být jedna jediná věc, která vás udrží od sebevražedných myšlenek.</li>
                            <li>Toto neplatí v případě, že děti opuštěných rodičů mají duševní poruchu nebo trpí nějakou závislostí. Autoritáři myslí černobíle, takže se lehce dostanou mezi &#8220;moje duševně nemocné dítě není zodpovědné za nic, co dělá, takže to musím všechno akceptovat&#8221; a &#8220;moje dítě to všechno dělá schválně, takže se s ničím, co dělá, nebudu zabývat.&#8221;</li>
                        </Fragment>
                    } />

                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default IssendaiEPoznamky;