import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageWrapper from "../../components/PageWrapper";

const PravoNaNazor = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Ne, nemáte nárok na vlastní názor'}}/>
            
            <PageContent content={
                <Fragment>
                    <PageBlockQuote quote="https://theconversation.com/amp/no-youre-not-entitled-to-your-opinion-9978" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Každý rok se snažím se svými studenty alespoň jednou procvičit dvě věci. Nejprve je oslovím slovem „<em>filosof</em>ové“ – trochu kýčovité, ale doufám, že je to povzbudí k aktivnímu učení.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Zadruhé jim řeknu něco takového: „<em>Jsem si jistý, že jste slyšeli výraz ‚každý má právo na svůj názor.‘ Možná jste to dokonce někdy řekli sami. Možná proto, abyste odvrátili hádku nebo ji ukončili. No, jakmile vejdete do této místnosti, už to není pravda. Nemáte právo na svůj názor. Máte právo jen na to, co dokážete vyargumentovat</em>.&#8221;
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Příliš drsné? Možná, ale učitelé filozofie dluží našim studentům to, naučit je, jak správně sestavit a obhájit argument – a jak rozpoznat, kdy se nějaká víra stane neobhajitelnou.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Problém s výrazem „<em>Mám právo na svůj názor</em>“ je ten, že se až příliš často používá k zachování přesvědčení, kterých by se mělo zanechat. Stává se to zkratkou pro: „<em>mohu říkat nebo si myslet, co se mi zlíbí</em>“ – a naznačit tak druhé straně, že pokračovat v hádce je už jaksi neuctivé. A tento postoj podle mě přispívá k falešné rovnosti mezi odborníky a neodborníky, která je stále zhoubnějším rysem našeho veřejného diskurzu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Zaprvé, co je to názor?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Platón rozlišoval mezi názorem, běžnou domněnkou (doxa) a určitými znalostmi, a to je dnes stále funkční rozlišení: narozdíl od „<em>1+1=2</em>“ nebo „<em>neexistují žádné čtvercové kruhy</em>“ má názor určitý stupeň subjektivity a nejistoty. Pod pojmem „názor“ se rozumí vše od osobního vkusu a preferencí, přes názory na otázky týkající se většiny lidí, jako je obezřetnost nebo politika, až po názory založené na technických znalostech, jako jsou právní nebo vědecké názory.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            O prvním typu názoru se opravdu nemůžete dohadovat. Byl bych hloupý, kdybych trval na tom, že se mýlíte, pokud si myslíte, že jahodová zmrzlina je lepší než čokoládová. Problém je v tom, že někdy se nám může zdát, že názory druhého a dokonce i třetího druhu také považujeme za plně nevyřešitelné, podobně jako by šlo o otázky vkusu. Možná to je jeden z důvodů (nepochybně existují i další), proč si nadšení amatéři myslí, že mají právo nesouhlasit s klimatickými vědci a imunology a očekávat, že se jejich názory budou „<em>respektovat</em>.“
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Meryl Dorey je vedoucí <em>Australské Vakcinační Sítě</em>, která je navzdory svému jménu vehementně protivakcinační. Paní Dorey nemá žádnou lékařskou kvalifikaci, ale tvrdí, že pokud může Bob Brown komentovat jadernou energii přestože není vědec, mělo by jí být tím pádem také umožněno komentovat vakcíny. Ale nikdo nepředpokládá, že Dr. Brown je odborníkem na fyziku jaderného štěpení; jeho úkolem je komentovat <strong>politické reakce</strong> na vědu, nikoli <strong>vědu samotnou</strong>.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Co to tedy znamená mít &#8220;<em>nárok</em>&#8221; na názor?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pokud „<em>každý má právo na svůj názor</em>“ znamená, že nikdo nemá právo zastavit ostatní v tom, aby si mysleli a říkali, co chtějí, pak je toto tvrzení pravdivé, ale docela triviální. Nikdo Vám opravdu nemůže zabránit v tom tvrdit, že vakcíny způsobují autismus, bez ohledu na to, kolikrát bylo toto tvrzení vyvráceno.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Ale pokud „<em>nárok na názor</em>“ znamená „<em>právo na to, aby se s Vašimi názory zacházelo jako s vážnými kandidáty na pravdu</em>,“ pak je to zcela jasně nepravdivé. A to je také rozdíl, který má tendenci se často rozmazávat.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            V pondělí program Mediawatch stanice ABC vzal lokální televizi <em>WIN-TV Wollongong</em> a dal jí za úkol vytvořit reportáž o vypuknutí spalniček, která také zahrnovala komentář – uhodli jste – Meryl Dorey. V reakci na stížnost diváků <em>WIN-TV</em> uvedla, že reportáž byla „<em>přesná, spravedlivá a vyvážená a prezentovala názory lékařů a vybraných skupin</em>.“ To ale předpokládá rovnocenné právo na slyšení ve věcech, ve kterých má pouze jedna ze dvou stran příslušné odborné znalosti. Opět, pokud by se jednalo o politické reakce na vědu, bylo by to v pořádku. Ale takzvaná „<em>debata</em>“ je zde o <strong>vědě samotné</strong> a „<em>vybrané skupiny</em>“ jednoduše nemají nárok na vysílací čas v případě takového druhu debat.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Moderátor <em>Mediawatch </em>Jonathan Holmes byl mnohem otevřenější: „<em>Existují důkazy a existují kraviny</em>“ a není součástí práce reportéra, aby kravinám dával stejný vysílací čas jako seriózním odborníkům.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Reakce odpůrců očkování byla předvídatelná. Na stránce <em>Mediawatch </em>paní Dorey obvinila ABC z „<em>otevřeného volání po cenzuře vědecké debaty</em>.“ Tato odpověď zaměňuje to, že Vaše názory nejsou brány vážně, s tím, že Vám není dovoleno tyto názory zastávat nebo vyjadřovat vůbec – když si vypůjčím frázi od Andrewa Browna, „<em>plete se tu ztráta argumentu se ztrátou práva argumentovat</em>.“ Opět se zde spojují dva druhy „<em>nároku</em>“ na názor.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Takže až příště uslyšíte někoho prohlásit, že má právo na svůj názor, zeptejte se ho, proč si to myslí. Je pravděpodobné, že když nic jiného, skončíte alespoň s mnohem zábavnějším rozhovorem.
                        </Fragment>
                    } />
                    
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default PravoNaNazor;