import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

const MenuIndentedButton = ({linkTo, currentPage, buttonText, isHidden, siblingPages}) => {
    return (
        <div>
            {!siblingPages.includes(currentPage) ? (
                <Link to={linkTo}>
                    <button onclick={<Link to={linkTo}></Link>} class="hidden text-ellipsis text-sm text-left ml-12 p-1 mx-4 mt-1 items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white hover:bg-opacity-20 text-white hover:border-white border-transparent border-2">
                        {buttonText}
                    </button>
                </Link>
            ) : linkTo === currentPage ? (
                <Link to={linkTo}>
                    <div class="lg:ml-8 lg:mr-5 lg:pl-2 lg:p-1 lg:border-l-4 dark:border-white">
                        <button onclick={<Link to={linkTo}></Link>} class="w-full text-ellipsis text-sm p-1 text-left mr-8 flex items-center rounded-md px-4 duration-300 cursor-pointer bg-white bg-opacity-20 border-white border-transparent border-2 text-[15px] text-gray-200 font-bold">
                            {buttonText}
                        </button>
                    </div>
                </Link>
            ) : (
                <Link to={linkTo}>
                    <div class="lg:ml-8 lg:mr-5 lg:pl-2 lg:p-1 lg:border-l-4 dark:border-white">
                        <button onclick={<Link to={linkTo}></Link>} class="w-full text-ellipsis text-sm p-1 text-left mr-2 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white hover:bg-opacity-20 hover:border-white border-transparent border-2 text-[15px] text-gray-200 font-bold">
                            {buttonText}
                        </button>
                    </div>
                </Link>
            )}
        </div>
    )
}

export default MenuIndentedButton