import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

const MenuMainButton = ({linkTo, currentPage, buttonText, isHidden}) => {
    return (
        <div>
            {isHidden ? (
                <Link to={linkTo}>
                    <button onclick={<Link to={linkTo}></Link>} class="hidden text-ellipsis w-full p-2 lg:mt-2 text-left rounded-md lg:px-4 duration-300 cursor-pointer hover:bg-white hover:bg-opacity-20 text-white hover:border-white border-transparent border-2 text-[15px] lg:ml-2 font-bold">
                        {buttonText}
                    </button>
                </Link>
            ) : linkTo === currentPage ? (
                <Link to={linkTo}>
                    <button onclick={<Link to={linkTo}></Link>} class="text-ellipsis w-full p-2 lg:mt-2 flex text-left rounded-md lg:px-4 duration-300 cursor-pointer bg-white bg-opacity-20 text-white border-white border-transparent border-2 text-[15px] lg:ml-2 font-bold">
                        {buttonText}
                    </button>
                </Link>
            ) : (
                <Link to={linkTo}>
                <button onclick={<Link to={linkTo}></Link>} class="text-ellipsis w-full p-2 lg:mt-2 flex text-left rounded-md lg:px-4 duration-300 cursor-pointer hover:bg-white hover:bg-opacity-20 text-white hover:border-white border-transparent border-2 text-[15px] lg:ml-2 font-bold">
                    {buttonText}
                </button>
                </Link>
            )}
        </div>
    )
}

export default MenuMainButton