import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import PageWrapper from "../../components/PageWrapper";

const NavodNaZhubnuti = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Návod na zhubnutí pro líná prasata'}}/>
            
            <PageContent content={
                <Fragment>                    
                    <PageBlockQuote quote="http://deadmailboxsociety.com/the-lazy-assholes-guide-to-losing-some-fucking-weight/" author="Originál v angličtině" />

                    <PageParagraph content={
                        <Fragment>
                            <i>Tento text je volným překladem. Jeho cílem není nikoho urážet, ale vtipnou a neortodoxní cestou poučit lidi o tom, jak lze snadno zhubnout bez toho, aniž by museli dělat příliš velké změny ve svých životech.</i>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Existují přesně dva různé důvody, proč právě čtete tento článek:
                        </Fragment>
                    } />

                    <PageNumberedList content={
                        <Fragment>
                                <li>Nemáte nadváhu. Nenávidíte tlusté lidi a chcete se jim smát.</li>
                                  <li>Jste tlouštík &#8211; nejste jenom obézní, ale také líný a pravděpodobně i antisociální. Nejspíše jste také závislý na pornu nebo videohrách (seru na vaše sebevědomí). Pokud je tohle váš případ, tak je tenhle článek přesně pro vás!</li>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nejste sami. Obezita je v dnešní době prakticky běžná a normální věc. Nikdy v historii nebylo tak jednoduché být tlustým, líným prasetem a vyhýbat se jakékoliv kritice od vašich známých, kteří by mohli nedejbože kritizovat vaše špatné životní volby.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A přesto jste vždy fantazírovali o tom, že zhubnete. Ne jenom kvůli holkám nebo aby pro vás bylo jednodušší umývat si místo mezi vašimi špeky, aby to tam mezi nimi nesmrdělo jako zkyslé mléko, nebo abyste už nikdy nemuseli zažít potupu, kdy jste stáli ve frontě na focení a zdržovali ostatní lidi v řadě kvůli tomu, že si na vás fotograf musel vzít jinou čočku, aby vám byl vůbec vidět obličej (velká rána pro vaše sebevědomí, která se dá ale jednoduše zacelit nějakým pořádným hamburgerem nebo preclíkem namočeným v rozpuštěném másle). Není to už jenom o tom, abyste vypadali lépe nebo abyste měli konečně kuráž pozvat na rande holku, která vás stejně nikdy nebude chtít &#8211; už jste totiž postupem času ztratili i poslední špetku naděje, že jednou budete mít dostatek sebekontroly nutné k tomu, abyste se stali fit. Zkrátka věci se mají tak, že jste zatím neprokázali jakékoliv úsilí nutné k tomu, abyste něco ve vašem životě zlepšili &#8211; takže je to nyní i o tom, mít konečně pocit, že alespoň něco ve vašem životě máte trochu pod kontrolou, sakra už!
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Ale na druhou stranu &#8211; kdybyste nějakou sebekontrolu měli, tak byste nebyli líným prasetem, co?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Každopádně, ať už je to tak či onak, dali jsme na Dead Mailbox Society dohromady něco přesně pro vás (no necítíte se už teď speciálně?): návod k tomu, jak ztratit nějaká nadbytečná kila bez toho, aniž byste se kvůli tomu museli nějak extra předřít. Místo toho, abyste zaplatili 5000 Kč za členství v posilovně, kam půjdete stejně jenom jednou na patnáct minut před tím, než nevyhnutelně půjdete zase zpátky domů pobrečet si, protože se kvůli všem těm namakancům v tílcích cítíte méněcenně, tak se tento návod zaměří na věci, které jsou levné, jednoduché a co je to nejlepší &#8211; nevyžadují prakticky žádné extra úsilí.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Necháme už těch keců a jdeme si ukázat, jak může i takové líné prase jako vy shodit nějaká ta kila.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="1) Přestaňte pít limonády, džusy a další slazené nápoje" />

                    <PageParagraph content={
                        <Fragment>
                            Pokud už neuděláte nic dalšího z tohohle seznamu, udělejte alespoň tohle. A především pokud jde o limonády, které by se spíše měly jmenovat <em>tekutá obezita</em>. Opakujeme ještě jednou: POKUD NEUDĚLÁTE NIC Z TOHOHLE SEZNAMU, PŘESTAŇTE ASPOŇ PÍT TY DEBILNÍ LIMONÁDY.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Na slazených nápojích není naprosto nic dobrého. Nejen, že mají naprosto nulovou výživovou hodnotu, ale aktivně ničí vaše tělo. Kyseliny, barviva, ochucovadla, konzervanty a podobně, které do těchto nápojů výrobci přímo sypou z těchto nápojů prakticky dělají pouhé toxické splašky a některé z výše jmenovaných dobrot mohou dokonce způsobovat rakovinu. Ale nejhorší je to, že pokaždé, když je pijete, tak do sebe dostáváte neuvěřitelná kvanta cukru. Cukr &#8211; ne tuk, ne červené maso, ne olej na smažení, ale bílý snížek ze Satanovy lyžařské chaty &#8211; je absolutně jasný viník, který může za to, že jako chlap máte větší prsa než většina žen. Limonáda je nejpopulárnější a nejrozšířenější mechanismus, jak do sebe nacpat hromady cukru. Pokud je zcela přestanete pít, tak ztratíte hromadu kil bez toho, aniž bych museli cokoliv dalšího dělat.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A myslete také na to, že džusy a podobné nápoje nejsou o moc lepší. Většinou sice obsahují vitamíny (i když všechny uměle přidané), díky kterým jsou o trošku lepší než limonády, ale jsou pořád naprosto plné cukru. A co je horší, hromada &#8220;džusů&#8221; se sotva dá nazývat džusy &#8211; většina džusů prodávaných v obchodě jsou šťávy, které jsou po vymačkání vyvařené a zkondenzované tak, že se z nich stane neidentifikovatelná šedá tekutina. Ta je až do času plnění držena v chladu. Poté se do ní před plněním přidá cukr, vitamíny, barviva a umělá ochucovadla (to si pište, že jo), aby džus vypadal jako &#8220;<em>čerstvě vymačkaný</em>.&#8221; Je to (alespoň podle mě) obrovský podvod a když už víte, co to obnáší, tak víte, proč byste se jim měli vyhýbat.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Ale co když máte nějakou divnou orální fixaci a absolutně bezpodmínečně potřebujete něco neustále pít? No, pokud vám nestačí normální obyčejná voda (čemuž naprosto rozumíme), tak navrhujeme čaj bez cukru nebo nějaké minerální vody bez cukru. Váš mozek nepozná rozdíl a alespoň nebudete do svého těla cpát tolik sraček.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A pokud ani tohle není možné, tak alespoň pijte vaše oblíbené limonády v jejich verzích bez cukru. Božínku.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="2) Vyměňte to, co do sebe bezmyšlenkovitě cpete" />

                    <PageParagraph content={
                        <Fragment>
                            Jste tlustí, protože jíte sračky a jíte jich opravdu hodně. Nemáte záhadnou genetickou poruchu, která by způsobovala nekontrolovatelné nafukování těla až do dosáhnutí 130 kg a to jenom tím, že si občas uždíbnete kousíček tuřínu nebo rýžových chlebíčků (pokud se tedy vaše chromozomy neskládají z brambůrek a pokud ano, tak byste měli být v nemocnici a ne číst tento článek). Chybí vám sebekontrola a používáte brambůrky a podobné dobroty k tomu, aby vám pomohly s vašimi nepředvídatelnými náladami. Výsledkem toho všeho je, že jste závislí na soli, sacharidech a cukru.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Většina lidí, kteří se pokouší něco shodit, by totiž po nějakém čase přehodnotila, jaké místo tato &#8220;jídla&#8221; představují v jejich životech a poté by jejich konzumaci drasticky omezili nebo s nimi kompletně přestali. Ale vy ne! Jednou jste snědli celý karton ruských zmrzlin hned poté, co jste přišli z obchodu (&#8220;<em>Dám si jenom dvě&#8230;</em>&#8221; Ne, nedáte, prasata).
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Je zcela evidentní, že nejste zrovna disciplinovaný typ. Neustálé žraní všeho možného, zatímco jste 16 hodin denně na internetu je to jediné, díky čemu vůbec přežijete den a neumíte si bez toho ani představit, jak byste to všechno zvládali. Ale nebojte! S naším návodem budete schopní si nejen neustále něco cpát do pusy, ALE TAKÉ zároveň něco zhubnout.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Jak? Jednoduše tak, že tyto pokrmy nahradíte něčím, co pro vaše tělo není tak hrůzostrašné. Místo toho, abyste do sebe cpali brambůrky, zkuste pro změnu zkusit nějaké ovoce nebo zeleninu. Díky tomu nebudete muset dělat žádnou těžkou práci nutnou ke změně vašich hluboce zakořeněných zvyků.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Co to slyším? Že máte rádi brambůrky? Přemýšlejte: Jak moc si ty brambůrky vychutnáváte? Opravdu jejich chuti věnujete tolik pozornosti a nebo je do sebe prostě jenom rvete a přitom se soustředíte na úplně něco jiného? Aha, já si to myslel.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Dobře, dobře, nikdo nemá rád celer nebo mrkev. Chutnají jako chcanky staré báby smíchané s vodou z kaluže. Ale co třeba tohle: dětský hrášek, hrozny, pomeranče, třešně, sušené banánové lupínky nebo hummus s bezlepkovými sušenkami? To byste zvládnout mohli.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="3) Trošku více myslete na to, co si mažete na jídlo" />

                    <PageParagraph content={
                        <Fragment>
                            Prakticky to samé jako výše. Nemusíte nutně měnit množství toho, co jíte, ale typ toho, co jíte. Pokud milujete arašídové máslo, zkuste najít nějaké bez cukru nebo jezte to neumělé, do kterého se žádný cukr nepřidává.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Už vidíte něco, co se tu furt dokola opakuje? Cukr je nepřítel. CUKR JE NEPŘÍTEL!
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Společnosti naštěstí začínají slyšet na to, že lidé nyní chtějí i věci bez cukru a tak si v dnešní době můžete koupit magické arašídové máslo bez cukru a podobné dobroty.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="4) Trošku ztmavte vaši ranní kávu" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Další drobnost, díky které můžete zredukovat <em>Bílého Ďábla</em>. Váš ranní šálek kávy je pravděpodobně ten nezdravější zvyk, který máte &#8211; je to ještě lepší zvyk, než nedýchat při žvýkání jídla. Káva totiž není jenom stimulant. Dokonce se zjistilo, že je bohatá na antioxidanty a co je ještě lepší, stimuluje lidské tělo tak, aby spalovalo tuky.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            &#8230; Pokud tedy samozřejmě není plná cukrů, sirupů a umělých smetan do kávy. Jediné &#8220;vysoké&#8221; oříškové lattéčko ze Starbucks má 190 kalorií (skoro 10% toho, co má průměrný dospělý muž za den zkonzumovat) a 26 gramů cukru (to je více, než Fanta). A teď se sami sebe zeptejte &#8211; opravdu si myslíte, že ta káva, kterou si ráno děláte doma a naplníte ji lžičkami (ano, množné číslo) cukru a hromadou umělé smetany, pro vás může být nějakým způsobem dobrá?
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Je čas na to, nechat si vyrůst trošku chlupů na vašich koulích a naučit se, jak pít kafe bez cukru a dalších přísad jako pořádnej chlap. A jsme zase u toho &#8211; neměníte prakticky nic z vašich každodenních rutin nebo návyků. Pokud rádi pijete kafe, tak ho pijte dále. Ale nebuďte ohledně toho hloupí.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="5) Schodocvičení" />

                    <PageParagraph content={
                        <Fragment>
                            Už jste slyšeli milionkrát, že máte chodit po schodech místo používání výtahu. Dobře, fajn. ALE &#8211; vy jste líné prase a schody pro vás představují bolest, protože nemáte rádi, když nemůžete popadnout dech a topíte se ve vlastním potu v 8 hodin ráno.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Tak zkuste udělat kompromis: Pokud do místa, kam právě jdete, je to tři poschodí nebo méně, tak běžte po schodech; pokud to je více než tři poschodí, tak běžte po schodech první dvě nebo tři poschodí a zbytek si dojeďte výtahem. Díky tomu nejen, že můžete využít tuto dlouhou cestu jako skvělou výmluvu k tomu, proč zrovna neděláte to, co dělat máte, ale zároveň neděláte nic moc extra.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Možná vám tenhle nápad připadá směšný. Ale když se nad tím zamyslíte, tak možná jste také TLUSTÉ PRASE. Šach mat, tlouštíku.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="6) 15 minut chůze za den" />

                    <PageParagraph content={
                        <Fragment>
                            Víme, že představa cvičení je pro vás příjemná asi jako používání staré opotřebované umělé vagíny vašeho strýce jako skleničky na pití (jedna rada &#8211; je důvod, proč teď sedíte na židli a čtete šestý článek o hubnutí místo toho, abyste právě venku hráli fotbal nebo něco podobného), ale přemýšlejte nad tím&#8230; je to jenom chození. Žádné rychlé tempo. Žádné stupidní speciální boty, které si musíte koupit. Žádné směšné speciální oblečení, do kterého se musíte obléct. Žádné běhání. Žádná posilovna.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            &#8230; Jenom troška chůze po obědě, po večeři nebo po tom, co zapadne slunce. Dělejte to každý den stejně jako všechno ostatní v tomto návodu. Budete se cítit lépe. Zhubnete. A přitom budete sotva dělat něco navíc.
                        </Fragment>
                    } />
                    
                    <PageBlockHeaderTwo header="7) Pokud pracujete v kanceláři nebo trávíte hodně času u počítače, začněte s nějakým &#8220;nervózním zvykem&#8221;" />

                    <PageParagraph content={
                        <Fragment>
                            Na co si tu hrajeme? Vy <em>sedíte</em> v kanceláři. A poté, když přijdete domů, tak trávíte další hodiny koukáním na zvrácené věci na internetu (a pravděpodobně u toho pijete limonádu a cpete se brambůrkami, viz výše).
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Abychom se ale příliš nevzdalovali od původního duchu tohoto článku a říkali vám třeba, abyste se protáhli nebo zkusili nějaký jinačí koníček (a sakra, my víme, že chtít po vás, ať chodíte 15 minut každý den znamená, že toho chceme už ale kurva hodně), takže proč ne, seďte na své tlusté prdeli na židli. Vaše tělo vám za váš přístup a šokující bezohlednost k daru života, který jste dostali, nakonec poděkuje.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            A zatímco nic neděláte, zkuste dělat alespoň něco, co spálí trošku extra energie; &#8220;nervózní zvyk,&#8221; který alespoň některou vaši část těla (i kdyby to byly jenom prsty) drží v pohybu. Antistresové míčky a podobné hračky jsou skvělé. Nebo použijte svou volnou ruku&#8230;
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            K&#8230;
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            &#8230; Vy chlapi pravděpodobně víte, co dělat s volnou rukou při prohlížení internetu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Nebo se staňte chronickým klepačem nohy. Můj známý pořád klepe nohou, když sedí. A zachvíli z toho zešílím. Ale spaluje to kalorie. Dokonce si představte, že až 350 kalorií, pokud to děláte celý den. Takže směle do toho!
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Velký tlustý konec" />

                    <PageParagraph content={
                        <Fragment>
                            Rozhodně nepočítejte s tím, že se díky těmto změnám stanete vymakanou korbou. ALE, pokud se budete řídit všemi radami výše, tak postupně snížíte celkový procentuální podíl vašeho tělesného tuku, budete mít atraktivnější obličej a to ani nezmiňujeme lepší zdraví a kvalitnější spánek. Nebudete vypadat jako pornohvězda, ale BUDETE vypadat a cítit se mnohem lépe, než předtím. A jako bonus budete pravděpodobně pociťovat i nějaký pocit vlastního úspěchu, který by vás nakonec mohl motivovat i k tomu, dělat toho více a nebýt celkově takový líný zmrd.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Závěr je tedy následující: Jednoduchým důsledkem toho, že budete žrát méně sraček a spálíte zbytek sraček, které do sebe i přesto dostanete, bude redukce vaší váhy i bez toho, abyste prakticky dělali JAKÉKOLIV velké změny. Jenom trošku upravíte věci, které už dávno děláte tak, že si toho váš mozek ani nevšimne a díky tomu vás nedonutí to všechno zkurvit.
                        </Fragment>
                    } />
                    
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default NavodNaZhubnuti;