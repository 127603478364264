import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import MovieComponent from '../../components/PageComponents/MovieComponent'
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageTableHeader from "../../components/PageComponents/PageTable/page-table-header";
import PageWrapper from "../../components/PageWrapper";

const Movies = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: '1001 movies you must see before you die'}}/>

            <PageContent content={
                <Fragment>

                    <PageBlockHeaderTwo header="One-of-a-kind challenge" />

                    <PageParagraph content={
                        <Fragment>
                            Do you like a lifelong challenge? This is a list of 1001 movies which you should see before you die.
                        </Fragment>
                    } />

                    <PageBlockQuote quote={<Fragment>Cinema is the most beautiful fraud in the world.</Fragment>} author="Jean-Luc Godard" />

                    <PageBlockHeaderThree header="Official 1001 list:"/>
                    
                    <MovieComponent moviePosition="1" isSeen={true} movieName="Le Voyage dans la lune (1902)" csfdLink="http://www.csfd.cz/film/47384-trip-to-the-moon-a-voyage-dans-la-lune-le/" imdbLink="https://www.imdb.com/title/tt0000417/"/>
                    <MovieComponent moviePosition="2" isSeen={true} movieName="The Great Train Robbery (1903)" csfdLink="http://www.csfd.cz/film/167098-great-train-robbery-the/" imdbLink="https://www.imdb.com/title/tt0000439/"/>
                    <MovieComponent moviePosition="3" isSeen={true} movieName="The Birth of a Nation (1915)" csfdLink="http://www.csfd.cz/film/3439-birth-of-a-nation-the/" imdbLink="https://www.imdb.com/title/tt0004972/"/>
                    <MovieComponent moviePosition="4" isSeen={false} movieName="Les Vampires (1915)" csfdLink="http://www.csfd.cz/film/189916-vampires-les/" imdbLink="https://www.imdb.com/title/tt0006206/"/>
                    <MovieComponent moviePosition="5" isSeen={true} movieName="Intolerance (1916)" csfdLink="http://www.csfd.cz/film/3640-intolerance/" imdbLink="https://www.imdb.com/title/tt0006864/"/>
                    <MovieComponent moviePosition="6" isSeen={true} movieName="Das Kabinett des Doktor Caligari (1919)" csfdLink="http://www.csfd.cz/film/9756-kabinett-des-doktor-caligari-das/" imdbLink="https://www.imdb.com/title/tt0010323/"/>
                    <MovieComponent moviePosition="7" isSeen={false} movieName="Broken Blossoms (1919)" csfdLink="http://www.csfd.cz/film/3447-broken-blossoms/" imdbLink="https://www.imdb.com/title/tt0009968/"/>
                    <MovieComponent moviePosition="8" isSeen={false} movieName="Way Down East (1920)" csfdLink="http://www.csfd.cz/film/3920-way-down-east/" imdbLink="https://www.imdb.com/title/tt0011841/"/>
                    <MovieComponent moviePosition="9" isSeen={true} movieName="Within Our Gates (1920)" csfdLink="http://www.csfd.cz/film/105600-within-our-gates/" imdbLink="https://www.imdb.com/title/tt0011870/"/>
                    <MovieComponent moviePosition="10" isSeen={true} movieName="Körkarlen (1921)" csfdLink="http://www.csfd.cz/film/107584-vozka-smrti/" imdbLink="https://www.imdb.com/title/tt0012364/"/>
                    <MovieComponent moviePosition="11" isSeen={false} movieName="Orphans of the Storm (1921)" csfdLink="http://www.csfd.cz/film/3757-orphans-of-the-storm/" imdbLink=""/>
                    <MovieComponent moviePosition="12" isSeen={false} movieName="La Souriante Madame Beudet (1922)" csfdLink="http://www.csfd.cz/film/110760-souriante-madame-beudet-la/" imdbLink=""/>
                    <MovieComponent moviePosition="13" isSeen={false} movieName="Dr. Mabuse, der Spieler (1922) Parts 1 and 2" csfdLink="http://www.csfd.cz/film/5598-dr-mabuse-der-spieler/" imdbLink=""/>
                    <MovieComponent moviePosition="14" isSeen={true} movieName="Nanook of the North (1922)" csfdLink="http://www.csfd.cz/film/81655-nanook-of-the-north/" imdbLink=""/>
                    <MovieComponent moviePosition="15" isSeen={true} movieName="Nosferatu, eine Symphonie des Grauens (1922)" csfdLink="http://www.csfd.cz/film/6860-upir-nosferatu-nosferatu-eine-symphonie-des-grauens/" imdbLink=""/>
                    <MovieComponent moviePosition="16" isSeen={true} movieName="Häxan (1923)" csfdLink="http://www.csfd.cz/film/157247-carodejnictvi-v-prubehu-veku-haxan/" imdbLink=""/>
                    <MovieComponent moviePosition="17" isSeen={false} movieName="Foolish Wives (1922)" csfdLink="http://www.csfd.cz/film/9377-foolish-wives/" imdbLink=""/>
                    <MovieComponent moviePosition="18" isSeen={true} movieName="Our Hospitality (1923)" csfdLink="http://www.csfd.cz/film/5122-our-hospitality/" imdbLink=""/>
                    <MovieComponent moviePosition="19" isSeen={false} movieName="The Wheel (1923)" csfdLink="http://www.csfd.cz/film/226746-wheel-the-roue-la/" imdbLink=""/>
                    <MovieComponent moviePosition="20" isSeen={false} movieName="The Thief of Bagdad (1924)" csfdLink="http://www.csfd.cz/film/24029-thief-of-bagdad-the/" imdbLink=""/>
                    <MovieComponent moviePosition="21" isSeen={true} movieName="Stachka (1924)" csfdLink="http://www.csfd.cz/film/32384-stachka/" imdbLink=""/>
                    <MovieComponent moviePosition="22" isSeen={false} movieName="Greed (1924)" csfdLink="http://www.csfd.cz/film/9378-greed/" imdbLink=""/>
                    <MovieComponent moviePosition="23" isSeen={true} movieName="Sherlock, Jr. (1924)" csfdLink="http://www.csfd.cz/film/5127-sherlock-jr/" imdbLink=""/>
                    <MovieComponent moviePosition="24" isSeen={false} movieName="Der Letzte Mann (1924)" csfdLink="http://www.csfd.cz/film/6858-letzte-mann-der/" imdbLink=""/>
                    <MovieComponent moviePosition="25" isSeen={true} movieName="Seven Chances (1925)" csfdLink="http://www.csfd.cz/film/5126-seven-chances/" imdbLink=""/>
                    <MovieComponent moviePosition="26" isSeen={true} movieName="The Phantom of the Opera (1925)" csfdLink="http://www.csfd.cz/film/47765-phantom-of-the-opera-the/" imdbLink=""/>
                    <MovieComponent moviePosition="27" isSeen={true} movieName="Bronenosec Poťomkin (1925)" csfdLink="http://www.csfd.cz/film/32365-kriznik-potemkin-bronenosec-potomkin/" imdbLink=""/>
                    <MovieComponent moviePosition="28" isSeen={true} movieName="The Gold Rush (1925)" csfdLink="http://www.csfd.cz/film/1471-gold-rush-the/" imdbLink=""/>
                    <MovieComponent moviePosition="29" isSeen={true} movieName="The Big Parade (1925)" csfdLink="http://www.csfd.cz/film/47489-big-parade-the/" imdbLink=""/>
                    <MovieComponent moviePosition="30" isSeen={true} movieName="Metropolis (1927)" csfdLink="http://www.csfd.cz/film/5612-metropolis/" imdbLink=""/>
                    <MovieComponent moviePosition="31" isSeen={true} movieName="Sunrise (1927)" csfdLink="http://www.csfd.cz/film/6865-sunrise-a-song-of-two-humans/" imdbLink=""/>
                    <MovieComponent moviePosition="32" isSeen={true} movieName="The General (1927)" csfdLink="http://www.csfd.cz/film/5104-frigo-na-masine-general-the/" imdbLink=""/>
                    <MovieComponent moviePosition="33" isSeen={true} movieName="The Unknown (1927)" csfdLink="http://www.csfd.cz/film/1000-unknown-the/gallery/" imdbLink=""/>
                    <MovieComponent moviePosition="34" isSeen={true} movieName="October (1927)" csfdLink="http://www.csfd.cz/film/32380-deset-dni-ktere-otrasly-svetem/" imdbLink=""/>
                    <MovieComponent moviePosition="35" isSeen={false} movieName="The Jazz Singer (1927)" csfdLink="http://www.csfd.cz/film/24705-jazz-singer-the/" imdbLink=""/>
                    <MovieComponent moviePosition="36" isSeen={false} movieName="Napoléon (1927)" csfdLink="http://www.csfd.cz/film/102458-napoleon-napoleon/" imdbLink=""/>
                    <MovieComponent moviePosition="37" isSeen={false} movieName="The Kid Brother (1927)" csfdLink="http://www.csfd.cz/film/25180-kid-brother-the/" imdbLink=""/>
                    <MovieComponent moviePosition="38" isSeen={false} movieName="The Crowd (1928)" csfdLink="http://www.csfd.cz/film/47493-crowd-the/" imdbLink=""/>
                    <MovieComponent moviePosition="39" isSeen={false} movieName="The Docks of New York (1928)" csfdLink="http://www.csfd.cz/film/71914-docks-of-new-york-the/" imdbLink=""/>
                    <MovieComponent moviePosition="40" isSeen={true} movieName="Un chien andalou (1928)" csfdLink="http://www.csfd.cz/film/1112-andalusian-dog-an-un-chien-andalou/" imdbLink=""/>
                    <MovieComponent moviePosition="41" isSeen={true} movieName="La Passion de Jeanne d&#8217;Arc (1928)" csfdLink="http://www.csfd.cz/film/100287-passion-de-jeanne-darc-la/" imdbLink=""/>
                    <MovieComponent moviePosition="42" isSeen={true} movieName="Steamboat Bill, Jr. (1928)" csfdLink="http://www.csfd.cz/film/24804-steamboat-bill-jr/" imdbLink=""/>
                    <MovieComponent moviePosition="43" isSeen={true} movieName="Potomok Chingis-Khana (1928)" csfdLink="http://www.csfd.cz/film/37429-potomok-chingiskhana/" imdbLink=""/>
                    <MovieComponent moviePosition="44" isSeen={false} movieName="Blackmail (1929)" csfdLink="http://www.csfd.cz/film/4353-blackmail/" imdbLink=""/>
                    <MovieComponent moviePosition="45" isSeen={true} movieName="Chelovek s kinoapparatom (1929)" csfdLink="http://www.csfd.cz/film/99416-chelovek-s-kinoapparatom/" imdbLink=""/>
                    <MovieComponent moviePosition="46" isSeen={false} movieName="Die Büchse der Pandora (1929)" csfdLink="http://www.csfd.cz/film/27415-buchse-der-pandora-die/" imdbLink=""/>
                    <MovieComponent moviePosition="47" isSeen={false} movieName="Der Blaue Engel (1930)" csfdLink="http://www.csfd.cz/film/27623-blaue-engel-der/" imdbLink=""/>
                    <MovieComponent moviePosition="48" isSeen={true} movieName="L&#8217;Âge d&#8217;or (1930)" csfdLink="http://www.csfd.cz/film/1117-zlaty-vek-ge-dor-l/" imdbLink=""/>
                    <MovieComponent moviePosition="49" isSeen={true} movieName="Zemlja (1930)" csfdLink="http://www.csfd.cz/film/158442-zeme-zemlja/" imdbLink=""/>
                    <MovieComponent moviePosition="50" isSeen={true} movieName="Little Caesar (1930)" csfdLink="http://www.csfd.cz/film/26766-little-caesar/" imdbLink=""/>
                    <MovieComponent moviePosition="51" isSeen={true} movieName="All Quiet on the Western Front (1930)" csfdLink="http://www.csfd.cz/film/77143-all-quiet-on-the-western-front/" imdbLink=""/>
                    <MovieComponent moviePosition="52" isSeen={false} movieName="A nous la liberté (1931)" csfdLink="http://www.csfd.cz/film/147970-r-nous-la-liberte/" imdbLink=""/>
                    <MovieComponent moviePosition="53" isSeen={false} movieName="Le Million (1931)" csfdLink="http://www.csfd.cz/film/141198-million-le/" imdbLink=""/>
                    <MovieComponent moviePosition="54" isSeen={true} movieName="Tabu (1931)" csfdLink="http://www.csfd.cz/film/6866-tabu/" imdbLink=""/>
                    <MovieComponent moviePosition="55" isSeen={true} movieName="Dracula (1931)" csfdLink="http://www.csfd.cz/film/959-dracula/" imdbLink=""/>
                    <MovieComponent moviePosition="56" isSeen={true} movieName="Frankenstein (1931)" csfdLink="http://www.csfd.cz/film/9728-frankenstein/" imdbLink=""/>
                    <MovieComponent moviePosition="57" isSeen={true} movieName="City Lights (1931)" csfdLink="http://www.csfd.cz/film/1461-city-lights/" imdbLink=""/>
                    <MovieComponent moviePosition="58" isSeen={true} movieName="The Public Enemy (1931)" csfdLink="http://www.csfd.cz/film/9689-public-enemy-the/" imdbLink=""/>
                    <MovieComponent moviePosition="59" isSeen={true} movieName="M (1931)" csfdLink="http://www.csfd.cz/film/5610-m-/" imdbLink=""/>
                    <MovieComponent moviePosition="60" isSeen={false} movieName="La Chienne (1931)" csfdLink="http://www.csfd.cz/film/7643-chienne-la/" imdbLink=""/>
                    <MovieComponent moviePosition="61" isSeen={true} movieName="Vampyr &#8211; Der Traum des Allan Grey (1932)" csfdLink="http://www.csfd.cz/film/102472-vampyr-der-traum-des-allan-grey/" imdbLink=""/>
                    <MovieComponent moviePosition="62" isSeen={false} movieName="Love Me Tonight (1932)" csfdLink="http://www.csfd.cz/film/28319-love-me-tonight/" imdbLink=""/>
                    <MovieComponent moviePosition="63" isSeen={false} movieName="Boudu sauvé des eaux (1932)" csfdLink="http://www.csfd.cz/film/7639-boudu-sauve-des-eaux/" imdbLink=""/>
                    <MovieComponent moviePosition="64" isSeen={true} movieName="I Am a Fugitive from a Chain Gang (1932)" csfdLink="http://www.csfd.cz/film/25955-i-am-a-fugitive-from-a-chain-gang/" imdbLink=""/>
                    <MovieComponent moviePosition="65" isSeen={false} movieName="Trouble in Paradise (1932)" csfdLink="http://www.csfd.cz/film/6153-trouble-in-paradise/" imdbLink=""/>
                    <MovieComponent moviePosition="66" isSeen={true} movieName="Scarface (1932)" csfdLink="http://www.csfd.cz/film/4205-scarface/" imdbLink=""/>
                    <MovieComponent moviePosition="67" isSeen={false} movieName="Shanghai Express (1932)" csfdLink="http://www.csfd.cz/film/27653-shanghai-express/" imdbLink=""/>
                    <MovieComponent moviePosition="68" isSeen={true} movieName="Freaks (1932)" csfdLink="http://www.csfd.cz/film/967-freaks/" imdbLink=""/>
                    <MovieComponent moviePosition="69" isSeen={false} movieName="Me and My Gal (1932)" csfdLink="http://www.csfd.cz/film/27057-me-and-my-gal/" imdbLink=""/>
                    <MovieComponent moviePosition="70" isSeen={true} movieName="Zéro de conduite (1933)" csfdLink="http://www.csfd.cz/film/131062-zero-for-conduct-zero-de-conduite/" imdbLink=""/>
                    <MovieComponent moviePosition="71" isSeen={false} movieName="42nd Street (1933)" csfdLink="http://www.csfd.cz/film/26286-42nd-street/" imdbLink=""/>
                    <MovieComponent moviePosition="72" isSeen={false} movieName="Footlight Parade (1933)" csfdLink="http://www.csfd.cz/film/23499-footlight-parade/" imdbLink=""/>
                    <MovieComponent moviePosition="73" isSeen={false} movieName="Gold Diggers of 1933 (1933)" csfdLink="http://www.csfd.cz/film/26298-gold-diggers-of-1933/" imdbLink=""/>
                    <MovieComponent moviePosition="74" isSeen={false} movieName="She Done Him Wrong (1933)" csfdLink="http://www.csfd.cz/film/24422-she-done-him-wrong/" imdbLink=""/>
                    <MovieComponent moviePosition="75" isSeen={true} movieName="Duck Soup (1933)" csfdLink="http://www.csfd.cz/film/37217-duck-soup/" imdbLink=""/>
                    <MovieComponent moviePosition="76" isSeen={false} movieName="Queen Christina (1933)" csfdLink="http://www.csfd.cz/film/27829-queen-christina/" imdbLink=""/>
                    <MovieComponent moviePosition="77" isSeen={true} movieName="Las Hurdes (1933)" csfdLink="http://www.csfd.cz/film/42924-hurdes-las/" imdbLink=""/>
                    <MovieComponent moviePosition="78" isSeen={true} movieName="King Kong (1933)" csfdLink="http://www.csfd.cz/film/8135-king-kong/" imdbLink=""/>
                    <MovieComponent moviePosition="79" isSeen={false} movieName="The Bitter Tea of General Yen (1933)" csfdLink="http://www.csfd.cz/film/1265-bitter-tea-of-general-yen-the/" imdbLink=""/>
                    <MovieComponent moviePosition="80" isSeen={true} movieName="Sons of the Desert (1933)" csfdLink="http://www.csfd.cz/film/74510-sons-of-the-desert/" imdbLink=""/>
                    <MovieComponent moviePosition="81" isSeen={false} movieName="It&#8217;s a Gift (1934)" csfdLink="http://www.csfd.cz/film/24111-its-a-gift/" imdbLink=""/>
                    <MovieComponent moviePosition="82" isSeen={true} movieName="Triumph des Willens (1934)" csfdLink="http://www.csfd.cz/film/99752-triumph-des-willens/" imdbLink=""/>
                    <MovieComponent moviePosition="83" isSeen={false} movieName="L&#8217;Atalante (1934)" csfdLink="http://www.csfd.cz/film/131060-atalante-l/" imdbLink=""/>
                    <MovieComponent moviePosition="84" isSeen={true} movieName="The Black Cat (1934)" csfdLink="http://www.csfd.cz/film/9216-black-cat-the/" imdbLink=""/>
                    <MovieComponent moviePosition="85" isSeen={false} movieName="Judge Priest (1934)" csfdLink="http://www.csfd.cz/film/2890-judge-priest/" imdbLink=""/>
                    <MovieComponent moviePosition="86" isSeen={true} movieName="It Happened One Night (1934)" csfdLink="http://www.csfd.cz/film/1278-it-happened-one-night/" imdbLink=""/>
                    <MovieComponent moviePosition="87" isSeen={false} movieName="The Thin Man (1934)" csfdLink="http://www.csfd.cz/film/26398-thin-man-the/" imdbLink=""/>
                    <MovieComponent moviePosition="88" isSeen={true} movieName="Captain Blood (1935)" csfdLink="http://www.csfd.cz/film/1910-captain-blood/" imdbLink=""/>
                    <MovieComponent moviePosition="89" isSeen={false} movieName="Mutiny on the Bounty (1935)" csfdLink="http://www.csfd.cz/film/24335-mutiny-on-the-bounty/" imdbLink=""/>
                    <MovieComponent moviePosition="90" isSeen={true} movieName="A Night at the Opera (1935)" csfdLink="http://www.csfd.cz/film/42721-night-at-the-opera-a/" imdbLink=""/>
                    <MovieComponent moviePosition="91" isSeen={true} movieName="The 39 Steps (1935)" csfdLink="http://www.csfd.cz/film/4350-39-steps-the/" imdbLink=""/>
                    <MovieComponent moviePosition="92" isSeen={true} movieName="Bride of Frankenstein (1935)" csfdLink="http://www.csfd.cz/film/9726-bride-of-frankenstein/" imdbLink=""/>
                    <MovieComponent moviePosition="93" isSeen={false} movieName="Top Hat (1935)" csfdLink="http://www.csfd.cz/film/23046-top-hat/" imdbLink=""/>
                    <MovieComponent moviePosition="94" isSeen={true} movieName="Une partie de campagne (1936)" csfdLink="http://www.csfd.cz/film/7673-une-partie-de-campagne/" imdbLink=""/>
                    <MovieComponent moviePosition="95" isSeen={true} movieName="Modern Times (1936)" csfdLink="http://www.csfd.cz/film/1488-modern-times/" imdbLink=""/>
                    <MovieComponent moviePosition="96" isSeen={false} movieName="Swing Time (1936)" csfdLink="http://www.csfd.cz/film/8738-swing-time/" imdbLink=""/>
                    <MovieComponent moviePosition="97" isSeen={false} movieName="My Man Godfrey (1936)" csfdLink="http://www.csfd.cz/film/26378-my-man-godfrey/" imdbLink=""/>
                    <MovieComponent moviePosition="98" isSeen={false} movieName="Mr. Deeds Goes to Town (1936)" csfdLink="http://www.csfd.cz/film/1288-mr-deeds-goes-to-town/" imdbLink=""/>
                    <MovieComponent moviePosition="99" isSeen={false} movieName="Camille (1936)" csfdLink="http://www.csfd.cz/film/1815-camille/" imdbLink=""/>
                    <MovieComponent moviePosition="100" isSeen={false} movieName="Sabotage (1936)" csfdLink="http://www.csfd.cz/film/4391-sabotage/" imdbLink=""/>
                    <MovieComponent moviePosition="101" isSeen={false} movieName="Dodsworth (1936)" csfdLink="http://www.csfd.cz/film/9891-dodsworth/" imdbLink=""/>
                    <MovieComponent moviePosition="102" isSeen={true} movieName="Things to Come (1936)" csfdLink="http://www.csfd.cz/film/26716-things-to-come/" imdbLink=""/>
                    <MovieComponent moviePosition="103" isSeen={false} movieName="Le Roman d&#8217;un tricheur (1936)" csfdLink="http://www.csfd.cz/film/229437-roman-dun-tricheur-le/" imdbLink=""/>
                    <MovieComponent moviePosition="104" isSeen={true} movieName="Captains Courageous (1937)" csfdLink="http://www.csfd.cz/film/2786-captains-courageous/" imdbLink=""/>
                    <MovieComponent moviePosition="105" isSeen={false} movieName="Ye ban ge sheng (1937)" csfdLink="http://www.csfd.cz/film/229456-ye-ban-ge-sheng/" imdbLink=""/>
                    <MovieComponent moviePosition="106" isSeen={true} movieName="La Grande illusion (1937)" csfdLink="http://www.csfd.cz/film/7652-grande-illusion-la/" imdbLink=""/>
                    <MovieComponent moviePosition="107" isSeen={false} movieName="Stella Dallas (1937)" csfdLink="http://www.csfd.cz/film/28890-stella-dallas/" imdbLink=""/>
                    <MovieComponent moviePosition="108" isSeen={false} movieName="The Life of Emile Zola (1937)" csfdLink="http://www.csfd.cz/film/25958-life-of-emile-zola-the/" imdbLink=""/>
                    <MovieComponent moviePosition="109" isSeen={false} movieName="Make Way for Tomorrow (1937)" csfdLink="http://www.csfd.cz/film/37239-make-way-for-tomorrow/" imdbLink=""/>
                    <MovieComponent moviePosition="110" isSeen={false} movieName="Snow White and the Seven Dwarfs (1937)" csfdLink="http://www.csfd.cz/film/83776-snow-white-and-the-seven-dwarfs/" imdbLink=""/>
                    <MovieComponent moviePosition="111" isSeen={true} movieName="The Awful Truth (1937)" csfdLink="http://www.csfd.cz/film/24394-awful-truth-the/" imdbLink=""/>
                    <MovieComponent moviePosition="112" isSeen={false} movieName="Pepe Le Moko (1937)" csfdLink="http://www.csfd.cz/film/36576-pepe-le-moko/" imdbLink=""/>
                    <MovieComponent moviePosition="113" isSeen={false} movieName="Jezebel (1938)" csfdLink="http://www.csfd.cz/film/9911-jezebel/" imdbLink=""/>
                    <MovieComponent moviePosition="114" isSeen={false} movieName="The Adventures of Robin Hood (1938)" csfdLink="http://www.csfd.cz/film/1892-adventures-of-robin-hood-the/" imdbLink=""/>
                    <MovieComponent moviePosition="115" isSeen={true} movieName="Angels with Dirty Faces (1938)" csfdLink="http://www.csfd.cz/film/1896-angels-with-dirty-faces/" imdbLink=""/>
                    <MovieComponent moviePosition="116" isSeen={true} movieName="Olympia (1938)" csfdLink="http://www.csfd.cz/film/101174-olympia-1-teil-fest-der-volker/" imdbLink=""/>
                    <MovieComponent moviePosition="117" isSeen={false} movieName="La Femme du boulanger (1938)" csfdLink="http://www.csfd.cz/film/201931-femme-du-boulanger-la/" imdbLink=""/>
                    <MovieComponent moviePosition="118" isSeen={true} movieName="Bringing Up Baby (1938)" csfdLink="http://www.csfd.cz/film/4177-bringing-up-baby/" imdbLink=""/>
                    <MovieComponent moviePosition="119" isSeen={false} movieName="Stagecoach (1939)" csfdLink="http://www.csfd.cz/film/2937-stagecoach/" imdbLink=""/>
                    <MovieComponent moviePosition="120" isSeen={false} movieName="The Story of the Late Chrysanthemums (1939)" csfdLink="http://www.csfd.cz/film/127899-zangiku-monogatari/" imdbLink=""/>
                    <MovieComponent moviePosition="121" isSeen={false} movieName="Babes in Arms (1939)" csfdLink="http://www.csfd.cz/film/26806-babes-in-arms/" imdbLink=""/>
                    <MovieComponent moviePosition="122" isSeen={true} movieName="Mr. Smith Goes to Washington (1939)" csfdLink="http://www.csfd.cz/film/1289-mr-smith-goes-to-washington/" imdbLink=""/>
                    <MovieComponent moviePosition="123" isSeen={false} movieName="The Wizard of Oz (1939)" csfdLink="http://www.csfd.cz/film/2818-wizard-of-oz-the/" imdbLink=""/>
                    <MovieComponent moviePosition="124" isSeen={true} movieName="Destry Rides Again (1939)" csfdLink="http://www.csfd.cz/film/16226-destry-rides-again/" imdbLink=""/>
                    <MovieComponent moviePosition="125" isSeen={false} movieName="Only Angels Have Wings (1939)" csfdLink="http://www.csfd.cz/film/4197-only-angels-have-wings/" imdbLink=""/>
                    <MovieComponent moviePosition="126" isSeen={true} movieName="Gone With the Wind (1939)" csfdLink="http://www.csfd.cz/film/2794-gone-with-the-wind/" imdbLink=""/>
                    <MovieComponent moviePosition="127" isSeen={false} movieName="Le Jour se léve (1939)" csfdLink="http://www.csfd.cz/film/36556-den-zacina-jour-se-lcve-le/" imdbLink=""/>
                    <MovieComponent moviePosition="128" isSeen={false} movieName="Gunga Din (1939)" csfdLink="http://www.csfd.cz/film/8713-gunga-din/" imdbLink=""/>
                    <MovieComponent moviePosition="129" isSeen={true} movieName="Ninotchka (1939)" csfdLink="http://www.csfd.cz/film/6130-ninotchka/" imdbLink=""/>
                    <MovieComponent moviePosition="130" isSeen={false} movieName="La Regle du jeu (1939)" csfdLink="http://www.csfd.cz/film/7662-pravidla-hry-regle-du-jeu-la/" imdbLink=""/>
                    <MovieComponent moviePosition="131" isSeen={false} movieName="Wuthering Heights (1939)" csfdLink="http://www.csfd.cz/film/9940-wuthering-heights/" imdbLink=""/>
                    <MovieComponent moviePosition="132" isSeen={true} movieName="His Girl Friday (1940)" csfdLink="http://www.csfd.cz/film/4191-his-girl-friday/" imdbLink=""/>
                    <MovieComponent moviePosition="133" isSeen={true} movieName="Rebecca (1940)" csfdLink="http://www.csfd.cz/film/4387-rebecca/" imdbLink=""/>
                    <MovieComponent moviePosition="134" isSeen={false} movieName="Fantasia (1940)" csfdLink="http://www.csfd.cz/film/577-fantasia/" imdbLink=""/>
                    <MovieComponent moviePosition="135" isSeen={true} movieName="The Philadelphia Story (1940)" csfdLink="http://www.csfd.cz/film/1842-philadelphia-story-the/" imdbLink=""/>
                    <MovieComponent moviePosition="136" isSeen={true} movieName="The Grapes of Wrath (1940)" csfdLink="http://www.csfd.cz/film/2875-grapes-of-wrath-the/" imdbLink=""/>
                    <MovieComponent moviePosition="137" isSeen={false} movieName="Dance, Girl, Dance (1940)" csfdLink="http://www.csfd.cz/film/28468-dance-girl-dance/" imdbLink=""/>
                    <MovieComponent moviePosition="138" isSeen={false} movieName="Pinocchio (1940)" csfdLink="http://www.csfd.cz/film/34136-pinocchio/" imdbLink=""/>
                    <MovieComponent moviePosition="139" isSeen={true} movieName="The Mortal Storm (1940)" csfdLink="http://www.csfd.cz/film/16240-mortal-storm-the/" imdbLink=""/>
                    <MovieComponent moviePosition="140" isSeen={false} movieName="The Bank Dick (1940)" csfdLink="http://www.csfd.cz/film/24101-bank-dick-the/" imdbLink=""/>
                    <MovieComponent moviePosition="141" isSeen={true} movieName="Citizen Kane (1941)" csfdLink="http://www.csfd.cz/film/9611-obcan-kane-citizen-kane/" imdbLink=""/>
                    <MovieComponent moviePosition="142" isSeen={false} movieName="The Lady Eve (1941)" csfdLink="http://www.csfd.cz/film/24216-lady-eve-the/" imdbLink=""/>
                    <MovieComponent moviePosition="143" isSeen={true} movieName="The Wolf Man (1941)" csfdLink="http://www.csfd.cz/film/14473-wolf-man-the/" imdbLink=""/>
                    <MovieComponent moviePosition="144" isSeen={true} movieName="The Maltese Falcon (1941)" csfdLink="http://www.csfd.cz/film/4601-maltese-falcon-the/" imdbLink=""/>
                    <MovieComponent moviePosition="145" isSeen={true} movieName="Sergeant York (1941)" csfdLink="http://www.csfd.cz/film/4206-sergeant-york/" imdbLink=""/>
                    <MovieComponent moviePosition="146" isSeen={false} movieName="Dumbo (1941)" csfdLink="http://www.csfd.cz/film/34122-dumbo/" imdbLink=""/>
                    <MovieComponent moviePosition="147" isSeen={true} movieName="High Sierra (1941)" csfdLink="http://www.csfd.cz/film/23344-high-sierra/" imdbLink=""/>
                    <MovieComponent moviePosition="148" isSeen={true} movieName="Sullivan&#8217;s Travels (1941)" csfdLink="http://www.csfd.cz/film/25726-sullivans-travels/" imdbLink=""/>
                    <MovieComponent moviePosition="149" isSeen={true} movieName="How Green Was My Valley (1941)" csfdLink="http://www.csfd.cz/film/2884-how-green-was-my-valley/" imdbLink=""/>
                    <MovieComponent moviePosition="150" isSeen={false} movieName="The Palm Beach Story (1942)" csfdLink="http://www.csfd.cz/film/25714-palm-beach-story-the/" imdbLink=""/>
                    <MovieComponent moviePosition="151" isSeen={false} movieName="Now, Voyager (1942)" csfdLink="http://www.csfd.cz/film/27570-now-voyager/" imdbLink=""/>
                    <MovieComponent moviePosition="152" isSeen={true} movieName="Casablanca (1942)" csfdLink="http://www.csfd.cz/film/1912-casablanca/" imdbLink=""/>
                    <MovieComponent moviePosition="153" isSeen={true} movieName="To Be or Not to Be (1942)" csfdLink="http://www.csfd.cz/film/6152-to-be-or-not-to-be/" imdbLink=""/>
                    <MovieComponent moviePosition="154" isSeen={true} movieName="Cat People (1942)" csfdLink="http://www.csfd.cz/film/9009-cat-people/" imdbLink=""/>
                    <MovieComponent moviePosition="155" isSeen={true} movieName="The Magnificent Ambersons (1942)" csfdLink="http://www.csfd.cz/film/9623-magnificent-ambersons-the/" imdbLink=""/>
                    <MovieComponent moviePosition="156" isSeen={false} movieName="Yankee Doodle Dandy (1942)" csfdLink="http://www.csfd.cz/film/2048-yankee-doodle-dandy/" imdbLink=""/>
                    <MovieComponent moviePosition="157" isSeen={true} movieName="Meshes of the Afternoon (1943)" csfdLink="http://www.csfd.cz/film/136854-meshes-of-the-afternoon/" imdbLink=""/>
                    <MovieComponent moviePosition="158" isSeen={true} movieName="Fires Were Started (1943)" csfdLink="http://www.csfd.cz/film/229466-fires-were-started-i-was-a-fireman/" imdbLink=""/>
                    <MovieComponent moviePosition="159" isSeen={false} movieName="The Man in Grey (1943)" csfdLink="http://www.csfd.cz/film/25509-man-in-grey-the/" imdbLink=""/>
                    <MovieComponent moviePosition="160" isSeen={false} movieName="The Life and Death of Colonel Blimp (1943)" csfdLink="http://www.csfd.cz/film/7429-life-and-death-of-colonel-blimp-the/" imdbLink=""/>
                    <MovieComponent moviePosition="161" isSeen={true} movieName="I Walked with a Zombie (1943)" csfdLink="http://www.csfd.cz/film/9026-i-walked-with-a-zombie/" imdbLink=""/>
                    <MovieComponent moviePosition="162" isSeen={true} movieName="The Seventh Victim (1943)" csfdLink="http://www.csfd.cz/film/86956-seventh-victim-the/" imdbLink=""/>
                    <MovieComponent moviePosition="163" isSeen={true} movieName="The Ox-Bow Incident (1943)" csfdLink="http://www.csfd.cz/film/9687-oxbow-incident-the/" imdbLink=""/>
                    <MovieComponent moviePosition="164" isSeen={true} movieName="Shadow of a Doubt (1943)" csfdLink="http://www.csfd.cz/film/4394-shadow-of-a-doubt/" imdbLink=""/>
                    <MovieComponent moviePosition="165" isSeen={false} movieName="Ossessione (1943)" csfdLink="http://www.csfd.cz/film/9323-ossessione/" imdbLink=""/>
                    <MovieComponent moviePosition="166" isSeen={false} movieName="Meet Me in St. Louis (1944)" csfdLink="http://www.csfd.cz/film/6768-meet-me-in-st-louis/" imdbLink=""/>
                    <MovieComponent moviePosition="167" isSeen={false} movieName="To Have and Have Not (1944)" csfdLink="http://www.csfd.cz/film/4209-to-have-and-have-not/" imdbLink=""/>
                    <MovieComponent moviePosition="168" isSeen={true} movieName="Laura (1944)" csfdLink="http://www.csfd.cz/film/7500-laura/" imdbLink=""/>
                    <MovieComponent moviePosition="169" isSeen={true} movieName="Gaslight (1944)" csfdLink="http://www.csfd.cz/film/1821-gaslight/" imdbLink=""/>
                    <MovieComponent moviePosition="170" isSeen={false} movieName="Henry V (1944)" csfdLink="http://www.csfd.cz/film/71216-jindrich-v-chronicle-history-or-king-henry-the-fift-with-his-battell-fought-at-agincourt-in-france-the/" imdbLink=""/>
                    <MovieComponent moviePosition="171" isSeen={false} movieName="Ivan Groznyj I (1944)" csfdLink="http://www.csfd.cz/film/32373-ivan-the-terrible-part-one-ivan-groznyj-i/" imdbLink=""/>
                    <MovieComponent moviePosition="172" isSeen={true} movieName="Double Indemnity (1944)" csfdLink="http://www.csfd.cz/film/9776-double-indemnity/" imdbLink=""/>
                    <MovieComponent moviePosition="173" isSeen={true} movieName="Murder, My Sweet (1944)" csfdLink="http://www.csfd.cz/film/26313-murder-my-sweet/" imdbLink=""/>
                    <MovieComponent moviePosition="174" isSeen={true} movieName="San Pietro (1945)" csfdLink="http://www.csfd.cz/film/116590-san-pietro/" imdbLink=""/>
                    <MovieComponent moviePosition="175" isSeen={true} movieName="Spellbound (1945)" csfdLink="http://www.csfd.cz/film/4397-spellbound/" imdbLink=""/>
                    <MovieComponent moviePosition="176" isSeen={false} movieName="Mildred Pierce (1945)" csfdLink="http://www.csfd.cz/film/1991-mildred-pierce/" imdbLink=""/>
                    <MovieComponent moviePosition="177" isSeen={false} movieName="Children of Paradise (1945)" csfdLink="http://www.csfd.cz/film/36894-enfants-du-paradis-les/" imdbLink=""/>
                    <MovieComponent moviePosition="178" isSeen={true} movieName="Roma, citta aperta (1945)" csfdLink="http://www.csfd.cz/film/43398-rim-otevrene-mesto-roma-cittr-aperta/" imdbLink=""/>
                    <MovieComponent moviePosition="179" isSeen={true} movieName="The Lost Weekend (1945)" csfdLink="http://www.csfd.cz/film/9785-lost-weekend-the/" imdbLink=""/>
                    <MovieComponent moviePosition="180" isSeen={true} movieName="Detour (1945)" csfdLink="http://www.csfd.cz/film/9224-detour/" imdbLink=""/>
                    <MovieComponent moviePosition="181" isSeen={false} movieName="I Know Where I&#8217;m Going! (1945)" csfdLink="http://www.csfd.cz/film/7426-i-know-where-im-going/" imdbLink=""/>
                    <MovieComponent moviePosition="182" isSeen={true} movieName="The Best Years of Our Lives (1946)" csfdLink="http://www.csfd.cz/film/9877-best-years-of-our-lives-the/" imdbLink=""/>
                    <MovieComponent moviePosition="183" isSeen={false} movieName="Brief Encounter (1946)" csfdLink="http://www.csfd.cz/film/5697-brief-encounter/" imdbLink=""/>
                    <MovieComponent moviePosition="184" isSeen={true} movieName="Paisa (1946)" csfdLink="http://www.csfd.cz/film/7877-paisa/" imdbLink=""/>
                    <MovieComponent moviePosition="185" isSeen={false} movieName="The Postman Always Rings Twice (1946)" csfdLink="http://www.csfd.cz/film/24376-postman-always-rings-twice-the/" imdbLink=""/>
                    <MovieComponent moviePosition="186" isSeen={false} movieName="My Darling Clementine (1946)" csfdLink="http://www.csfd.cz/film/2908-my-darling-clementine/" imdbLink=""/>
                    <MovieComponent moviePosition="187" isSeen={false} movieName="The Stranger (1946)" csfdLink="http://www.csfd.cz/film/9633-stranger-the/" imdbLink=""/>
                    <MovieComponent moviePosition="188" isSeen={false} movieName="La Belle et la bete (1946)" csfdLink="http://www.csfd.cz/film/35103-beauty-and-the-beast-belle-et-la-bte-la/" imdbLink=""/>
                    <MovieComponent moviePosition="189" isSeen={true} movieName="The Big Sleep (1946)" csfdLink="http://www.csfd.cz/film/4176-big-sleep-the/" imdbLink=""/>
                    <MovieComponent moviePosition="190" isSeen={false} movieName="The Killers (1946)" csfdLink="http://www.csfd.cz/film/24968-zabijaci-killers-the/" imdbLink=""/>
                    <MovieComponent moviePosition="191" isSeen={true} movieName="A Matter of Life and Death (1946)" csfdLink="http://www.csfd.cz/film/7434-matter-of-life-and-death-a/" imdbLink=""/>
                    <MovieComponent moviePosition="192" isSeen={false} movieName="Great Expectations (1946)" csfdLink="http://www.csfd.cz/film/5699-great-expectations/" imdbLink=""/>
                    <MovieComponent moviePosition="193" isSeen={true} movieName="Notorious (1946)" csfdLink="http://www.csfd.cz/film/4380-notorious/" imdbLink=""/>
                    <MovieComponent moviePosition="194" isSeen={false} movieName="Black Narcissus (1946)" csfdLink="http://www.csfd.cz/film/7408-black-narcissus/" imdbLink=""/>
                    <MovieComponent moviePosition="195" isSeen={true} movieName="It&#8217;s a Wonderful Life (1946)" csfdLink="http://www.csfd.cz/film/1279-its-a-wonderful-life/" imdbLink=""/>
                    <MovieComponent moviePosition="196" isSeen={false} movieName="Gilda (1946)" csfdLink="http://www.csfd.cz/film/28048-gilda/" imdbLink=""/>
                    <MovieComponent moviePosition="197" isSeen={true} movieName="Monsieur Verdoux (1947)" csfdLink="http://www.csfd.cz/film/1489-monsieur-verdoux/" imdbLink=""/>
                    <MovieComponent moviePosition="198" isSeen={true} movieName="Out of the Past (1947)" csfdLink="http://www.csfd.cz/film/9038-out-of-the-past/" imdbLink=""/>
                    <MovieComponent moviePosition="199" isSeen={false} movieName="The Ghost and Mrs. Muir (1947)" csfdLink="http://www.csfd.cz/film/6390-ghost-and-mrs-muir-the/" imdbLink=""/>
                    <MovieComponent moviePosition="200" isSeen={true} movieName="Odd Man Out (1947)" csfdLink="http://www.csfd.cz/film/25513-odd-man-out/" imdbLink=""/>
                    <MovieComponent moviePosition="201" isSeen={false} movieName="Ladri di biciclette (1948)" csfdLink="http://www.csfd.cz/film/33701-ladri-di-biciclette/" imdbLink=""/>
                    <MovieComponent moviePosition="202" isSeen={false} movieName="Letter from an Unknown Woman (1948)" csfdLink="http://www.csfd.cz/film/27799-letter-from-an-unknown-woman/" imdbLink=""/>
                    <MovieComponent moviePosition="203" isSeen={false} movieName="Secret Beyond the Door (1948)" csfdLink="http://www.csfd.cz/film/5622-secret-beyond-the-door/" imdbLink=""/>
                    <MovieComponent moviePosition="204" isSeen={false} movieName="Force of Evil (1948)" csfdLink="http://www.csfd.cz/film/24371-force-of-evil/" imdbLink=""/>
                    <MovieComponent moviePosition="205" isSeen={false} movieName="Xiao cheng zhi chun (1948)" csfdLink="http://www.csfd.cz/film/229525-spring-in-a-small-town-xiao-cheng-zhi-chun/" imdbLink=""/>
                    <MovieComponent moviePosition="206" isSeen={false} movieName="Red River (1948)" csfdLink="http://www.csfd.cz/film/4200-red-river/" imdbLink=""/>
                    <MovieComponent moviePosition="207" isSeen={true} movieName="Rope (1948)" csfdLink="http://www.csfd.cz/film/4390-rope/" imdbLink=""/>
                    <MovieComponent moviePosition="208" isSeen={false} movieName="The Snake Pit (1948)" csfdLink="http://www.csfd.cz/film/27619-snake-pit-the/" imdbLink=""/>
                    <MovieComponent moviePosition="209" isSeen={false} movieName="The Lady from Shanghai (1948)" csfdLink="http://www.csfd.cz/film/9620-lady-from-shanghai-the/" imdbLink=""/>
                    <MovieComponent moviePosition="210" isSeen={false} movieName="Paleface (1948)" csfdLink="http://www.csfd.cz/film/24598-paleface-the/" imdbLink=""/>
                    <MovieComponent moviePosition="211" isSeen={false} movieName="The Red Shoes (1948)" csfdLink="http://www.csfd.cz/film/7445-red-shoes-the/" imdbLink=""/>
                    <MovieComponent moviePosition="212" isSeen={true} movieName="The Treasure of the Sierra Madre (1948)" csfdLink="http://www.csfd.cz/film/4613-treasure-of-the-sierra-madre-the/" imdbLink=""/>
                    <MovieComponent moviePosition="213" isSeen={true} movieName="Louisiana Story (1948)" csfdLink="http://www.csfd.cz/film/81652-louisiana-story/" imdbLink=""/>
                    <MovieComponent moviePosition="214" isSeen={false} movieName="The Heiress (1949)" csfdLink="http://www.csfd.cz/film/9904-heiress-the/" imdbLink=""/>
                    <MovieComponent moviePosition="215" isSeen={false} movieName="Kind Hearts and Coronets (1949)" csfdLink="http://www.csfd.cz/film/19773-kind-hearts-and-coronets/" imdbLink=""/>
                    <MovieComponent moviePosition="216" isSeen={true} movieName="Deadly Is the Female (1949)" csfdLink="http://www.csfd.cz/film/80167-gun-crazy-deadly-is-the-female/" imdbLink=""/>
                    <MovieComponent moviePosition="217" isSeen={true} movieName="Adam&#8217;s Rib (1949)" csfdLink="http://www.csfd.cz/film/1810-adams-rib/" imdbLink=""/>
                    <MovieComponent moviePosition="218" isSeen={true} movieName="Whiskey Galore! (1949)" csfdLink="http://www.csfd.cz/film/85349-whisky-galore/" imdbLink=""/>
                    <MovieComponent moviePosition="219" isSeen={true} movieName="White Heat (1949)" csfdLink="http://www.csfd.cz/film/23531-white-heat/" imdbLink=""/>
                    <MovieComponent moviePosition="220" isSeen={false} movieName="The Reckless Moment (1949)" csfdLink="http://www.csfd.cz/film/25522-reckless-moment-the/" imdbLink=""/>
                    <MovieComponent moviePosition="221" isSeen={true} movieName="The Third Man (1949)" csfdLink="http://www.csfd.cz/film/11627-third-man-the/" imdbLink=""/>
                    <MovieComponent moviePosition="222" isSeen={false} movieName="On the Town (1949)" csfdLink="http://www.csfd.cz/film/2422-ve-meste-on-the-town/" imdbLink=""/>
                    <MovieComponent moviePosition="223" isSeen={true} movieName="Orpheus (1949)" csfdLink="http://www.csfd.cz/film/35108-orpheus-orphee/" imdbLink=""/>
                    <MovieComponent moviePosition="224" isSeen={true} movieName="The Asphalt Jungle (1950)" csfdLink="http://www.csfd.cz/film/4584-asphalt-jungle-the/" imdbLink=""/>
                    <MovieComponent moviePosition="225" isSeen={true} movieName="Rashômon (1950)" csfdLink="http://www.csfd.cz/film/5434-rasomon-rashomon/" imdbLink=""/>
                    <MovieComponent moviePosition="226" isSeen={true} movieName="Winchester &#8217;73 (1950)" csfdLink="http://www.csfd.cz/film/6442-winchester-73/" imdbLink=""/>
                    <MovieComponent moviePosition="227" isSeen={false} movieName="Rio Grande (1950)" csfdLink="http://www.csfd.cz/film/2921-rio-grande/" imdbLink=""/>
                    <MovieComponent moviePosition="228" isSeen={true} movieName="All About Eve (1950)" csfdLink="http://www.csfd.cz/film/6384-all-about-eve/" imdbLink=""/>
                    <MovieComponent moviePosition="229" isSeen={true} movieName="Sunset Blvd. (1950)" csfdLink="http://www.csfd.cz/film/9796-sunset-blvd/" imdbLink=""/>
                    <MovieComponent moviePosition="230" isSeen={true} movieName="Los Olvidados (1950)" csfdLink="http://www.csfd.cz/film/1106-olvidados-los/" imdbLink=""/>
                    <MovieComponent moviePosition="231" isSeen={true} movieName="In a Lonely Place (1950)" csfdLink="http://www.csfd.cz/film/23346-in-a-lonely-place/" imdbLink=""/>
                    <MovieComponent moviePosition="232" isSeen={true} movieName="Ace in the Hole (1951)" csfdLink="http://www.csfd.cz/film/9774-ace-in-the-hole/" imdbLink=""/>
                    <MovieComponent moviePosition="233" isSeen={true} movieName="A Streetcar Named Desire (1951)" csfdLink="http://www.csfd.cz/film/5088-streetcar-named-desire-a/" imdbLink=""/>
                    <MovieComponent moviePosition="234" isSeen={true} movieName="Strangers on a Train (1951)" csfdLink="http://www.csfd.cz/film/4399-strangers-on-a-train/" imdbLink=""/>
                    <MovieComponent moviePosition="235" isSeen={false} movieName="The Lavender Hill Mob (1951)" csfdLink="http://www.csfd.cz/film/19776-lavender-hill-mob-the/" imdbLink=""/>
                    <MovieComponent moviePosition="236" isSeen={false} movieName="Pandora and the Flying Dutchman (1951)" csfdLink="http://www.csfd.cz/film/25514-pandora-and-the-flying-dutchman/" imdbLink=""/>
                    <MovieComponent moviePosition="237" isSeen={true} movieName="The African Queen (1951)" csfdLink="http://www.csfd.cz/film/4582-african-queen-the/" imdbLink=""/>
                    <MovieComponent moviePosition="238" isSeen={true} movieName="Journal d&#8217;un curé de campagne (1951)" csfdLink="http://www.csfd.cz/film/35294-diary-of-a-country-priest-journal-dun-cure-de-campagne/" imdbLink=""/>
                    <MovieComponent moviePosition="239" isSeen={false} movieName="An American in Paris (1951)" csfdLink="http://www.csfd.cz/film/6746-american-in-paris-an/" imdbLink=""/>
                    <MovieComponent moviePosition="240" isSeen={true} movieName="A Place in the Sun (1951)" csfdLink="http://www.csfd.cz/film/8730-place-in-the-sun-a/" imdbLink=""/>
                    <MovieComponent moviePosition="241" isSeen={true} movieName="The Day the Earth Stood Still (1951)" csfdLink="http://www.csfd.cz/film/9813-day-the-earth-stood-still-the/" imdbLink=""/>
                    <MovieComponent moviePosition="242" isSeen={false} movieName="The Quiet Man (1952)" csfdLink="http://www.csfd.cz/film/2917-quiet-man-the/" imdbLink=""/>
                    <MovieComponent moviePosition="243" isSeen={true} movieName="Jeux interdits (1952)" csfdLink="http://www.csfd.cz/film/124627-jeux-interdits/" imdbLink=""/>
                    <MovieComponent moviePosition="244" isSeen={false} movieName="Angel Face (1952)" csfdLink="http://www.csfd.cz/film/7481-angel-face/" imdbLink=""/>
                    <MovieComponent moviePosition="245" isSeen={true} movieName="Singin&#8217; in the Rain (1952)" csfdLink="http://www.csfd.cz/film/2428-singin-in-the-rain/" imdbLink=""/>
                    <MovieComponent moviePosition="246" isSeen={true} movieName="Ikiru (1952)" csfdLink="http://www.csfd.cz/film/5427-ikiru/" imdbLink=""/>
                    <MovieComponent moviePosition="247" isSeen={false} movieName="Europa &#8217;51 (1952)" csfdLink="http://www.csfd.cz/film/7865-europa-51/" imdbLink=""/>
                    <MovieComponent moviePosition="248" isSeen={false} movieName="The Bad and the Beautiful (1952)" csfdLink="http://www.csfd.cz/film/6747-bad-and-the-beautiful-the/" imdbLink=""/>
                    <MovieComponent moviePosition="249" isSeen={false} movieName="The Big Sky (1952)" csfdLink="http://www.csfd.cz/film/4175-big-sky-the/" imdbLink=""/>
                    <MovieComponent moviePosition="250" isSeen={true} movieName="High Noon (1952)" csfdLink="http://www.csfd.cz/film/10157-high-noon/" imdbLink=""/>
                    <MovieComponent moviePosition="251" isSeen={true} movieName="Umberto D. (1952)" csfdLink="http://www.csfd.cz/film/42985-umberto-d/" imdbLink=""/>
                    <MovieComponent moviePosition="252" isSeen={false} movieName="Le Carrosse d&#8217;or (1952)" csfdLink="http://www.csfd.cz/film/7642-carrosse-dor-le/" imdbLink=""/>
                    <MovieComponent moviePosition="253" isSeen={false} movieName="The Bigamist (1953)" csfdLink="http://www.csfd.cz/film/6228-bigamist-the/" imdbLink=""/>
                    <MovieComponent moviePosition="254" isSeen={false} movieName="The Band Wagon (1953)" csfdLink="http://www.csfd.cz/film/6748-band-wagon-the/" imdbLink=""/>
                    <MovieComponent moviePosition="255" isSeen={false} movieName="Madame De&#8230; (1953)" csfdLink="http://www.csfd.cz/film/23406-madame-de/" imdbLink=""/>
                    <MovieComponent moviePosition="256" isSeen={true} movieName="From Here to Eternity (1953)" csfdLink="http://www.csfd.cz/film/10153-from-here-to-eternity/" imdbLink=""/>
                    <MovieComponent moviePosition="257" isSeen={true} movieName="Tokyo monogatari (1953)" csfdLink="http://www.csfd.cz/film/124940-tokyo-monogatari/" imdbLink=""/>
                    <MovieComponent moviePosition="258" isSeen={true} movieName="Roman Holiday (1953)" csfdLink="http://www.csfd.cz/film/9926-roman-holiday/" imdbLink=""/>
                    <MovieComponent moviePosition="259" isSeen={true} movieName="Le Salaire de la peur (1953)" csfdLink="http://www.csfd.cz/film/38791-wages-of-fear-the-salaire-de-la-peur-le/" imdbLink=""/>
                    <MovieComponent moviePosition="260" isSeen={false} movieName="The Naked Spur (1953)" csfdLink="http://www.csfd.cz/film/6426-naked-spur-the/" imdbLink=""/>
                    <MovieComponent moviePosition="261" isSeen={false} movieName="Pickup on South Street (1953)" csfdLink="http://www.csfd.cz/film/27128-pickup-on-south-street/" imdbLink=""/>
                    <MovieComponent moviePosition="262" isSeen={false} movieName="Gentlemen Prefer Blondes (1953)" csfdLink="http://www.csfd.cz/film/4188-gentlemen-prefer-blondes/" imdbLink=""/>
                    <MovieComponent moviePosition="263" isSeen={true} movieName="The Big Heat (1953)" csfdLink="http://www.csfd.cz/film/5594-big-heat-the/" imdbLink=""/>
                    <MovieComponent moviePosition="264" isSeen={false} movieName="Les Vacances de Monsieur Hulot (1953)" csfdLink="http://www.csfd.cz/film/44995-mr-hulots-holiday-vacances-de-monsieur-hulot-les/" imdbLink=""/>
                    <MovieComponent moviePosition="265" isSeen={false} movieName="Viaggio in Italia (1953)" csfdLink="http://www.csfd.cz/film/7892-viaggio-in-italia/" imdbLink=""/>
                    <MovieComponent moviePosition="266" isSeen={true} movieName="Ugetsu monogatari (1953)" csfdLink="http://www.csfd.cz/film/126860-ugetsu-monogatari/" imdbLink=""/>
                    <MovieComponent moviePosition="267" isSeen={false} movieName="Shane (1953)" csfdLink="http://www.csfd.cz/film/8735-shane/" imdbLink=""/>
                    <MovieComponent moviePosition="268" isSeen={false} movieName="Beat the Devil (1953)" csfdLink="http://www.csfd.cz/film/4587-beat-the-devil/" imdbLink=""/>
                    <MovieComponent moviePosition="269" isSeen={false} movieName="Johnny Guitar (1954)" csfdLink="http://www.csfd.cz/film/27510-johnny-guitar/" imdbLink=""/>
                    <MovieComponent moviePosition="270" isSeen={false} movieName="On the Waterfront (1954)" csfdLink="http://www.csfd.cz/film/5082-on-the-waterfront/" imdbLink=""/>
                    <MovieComponent moviePosition="271" isSeen={false} movieName="Seven Brides for Seven Brothers (1954)" csfdLink="http://www.csfd.cz/film/2427-seven-brides-for-seven-brothers/" imdbLink=""/>
                    <MovieComponent moviePosition="272" isSeen={true} movieName="Les Diaboliques (1954)" csfdLink="http://www.csfd.cz/film/69705-diaboliques-les/" imdbLink=""/>
                    <MovieComponent moviePosition="273" isSeen={true} movieName="Animal Farm (1954)" csfdLink="http://www.csfd.cz/film/94113-animal-farm/" imdbLink=""/>
                    <MovieComponent moviePosition="274" isSeen={true} movieName="Rear Window (1954)" csfdLink="http://www.csfd.cz/film/4386-rear-window/" imdbLink=""/>
                    <MovieComponent moviePosition="275" isSeen={false} movieName="A Star Is Born (1954)" csfdLink="http://www.csfd.cz/film/1850-star-is-born-a/" imdbLink=""/>
                    <MovieComponent moviePosition="276" isSeen={false} movieName="The Barefoot Contessa (1954)" csfdLink="http://www.csfd.cz/film/6385-barefoot-contessa-the/" imdbLink=""/>
                    <MovieComponent moviePosition="277" isSeen={true} movieName="La Strada (1954)" csfdLink="http://www.csfd.cz/film/2615-strada-la/" imdbLink=""/>
                    <MovieComponent moviePosition="278" isSeen={true} movieName="Shichinin no samurai (1954)" csfdLink="http://www.csfd.cz/film/5435-seven-samurai-shichinin-no-samurai/" imdbLink=""/>
                    <MovieComponent moviePosition="279" isSeen={false} movieName="Senso (1954)" csfdLink="http://www.csfd.cz/film/9325-senso/" imdbLink=""/>
                    <MovieComponent moviePosition="280" isSeen={false} movieName="Silver Lode (1954)" csfdLink="http://www.csfd.cz/film/80781-silver-lode/" imdbLink=""/>
                    <MovieComponent moviePosition="281" isSeen={false} movieName="Carmen Jones (1954)" csfdLink="http://www.csfd.cz/film/7485-carmen-jones/" imdbLink=""/>
                    <MovieComponent moviePosition="282" isSeen={false} movieName="Sansho dayu (1954)" csfdLink="http://www.csfd.cz/film/123701-sansho-dayu/" imdbLink=""/>
                    <MovieComponent moviePosition="283" isSeen={true} movieName="Salt of the Earth (1954)" csfdLink="http://www.csfd.cz/film/139327-salt-of-the-earth/" imdbLink=""/>
                    <MovieComponent moviePosition="284" isSeen={false} movieName="Artists and Models (1955)" csfdLink="http://www.csfd.cz/film/22490-artists-and-models/" imdbLink=""/>
                    <MovieComponent moviePosition="285" isSeen={false} movieName="Guys and Dolls (1955)" csfdLink="http://www.csfd.cz/film/6391-guys-and-dolls/" imdbLink=""/>
                    <MovieComponent moviePosition="286" isSeen={true} movieName="Pather Panchali (1955)" csfdLink="http://www.csfd.cz/film/130930-pather-panchali/" imdbLink=""/>
                    <MovieComponent moviePosition="287" isSeen={true} movieName="Bad Day at Black Rock (1955)" csfdLink="http://www.csfd.cz/film/25459-bad-day-at-black-rock/" imdbLink=""/>
                    <MovieComponent moviePosition="288" isSeen={true} movieName="The Mad Masters (1955)" csfdLink="http://www.csfd.cz/film/195804-matres-fous-les/" imdbLink=""/>
                    <MovieComponent moviePosition="289" isSeen={false} movieName="Giv&#8217;a 24 Eina Ona (1955)" csfdLink="http://www.csfd.cz/film/129181-giva-24-eina-ona/" imdbLink=""/>
                    <MovieComponent moviePosition="290" isSeen={true} movieName="The Ladykillers (1955)" csfdLink="http://www.csfd.cz/film/19774-ladykillers-the/" imdbLink=""/>
                    <MovieComponent moviePosition="291" isSeen={false} movieName="Marty (1955)" csfdLink="http://www.csfd.cz/film/39800-marty/" imdbLink=""/>
                    <MovieComponent moviePosition="292" isSeen={true} movieName="Ordet (1955)" csfdLink="http://www.csfd.cz/film/89641-ordet/" imdbLink=""/>
                    <MovieComponent moviePosition="293" isSeen={false} movieName="Bob Le Flambeur (1955)" csfdLink="http://www.csfd.cz/film/145629-bob-le-flambeur/" imdbLink=""/>
                    <MovieComponent moviePosition="294" isSeen={true} movieName="Kiss Me Deadly (1955)" csfdLink="http://www.csfd.cz/film/34385-kiss-me-deadly/" imdbLink=""/>
                    <MovieComponent moviePosition="295" isSeen={false} movieName="The Man from Laramie (1955)" csfdLink="http://www.csfd.cz/film/6421-man-from-laramie-the/" imdbLink=""/>
                    <MovieComponent moviePosition="296" isSeen={true} movieName="Rebel without a Cause (1955)" csfdLink="http://www.csfd.cz/film/13420-rebel-bez-priciny-rebel-without-a-cause/" imdbLink=""/>
                    <MovieComponent moviePosition="297" isSeen={false} movieName="The Phenix City Story (1955)" csfdLink="http://www.csfd.cz/film/166031-phenix-city-story-the/" imdbLink=""/>
                    <MovieComponent moviePosition="298" isSeen={true} movieName="Sommarnattens leende (1955)" csfdLink="http://www.csfd.cz/film/729-smiles-of-a-summer-night-sommarnattens-leende/" imdbLink=""/>
                    <MovieComponent moviePosition="299" isSeen={true} movieName="Nuit et brouillard (1955)" csfdLink="http://www.csfd.cz/film/71406-night-and-fog-nuit-et-brouillard/" imdbLink=""/>
                    <MovieComponent moviePosition="300" isSeen={true} movieName="The Night of the Hunter (1955)" csfdLink="http://www.csfd.cz/film/25907-night-of-the-hunter-the/" imdbLink=""/>
                    <MovieComponent moviePosition="301" isSeen={false} movieName="Lola Montes (1955)" csfdLink="http://www.csfd.cz/film/86558-lola-montcs/" imdbLink=""/>
                    <MovieComponent moviePosition="302" isSeen={true} movieName="Forbidden Planet (1956)" csfdLink="http://www.csfd.cz/film/25994-forbidden-planet/" imdbLink=""/>
                    <MovieComponent moviePosition="303" isSeen={true} movieName="Biruma no tategoto (1956)" csfdLink="http://www.csfd.cz/film/130469-the-burmese-harp-biruma-no-tategoto/" imdbLink=""/>
                    <MovieComponent moviePosition="304" isSeen={false} movieName="The Searchers (1956)" csfdLink="http://www.csfd.cz/film/2928-searchers-the/" imdbLink=""/>
                    <MovieComponent moviePosition="305" isSeen={true} movieName="Un condamné a mort s&#8217;est echappe ou Le vent souffle ou il veut (1956)" csfdLink="http://www.csfd.cz/film/35300-man-escaped-a-un-condamne-r-mort-sest-echappe-ou-le-vent-souffle-ou-il-veut/" imdbLink=""/>
                    <MovieComponent moviePosition="306" isSeen={false} movieName="Written on the Wind (1956)" csfdLink="http://www.csfd.cz/film/8460-written-on-the-wind/" imdbLink=""/>
                    <MovieComponent moviePosition="307" isSeen={false} movieName="The Man Who Knew Too Much (1956)" csfdLink="http://www.csfd.cz/film/4372-man-who-knew-too-much-the/" imdbLink=""/>
                    <MovieComponent moviePosition="308" isSeen={false} movieName="Giant (1956)" csfdLink="http://www.csfd.cz/film/8710-giant/" imdbLink=""/>
                    <MovieComponent moviePosition="309" isSeen={false} movieName="All That Heaven Allows (1956)" csfdLink="http://www.csfd.cz/film/8422-all-that-heaven-allows/" imdbLink=""/>
                    <MovieComponent moviePosition="310" isSeen={true} movieName="Invasion of the Body Snatchers (1956)" csfdLink="http://www.csfd.cz/film/8387-invasion-of-the-body-snatchers/" imdbLink=""/>
                    <MovieComponent moviePosition="311" isSeen={false} movieName="The Wrong Man (1956)" csfdLink="http://www.csfd.cz/film/4408-wrong-man-the/" imdbLink=""/>
                    <MovieComponent moviePosition="312" isSeen={true} movieName="Bigger Than Life (1956)" csfdLink="http://www.csfd.cz/film/20439-bigger-than-life/" imdbLink=""/>
                    <MovieComponent moviePosition="313" isSeen={false} movieName="High Society (1956)" csfdLink="http://www.csfd.cz/film/23734-high-society/" imdbLink=""/>
                    <MovieComponent moviePosition="314" isSeen={true} movieName="The Ten Commandments (1956)" csfdLink="http://www.csfd.cz/film/18875-ten-commandments-the/" imdbLink=""/>
                    <MovieComponent moviePosition="315" isSeen={true} movieName="12 Angry Men (1957)" csfdLink="http://www.csfd.cz/film/6178-12-angry-men/" imdbLink=""/>
                    <MovieComponent moviePosition="316" isSeen={true} movieName="Det Sjunde inseglet (1957)" csfdLink="http://www.csfd.cz/film/723-seventh-seal-the-sjunde-inseglet-det/" imdbLink=""/>
                    <MovieComponent moviePosition="317" isSeen={true} movieName="An Affair to Remember (1957)" csfdLink="http://www.csfd.cz/film/24392-affair-to-remember-an/" imdbLink=""/>
                    <MovieComponent moviePosition="318" isSeen={true} movieName="Smultronstället (1957)" csfdLink="http://www.csfd.cz/film/726-wild-strawberries-smultronstallet/" imdbLink=""/>
                    <MovieComponent moviePosition="319" isSeen={false} movieName="Le Notti di Cabiria (1957)" csfdLink="http://www.csfd.cz/film/2610-notti-di-cabiria-le/" imdbLink=""/>
                    <MovieComponent moviePosition="320" isSeen={true} movieName="Kumonosu jo (1957)" csfdLink="http://www.csfd.cz/film/5430-throne-of-blood-kumonosu-jo/" imdbLink=""/>
                    <MovieComponent moviePosition="321" isSeen={true} movieName="The Incredible Shrinking Man (1957)" csfdLink="http://www.csfd.cz/film/326-incredible-shrinking-man-the/" imdbLink=""/>
                    <MovieComponent moviePosition="322" isSeen={false} movieName="Aparajito (1957)" csfdLink="http://www.csfd.cz/film/130909-aparajito/" imdbLink=""/>
                    <MovieComponent moviePosition="323" isSeen={false} movieName="Gunfight at the O.K. Corral (1957)" csfdLink="http://www.csfd.cz/film/13392-gunfight-at-the-ok-corral/" imdbLink=""/>
                    <MovieComponent moviePosition="324" isSeen={true} movieName="The Bridge on the River Kwai (1957)" csfdLink="http://www.csfd.cz/film/5696-bridge-on-the-river-kwai-the/" imdbLink=""/>
                    <MovieComponent moviePosition="325" isSeen={false} movieName="Mother India (1957)" csfdLink="http://www.csfd.cz/film/177919-mother-india/" imdbLink=""/>
                    <MovieComponent moviePosition="326" isSeen={true} movieName="Letjať žuravli (1957)" csfdLink="http://www.csfd.cz/film/104630-jerabi-tahnou-letjat-zuravli/" imdbLink=""/>
                    <MovieComponent moviePosition="327" isSeen={true} movieName="Paths of Glory (1957)" csfdLink="http://www.csfd.cz/film/5405-paths-of-glory/" imdbLink=""/>
                    <MovieComponent moviePosition="328" isSeen={true} movieName="Sweet Smell of Success (1957)" csfdLink="http://www.csfd.cz/film/11845-sweet-smell-of-success/" imdbLink=""/>
                    <MovieComponent moviePosition="329" isSeen={false} movieName="Man of the West (1958)" csfdLink="http://www.csfd.cz/film/6422-man-of-the-west/" imdbLink=""/>
                    <MovieComponent moviePosition="330" isSeen={true} movieName="Touch of Evil (1958)" csfdLink="http://www.csfd.cz/film/9635-touch-of-evil/" imdbLink=""/>
                    <MovieComponent moviePosition="331" isSeen={false} movieName="Cairo Station (1958)" csfdLink="http://www.csfd.cz/film/198139-bab-el-hadid/" imdbLink=""/>
                    <MovieComponent moviePosition="332" isSeen={false} movieName="Gigi (1958)" csfdLink="http://www.csfd.cz/film/6759-gigi/" imdbLink=""/>
                    <MovieComponent moviePosition="333" isSeen={true} movieName="The Defiant Ones (1958)" csfdLink="http://www.csfd.cz/film/5301-defiant-ones-the/" imdbLink=""/>
                    <MovieComponent moviePosition="334" isSeen={true} movieName="Vertigo (1958)" csfdLink="http://www.csfd.cz/film/4406-vertigo-zavrat-vertigo/" imdbLink=""/>
                    <MovieComponent moviePosition="335" isSeen={true} movieName="Popiól i diament (1958)" csfdLink="http://www.csfd.cz/film/35216-ashes-and-diamonds-popiol-i-diament/" imdbLink=""/>
                    <MovieComponent moviePosition="336" isSeen={true} movieName="Dracula (1958)" csfdLink="http://www.csfd.cz/film/2681-dracula/" imdbLink=""/>
                    <MovieComponent moviePosition="337" isSeen={false} movieName="Mon Oncle (1958)" csfdLink="http://www.csfd.cz/film/47475-mon-oncle/" imdbLink=""/>
                    <MovieComponent moviePosition="338" isSeen={false} movieName="Jalsaghar (1958)" csfdLink="http://www.csfd.cz/film/130921-jalsaghar/" imdbLink=""/>
                    <MovieComponent moviePosition="339" isSeen={true} movieName="Les Quatre cents coups (1959)" csfdLink="http://www.csfd.cz/film/9092-400-blows-quatre-cents-coups-les/" imdbLink=""/>
                    <MovieComponent moviePosition="340" isSeen={true} movieName="North by Northwest (1959)" csfdLink="http://www.csfd.cz/film/4379-north-by-northwest/" imdbLink=""/>
                    <MovieComponent moviePosition="341" isSeen={true} movieName="Some Like It Hot (1959)" csfdLink="http://www.csfd.cz/film/9793-some-like-it-hot/" imdbLink=""/>
                    <MovieComponent moviePosition="342" isSeen={true} movieName="Anatomy of a Murder (1959)" csfdLink="http://www.csfd.cz/film/7480-anatomy-of-a-murder/" imdbLink=""/>
                    <MovieComponent moviePosition="343" isSeen={true} movieName="Les Yeux sans visage (1959)" csfdLink="http://www.csfd.cz/film/48810-eyes-without-a-face-yeux-sans-visage-les/" imdbLink=""/>
                    <MovieComponent moviePosition="344" isSeen={false} movieName="Ride Lonesome (1959)" csfdLink="http://www.csfd.cz/film/19105-ride-lonesome/" imdbLink=""/>
                    <MovieComponent moviePosition="345" isSeen={true} movieName="Orfeu Negro (1959)" csfdLink="http://www.csfd.cz/film/129130-orfeu-negro/" imdbLink=""/>
                    <MovieComponent moviePosition="346" isSeen={false} movieName="Shadows (1959)" csfdLink="http://www.csfd.cz/film/44065-shadows/" imdbLink=""/>
                    <MovieComponent moviePosition="347" isSeen={false} movieName="Apur Sansar (1959)" csfdLink="http://www.csfd.cz/film/130910-apur-sansar/" imdbLink=""/>
                    <MovieComponent moviePosition="348" isSeen={false} movieName="A bout de souffle (1959)" csfdLink="http://www.csfd.cz/film/32453-breathless-a-bout-de-souffle/" imdbLink=""/>
                    <MovieComponent moviePosition="349" isSeen={true} movieName="Ben-Hur (1959)" csfdLink="http://www.csfd.cz/film/9876-benhur/" imdbLink=""/>
                    <MovieComponent moviePosition="350" isSeen={false} movieName="Pickpocket (1959)" csfdLink="http://www.csfd.cz/film/35297-pickpocket/" imdbLink=""/>
                    <MovieComponent moviePosition="351" isSeen={true} movieName="Hiroshima Mon Amour (1959)" csfdLink="http://www.csfd.cz/film/71397-hiroshima-mon-amour/" imdbLink=""/>
                    <MovieComponent moviePosition="352" isSeen={false} movieName="Rio Bravo (1959)" csfdLink="http://www.csfd.cz/film/4201-rio-bravo/" imdbLink=""/>
                    <MovieComponent moviePosition="353" isSeen={false} movieName="Le Trou (1959)" csfdLink="http://www.csfd.cz/film/90082-trou-le/" imdbLink=""/>
                    <MovieComponent moviePosition="354" isSeen={false} movieName="Ukigusa (1959)" csfdLink="http://www.csfd.cz/film/124943-ukigusa/" imdbLink=""/>
                    <MovieComponent moviePosition="355" isSeen={false} movieName="Rocco e i suoi fratelli (1960)" csfdLink="http://www.csfd.cz/film/9324-rocco-e-i-suoi-fratelli-rocco-et-ses-freres/" imdbLink=""/>
                    <MovieComponent moviePosition="356" isSeen={true} movieName="La Dolce Vita (1960)" csfdLink="http://www.csfd.cz/film/2604-dolce-vita-la/" imdbLink=""/>
                    <MovieComponent moviePosition="357" isSeen={true} movieName="Saturday Night and Sunday Morning (1960)" csfdLink="http://www.csfd.cz/film/12573-saturday-night-and-sunday-morning/" imdbLink=""/>
                    <MovieComponent moviePosition="358" isSeen={false} movieName="Tirez sur le pianiste (1960)" csfdLink="http://www.csfd.cz/film/9095-shoot-the-pianist-tirez-sur-le-pianiste/" imdbLink=""/>
                    <MovieComponent moviePosition="359" isSeen={false} movieName="L&#8217;Avventura (1960)" csfdLink="http://www.csfd.cz/film/228-avventura-l/" imdbLink=""/>
                    <MovieComponent moviePosition="360" isSeen={false} movieName="La Joven (1960)" csfdLink="http://www.csfd.cz/film/1102-joven-la/" imdbLink=""/>
                    <MovieComponent moviePosition="361" isSeen={false} movieName="Meghe Dhaka Tara (1960)" csfdLink="http://www.csfd.cz/film/200790-meghe-dhaka-tara/" imdbLink=""/>
                    <MovieComponent moviePosition="362" isSeen={false} movieName="Hanyo (1960)" csfdLink="http://www.csfd.cz/film/205427-hanyo/" imdbLink=""/>
                    <MovieComponent moviePosition="363" isSeen={true} movieName="Psycho (1960)" csfdLink="http://www.csfd.cz/film/4385-psycho/" imdbLink=""/>
                    <MovieComponent moviePosition="364" isSeen={true} movieName="La Maschera del demonio (1960)" csfdLink="http://www.csfd.cz/film/505-maschera-del-demonio-la/" imdbLink=""/>
                    <MovieComponent moviePosition="365" isSeen={true} movieName="Peeping Tom (1960)" csfdLink="http://www.csfd.cz/film/7439-peeping-tom/" imdbLink=""/>
                    <MovieComponent moviePosition="366" isSeen={true} movieName="The Apartment (1960)" csfdLink="http://www.csfd.cz/film/9772-apartment-the/" imdbLink=""/>
                    <MovieComponent moviePosition="367" isSeen={true} movieName="Spartacus (1960)" csfdLink="http://www.csfd.cz/film/5408-spartakus-spartacus/" imdbLink=""/>
                    <MovieComponent moviePosition="368" isSeen={false} movieName="Splendor in the Grass (1961)" csfdLink="http://www.csfd.cz/film/5087-splendor-in-the-grass/" imdbLink=""/>
                    <MovieComponent moviePosition="369" isSeen={false} movieName="L´ Année derniére á Marienbad (1961)" csfdLink="http://www.csfd.cz/film/71389-annee-derni-and-232re-and-224-marienbad-l/" imdbLink=""/>
                    <MovieComponent moviePosition="370" isSeen={true} movieName="La Jetee (1961)" csfdLink="http://www.csfd.cz/film/131457-jetee-la/" imdbLink=""/>
                    <MovieComponent moviePosition="371" isSeen={false} movieName="One-Eyed Jacks (1961)" csfdLink="http://www.csfd.cz/film/13600-oneeyed-jacks/" imdbLink=""/>
                    <MovieComponent moviePosition="372" isSeen={false} movieName="Lola (1961)" csfdLink="http://www.csfd.cz/film/40861-lola/" imdbLink=""/>
                    <MovieComponent moviePosition="373" isSeen={true} movieName="Breakfast at Tiffany&#8217;s (1961)" csfdLink="http://www.csfd.cz/film/2531-breakfast-at-tiffanys/" imdbLink=""/>
                    <MovieComponent moviePosition="374" isSeen={false} movieName="La Notte (1961)" csfdLink="http://www.csfd.cz/film/245-notte-la/" imdbLink=""/>
                    <MovieComponent moviePosition="375" isSeen={false} movieName="Jules and Jim (1961)" csfdLink="http://www.csfd.cz/film/9087-jules-a-jim-jules-et-jim/" imdbLink=""/>
                    <MovieComponent moviePosition="376" isSeen={true} movieName="Viridiana (1961)" csfdLink="http://www.csfd.cz/film/1114-viridiana/" imdbLink=""/>
                    <MovieComponent moviePosition="377" isSeen={false} movieName="The Ladies Man (1961)" csfdLink="http://www.csfd.cz/film/5969-ladies-man-the/" imdbLink=""/>
                    <MovieComponent moviePosition="378" isSeen={false} movieName="Sasom i en spegel (1961)" csfdLink="http://www.csfd.cz/film/732-through-a-glass-darkly-sasom-i-en-spegel/" imdbLink=""/>
                    <MovieComponent moviePosition="379" isSeen={false} movieName="Chronique d&#8217;un été (1961)" csfdLink="http://www.csfd.cz/film/195800-chronique-dun-ete/" imdbLink=""/>
                    <MovieComponent moviePosition="380" isSeen={false} movieName="The Hustler (1961)" csfdLink="http://www.csfd.cz/film/13052-hustler-the/" imdbLink=""/>
                    <MovieComponent moviePosition="381" isSeen={false} movieName="West Side Story (1961)" csfdLink="http://www.csfd.cz/film/9843-west-side-story/" imdbLink=""/>
                    <MovieComponent moviePosition="382" isSeen={false} movieName="Mondo Cane (1962)" csfdLink="http://www.csfd.cz/film/84047-mondo-cane/" imdbLink=""/>
                    <MovieComponent moviePosition="383" isSeen={false} movieName="Cléo de 5 á 7 (1962)" csfdLink="http://www.csfd.cz/film/39853-cleo-from-5-to-7-cleo-de-5-and-224-7/" imdbLink=""/>
                    <MovieComponent moviePosition="384" isSeen={false} movieName="Dog Star Man (1962)" csfdLink="http://www.csfd.cz/film/174363-prelude-dog-star-man/" imdbLink=""/>
                    <MovieComponent moviePosition="385" isSeen={false} movieName="Sanma no aji (1962)" csfdLink="http://www.csfd.cz/film/124929-sanma-no-aji/" imdbLink=""/>
                    <MovieComponent moviePosition="386" isSeen={false} movieName="L&#8217;Eclisse (1962)" csfdLink="http://www.csfd.cz/film/234-eclisse-l/" imdbLink=""/>
                    <MovieComponent moviePosition="387" isSeen={true} movieName="Lawrence of Arabia (1962)" csfdLink="http://www.csfd.cz/film/5702-lawrence-of-arabia/" imdbLink=""/>
                    <MovieComponent moviePosition="388" isSeen={true} movieName="To Kill a Mockingbird (1962)" csfdLink="http://www.csfd.cz/film/12380-to-kill-a-mockingbird/" imdbLink=""/>
                    <MovieComponent moviePosition="389" isSeen={true} movieName="The Manchurian Candidate (1962)" csfdLink="http://www.csfd.cz/film/17543-manchurian-candidate-the/" imdbLink=""/>
                    <MovieComponent moviePosition="390" isSeen={true} movieName="Lolita (1962)" csfdLink="http://www.csfd.cz/film/5404-lolita/" imdbLink=""/>
                    <MovieComponent moviePosition="391" isSeen={false} movieName="O Pagador de Promessas (1962)" csfdLink="http://www.csfd.cz/film/177462-pagador-de-promessas-o/" imdbLink=""/>
                    <MovieComponent moviePosition="392" isSeen={true} movieName="The Man Who Shot Liberty Valance (1962)" csfdLink="http://www.csfd.cz/film/2901-man-who-shot-liberty-valance-the/" imdbLink=""/>
                    <MovieComponent moviePosition="393" isSeen={false} movieName="What Ever Happened to Baby Jane? (1962)" csfdLink="http://www.csfd.cz/film/27540-what-ever-happened-to-baby-jane/" imdbLink=""/>
                    <MovieComponent moviePosition="394" isSeen={false} movieName="Vivre sa vie: Film en douze tableaux (1962)" csfdLink="http://www.csfd.cz/film/35196-vivre-sa-vie-film-en-douze-tableaux/" imdbLink=""/>
                    <MovieComponent moviePosition="395" isSeen={false} movieName="Heaven and Earth Magic (1962)" csfdLink="http://www.csfd.cz/film/168264-heaven-and-earth-magic/" imdbLink=""/>
                    <MovieComponent moviePosition="396" isSeen={true} movieName="The Birds (1963)" csfdLink="http://www.csfd.cz/film/4352-birds-the/" imdbLink=""/>
                    <MovieComponent moviePosition="397" isSeen={false} movieName="The Nutty Professor (1963)" csfdLink="http://www.csfd.cz/film/5970-nutty-professor-the/" imdbLink=""/>
                    <MovieComponent moviePosition="398" isSeen={false} movieName="Blonde Cobra (1963)" csfdLink="http://www.csfd.cz/film/217560-blonde-cobra/" imdbLink=""/>
                    <MovieComponent moviePosition="399" isSeen={false} movieName="The Cool World (1963)" csfdLink="http://www.csfd.cz/film/165813-cool-world-the/" imdbLink=""/>
                    <MovieComponent moviePosition="400" isSeen={false} movieName="8 1/2 (1963)" csfdLink="http://www.csfd.cz/film/2596-8-12/" imdbLink=""/>
                    <MovieComponent moviePosition="401" isSeen={false} movieName="Pasazerka (1963)" csfdLink="http://www.csfd.cz/film/122458-pasazerka/" imdbLink=""/>
                    <MovieComponent moviePosition="402" isSeen={false} movieName="Le Mépris (1963)" csfdLink="http://www.csfd.cz/film/15203-mepris-le/" imdbLink=""/>
                    <MovieComponent moviePosition="403" isSeen={true} movieName="Hud (1963)" csfdLink="http://www.csfd.cz/film/7715-hud/" imdbLink=""/>
                    <MovieComponent moviePosition="404" isSeen={true} movieName="Nattvardsgästerna (1963)" csfdLink="http://www.csfd.cz/film/712-winter-light-nattvardsgasterna/" imdbLink=""/>
                    <MovieComponent moviePosition="405" isSeen={false} movieName="Flaming Creatures (1963)" csfdLink="http://www.csfd.cz/film/195592-flaming-creatures/" imdbLink=""/>
                    <MovieComponent moviePosition="406" isSeen={true} movieName="The Great Escape (1963)" csfdLink="http://www.csfd.cz/film/18777-great-escape-the/" imdbLink=""/>
                    <MovieComponent moviePosition="407" isSeen={true} movieName="Shock Corridor (1963)" csfdLink="http://www.csfd.cz/film/81664-shock-corridor/" imdbLink=""/>
                    <MovieComponent moviePosition="408" isSeen={false} movieName="Il Gattopardo (1963)" csfdLink="http://www.csfd.cz/film/9316-gattopardo-il/" imdbLink=""/>
                    <MovieComponent moviePosition="409" isSeen={true} movieName="Vidas Secas (1963)" csfdLink="http://www.csfd.cz/film/214945-vidas-secas/" imdbLink=""/>
                    <MovieComponent moviePosition="410" isSeen={false} movieName="Méditerranée (1963)" csfdLink="http://www.csfd.cz/film/43431-mediterranee/" imdbLink=""/>
                    <MovieComponent moviePosition="411" isSeen={true} movieName="Khaneh siah ast (1963)" csfdLink="http://www.csfd.cz/film/237868-khaneh-siah-ast/" imdbLink=""/>
                    <MovieComponent moviePosition="412" isSeen={true} movieName="The Haunting (1963)" csfdLink="http://www.csfd.cz/film/9818-haunting-the/" imdbLink=""/>
                    <MovieComponent moviePosition="413" isSeen={false} movieName="Yukinojo henge (1963)" csfdLink="http://www.csfd.cz/film/116155-yukinojo-henge/" imdbLink=""/>
                    <MovieComponent moviePosition="414" isSeen={true} movieName="The Servant (1963)" csfdLink="http://www.csfd.cz/film/23316-servant-the/" imdbLink=""/>
                    <MovieComponent moviePosition="415" isSeen={false} movieName="Goldfinger (1964)" csfdLink="http://www.csfd.cz/film/11538-goldfinger/" imdbLink=""/>
                    <MovieComponent moviePosition="416" isSeen={true} movieName="Scorpio Rising (1964)" csfdLink="http://www.csfd.cz/film/121441-scorpio-rising/" imdbLink=""/>
                    <MovieComponent moviePosition="417" isSeen={false} movieName="Les Parapluies de Cherbourg (1964)" csfdLink="http://www.csfd.cz/film/22149-parapluies-de-cherbourg-les/" imdbLink=""/>
                    <MovieComponent moviePosition="418" isSeen={false} movieName="Marnie (1964)" csfdLink="http://www.csfd.cz/film/4374-marnie/" imdbLink=""/>
                    <MovieComponent moviePosition="419" isSeen={true} movieName="My Fair Lady (1964)" csfdLink="http://www.csfd.cz/film/1837-my-fair-lady/" imdbLink=""/>
                    <MovieComponent moviePosition="420" isSeen={true} movieName="Suna no onna (1964)" csfdLink="http://www.csfd.cz/film/124512-suna-no-onna/" imdbLink=""/>
                    <MovieComponent moviePosition="421" isSeen={true} movieName="Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb (1964)" csfdLink="http://www.csfd.cz/film/5397-dr-divnolaska-aneb-jak-jsem-se-naucil-nedelat-si-starosti-a-mit-rad-bombu-dr-strangelove-or-how-i-learned-to-stop-worrying-and-love-the-bomb/" imdbLink=""/>
                    <MovieComponent moviePosition="422" isSeen={true} movieName="A Hard Day&#8217;s Night (1964)" csfdLink="http://www.csfd.cz/film/5924-hard-days-night-a/" imdbLink=""/>
                    <MovieComponent moviePosition="423" isSeen={false} movieName="Il Deserto rosso (1964)" csfdLink="http://www.csfd.cz/film/233-red-desert-the-deserto-rosso-il/" imdbLink=""/>
                    <MovieComponent moviePosition="424" isSeen={false} movieName="Tini zabutykh predkiv (1964)" csfdLink="http://www.csfd.cz/film/136525-shadows-of-forgotten-ancestors-tini-zabutykh-predkiv/" imdbLink=""/>
                    <MovieComponent moviePosition="425" isSeen={true} movieName="The Masque of the Red Death (1964)" csfdLink="http://www.csfd.cz/film/1681-masque-of-the-red-death-the/" imdbLink=""/>
                    <MovieComponent moviePosition="426" isSeen={false} movieName="Prima della rivoluzione (1964)" csfdLink="http://www.csfd.cz/film/759-prima-della-rivoluzione/" imdbLink=""/>
                    <MovieComponent moviePosition="427" isSeen={false} movieName="Gertrud (1964)" csfdLink="http://www.csfd.cz/film/104965-gertrud/" imdbLink=""/>
                    <MovieComponent moviePosition="428" isSeen={true} movieName="Il Vangelo secondo Matteo (1964)" csfdLink="http://www.csfd.cz/film/7147-gospel-according-to-st-matthew-the-vangelo-secondo-matteo-il/" imdbLink=""/>
                    <MovieComponent moviePosition="429" isSeen={true} movieName="Deus E o Diabo na Terra do Sol (1964)" csfdLink="http://www.csfd.cz/film/197807-black-god-white-devil-deus-e-o-diabo-na-terra-do-sol/" imdbLink=""/>
                    <MovieComponent moviePosition="430" isSeen={true} movieName="Onibaba (1964)" csfdLink="http://www.csfd.cz/film/121232-onibaba/" imdbLink=""/>
                    <MovieComponent moviePosition="431" isSeen={false} movieName="Vinyl (1965)" csfdLink="http://www.csfd.cz/film/37587-vinyl/" imdbLink=""/>
                    <MovieComponent moviePosition="432" isSeen={true} movieName="Obchod na korze (1965)" csfdLink="http://www.csfd.cz/film/4999-shop-on-main-street-the-obchod-na-korze/" imdbLink=""/>
                    <MovieComponent moviePosition="433" isSeen={false} movieName="Doctor Zhivago (1965)" csfdLink="http://www.csfd.cz/film/5698-doctor-zhivago/" imdbLink=""/>
                    <MovieComponent moviePosition="434" isSeen={true} movieName="The War Game (1965)" csfdLink="http://www.csfd.cz/film/228832-war-game-the/" imdbLink=""/>
                    <MovieComponent moviePosition="435" isSeen={false} movieName="Tokyo orimpikku (1965)" csfdLink="http://www.csfd.cz/film/230406-tokyo-orimpikku/" imdbLink=""/>
                    <MovieComponent moviePosition="436" isSeen={true} movieName="La Battaglia di Algeri (1965)" csfdLink="http://www.csfd.cz/film/124195-battle-of-algiers-the-battaglia-di-algeri-la/" imdbLink=""/>
                    <MovieComponent moviePosition="437" isSeen={false} movieName="The Sound of Music (1965)" csfdLink="http://www.csfd.cz/film/9833-sound-of-music-the/" imdbLink=""/>
                    <MovieComponent moviePosition="438" isSeen={true} movieName="Rekopis znaleziony w Saragossie (1965)" csfdLink="http://www.csfd.cz/film/109809-rekopis-znaleziony-w-saragossie/" imdbLink=""/>
                    <MovieComponent moviePosition="439" isSeen={false} movieName="Alphaville, une étrange aventure de Lemmy Caution (1965)" csfdLink="http://www.csfd.cz/film/35146-alphaville-alphaville-une-etrange-aventure-de-lemmy-caution/" imdbLink=""/>
                    <MovieComponent moviePosition="440" isSeen={true} movieName="Campanadas a medianoche (1965)" csfdLink="http://www.csfd.cz/film/9610-campanadas-a-medianoche/" imdbLink=""/>
                    <MovieComponent moviePosition="441" isSeen={true} movieName="Repulsion (1965)" csfdLink="http://www.csfd.cz/film/7358-repulsion/" imdbLink=""/>
                    <MovieComponent moviePosition="442" isSeen={false} movieName="Juliet of the Spirits (1965)" csfdLink="http://www.csfd.cz/film/2607-giulietta-degli-spiriti/" imdbLink=""/>
                    <MovieComponent moviePosition="443" isSeen={false} movieName="Pierrot Le Fou (1965)" csfdLink="http://www.csfd.cz/film/32439-pierrot-le-fou/" imdbLink=""/>
                    <MovieComponent moviePosition="444" isSeen={true} movieName="Faster, Pussy Cat! Kill! Kill! (1965)" csfdLink="http://www.csfd.cz/film/46856-faster-pussycat-kill-kill/" imdbLink=""/>
                    <MovieComponent moviePosition="445" isSeen={false} movieName="Golden River (1965)" csfdLink="http://www.csfd.cz/film/198401-zlata-nit/" imdbLink=""/>
                    <MovieComponent moviePosition="446" isSeen={false} movieName="De Man die zijn haar kort liet knippen (1965)" csfdLink="http://www.csfd.cz/film/200669-man-die-zijn-haar-kort-liet-knippen-de/" imdbLink=""/>
                    <MovieComponent moviePosition="447" isSeen={true} movieName="Hold Me While I'm Naked (1966)" csfdLink="http://www.csfd.cz/film/175152-hold-me-while-im-naked/" imdbLink=""/>
                    <MovieComponent moviePosition="448" isSeen={false} movieName="BlowUp (1966)" csfdLink="http://www.csfd.cz/film/229-blowup/" imdbLink=""/>
                    <MovieComponent moviePosition="449" isSeen={false} movieName="Il Buono, il brutto, il cattivo (1966)" csfdLink="http://www.csfd.cz/film/5910-good-the-bad-and-the-ugly-the-buono-il-brutto-il-cattivo-il/" imdbLink=""/>
                    <MovieComponent moviePosition="450" isSeen={true} movieName="Sedmikrásky (1966)" csfdLink="http://www.csfd.cz/film/1542-sedmikrasky/" imdbLink=""/>
                    <MovieComponent moviePosition="451" isSeen={false} movieName="Da zui xia (1966)" csfdLink="http://www.csfd.cz/film/44080-da-zui-xia/" imdbLink=""/>
                    <MovieComponent moviePosition="452" isSeen={false} movieName="Seconds (1966)" csfdLink="http://www.csfd.cz/film/13067-seconds/" imdbLink=""/>
                    <MovieComponent moviePosition="453" isSeen={false} movieName="Who&#8217;s Afraid of Virginia Woolf? (1966)" csfdLink="http://www.csfd.cz/film/6984-whos-afraid-of-virginia-woolf/" imdbLink=""/>
                    <MovieComponent moviePosition="454" isSeen={false} movieName="Persona (1966)" csfdLink="http://www.csfd.cz/film/716-persona/" imdbLink=""/>
                    <MovieComponent moviePosition="455" isSeen={false} movieName="Masculin féminin: 15 faits précis (1966)" csfdLink="http://www.csfd.cz/film/27325-masculin-feminin-15-faits-precis/" imdbLink=""/>
                    <MovieComponent moviePosition="456" isSeen={false} movieName="Au Hazard Balthazar (1966)" csfdLink="http://www.csfd.cz/film/35291-au-hasard-balthazar/" imdbLink=""/>
                    <MovieComponent moviePosition="457" isSeen={false} movieName="In the Heat of the Night (1967)" csfdLink="http://www.csfd.cz/film/4805-in-the-heat-of-the-night/" imdbLink=""/>
                    <MovieComponent moviePosition="458" isSeen={false} movieName="2 ou 3 choses que je sais d&#8217;elle (1967)" csfdLink="http://www.csfd.cz/film/35144-2-ou-3-choses-que-je-sais-delle/" imdbLink=""/>
                    <MovieComponent moviePosition="459" isSeen={true} movieName="The Graduate (1967)" csfdLink="http://www.csfd.cz/film/6977-graduate-the/" imdbLink=""/>
                    <MovieComponent moviePosition="460" isSeen={false} movieName="Playtime (1967)" csfdLink="http://www.csfd.cz/film/47478-playtime/" imdbLink=""/>
                    <MovieComponent moviePosition="461" isSeen={true} movieName="Report (1967)" csfdLink="http://www.csfd.cz/film/180762-report/" imdbLink=""/>
                    <MovieComponent moviePosition="462" isSeen={false} movieName="Hombre (1967)" csfdLink="http://www.csfd.cz/film/7714-hombre/" imdbLink=""/>
                    <MovieComponent moviePosition="463" isSeen={true} movieName="Belle de Jour (1967)" csfdLink="http://www.csfd.cz/film/1088-belle-de-jour/" imdbLink=""/>
                    <MovieComponent moviePosition="464" isSeen={false} movieName="Les Demoiselles de Rochefort (1967)" csfdLink="http://www.csfd.cz/film/22128-demoiselles-de-rochefort-les/" imdbLink=""/>
                    <MovieComponent moviePosition="465" isSeen={false} movieName="Week End (1967)" csfdLink="http://www.csfd.cz/film/35198-week-end/" imdbLink=""/>
                    <MovieComponent moviePosition="466" isSeen={false} movieName="Le Samourai (1967)" csfdLink="http://www.csfd.cz/film/32498-samourai-le/" imdbLink=""/>
                    <MovieComponent moviePosition="467" isSeen={false} movieName="Cool Hand Luke (1967)" csfdLink="http://www.csfd.cz/film/7827-cool-hand-luke/" imdbLink=""/>
                    <MovieComponent moviePosition="468" isSeen={true} movieName="Point Blank (1967)" csfdLink="http://www.csfd.cz/film/868-point-blank/" imdbLink=""/>
                    <MovieComponent moviePosition="469" isSeen={true} movieName="Wavelength (1967)" csfdLink="http://www.csfd.cz/film/222769-wavelength/" imdbLink=""/>
                    <MovieComponent moviePosition="470" isSeen={true} movieName="Bonnie and Clyde (1967)" csfdLink="http://www.csfd.cz/film/7190-bonnie-a-clyde-bonnie-and-clyde/" imdbLink=""/>
                    <MovieComponent moviePosition="471" isSeen={true} movieName="Csillagosok, katonák (1967)" csfdLink="http://www.csfd.cz/film/75732-csillagosok-katonak/" imdbLink=""/>
                    <MovieComponent moviePosition="472" isSeen={true} movieName="Markéta Lazarová (1967)" csfdLink="http://www.csfd.cz/film/9342-marketa-lazarova/" imdbLink=""/>
                    <MovieComponent moviePosition="473" isSeen={false} movieName="The Jungle Book (1967)" csfdLink="http://www.csfd.cz/film/34106-jungle-book-the/" imdbLink=""/>
                    <MovieComponent moviePosition="474" isSeen={true} movieName="Hoří má panenko (1967)" csfdLink="http://www.csfd.cz/film/2975-hori-ma-panenko/" imdbLink=""/>
                    <MovieComponent moviePosition="475" isSeen={false} movieName="Earth Entranced (1967)" csfdLink="http://www.csfd.cz/film/201482-terra-em-transe/" imdbLink=""/>
                    <MovieComponent moviePosition="476" isSeen={true} movieName="Ostře sledované vlaky (1967)" csfdLink="http://www.csfd.cz/film/6664-ostre-sledovane-vlaky/" imdbLink=""/>
                    <MovieComponent moviePosition="477" isSeen={true} movieName="Vij (1967)" csfdLink="http://www.csfd.cz/film/139842-vij/" imdbLink=""/>
                    <MovieComponent moviePosition="478" isSeen={false} movieName="Gaav (1968)" csfdLink="http://www.csfd.cz/film/224413-cow-the-gaav/" imdbLink=""/>
                    <MovieComponent moviePosition="479" isSeen={true} movieName="C&#8217;era una volta il West (1968)" csfdLink="http://www.csfd.cz/film/5911-once-upon-a-time-in-the-west-cera-una-volta-il-west/" imdbLink=""/>
                    <MovieComponent moviePosition="480" isSeen={true} movieName="Planet of the Apes (1968)" csfdLink="http://www.csfd.cz/film/19977-planet-of-the-apes/" imdbLink=""/>
                    <MovieComponent moviePosition="481" isSeen={false} movieName="Faces (1968)" csfdLink="http://www.csfd.cz/film/69601-faces/" imdbLink=""/>
                    <MovieComponent moviePosition="482" isSeen={false} movieName="Rosemary&#8217;s Baby (1968)" csfdLink="http://www.csfd.cz/film/7359-rosemarys-baby/" imdbLink=""/>
                    <MovieComponent moviePosition="483" isSeen={false} movieName="If&#8230; (1968)" csfdLink="http://www.csfd.cz/film/14679-if/" imdbLink=""/>
                    <MovieComponent moviePosition="484" isSeen={true} movieName="Memorias del subdesarrollo (1968)" csfdLink="http://www.csfd.cz/film/70304-memorias-del-subdesarrollo/" imdbLink=""/>
                    <MovieComponent moviePosition="485" isSeen={false} movieName="The Producers (1968)" csfdLink="http://www.csfd.cz/film/27143-producers-the/" imdbLink=""/>
                    <MovieComponent moviePosition="486" isSeen={false} movieName="David Holzman&#8217;s Diary (1968)" csfdLink="http://www.csfd.cz/film/178063-david-holzmans-diary/" imdbLink=""/>
                    <MovieComponent moviePosition="487" isSeen={true} movieName="Skammen (1968)" csfdLink="http://www.csfd.cz/film/724-shame-skammen/" imdbLink=""/>
                    <MovieComponent moviePosition="488" isSeen={true} movieName="2001: A Space Odyssey (1968)" csfdLink="http://www.csfd.cz/film/5393-2001-a-space-odyssey/" imdbLink=""/>
                    <MovieComponent moviePosition="489" isSeen={false} movieName="Vargtimmen (1968)" csfdLink="http://www.csfd.cz/film/738-hour-of-the-wolf-vargtimmen/" imdbLink=""/>
                    <MovieComponent moviePosition="490" isSeen={true} movieName="Targets (1968)" csfdLink="http://www.csfd.cz/film/849-targets/" imdbLink=""/>
                    <MovieComponent moviePosition="491" isSeen={true} movieName="Night of the Living Dead (1968)" csfdLink="http://www.csfd.cz/film/7810-night-of-the-living-dead/" imdbLink=""/>
                    <MovieComponent moviePosition="492" isSeen={false} movieName="Ma nuit chez Maud (1969)" csfdLink="http://www.csfd.cz/film/37980-ma-nuit-chez-maud/" imdbLink=""/>
                    <MovieComponent moviePosition="493" isSeen={false} movieName="Lucia (1969)" csfdLink="http://www.csfd.cz/film/201588-lucia/" imdbLink=""/>
                    <MovieComponent moviePosition="494" isSeen={false} movieName="Hsia nu (1969)" csfdLink="http://www.csfd.cz/film/33791-hsia-nu/" imdbLink=""/>
                    <MovieComponent moviePosition="495" isSeen={false} movieName="Butch Cassidy and the Sundance Kid (1969)" csfdLink="http://www.csfd.cz/film/15031-butch-cassidy-a-sundance-kid-butch-cassidy-and-the-sundance-kid/" imdbLink=""/>
                    <MovieComponent moviePosition="496" isSeen={false} movieName="Midnight Cowboy (1969)" csfdLink="http://www.csfd.cz/film/8084-midnight-cowboy/" imdbLink=""/>
                    <MovieComponent moviePosition="497" isSeen={false} movieName="Satyricon (1969)" csfdLink="http://www.csfd.cz/film/2613-satyricon/" imdbLink=""/>
                    <MovieComponent moviePosition="498" isSeen={false} movieName="Z (1969)" csfdLink="http://www.csfd.cz/film/1744-z-/" imdbLink=""/>
                    <MovieComponent moviePosition="499" isSeen={false} movieName="Il Conformista (1969)" csfdLink="http://www.csfd.cz/film/751-conformista-il/" imdbLink=""/>
                    <MovieComponent moviePosition="500" isSeen={true} movieName="Easy Rider (1969)" csfdLink="http://www.csfd.cz/film/4508-easy-rider/" imdbLink=""/>
                    <MovieComponent moviePosition="501" isSeen={true} movieName="High School (1969)" csfdLink="http://www.csfd.cz/film/197152-high-school/" imdbLink=""/>
                    <MovieComponent moviePosition="502" isSeen={true} movieName="In the Year of the Pig (1969)" csfdLink="http://www.csfd.cz/film/233421-in-the-year-of-the-pig/" imdbLink=""/>
                    <MovieComponent moviePosition="503" isSeen={false} movieName="The Wild Bunch (1969)" csfdLink="http://www.csfd.cz/film/7181-wild-bunch-the/" imdbLink=""/>
                    <MovieComponent moviePosition="504" isSeen={false} movieName="Andrej Rubljov (1969)" csfdLink="http://www.csfd.cz/film/32345-andrej-rublev-andrej-rubljov/" imdbLink=""/>
                    <MovieComponent moviePosition="505" isSeen={false} movieName="Le Boucher (1969)" csfdLink="http://www.csfd.cz/film/89468-boucher-le/" imdbLink=""/>
                    <MovieComponent moviePosition="506" isSeen={false} movieName="Sayat Nova (1969)" csfdLink="http://www.csfd.cz/film/155412-sayat-nova-cvet-granata/" imdbLink=""/>
                    <MovieComponent moviePosition="507" isSeen={false} movieName="Kes (1969)" csfdLink="http://www.csfd.cz/film/36166-kes/" imdbLink=""/>
                    <MovieComponent moviePosition="508" isSeen={false} movieName="Tristana (1970)" csfdLink="http://www.csfd.cz/film/1111-tristana/" imdbLink=""/>
                    <MovieComponent moviePosition="509" isSeen={false} movieName="Five Easy Pieces (1970)" csfdLink="http://www.csfd.cz/film/15058-five-easy-pieces/" imdbLink=""/>
                    <MovieComponent moviePosition="510" isSeen={false} movieName="El Topo (1970)" csfdLink="http://www.csfd.cz/film/72254-topo-el/" imdbLink=""/>
                    <MovieComponent moviePosition="511" isSeen={false} movieName="Woodstock (1970)" csfdLink="http://www.csfd.cz/film/83439-woodstock/" imdbLink=""/>
                    <MovieComponent moviePosition="512" isSeen={false} movieName="Deep End (1970)" csfdLink="http://www.csfd.cz/film/36643-deep-end/" imdbLink=""/>
                    <MovieComponent moviePosition="513" isSeen={false} movieName="La Strategia del ragno (1970)" csfdLink="http://www.csfd.cz/film/763-strategia-del-ragno-la/" imdbLink=""/>
                    <MovieComponent moviePosition="514" isSeen={false} movieName="Little Big Man (1970)" csfdLink="http://www.csfd.cz/film/7197-little-big-man/" imdbLink=""/>
                    <MovieComponent moviePosition="515" isSeen={false} movieName="Ucho (1970)" csfdLink="http://www.csfd.cz/film/4983-ucho/" imdbLink=""/>
                    <MovieComponent moviePosition="516" isSeen={true} movieName="Patton (1970)" csfdLink="http://www.csfd.cz/film/26929-patton/" imdbLink=""/>
                    <MovieComponent moviePosition="517" isSeen={false} movieName="M*A*S*H (1970)" csfdLink="http://www.csfd.cz/film/118-mash/" imdbLink=""/>
                    <MovieComponent moviePosition="518" isSeen={false} movieName="Performance (1970)" csfdLink="http://www.csfd.cz/film/47940-performance/" imdbLink=""/>
                    <MovieComponent moviePosition="519" isSeen={false} movieName="Gimme Shelter (1970)" csfdLink="http://www.csfd.cz/film/101323-gimme-shelter/" imdbLink=""/>
                    <MovieComponent moviePosition="520" isSeen={false} movieName="Zabriskie Point (1970)" csfdLink="http://www.csfd.cz/film/258-zabriskie-point/" imdbLink=""/>
                    <MovieComponent moviePosition="521" isSeen={false} movieName="L´ Uccello dalle piume di cristallo (1970)" csfdLink="http://www.csfd.cz/film/313-bird-with-the-crystal-plumage-the-uccello-dalle-piume-di-cristallo-l/" imdbLink=""/>
                    <MovieComponent moviePosition="522" isSeen={false} movieName="Il Giardino dei Finzi-Contini (1970)" csfdLink="http://www.csfd.cz/film/33700-giardino-dei-finzicontini-il/" imdbLink=""/>
                    <MovieComponent moviePosition="523" isSeen={false} movieName="Wanda (1971)" csfdLink="http://www.csfd.cz/film/239189-wanda/" imdbLink=""/>
                    <MovieComponent moviePosition="524" isSeen={true} movieName="W.R. &#8211; Misterije organizma (1971)" csfdLink="http://www.csfd.cz/film/166579-wr-misterije-organizma/" imdbLink=""/>
                    <MovieComponent moviePosition="525" isSeen={true} movieName="A Clockwork Orange (1971)" csfdLink="http://www.csfd.cz/film/5395-clockwork-orange-a/" imdbLink=""/>
                    <MovieComponent moviePosition="526" isSeen={false} movieName="Le Chagrin et la pitié (1971)" csfdLink="http://www.csfd.cz/film/203412-chagrin-et-la-pitie-le/" imdbLink=""/>
                    <MovieComponent moviePosition="527" isSeen={false} movieName="Willy Wonka and the Chocolate Factory (1971)" csfdLink="http://www.csfd.cz/film/27148-pan-wonka-a-jeho-cokoladovna-willy-wonka-and-the-chocolate-factory/" imdbLink=""/>
                    <MovieComponent moviePosition="528" isSeen={false} movieName="McCabe and Mrs. Miller (1971)" csfdLink="http://www.csfd.cz/film/119-mccabe-and-mrs-miller/" imdbLink=""/>
                    <MovieComponent moviePosition="529" isSeen={false} movieName="Walkabout (1971)" csfdLink="http://www.csfd.cz/film/85214-walkabout/" imdbLink=""/>
                    <MovieComponent moviePosition="530" isSeen={false} movieName="Klute (1971)" csfdLink="http://www.csfd.cz/film/7088-klute/" imdbLink=""/>
                    <MovieComponent moviePosition="531" isSeen={true} movieName="Harold and Maude (1971)" csfdLink="http://www.csfd.cz/film/16019-harold-a-maude-harold-and-maude/" imdbLink=""/>
                    <MovieComponent moviePosition="532" isSeen={false} movieName="Még kér a nép (1971)" csfdLink="http://www.csfd.cz/film/29314-meg-ker-a-nep/" imdbLink=""/>
                    <MovieComponent moviePosition="533" isSeen={false} movieName="Get Carter (1971)" csfdLink="http://www.csfd.cz/film/23541-get-carter/" imdbLink=""/>
                    <MovieComponent moviePosition="534" isSeen={false} movieName="The French Connection (1971)" csfdLink="http://www.csfd.cz/film/3191-french-connection-the/" imdbLink=""/>
                    <MovieComponent moviePosition="535" isSeen={false} movieName="Shaft (1971)" csfdLink="http://www.csfd.cz/film/29932-shaft/" imdbLink=""/>
                    <MovieComponent moviePosition="536" isSeen={false} movieName="Dirty Harry (1971)" csfdLink="http://www.csfd.cz/film/8378-dirty-harry/" imdbLink=""/>
                    <MovieComponent moviePosition="537" isSeen={false} movieName="Le Souffle au coeur (1971)" csfdLink="http://www.csfd.cz/film/6365-souffle-au-coeur-le/" imdbLink=""/>
                    <MovieComponent moviePosition="538" isSeen={false} movieName="Sweet Sweetback&#8217;s Baadassss Song (1971)" csfdLink="http://www.csfd.cz/film/32982-sweet-sweetbacks-baad-asssss-song/" imdbLink=""/>
                    <MovieComponent moviePosition="539" isSeen={false} movieName="The Last Picture Show (1971)" csfdLink="http://www.csfd.cz/film/839-last-picture-show-the/" imdbLink=""/>
                    <MovieComponent moviePosition="540" isSeen={true} movieName="Straw Dogs (1971)" csfdLink="http://www.csfd.cz/film/7180-straw-dogs/" imdbLink=""/>
                    <MovieComponent moviePosition="541" isSeen={true} movieName="Two-Lane Blacktop (1971)" csfdLink="http://www.csfd.cz/film/16143-twolane-blacktop/" imdbLink=""/>
                    <MovieComponent moviePosition="542" isSeen={false} movieName="The Heartbreak Kid (1972)" csfdLink="http://www.csfd.cz/film/19744-heartbreak-kid-the/" imdbLink=""/>
                    <MovieComponent moviePosition="543" isSeen={false} movieName="Aguirre, der Zorn Gottes (1972)" csfdLink="http://www.csfd.cz/film/4260-aguirre-hnev-bozi-aguirre-der-zorn-gottes/" imdbLink=""/>
                    <MovieComponent moviePosition="544" isSeen={false} movieName="Cabaret (1972)" csfdLink="http://www.csfd.cz/film/2989-cabaret/" imdbLink=""/>
                    <MovieComponent moviePosition="545" isSeen={true} movieName="Ultimo tango a Parigi (1972)" csfdLink="http://www.csfd.cz/film/766-last-tango-in-paris-ultimo-tango-a-parigi/" imdbLink=""/>
                    <MovieComponent moviePosition="546" isSeen={false} movieName="High Plains Drifter (1972)" csfdLink="http://www.csfd.cz/film/2504-high-plains-drifter/" imdbLink=""/>
                    <MovieComponent moviePosition="547" isSeen={false} movieName="Sleuth (1972)" csfdLink="http://www.csfd.cz/film/6400-sleuth/" imdbLink=""/>
                    <MovieComponent moviePosition="548" isSeen={false} movieName="Deliverance (1972)" csfdLink="http://www.csfd.cz/film/857-deliverance/" imdbLink=""/>
                    <MovieComponent moviePosition="549" isSeen={true} movieName="Solyaris (1972)" csfdLink="http://www.csfd.cz/film/32352-solaris-solyaris/" imdbLink=""/>
                    <MovieComponent moviePosition="550" isSeen={true} movieName="The Godfather (1972)" csfdLink="http://www.csfd.cz/film/1644-godfather-the/" imdbLink=""/>
                    <MovieComponent moviePosition="551" isSeen={false} movieName="Viskningar och rop (1972)" csfdLink="http://www.csfd.cz/film/740-viskningar-och-rop/" imdbLink=""/>
                    <MovieComponent moviePosition="552" isSeen={false} movieName="Fat City (1972)" csfdLink="http://www.csfd.cz/film/4590-fat-city/" imdbLink=""/>
                    <MovieComponent moviePosition="553" isSeen={true} movieName="Le Charme discret de la bourgeoisie (1972)" csfdLink="http://www.csfd.cz/film/1092-discreet-charm-of-the-bourgeoisie-the-charme-discret-de-la-bourgeoisie-le/" imdbLink=""/>
                    <MovieComponent moviePosition="554" isSeen={false} movieName="Die Bitteren Tränen der Petra von Kant (1972)" csfdLink="http://www.csfd.cz/film/41531-bitteren-tranen-der-petra-von-kant-die/" imdbLink=""/>
                    <MovieComponent moviePosition="555" isSeen={false} movieName="Frenzy (1972)" csfdLink="http://www.csfd.cz/film/4364-frenzy/" imdbLink=""/>
                    <MovieComponent moviePosition="556" isSeen={false} movieName="Pink Flamingos (1972)" csfdLink="http://www.csfd.cz/film/9547-pink-flamingos/" imdbLink=""/>
                    <MovieComponent moviePosition="557" isSeen={false} movieName="Superfly (1972)" csfdLink="http://www.csfd.cz/film/195405-superfly/" imdbLink=""/>
                    <MovieComponent moviePosition="558" isSeen={true} movieName="The Sting (1973)" csfdLink="http://www.csfd.cz/film/15046-sting-the/" imdbLink=""/>
                    <MovieComponent moviePosition="559" isSeen={false} movieName="La Maman et la putain (1973)" csfdLink="http://www.csfd.cz/film/129555-maman-et-la-putain-la/" imdbLink=""/>
                    <MovieComponent moviePosition="560" isSeen={false} movieName="Badlands (1973)" csfdLink="http://www.csfd.cz/film/6339-badlands/" imdbLink=""/>
                    <MovieComponent moviePosition="561" isSeen={true} movieName="American Graffiti (1973)" csfdLink="http://www.csfd.cz/film/6162-american-graffiti/" imdbLink=""/>
                    <MovieComponent moviePosition="562" isSeen={false} movieName="Papillon (1973)" csfdLink="http://www.csfd.cz/film/20008-papillon/" imdbLink=""/>
                    <MovieComponent moviePosition="563" isSeen={true} movieName="Enter the Dragon (1973)" csfdLink="http://www.csfd.cz/film/25031-enter-the-dragon/" imdbLink=""/>
                    <MovieComponent moviePosition="564" isSeen={false} movieName="Mean Streets (1973)" csfdLink="http://www.csfd.cz/film/8254-mean-streets/" imdbLink=""/>
                    <MovieComponent moviePosition="565" isSeen={false} movieName="The Long Goodbye (1973)" csfdLink="http://www.csfd.cz/film/117-long-goodbye-the/" imdbLink=""/>
                    <MovieComponent moviePosition="566" isSeen={true} movieName="The Wicker Man (1973)" csfdLink="http://www.csfd.cz/film/14276-wicker-man-the/" imdbLink=""/>
                    <MovieComponent moviePosition="567" isSeen={false} movieName="La Nuit américaine (1973)" csfdLink="http://www.csfd.cz/film/9090-day-for-night-nuit-americaine-la/" imdbLink=""/>
                    <MovieComponent moviePosition="568" isSeen={false} movieName="Don&#8217;t Look Now (1973)" csfdLink="http://www.csfd.cz/film/21439-dont-look-now/" imdbLink=""/>
                    <MovieComponent moviePosition="569" isSeen={true} movieName="Sleeper (1973)" csfdLink="http://www.csfd.cz/film/53-sleeper/" imdbLink=""/>
                    <MovieComponent moviePosition="570" isSeen={false} movieName="Serpico (1973)" csfdLink="http://www.csfd.cz/film/6220-serpico/" imdbLink=""/>
                    <MovieComponent moviePosition="571" isSeen={true} movieName="The Exorcist (1973)" csfdLink="http://www.csfd.cz/film/3190-exorcist-the/" imdbLink=""/>
                    <MovieComponent moviePosition="572" isSeen={false} movieName="Turks fruit (1973)" csfdLink="http://www.csfd.cz/film/9304-turks-fruit/" imdbLink=""/>
                    <MovieComponent moviePosition="573" isSeen={false} movieName="El Espíritu de la colmena (1973)" csfdLink="http://www.csfd.cz/film/91695-espiritu-de-la-colmena-el/" imdbLink=""/>
                    <MovieComponent moviePosition="574" isSeen={true} movieName="La Planete sauvage (1973)" csfdLink="http://www.csfd.cz/film/88180-fantastic-planet-the-plancte-sauvage-la/" imdbLink=""/>
                    <MovieComponent moviePosition="575" isSeen={false} movieName="Amarcord (1973)" csfdLink="http://www.csfd.cz/film/2597-amarcord/" imdbLink=""/>
                    <MovieComponent moviePosition="576" isSeen={false} movieName="The Harder They Come (1973)" csfdLink="http://www.csfd.cz/film/198207-harder-they-come-the/" imdbLink=""/>
                    <MovieComponent moviePosition="577" isSeen={false} movieName="Pat Garret and Billy the Kid (1973)" csfdLink="http://www.csfd.cz/film/7178-pat-garrett-a-billy-the-kid-pat-garrett-and-billy-the-kid/" imdbLink=""/>
                    <MovieComponent moviePosition="578" isSeen={true} movieName="Dersu Uzala (1974)" csfdLink="http://www.csfd.cz/film/5420-dersu-uzala/" imdbLink=""/>
                    <MovieComponent moviePosition="579" isSeen={false} movieName="The Conversation (1974)" csfdLink="http://www.csfd.cz/film/1636-conversation-the/" imdbLink=""/>
                    <MovieComponent moviePosition="580" isSeen={true} movieName="The Texas Chainsaw Massacre (1974)" csfdLink="http://www.csfd.cz/film/4501-texas-chain-saw-massacre-the/" imdbLink=""/>
                    <MovieComponent moviePosition="581" isSeen={false} movieName="Zerkalo (1974)" csfdLink="http://www.csfd.cz/film/32356-zerkalo/" imdbLink=""/>
                    <MovieComponent moviePosition="582" isSeen={false} movieName="A Woman under the Influence (1974)" csfdLink="http://www.csfd.cz/film/24100-woman-under-the-influence-a/" imdbLink=""/>
                    <MovieComponent moviePosition="583" isSeen={true} movieName="Young Frankenstein (1974)" csfdLink="http://www.csfd.cz/film/17218-young-frankenstein/" imdbLink=""/>
                    <MovieComponent moviePosition="584" isSeen={false} movieName="Chinatown (1974)" csfdLink="http://www.csfd.cz/film/7341-chinatown/" imdbLink=""/>
                    <MovieComponent moviePosition="585" isSeen={false} movieName="Céline et Julie vont en bateau (1974)" csfdLink="http://www.csfd.cz/film/37450-celine-et-julie-vont-en-bateau/" imdbLink=""/>
                    <MovieComponent moviePosition="586" isSeen={true} movieName="Blazing Saddles (1974)" csfdLink="http://www.csfd.cz/film/18811-blazing-saddles/" imdbLink=""/>
                    <MovieComponent moviePosition="587" isSeen={true} movieName="The Godfather Part II (1974)" csfdLink="http://www.csfd.cz/film/1645-godfather-part-ii-the/" imdbLink=""/>
                    <MovieComponent moviePosition="588" isSeen={false} movieName="Angst essen Seele auf (1974)" csfdLink="http://www.csfd.cz/film/88419-fear-eats-the-soul-angst-essen-seele-auf/" imdbLink=""/>
                    <MovieComponent moviePosition="589" isSeen={false} movieName="Bring Me the Head of Alfredo Garcia (1974)" csfdLink="http://www.csfd.cz/film/7168-bring-me-the-head-of-alfredo-garcia/" imdbLink=""/>
                    <MovieComponent moviePosition="590" isSeen={true} movieName="Dog Day Afternoon (1975)" csfdLink="http://www.csfd.cz/film/6190-dog-day-afternoon/" imdbLink=""/>
                    <MovieComponent moviePosition="591" isSeen={true} movieName="One Flew over the Cuckoo&#8217;s Nest (1975)" csfdLink="http://www.csfd.cz/film/2982-one-flew-over-the-cuckoos-nest/" imdbLink=""/>
                    <MovieComponent moviePosition="592" isSeen={false} movieName="Jeanne Dielman, 23 Quai Du Commerce, 1080 Bruxelles (1975)" csfdLink="http://www.csfd.cz/film/48833-jeanne-dielman-23-quai-du-commerce-1080-bruxelles/" imdbLink=""/>
                    <MovieComponent moviePosition="593" isSeen={true} movieName="The Rocky Horror Picture Show (1975)" csfdLink="http://www.csfd.cz/film/11747-rocky-horror-picture-show-rocky-horror-picture-show-the/" imdbLink=""/>
                    <MovieComponent moviePosition="594" isSeen={false} movieName="Zidul (1975)" csfdLink="http://www.csfd.cz/film/244686-zidul/" imdbLink=""/>
                    <MovieComponent moviePosition="595" isSeen={true} movieName="Monty Python and the Holy Grail (1975)" csfdLink="http://www.csfd.cz/film/3308-monty-python-and-the-holy-grail/" imdbLink=""/>
                    <MovieComponent moviePosition="596" isSeen={false} movieName="Barry Lyndon (1975)" csfdLink="http://www.csfd.cz/film/5394-barry-lyndon/" imdbLink=""/>
                    <MovieComponent moviePosition="597" isSeen={false} movieName="Faustrecht der Freiheit (1975)" csfdLink="http://www.csfd.cz/film/88425-faustrecht-der-freiheit/" imdbLink=""/>
                    <MovieComponent moviePosition="598" isSeen={false} movieName="India Song (1975)" csfdLink="http://www.csfd.cz/film/128350-india-song/" imdbLink=""/>
                    <MovieComponent moviePosition="599" isSeen={true} movieName="Picnic at Hanging Rock (1975)" csfdLink="http://www.csfd.cz/film/9566-piknik-na-hanging-rock-picnic-at-hanging-rock/" imdbLink=""/>
                    <MovieComponent moviePosition="600" isSeen={false} movieName="Maynila: Sa mga kuko ng liwanag (1975)" csfdLink="http://www.csfd.cz/film/250109-maynila-sa-mga-kuko-ng-liwanag/" imdbLink=""/>
                    <MovieComponent moviePosition="601" isSeen={true} movieName="Salo o le 120 giornate di Sodoma (1975)" csfdLink="http://www.csfd.cz/film/7142-salo-aneb-120-dnu-sodomy-sal-and-242-o-le-120-giornate-di-sodoma/" imdbLink=""/>
                    <MovieComponent moviePosition="602" isSeen={false} movieName="Nashville (1975)" csfdLink="http://www.csfd.cz/film/122-nashville/" imdbLink=""/>
                    <MovieComponent moviePosition="603" isSeen={false} movieName="Cría cuervos (1975)" csfdLink="http://www.csfd.cz/film/22014-cria-cuervos/" imdbLink=""/>
                    <MovieComponent moviePosition="604" isSeen={false} movieName="O Thiasos (1975)" csfdLink="http://www.csfd.cz/film/36873-putovni-divadlo-thiassos-o/" imdbLink=""/>
                    <MovieComponent moviePosition="605" isSeen={true} movieName="Jaws (1975)" csfdLink="http://www.csfd.cz/film/8643-jaws/" imdbLink=""/>
                    <MovieComponent moviePosition="606" isSeen={false} movieName="The Killing of a Chinese Bookie (1976)" csfdLink="http://www.csfd.cz/film/69602-killing-of-a-chinese-bookie-the/" imdbLink=""/>
                    <MovieComponent moviePosition="607" isSeen={true} movieName="Carrie (1976)" csfdLink="http://www.csfd.cz/film/2316-carrie/" imdbLink=""/>
                    <MovieComponent moviePosition="608" isSeen={false} movieName="The Outlaw Josey Wales (1976)" csfdLink="http://www.csfd.cz/film/2507-outlaw-josey-wales-the/" imdbLink=""/>
                    <MovieComponent moviePosition="609" isSeen={true} movieName="All the President&#8217;s Men (1976)" csfdLink="http://www.csfd.cz/film/7083-all-the-presidents-men/" imdbLink=""/>
                    <MovieComponent moviePosition="610" isSeen={true} movieName="Rocky (1976)" csfdLink="http://www.csfd.cz/film/16103-rocky/" imdbLink=""/>
                    <MovieComponent moviePosition="611" isSeen={false} movieName="Taxi Driver (1976)" csfdLink="http://www.csfd.cz/film/8260-taxi-driver/" imdbLink=""/>
                    <MovieComponent moviePosition="612" isSeen={false} movieName="Network (1976)" csfdLink="http://www.csfd.cz/film/6210-network/" imdbLink=""/>
                    <MovieComponent moviePosition="613" isSeen={true} movieName="Voskhozhdeniye (1976)" csfdLink="http://www.csfd.cz/film/83301-voskhozhdeniye/" imdbLink=""/>
                    <MovieComponent moviePosition="614" isSeen={true} movieName="Ai no corrida (1976)" csfdLink="http://www.csfd.cz/film/37315-ai-no-corrida/" imdbLink=""/>
                    <MovieComponent moviePosition="615" isSeen={false} movieName="Novecento (1976)" csfdLink="http://www.csfd.cz/film/746-novecento/" imdbLink=""/>
                    <MovieComponent moviePosition="616" isSeen={false} movieName="The Man Who Fell to Earth (1976)" csfdLink="http://www.csfd.cz/film/10933-man-who-fell-to-earth-the/" imdbLink=""/>
                    <MovieComponent moviePosition="617" isSeen={true} movieName="Star Wars (1977)" csfdLink="http://www.csfd.cz/film/6169-star-wars-epizoda-iv-nova-nadeje-hvezdne-valky-star-wars/" imdbLink=""/>
                    <MovieComponent moviePosition="618" isSeen={true} movieName="Close Encounters of the Third Kind (1977)" csfdLink="http://www.csfd.cz/film/8631-close-encounters-of-the-third-kind/" imdbLink=""/>
                    <MovieComponent moviePosition="619" isSeen={false} movieName="The Last Wave (1977)" csfdLink="http://www.csfd.cz/film/9564-last-wave-the/" imdbLink=""/>
                    <MovieComponent moviePosition="620" isSeen={true} movieName="Annie Hall (1977)" csfdLink="http://www.csfd.cz/film/26-annie-hall/" imdbLink=""/>
                    <MovieComponent moviePosition="621" isSeen={false} movieName="Last Chants for a Slow Dance (1977)" csfdLink="http://www.csfd.cz/film/210425-last-chants-for-a-slow-dance/" imdbLink=""/>
                    <MovieComponent moviePosition="622" isSeen={true} movieName="Stroszek (1977)" csfdLink="http://www.csfd.cz/film/4299-stroszek/" imdbLink=""/>
                    <MovieComponent moviePosition="623" isSeen={false} movieName="Czlowiek z marmuru (1977)" csfdLink="http://www.csfd.cz/film/35203-czlowiek-z-marmuru/" imdbLink=""/>
                    <MovieComponent moviePosition="624" isSeen={false} movieName="Saturday Night Fever (1977)" csfdLink="http://www.csfd.cz/film/386-saturday-night-fever/" imdbLink=""/>
                    <MovieComponent moviePosition="625" isSeen={false} movieName="Killer of Sheep (1977)" csfdLink="http://www.csfd.cz/film/41700-killer-of-sheep/" imdbLink=""/>
                    <MovieComponent moviePosition="626" isSeen={true} movieName="Eraserhead (1977)" csfdLink="http://www.csfd.cz/film/6252-eraserhead/" imdbLink=""/>
                    <MovieComponent moviePosition="627" isSeen={false} movieName="Ceddo (1977)" csfdLink="http://www.csfd.cz/film/199183-ceddo/" imdbLink=""/>
                    <MovieComponent moviePosition="628" isSeen={false} movieName="Der Amerikanische Freund (1977)" csfdLink="http://www.csfd.cz/film/13374-amerikanische-freund-der/" imdbLink=""/>
                    <MovieComponent moviePosition="629" isSeen={true} movieName="The Hills Have Eyes (1977)" csfdLink="http://www.csfd.cz/film/1755-hills-have-eyes-the/" imdbLink=""/>
                    <MovieComponent moviePosition="630" isSeen={false} movieName="Soldaat van Oranje (1977)" csfdLink="http://www.csfd.cz/film/9300-soldaat-van-oranje/" imdbLink=""/>
                    <MovieComponent moviePosition="631" isSeen={false} movieName="Suspiria (1977)" csfdLink="http://www.csfd.cz/film/309-suspiria/" imdbLink=""/>
                    <MovieComponent moviePosition="632" isSeen={false} movieName="The Chant of Jimmie Blacksmith (1978)" csfdLink="http://www.csfd.cz/film/18824-chant-of-jimmie-blacksmith-the/" imdbLink=""/>
                    <MovieComponent moviePosition="633" isSeen={false} movieName="Wu du (1978)" csfdLink="http://www.csfd.cz/film/128513-five-deadly-venoms-wu-du/" imdbLink=""/>
                    <MovieComponent moviePosition="634" isSeen={false} movieName="L´ Albero degli zoccoli (1978)" csfdLink="http://www.csfd.cz/film/129224-tree-with-the-wooden-clogs-the-albero-degli-zoccoli-l/" imdbLink=""/>
                    <MovieComponent moviePosition="635" isSeen={true} movieName="The Deer Hunter (1978)" csfdLink="http://www.csfd.cz/film/1595-deer-hunter-the/" imdbLink=""/>
                    <MovieComponent moviePosition="636" isSeen={true} movieName="Grease (1978)" csfdLink="http://www.csfd.cz/film/16442-grease/" imdbLink=""/>
                    <MovieComponent moviePosition="637" isSeen={false} movieName="Days of Heaven (1978)" csfdLink="http://www.csfd.cz/film/6340-days-of-heaven/" imdbLink=""/>
                    <MovieComponent moviePosition="638" isSeen={true} movieName="Dawn of the Dead (1978)" csfdLink="http://www.csfd.cz/film/7804-dawn-of-the-dead/" imdbLink=""/>
                    <MovieComponent moviePosition="639" isSeen={false} movieName="Shao Lin san shi liu fang (1978)" csfdLink="http://www.csfd.cz/film/109157-shao-lin-san-shi-liu-fang/" imdbLink=""/>
                    <MovieComponent moviePosition="640" isSeen={false} movieName="Up in Smoke (1978)" csfdLink="http://www.csfd.cz/film/14598-up-in-smoke/" imdbLink=""/>
                    <MovieComponent moviePosition="641" isSeen={true} movieName="Halloween (1978)" csfdLink="http://www.csfd.cz/film/1322-halloween/" imdbLink=""/>
                    <MovieComponent moviePosition="642" isSeen={false} movieName="Die Ehe der Maria Braun (1979)" csfdLink="http://www.csfd.cz/film/41537-ehe-der-maria-braun-die/" imdbLink=""/>
                    <MovieComponent moviePosition="643" isSeen={false} movieName="Real Life (1979)" csfdLink="http://www.csfd.cz/film/19752-real-life/" imdbLink=""/>
                    <MovieComponent moviePosition="644" isSeen={false} movieName="My Brilliant Career (1979)" csfdLink="http://www.csfd.cz/film/20561-my-brilliant-career/" imdbLink=""/>
                    <MovieComponent moviePosition="645" isSeen={true} movieName="Stalker (1979)" csfdLink="http://www.csfd.cz/film/32353-stalker/" imdbLink=""/>
                    <MovieComponent moviePosition="646" isSeen={true} movieName="Alien (1979)" csfdLink="http://www.csfd.cz/film/8265-alien/" imdbLink=""/>
                    <MovieComponent moviePosition="647" isSeen={false} movieName="Breaking Away (1979)" csfdLink="http://www.csfd.cz/film/20793-breaking-away/" imdbLink=""/>
                    <MovieComponent moviePosition="648" isSeen={true} movieName="Die Blechtrommel (1979)" csfdLink="http://www.csfd.cz/film/8094-tin-drum-blechtrommel-die/" imdbLink=""/>
                    <MovieComponent moviePosition="649" isSeen={false} movieName="All That Jazz (1979)" csfdLink="http://www.csfd.cz/film/2988-all-that-jazz/" imdbLink=""/>
                    <MovieComponent moviePosition="650" isSeen={true} movieName="Being There (1979)" csfdLink="http://www.csfd.cz/film/21144-being-there/" imdbLink=""/>
                    <MovieComponent moviePosition="651" isSeen={true} movieName="Kramer vs. Kramer (1979)" csfdLink="http://www.csfd.cz/film/646-kramer-vs-kramer/" imdbLink=""/>
                    <MovieComponent moviePosition="652" isSeen={true} movieName="Life of Brian (1979)" csfdLink="http://www.csfd.cz/film/23587-life-of-brian/" imdbLink=""/>
                    <MovieComponent moviePosition="653" isSeen={true} movieName="Apocalypse Now (1979)" csfdLink="http://www.csfd.cz/film/1634-apocalypse-now/" imdbLink=""/>
                    <MovieComponent moviePosition="654" isSeen={false} movieName="The Jerk (1979)" csfdLink="http://www.csfd.cz/film/7610-jerk-the/" imdbLink=""/>
                    <MovieComponent moviePosition="655" isSeen={false} movieName="The Muppet Movie (1979)" csfdLink="http://www.csfd.cz/film/15494-muppet-movie-the/" imdbLink=""/>
                    <MovieComponent moviePosition="656" isSeen={true} movieName="Manhattan (1979)" csfdLink="http://www.csfd.cz/film/43-manhattan/" imdbLink=""/>
                    <MovieComponent moviePosition="657" isSeen={false} movieName="Mad Max (1979)" csfdLink="http://www.csfd.cz/film/6722-mad-max-sileny-max-mad-max/" imdbLink=""/>
                    <MovieComponent moviePosition="658" isSeen={false} movieName="Nosferatu: Phantom der Nacht (1979)" csfdLink="http://www.csfd.cz/film/4295-nosferatu-phantom-der-nacht/" imdbLink=""/>
                    <MovieComponent moviePosition="659" isSeen={false} movieName="Ordinary People (1980)" csfdLink="http://www.csfd.cz/film/7601-ordinary-people/" imdbLink=""/>
                    <MovieComponent moviePosition="660" isSeen={false} movieName="Atlantic City (1980)" csfdLink="http://www.csfd.cz/film/6347-atlantic-city/" imdbLink=""/>
                    <MovieComponent moviePosition="661" isSeen={false} movieName="Le Dernier métro (1980)" csfdLink="http://www.csfd.cz/film/9079-last-metro-the-dernier-metro-le/" imdbLink=""/>
                    <MovieComponent moviePosition="662" isSeen={true} movieName="The Shining (1980)" csfdLink="http://www.csfd.cz/film/5407-shining-the/" imdbLink=""/>
                    <MovieComponent moviePosition="663" isSeen={false} movieName="Star Wars: Episode V &#8211; The Empire Strikes Back (1980)" csfdLink="http://www.csfd.cz/film/5238-star-wars-epizoda-v-imperium-vraci-uder-star-wars-episode-v-the-empire-dels-back/" imdbLink=""/>
                    <MovieComponent moviePosition="664" isSeen={true} movieName="The Elephant Man (1980)" csfdLink="http://www.csfd.cz/film/6251-elephant-man-the/" imdbLink=""/>
                    <MovieComponent moviePosition="665" isSeen={false} movieName="The Big Red One (1980)" csfdLink="http://www.csfd.cz/film/19807-big-red-one-the/" imdbLink=""/>
                    <MovieComponent moviePosition="666" isSeen={false} movieName="Loulou (1980)" csfdLink="http://www.csfd.cz/film/12060-loulou/" imdbLink=""/>
                    <MovieComponent moviePosition="667" isSeen={true} movieName="Airplane! (1980)" csfdLink="http://www.csfd.cz/film/2-airplane/" imdbLink=""/>
                    <MovieComponent moviePosition="668" isSeen={false} movieName="Raging Bull (1980)" csfdLink="http://www.csfd.cz/film/8258-raging-bull/" imdbLink=""/>
                    <MovieComponent moviePosition="669" isSeen={true} movieName="Raiders of the Lost Ark (1981)" csfdLink="http://www.csfd.cz/film/8650-raiders-of-the-lost-ark/" imdbLink=""/>
                    <MovieComponent moviePosition="670" isSeen={false} movieName="Das Boot (1981)" csfdLink="http://www.csfd.cz/film/7222-boot-das/" imdbLink=""/>
                    <MovieComponent moviePosition="671" isSeen={true} movieName="Gallipoli (1981)" csfdLink="http://www.csfd.cz/film/9561-gallipoli/" imdbLink=""/>
                    <MovieComponent moviePosition="672" isSeen={false} movieName="Chariots of Fire (1981)" csfdLink="http://www.csfd.cz/film/12881-chariots-of-fire/" imdbLink=""/>
                    <MovieComponent moviePosition="673" isSeen={false} movieName="Body Heat (1981)" csfdLink="http://www.csfd.cz/film/19178-body-heat/" imdbLink=""/>
                    <MovieComponent moviePosition="674" isSeen={false} movieName="Reds (1981)" csfdLink="http://www.csfd.cz/film/551-reds/" imdbLink=""/>
                    <MovieComponent moviePosition="675" isSeen={true} movieName="An American Werewolf in London (1981)" csfdLink="http://www.csfd.cz/film/5570-american-werewolf-in-london-an/" imdbLink=""/>
                    <MovieComponent moviePosition="676" isSeen={false} movieName="Tre fratelli (1981)" csfdLink="http://www.csfd.cz/film/32043-tre-fratelli/" imdbLink=""/>
                    <MovieComponent moviePosition="677" isSeen={false} movieName="Czlowiek z zelaza (1981)" csfdLink="http://www.csfd.cz/film/33147-czlowiek-z-zelaza/" imdbLink=""/>
                    <MovieComponent moviePosition="678" isSeen={false} movieName="Trop tot, trop tard (1981)" csfdLink="http://www.csfd.cz/film/173770-trop-tot-trop-tard/" imdbLink=""/>
                    <MovieComponent moviePosition="679" isSeen={true} movieName="Fast Times at Ridgemont High (1981)" csfdLink="http://www.csfd.cz/film/11221-fast-times-at-ridgemont-high/" imdbLink=""/>
                    <MovieComponent moviePosition="680" isSeen={false} movieName="E.T. The Extra-Terestrial (1982)" csfdLink="http://www.csfd.cz/film/8635-et-mimozemstan-et-the-extraterrestrial/" imdbLink=""/>
                    <MovieComponent moviePosition="681" isSeen={true} movieName="The Thing (1982)" csfdLink="http://www.csfd.cz/film/1329-thing-the/" imdbLink=""/>
                    <MovieComponent moviePosition="682" isSeen={true} movieName="Poltergeist (1982)" csfdLink="http://www.csfd.cz/film/4498-poltergeist/" imdbLink=""/>
                    <MovieComponent moviePosition="683" isSeen={true} movieName="Blade Runner (1982)" csfdLink="http://www.csfd.cz/film/8268-blade-runner/" imdbLink=""/>
                    <MovieComponent moviePosition="684" isSeen={true} movieName="The Evil Dead (1982)" csfdLink="http://www.csfd.cz/film/7557-evil-dead-the/" imdbLink=""/>
                    <MovieComponent moviePosition="685" isSeen={true} movieName="Tootsie (1982)" csfdLink="http://www.csfd.cz/film/7384-tootsie/" imdbLink=""/>
                    <MovieComponent moviePosition="686" isSeen={false} movieName="Yol (1982)" csfdLink="http://www.csfd.cz/film/121969-yol/" imdbLink=""/>
                    <MovieComponent moviePosition="687" isSeen={false} movieName="Diner (1982)" csfdLink="http://www.csfd.cz/film/5945-diner/" imdbLink=""/>
                    <MovieComponent moviePosition="688" isSeen={false} movieName="Fitzcarraldo (1982)" csfdLink="http://www.csfd.cz/film/4270-fitzcarraldo/" imdbLink=""/>
                    <MovieComponent moviePosition="689" isSeen={false} movieName="Gandhi (1982)" csfdLink="http://www.csfd.cz/film/350-gandhi/" imdbLink=""/>
                    <MovieComponent moviePosition="690" isSeen={false} movieName="La Notte di San Lorenzo (1982)" csfdLink="http://www.csfd.cz/film/42600-notte-di-san-lorenzo-la/" imdbLink=""/>
                    <MovieComponent moviePosition="691" isSeen={false} movieName="De Stilte rond Christine M. (1982)" csfdLink="http://www.csfd.cz/film/33727-stilte-rond-christine-m-de/" imdbLink=""/>
                    <MovieComponent moviePosition="692" isSeen={false} movieName="Fanny och Alexander (1982)" csfdLink="http://www.csfd.cz/film/692-fanny-och-alexander/" imdbLink=""/>
                    <MovieComponent moviePosition="693" isSeen={false} movieName="A Christmas Story (1983)" csfdLink="http://www.csfd.cz/film/41778-christmas-story-a/" imdbLink=""/>
                    <MovieComponent moviePosition="694" isSeen={false} movieName="El Norte (1983)" csfdLink="http://www.csfd.cz/film/87575-el-norte/" imdbLink=""/>
                    <MovieComponent moviePosition="695" isSeen={true} movieName="Videodrome (1983)" csfdLink="http://www.csfd.cz/film/1806-videodrome/" imdbLink=""/>
                    <MovieComponent moviePosition="696" isSeen={false} movieName="Star Wars: Episode VI &#8211; Return of the Jedi (1983)" csfdLink="http://www.csfd.cz/film/6474-star-wars-epizoda-vi-navrat-jediho-star-wars-episode-vi-return-of-the-jedi/" imdbLink=""/>
                    <MovieComponent moviePosition="697" isSeen={false} movieName="The Big Chill (1983)" csfdLink="http://www.csfd.cz/film/10773-big-chill-the/" imdbLink=""/>
                    <MovieComponent moviePosition="698" isSeen={false} movieName="Sans Soleil (1983)" csfdLink="http://www.csfd.cz/film/132634-sans-soleil/" imdbLink=""/>
                    <MovieComponent moviePosition="699" isSeen={false} movieName="Le Dernier combat (1983)" csfdLink="http://www.csfd.cz/film/770-dernier-combat-le/" imdbLink=""/>
                    <MovieComponent moviePosition="700" isSeen={false} movieName="L&#8217;Argent (1983)" csfdLink="http://www.csfd.cz/film/35290-argent-l/" imdbLink=""/>
                    <MovieComponent moviePosition="701" isSeen={false} movieName="Utu (1983)" csfdLink="http://www.csfd.cz/film/16617-utu/" imdbLink=""/>
                    <MovieComponent moviePosition="702" isSeen={false} movieName="Terms of Endearment (1983)" csfdLink="http://www.csfd.cz/film/947-terms-of-endearment/" imdbLink=""/>
                    <MovieComponent moviePosition="703" isSeen={false} movieName="De Vierde man (1983)" csfdLink="http://www.csfd.cz/film/9305-fourth-man-the-vierde-man-de/" imdbLink=""/>
                    <MovieComponent moviePosition="704" isSeen={false} movieName="The King of Comedy (1983)" csfdLink="http://www.csfd.cz/film/8249-king-of-comedy-the/" imdbLink=""/>
                    <MovieComponent moviePosition="705" isSeen={false} movieName="The Right Stuff (1983)" csfdLink="http://www.csfd.cz/film/5024-right-stuff-the/" imdbLink=""/>
                    <MovieComponent moviePosition="706" isSeen={true} movieName="Koyaanisqatsi (1983)" csfdLink="http://www.csfd.cz/film/37440-koyaanisqatsi/" imdbLink=""/>
                    <MovieComponent moviePosition="707" isSeen={false} movieName="Once Upon a Time in America (1983)" csfdLink="http://www.csfd.cz/film/5914-once-upon-a-time-in-america/" imdbLink=""/>
                    <MovieComponent moviePosition="708" isSeen={false} movieName="Scarface (1983)" csfdLink="http://www.csfd.cz/film/2335-scarface/" imdbLink=""/>
                    <MovieComponent moviePosition="709" isSeen={true} movieName="The Battle of Narayama (1983)" csfdLink="http://www.csfd.cz/film/113086-narayama-bushiko/" imdbLink=""/>
                    <MovieComponent moviePosition="710" isSeen={true} movieName="Amadeus (1984)" csfdLink="http://www.csfd.cz/film/2971-amadeus/" imdbLink=""/>
                    <MovieComponent moviePosition="711" isSeen={true} movieName="The Terminator (1984)" csfdLink="http://www.csfd.cz/film/1249-terminator-terminator-the/" imdbLink=""/>
                    <MovieComponent moviePosition="712" isSeen={false} movieName="Paris, Texas (1984)" csfdLink="http://www.csfd.cz/film/15945-paris-texas/" imdbLink=""/>
                    <MovieComponent moviePosition="713" isSeen={true} movieName="A Nightmare on Elm Street (1984)" csfdLink="http://www.csfd.cz/film/1761-nocni-mura-v-elm-street-nightmare-on-elm-street-a/" imdbLink=""/>
                    <MovieComponent moviePosition="714" isSeen={false} movieName="This Is Spinal Tap (1984)" csfdLink="http://www.csfd.cz/film/7629-this-is-spinal-tap/" imdbLink=""/>
                    <MovieComponent moviePosition="715" isSeen={true} movieName="Beverly Hills Cop (1984)" csfdLink="http://www.csfd.cz/film/928-beverly-hills-cop/" imdbLink=""/>
                    <MovieComponent moviePosition="716" isSeen={true} movieName="Ghostbusters (1984)" csfdLink="http://www.csfd.cz/film/10360-ghostbusters-ghost-busters/" imdbLink=""/>
                    <MovieComponent moviePosition="717" isSeen={false} movieName="A Passage to India (1984)" csfdLink="http://www.csfd.cz/film/5706-passage-to-india-a/" imdbLink=""/>
                    <MovieComponent moviePosition="718" isSeen={true} movieName="Stranger Than Paradise (1984)" csfdLink="http://www.csfd.cz/film/4749-stranger-than-paradise/" imdbLink=""/>
                    <MovieComponent moviePosition="719" isSeen={true} movieName="The Killing Fields (1984)" csfdLink="http://www.csfd.cz/film/4861-killing-fields-the/" imdbLink=""/>
                    <MovieComponent moviePosition="720" isSeen={false} movieName="The Natural (1984)" csfdLink="http://www.csfd.cz/film/5952-natural-the/" imdbLink=""/>
                    <MovieComponent moviePosition="721" isSeen={true} movieName="The Breakfast Club (1985)" csfdLink="http://www.csfd.cz/film/4573-breakfast-club-the/" imdbLink=""/>
                    <MovieComponent moviePosition="722" isSeen={false} movieName="Ran (1985)" csfdLink="http://www.csfd.cz/film/5433-ran/" imdbLink=""/>
                    <MovieComponent moviePosition="723" isSeen={true} movieName="Idi i smotri (1985)" csfdLink="http://www.csfd.cz/film/99087-go-and-see-come-and-see-idi-i-smotri/" imdbLink=""/>
                    <MovieComponent moviePosition="724" isSeen={false} movieName="La Historia oficial (1985)" csfdLink="http://www.csfd.cz/film/111493-historia-oficial-la/" imdbLink=""/>
                    <MovieComponent moviePosition="725" isSeen={false} movieName="Out of Africa (1985)" csfdLink="http://www.csfd.cz/film/7376-out-of-africa/" imdbLink=""/>
                    <MovieComponent moviePosition="726" isSeen={true} movieName="The Purple Rose of Cairo (1985)" csfdLink="http://www.csfd.cz/film/49-purple-rose-of-cairo-the/" imdbLink=""/>
                    <MovieComponent moviePosition="727" isSeen={true} movieName="Back to the Future (1985)" csfdLink="http://www.csfd.cz/film/10128-back-to-the-future/" imdbLink=""/>
                    <MovieComponent moviePosition="728" isSeen={false} movieName="Tong nien wang shi (1985)" csfdLink="http://www.csfd.cz/film/165338-time-to-live-and-the-time-to-die-the-tong-nien-wang-shi/" imdbLink=""/>
                    <MovieComponent moviePosition="729" isSeen={true} movieName="Brazil (1985)" csfdLink="http://www.csfd.cz/film/3301-brazil/" imdbLink=""/>
                    <MovieComponent moviePosition="730" isSeen={false} movieName="Kiss of the Spiderwoman (1985)" csfdLink="http://www.csfd.cz/film/20057-kiss-of-the-spider-woman/" imdbLink=""/>
                    <MovieComponent moviePosition="731" isSeen={true} movieName="The Quiet Earth (1985)" csfdLink="http://www.csfd.cz/film/29561-quiet-earth-the/" imdbLink=""/>
                    <MovieComponent moviePosition="732" isSeen={false} movieName="Mishima: A life in Four Chapters (1985)" csfdLink="http://www.csfd.cz/film/31217-mishima-a-life-in-four-chapters/" imdbLink=""/>
                    <MovieComponent moviePosition="733" isSeen={false} movieName="Prizzi&#8217;s Honor (1985)" csfdLink="http://www.csfd.cz/film/4608-prizzis-honor/" imdbLink=""/>
                    <MovieComponent moviePosition="734" isSeen={false} movieName="Sans toit ni loi (1985)" csfdLink="http://www.csfd.cz/film/71854-sans-toit-ni-loi/" imdbLink=""/>
                    <MovieComponent moviePosition="735" isSeen={false} movieName="Shoah (1985)" csfdLink="http://www.csfd.cz/film/221148-shoah/" imdbLink=""/>
                    <MovieComponent moviePosition="736" isSeen={false} movieName="The Color Purple (1985)" csfdLink="http://www.csfd.cz/film/8632-color-purple-the/" imdbLink=""/>
                    <MovieComponent moviePosition="737" isSeen={false} movieName="Manhunter (1986)" csfdLink="http://www.csfd.cz/film/6450-manhunter/" imdbLink=""/>
                    <MovieComponent moviePosition="738" isSeen={true} movieName="Stand By Me (1986)" csfdLink="http://www.csfd.cz/film/7626-stand-by-me/" imdbLink=""/>
                    <MovieComponent moviePosition="739" isSeen={false} movieName="Blue Velvet (1986)" csfdLink="http://www.csfd.cz/film/6247-blue-velvet/" imdbLink=""/>
                    <MovieComponent moviePosition="740" isSeen={true} movieName="Hannah and Her Sisters (1986)" csfdLink="http://www.csfd.cz/film/38-hannah-and-her-sisters/" imdbLink=""/>
                    <MovieComponent moviePosition="741" isSeen={true} movieName="She&#8217;s Gotta Have It (1986)" csfdLink="http://www.csfd.cz/film/5814-shes-gotta-have-it/" imdbLink=""/>
                    <MovieComponent moviePosition="742" isSeen={false} movieName="Le Déclin de l&#8217;empire américain (1986)" csfdLink="http://www.csfd.cz/film/130157-declin-de-lempire-americain-le/" imdbLink=""/>
                    <MovieComponent moviePosition="743" isSeen={false} movieName="The Fly (1986)" csfdLink="http://www.csfd.cz/film/1789-fly-the/" imdbLink=""/>
                    <MovieComponent moviePosition="744" isSeen={true} movieName="Aliens (1986)" csfdLink="http://www.csfd.cz/film/1245-aliens/" imdbLink=""/>
                    <MovieComponent moviePosition="745" isSeen={false} movieName="Ferris Bueller&#8217;s Day Off (1986)" csfdLink="http://www.csfd.cz/film/4575-ferris-buellers-day-off/" imdbLink=""/>
                    <MovieComponent moviePosition="746" isSeen={false} movieName="Down by Law (1986)" csfdLink="http://www.csfd.cz/film/4743-down-by-law/" imdbLink=""/>
                    <MovieComponent moviePosition="747" isSeen={false} movieName="A Room with a View (1986)" csfdLink="http://www.csfd.cz/film/4677-room-with-a-view-a/" imdbLink=""/>
                    <MovieComponent moviePosition="748" isSeen={false} movieName="Children of a Lesser God (1986)" csfdLink="http://www.csfd.cz/film/20052-children-of-a-lesser-god/" imdbLink=""/>
                    <MovieComponent moviePosition="749" isSeen={true} movieName="Platoon (1986)" csfdLink="http://www.csfd.cz/film/8759-platoon/" imdbLink=""/>
                    <MovieComponent moviePosition="750" isSeen={false} movieName="Caravaggio (1986)" csfdLink="http://www.csfd.cz/film/10635-caravaggio/" imdbLink=""/>
                    <MovieComponent moviePosition="751" isSeen={false} movieName="Tampopo (1986)" csfdLink="http://www.csfd.cz/film/86722-tampopo/" imdbLink=""/>
                    <MovieComponent moviePosition="752" isSeen={false} movieName="Do ma daan (1986)" csfdLink="http://www.csfd.cz/film/9109-do-ma-daan/" imdbLink=""/>
                    <MovieComponent moviePosition="753" isSeen={false} movieName="Salvador (1986)" csfdLink="http://www.csfd.cz/film/8760-salvador/" imdbLink=""/>
                    <MovieComponent moviePosition="754" isSeen={true} movieName="Top Gun (1986)" csfdLink="http://www.csfd.cz/film/8288-top-gun/" imdbLink=""/>
                    <MovieComponent moviePosition="755" isSeen={false} movieName="Sherman&#8217;s March (1986)" csfdLink="http://www.csfd.cz/film/45516-shermans-march/" imdbLink=""/>
                    <MovieComponent moviePosition="756" isSeen={false} movieName="Dao ma zei (1986)" csfdLink="http://www.csfd.cz/film/201633-dao-ma-zei/" imdbLink=""/>
                    <MovieComponent moviePosition="757" isSeen={false} movieName="Yeelen (1987)" csfdLink="http://www.csfd.cz/film/221087-yeelen/" imdbLink=""/>
                    <MovieComponent moviePosition="758" isSeen={false} movieName="Der Himmel über Berlin (1987)" csfdLink="http://www.csfd.cz/film/32913-wings-of-desire-himmel-uber-berlin-der/" imdbLink=""/>
                    <MovieComponent moviePosition="759" isSeen={false} movieName="&#8216;A&#8217; gai waak juk jaap (1987)" csfdLink="http://www.csfd.cz/film/1431-project-a-sequel-a-gai-waak-juk-jaap/" imdbLink=""/>
                    <MovieComponent moviePosition="760" isSeen={false} movieName="Babettes gćstebud (1987)" csfdLink="http://www.csfd.cz/film/89464-babettes-gstebud/" imdbLink=""/>
                    <MovieComponent moviePosition="761" isSeen={true} movieName="Raising Arizona (1987)" csfdLink="http://www.csfd.cz/film/1612-raising-arizona/" imdbLink=""/>
                    <MovieComponent moviePosition="762" isSeen={true} movieName="Full Metal Jacket (1987)" csfdLink="http://www.csfd.cz/film/5401-full-metal-jacket/" imdbLink=""/>
                    <MovieComponent moviePosition="763" isSeen={false} movieName="Withnail and I (1987)" csfdLink="http://www.csfd.cz/film/14739-withnail-and-i/" imdbLink=""/>
                    <MovieComponent moviePosition="764" isSeen={true} movieName="Good Morning, Vietnam (1987)" csfdLink="http://www.csfd.cz/film/5949-good-morning-vietnam/" imdbLink=""/>
                    <MovieComponent moviePosition="765" isSeen={false} movieName="Au Revoir Les Enfants (1987)" csfdLink="http://www.csfd.cz/film/6348-au-revoir-les-enfants/" imdbLink=""/>
                    <MovieComponent moviePosition="766" isSeen={false} movieName="Broadcast News (1987)" csfdLink="http://www.csfd.cz/film/945-broadcast-news/" imdbLink=""/>
                    <MovieComponent moviePosition="767" isSeen={false} movieName="Housekeeping (1987)" csfdLink="http://www.csfd.cz/film/22429-housekeeping/" imdbLink=""/>
                    <MovieComponent moviePosition="768" isSeen={false} movieName="The Princess Bride (1987)" csfdLink="http://www.csfd.cz/film/7625-princess-bride-the/" imdbLink=""/>
                    <MovieComponent moviePosition="769" isSeen={true} movieName="Moonstruck (1987)" csfdLink="https://www.csfd.cz/film/4808-pod-vlivem-uplnku/recenze/" imdbLink=""/>
                    <MovieComponent moviePosition="770" isSeen={false} movieName="The Untouchables (1987)" csfdLink="http://www.csfd.cz/film/2339-untouchables-the/" imdbLink=""/>
                    <MovieComponent moviePosition="771" isSeen={false} movieName="Hong gao liang (1987)" csfdLink="http://www.csfd.cz/film/38109-red-sorghum-hong-gao-liang/" imdbLink=""/>
                    <MovieComponent moviePosition="772" isSeen={false} movieName="The Dead (1987)" csfdLink="http://www.csfd.cz/film/4589-dead-the/" imdbLink=""/>
                    <MovieComponent moviePosition="773" isSeen={false} movieName="Fatal Attraction (1987)" csfdLink="http://www.csfd.cz/film/12165-fatal-attraction/" imdbLink=""/>
                    <MovieComponent moviePosition="774" isSeen={false} movieName="Sinnui yauman (1987)" csfdLink="http://www.csfd.cz/film/77083-chinese-ghost-story-a-sinnui-yauman/" imdbLink=""/>
                    <MovieComponent moviePosition="775" isSeen={true} movieName="Mujeres al borde de un ataque de nervios (1988)" csfdLink="http://www.csfd.cz/film/77-mujeres-al-borde-de-un-ataque-de-nervios/" imdbLink=""/>
                    <MovieComponent moviePosition="776" isSeen={false} movieName="Spoorloos (1988)" csfdLink="http://www.csfd.cz/film/49445-vanishing-the-spoorloos/" imdbLink=""/>
                    <MovieComponent moviePosition="777" isSeen={false} movieName="Bull Durham (1988)" csfdLink="http://www.csfd.cz/film/8335-bull-durham/" imdbLink=""/>
                    <MovieComponent moviePosition="778" isSeen={false} movieName="Ariel (1988)" csfdLink="http://www.csfd.cz/film/5029-ariel/" imdbLink=""/>
                    <MovieComponent moviePosition="779" isSeen={true} movieName="The Thin Blue Line (1988)" csfdLink="http://www.csfd.cz/film/130750-thin-blue-line-the/" imdbLink=""/>
                    <MovieComponent moviePosition="780" isSeen={true} movieName="Akira (1988)" csfdLink="http://www.csfd.cz/film/83541-akira/" imdbLink=""/>
                    <MovieComponent moviePosition="781" isSeen={false} movieName="Nuovo cinema Paradiso (1988)" csfdLink="http://www.csfd.cz/film/8995-cinema-paradiso-nuovo-cinema-paradiso/" imdbLink=""/>
                    <MovieComponent moviePosition="782" isSeen={true} movieName="Hôtel Terminus (1988)" csfdLink="http://www.csfd.cz/film/108491-hotel-konecna-stanice/" imdbLink=""/>
                    <MovieComponent moviePosition="783" isSeen={false} movieName="A Fish Called Wanda (1988)" csfdLink="http://www.csfd.cz/film/14011-fish-called-wanda-a/" imdbLink=""/>
                    <MovieComponent moviePosition="784" isSeen={true} movieName="The Naked Gun: From the Files of Police Squad! (1988)" csfdLink="http://www.csfd.cz/film/10197-naked-gun-from-the-files-of-police-squad-the/" imdbLink=""/>
                    <MovieComponent moviePosition="785" isSeen={false} movieName="Big (1988)" csfdLink="http://www.csfd.cz/film/6491-big/" imdbLink=""/>
                    <MovieComponent moviePosition="786" isSeen={true} movieName="Dangerous Liaisons (1988)" csfdLink="http://www.csfd.cz/film/3052-dangerous-liaisons/" imdbLink=""/>
                    <MovieComponent moviePosition="787" isSeen={true} movieName="Hotaru no haka (1988)" csfdLink="http://www.csfd.cz/film/129496-grave-of-the-fireflies-tombstone-for-the-fireflies-hotaru-no-haka/" imdbLink=""/>
                    <MovieComponent moviePosition="788" isSeen={false} movieName="Topio stin omichli (1988)" csfdLink="http://www.csfd.cz/film/36874-topio-stin-omichli/" imdbLink=""/>
                    <MovieComponent moviePosition="789" isSeen={false} movieName="Dekalog (1988)" csfdLink="http://www.csfd.cz/film/31662-dekalog-iv/" imdbLink=""/>
                    <MovieComponent moviePosition="790" isSeen={true} movieName="Die Hard (1988)" csfdLink="http://www.csfd.cz/film/6642-die-hard/" imdbLink=""/>
                    <MovieComponent moviePosition="791" isSeen={false} movieName="Une histoire de vent (1988)" csfdLink="http://www.csfd.cz/film/202307-tale-of-the-wind-a-une-histoire-de-vent/" imdbLink=""/>
                    <MovieComponent moviePosition="792" isSeen={false} movieName="Who Framed Roger Rabbit (1988)" csfdLink="http://www.csfd.cz/film/10142-who-framed-roger-rabbit/" imdbLink=""/>
                    <MovieComponent moviePosition="793" isSeen={true} movieName="Rain Man (1988)" csfdLink="http://www.csfd.cz/film/5954-rain-man/" imdbLink=""/>
                    <MovieComponent moviePosition="794" isSeen={false} movieName="The Story of Women (1988)" csfdLink="https://www.csfd.cz/film/41228-zenska-zalezitost/prehled/" imdbLink=""/>
                    <MovieComponent moviePosition="795" isSeen={false} movieName="Alice (1988)" csfdLink="http://www.csfd.cz/film/25-alice/" imdbLink=""/>
                    <MovieComponent moviePosition="796" isSeen={false} movieName="Batman (1989)" csfdLink="http://www.csfd.cz/film/1069-batman/" imdbLink=""/>
                    <MovieComponent moviePosition="797" isSeen={true} movieName="When Harry Met Sally (1989)" csfdLink="http://www.csfd.cz/film/7630-when-harry-met-sally/" imdbLink=""/>
                    <MovieComponent moviePosition="798" isSeen={false} movieName="Crimes and Misdemeanors (1989)" csfdLink="http://www.csfd.cz/film/32-crimes-and-misdemeanors/" imdbLink=""/>
                    <MovieComponent moviePosition="799" isSeen={false} movieName="The Cook, the Thief, His Wife and Her Lover (1989)" csfdLink="http://www.csfd.cz/film/3363-cook-the-thief-his-wife-and-her-lover-the/" imdbLink=""/>
                    <MovieComponent moviePosition="800" isSeen={false} movieName="Drugstore Cowboy (1989)" csfdLink="https://www.csfd.cz/film/9272-flakac/recenze/" imdbLink=""/>
                    <MovieComponent moviePosition="801" isSeen={false} movieName="My Left Foot (1989)" csfdLink="http://www.csfd.cz/film/8345-my-left-foot/" imdbLink=""/>
                    <MovieComponent moviePosition="802" isSeen={false} movieName="Die xue shuang xiong (1989)" csfdLink="http://www.csfd.cz/film/9849-killer-die-xue-shuang-xiong/" imdbLink=""/>
                    <MovieComponent moviePosition="803" isSeen={false} movieName="Do the Right Thing (1989)" csfdLink="http://www.csfd.cz/film/5799-do-the-right-thing/" imdbLink=""/>
                    <MovieComponent moviePosition="804" isSeen={false} movieName="Roger &amp; Me (1989)" csfdLink="http://www.csfd.cz/film/45485-roger-and-me/" imdbLink=""/>
                    <MovieComponent moviePosition="805" isSeen={false} movieName="Glory (1989)" csfdLink="http://www.csfd.cz/film/11016-glory/" imdbLink=""/>
                    <MovieComponent moviePosition="806" isSeen={true} movieName="Astenicheskiy sindrom (1989)" csfdLink="http://www.csfd.cz/film/180804-astenicheskij-sindrom/" imdbLink=""/>
                    <MovieComponent moviePosition="807" isSeen={true} movieName="Sex, Lies and Videotape (1989)" csfdLink="http://www.csfd.cz/film/8567-sex-lies-and-videotape/" imdbLink=""/>
                    <MovieComponent moviePosition="808" isSeen={true} movieName="Say Anything (1989)" csfdLink="http://www.csfd.cz/film/11873-say-anything/" imdbLink=""/>
                    <MovieComponent moviePosition="809" isSeen={false} movieName="The Unbelievable Truth (1989)" csfdLink="http://www.csfd.cz/film/97027-unbelievable-truth-the/" imdbLink=""/>
                    <MovieComponent moviePosition="810" isSeen={false} movieName="Beiqing chengshi (1989)" csfdLink="http://www.csfd.cz/film/96234-city-of-sadness-beiqing-chengshi/" imdbLink=""/>
                    <MovieComponent moviePosition="811" isSeen={false} movieName="S&#8217;en fout la mort (1990)" csfdLink="http://www.csfd.cz/film/39893-sen-fout-la-mort/" imdbLink=""/>
                    <MovieComponent moviePosition="812" isSeen={false} movieName="Reversal of Fortune (1990)" csfdLink="http://www.csfd.cz/film/8178-reversal-of-fortune/" imdbLink=""/>
                    <MovieComponent moviePosition="813" isSeen={false} movieName="Goodfellas (1990)" csfdLink="http://www.csfd.cz/film/8246-goodfellas/" imdbLink=""/>
                    <MovieComponent moviePosition="814" isSeen={true} movieName="Jacob&#8217;s Ladder (1990)" csfdLink="http://www.csfd.cz/film/15745-jacobs-ladder/" imdbLink=""/>
                    <MovieComponent moviePosition="815" isSeen={false} movieName="King of New York (1990)" csfdLink="http://www.csfd.cz/film/2635-king-of-new-york/" imdbLink=""/>
                    <MovieComponent moviePosition="816" isSeen={false} movieName="Dances with Wolves (1990)" csfdLink="http://www.csfd.cz/film/1747-dances-with-wolves/" imdbLink=""/>
                    <MovieComponent moviePosition="817" isSeen={true} movieName="Europa Europa (1990)" csfdLink="http://www.csfd.cz/film/42028-europa-europa/" imdbLink=""/>
                    <MovieComponent moviePosition="818" isSeen={true} movieName="Pretty Woman (1990)" csfdLink="http://www.csfd.cz/film/6486-pretty-woman/" imdbLink=""/>
                    <MovieComponent moviePosition="819" isSeen={false} movieName="Archangel (1990)" csfdLink="http://www.csfd.cz/film/173117-archangel/" imdbLink=""/>
                    <MovieComponent moviePosition="820" isSeen={false} movieName="Trust (1990)" csfdLink="http://www.csfd.cz/film/35356-trust/" imdbLink=""/>
                    <MovieComponent moviePosition="821" isSeen={false} movieName="Nema-ye Nazdik (1990)" csfdLink="http://www.csfd.cz/film/36122-nemaye-nazdik/" imdbLink=""/>
                    <MovieComponent moviePosition="822" isSeen={true} movieName="Edward Scissorhands (1990)" csfdLink="http://www.csfd.cz/film/1073-edward-scissorhands/" imdbLink=""/>
                    <MovieComponent moviePosition="823" isSeen={false} movieName="Henry: Portrait of a Serial Killer (1990)" csfdLink="http://www.csfd.cz/film/6634-henry-portrait-of-a-serial-killer/" imdbLink=""/>
                    <MovieComponent moviePosition="824" isSeen={true} movieName="Total Recall (1990)" csfdLink="http://www.csfd.cz/film/9303-total-recall/" imdbLink=""/>
                    <MovieComponent moviePosition="825" isSeen={false} movieName="Wong Fei-hung (1991)" csfdLink="http://www.csfd.cz/film/9128-once-upon-a-time-in-china-wong-feihung/" imdbLink=""/>
                    <MovieComponent moviePosition="826" isSeen={false} movieName="Boyz &#8216;n the Hood (1991)" csfdLink="http://www.csfd.cz/film/8415-boyz-n-the-hood/" imdbLink=""/>
                    <MovieComponent moviePosition="827" isSeen={false} movieName="Da hong deng long gao gao gua (1991)" csfdLink="http://www.csfd.cz/film/38107-raise-the-red-lantern-da-hong-deng-long-gao-gao-gua/" imdbLink=""/>
                    <MovieComponent moviePosition="828" isSeen={false} movieName="Delicatessen (1991)" csfdLink="http://www.csfd.cz/film/29220-delicatessen/" imdbLink=""/>
                    <MovieComponent moviePosition="829" isSeen={false} movieName="Guling jie shaonian sha ren shijian (1991)" csfdLink="http://www.csfd.cz/film/126192-brighter-summer-day-a-guling-jie-shaonian-sha-ren-shijian/" imdbLink=""/>
                    <MovieComponent moviePosition="830" isSeen={false} movieName="The Naked Lunch (1991)" csfdLink="http://www.csfd.cz/film/1797-naked-lunch/" imdbLink=""/>
                    <MovieComponent moviePosition="831" isSeen={false} movieName="La Belle Noiseuse (1991)" csfdLink="http://www.csfd.cz/film/37447-belle-noiseuse-la/" imdbLink=""/>
                    <MovieComponent moviePosition="832" isSeen={false} movieName="The Rapture (1991)" csfdLink="http://www.csfd.cz/film/12290-extaze-rapture-the/" imdbLink=""/>
                    <MovieComponent moviePosition="833" isSeen={false} movieName="My Own Private Idaho (1991)" csfdLink="http://www.csfd.cz/film/9280-my-own-private-idaho/" imdbLink=""/>
                    <MovieComponent moviePosition="834" isSeen={false} movieName="Thelma &amp; Louise (1991)" csfdLink="http://www.csfd.cz/film/8275-thelma-and-louise/" imdbLink=""/>
                    <MovieComponent moviePosition="835" isSeen={true} movieName="Terminator 2: Judgment Day (1991)" csfdLink="http://www.csfd.cz/film/1248-terminator-2-judgment-day/" imdbLink=""/>
                    <MovieComponent moviePosition="836" isSeen={true} movieName="The Silence of the Lambs (1991)" csfdLink="http://www.csfd.cz/film/2356-silence-of-the-lambs-the/" imdbLink=""/>
                    <MovieComponent moviePosition="837" isSeen={false} movieName="JFK (1991)" csfdLink="http://www.csfd.cz/film/8755-jfk/" imdbLink=""/>
                    <MovieComponent moviePosition="838" isSeen={true} movieName="Slacker (1991)" csfdLink="http://www.csfd.cz/film/34623-slacker/" imdbLink=""/>
                    <MovieComponent moviePosition="839" isSeen={false} movieName="Tongues Untied (1991)" csfdLink="http://www.csfd.cz/film/261972-tongues-untied/" imdbLink=""/>
                    <MovieComponent moviePosition="840" isSeen={true} movieName="Hearts of Darkness: A Filmmaker&#8217;s Apocalypse (1991)" csfdLink="http://www.csfd.cz/film/43867-hearts-of-darkness-a-filmmakers-apocalypse/" imdbLink=""/>
                    <MovieComponent moviePosition="841" isSeen={false} movieName="La Double vie de Véronique (1991)" csfdLink="http://www.csfd.cz/film/31672-double-vie-de-veronique-la/" imdbLink=""/>
                    <MovieComponent moviePosition="842" isSeen={false} movieName="Strictly Ballroom (1992)" csfdLink="http://www.csfd.cz/film/6177-strictly-ballroom/" imdbLink=""/>
                    <MovieComponent moviePosition="843" isSeen={false} movieName="The Player (1992)" csfdLink="http://www.csfd.cz/film/127-player-the/" imdbLink=""/>
                    <MovieComponent moviePosition="844" isSeen={false} movieName="Reservoir Dogs (1992)" csfdLink="http://www.csfd.cz/film/8853-reservoir-dogs/" imdbLink=""/>
                    <MovieComponent moviePosition="845" isSeen={false} movieName="Romper Stomper (1992)" csfdLink="http://www.csfd.cz/film/11700-romper-stomper/" imdbLink=""/>
                    <MovieComponent moviePosition="846" isSeen={false} movieName="Glengarry Glen Ross (1992)" csfdLink="http://www.csfd.cz/film/2830-glengarry-glen-ross/" imdbLink=""/>
                    <MovieComponent moviePosition="847" isSeen={false} movieName="Unforgiven (1992)" csfdLink="http://www.csfd.cz/film/2515-unforgiven/" imdbLink=""/>
                    <MovieComponent moviePosition="848" isSeen={false} movieName="Aileen Wuornos: The Selling of a Serial Killer (1992)" csfdLink="http://www.csfd.cz/film/218933-aileen-wuornos-the-selling-of-a-serial-killer/" imdbLink=""/>
                    <MovieComponent moviePosition="849" isSeen={false} movieName="Conte d&#8217;hiver (1992)" csfdLink="http://www.csfd.cz/film/37970-conte-dhiver/" imdbLink=""/>
                    <MovieComponent moviePosition="850" isSeen={false} movieName="Yuen Ling-yuk (1992)" csfdLink="http://www.csfd.cz/film/97963-yuen-lingyuk/" imdbLink=""/>
                    <MovieComponent moviePosition="851" isSeen={false} movieName="The Crying Game (1992)" csfdLink="http://www.csfd.cz/film/4878-crying-game-the/" imdbLink=""/>
                    <MovieComponent moviePosition="852" isSeen={false} movieName="C&#8217;est arrivé pres de chez vous (1992)" csfdLink="http://www.csfd.cz/film/46685-man-bites-dog-cest-arrive-prcs-de-chez-vous/" imdbLink=""/>
                    <MovieComponent moviePosition="853" isSeen={false} movieName="Ba wang bie ji (1993)" csfdLink="http://www.csfd.cz/film/69673-farewell-my-concubine-ba-wang-bie-ji/" imdbLink=""/>
                    <MovieComponent moviePosition="854" isSeen={false} movieName="Thirty Two Films about Glenn Gould (1993)" csfdLink="http://www.csfd.cz/film/37776-thirty-two-short-films-about-glenn-gould/" imdbLink=""/>
                    <MovieComponent moviePosition="855" isSeen={true} movieName="Groundhog Day (1993)" csfdLink="http://www.csfd.cz/film/7570-groundhog-day/" imdbLink=""/>
                    <MovieComponent moviePosition="856" isSeen={false} movieName="Short Cuts (1993)" csfdLink="http://www.csfd.cz/film/133-short-cuts/" imdbLink=""/>
                    <MovieComponent moviePosition="857" isSeen={false} movieName="Philadelphia (1993)" csfdLink="http://www.csfd.cz/film/2355-philadelphia/" imdbLink=""/>
                    <MovieComponent moviePosition="858" isSeen={false} movieName="Hsimeng jensheng (1993)" csfdLink="http://www.csfd.cz/film/165335-hsimeng-jensheng/" imdbLink=""/>
                    <MovieComponent moviePosition="859" isSeen={true} movieName="Jurassic Park (1993)" csfdLink="http://www.csfd.cz/film/8644-jurassic-park/" imdbLink=""/>
                    <MovieComponent moviePosition="860" isSeen={false} movieName="The Piano (1993)" csfdLink="http://www.csfd.cz/film/13942-piano-the/" imdbLink=""/>
                    <MovieComponent moviePosition="861" isSeen={false} movieName="Trois couleurs: Bleu (1993)" csfdLink="http://www.csfd.cz/film/31651-three-colours-blue-trois-couleurs-bleu/" imdbLink=""/>
                    <MovieComponent moviePosition="862" isSeen={false} movieName="Lan feng zheng (1993)" csfdLink="http://www.csfd.cz/film/133110-blue-kite-the-lan-feng-zheng/" imdbLink=""/>
                    <MovieComponent moviePosition="863" isSeen={false} movieName="Hsi yen (1993)" csfdLink="http://www.csfd.cz/film/5727-wedding-banquet-the-hsi-yen/" imdbLink=""/>
                    <MovieComponent moviePosition="864" isSeen={true} movieName="Schindler&#8217;s List (1993)" csfdLink="http://www.csfd.cz/film/8653-schindlers-list/" imdbLink=""/>
                    <MovieComponent moviePosition="865" isSeen={false} movieName="The Adventures of priscilla, Queen of the Desert (1994)" csfdLink="http://www.csfd.cz/film/16621-adventures-of-priscilla-queen-of-the-desert-the/" imdbLink=""/>
                    <MovieComponent moviePosition="866" isSeen={false} movieName="Trois couleurs: Rouge (1994)" csfdLink="http://www.csfd.cz/film/31652-three-colours-red-trois-couleurs-rouge/" imdbLink=""/>
                    <MovieComponent moviePosition="867" isSeen={false} movieName="Hoop Dreams (1994)" csfdLink="http://www.csfd.cz/film/75485-hoop-dreams/" imdbLink=""/>
                    <MovieComponent moviePosition="868" isSeen={true} movieName="Forrest Gump (1994)" csfdLink="http://www.csfd.cz/film/10135-forrest-gump/" imdbLink=""/>
                    <MovieComponent moviePosition="869" isSeen={true} movieName="Clerks (1994)" csfdLink="http://www.csfd.cz/film/30665-clerks/" imdbLink=""/>
                    <MovieComponent moviePosition="870" isSeen={true} movieName="Four Weddings and a Funeral (1994)" csfdLink="http://www.csfd.cz/film/17592-four-weddings-and-a-funeral/" imdbLink=""/>
                    <MovieComponent moviePosition="871" isSeen={false} movieName="The Lion King (1994)" csfdLink="http://www.csfd.cz/film/6741-lion-king-the/" imdbLink=""/>
                    <MovieComponent moviePosition="872" isSeen={false} movieName="Natural Born Killers (1994)" csfdLink="http://www.csfd.cz/film/8757-natural-born-killers/" imdbLink=""/>
                    <MovieComponent moviePosition="873" isSeen={false} movieName="The Last Seduction (1994)" csfdLink="http://www.csfd.cz/film/15512-last-seduction-the/" imdbLink=""/>
                    <MovieComponent moviePosition="874" isSeen={true} movieName="Pulp Fiction (1994)" csfdLink="http://www.csfd.cz/film/8852-pulp-fiction/" imdbLink=""/>
                    <MovieComponent moviePosition="875" isSeen={true} movieName="The Shawshank Redemption (1994)" csfdLink="http://www.csfd.cz/film/2294-shawshank-redemption-the/" imdbLink=""/>
                    <MovieComponent moviePosition="876" isSeen={false} movieName="Les Roseaux sauvages (1994)" csfdLink="http://www.csfd.cz/film/114772-roseaux-sauvages-les/" imdbLink=""/>
                    <MovieComponent moviePosition="877" isSeen={false} movieName="Chongqing senlin (1994)" csfdLink="http://www.csfd.cz/film/37590-chungking-express-chongqing-senlin/" imdbLink=""/>
                    <MovieComponent moviePosition="878" isSeen={false} movieName="Crumb (1994)" csfdLink="http://www.csfd.cz/film/38125-crumb/" imdbLink=""/>
                    <MovieComponent moviePosition="879" isSeen={true} movieName="Sátántangó (1994)" csfdLink="http://www.csfd.cz/film/143383-satantango/" imdbLink=""/>
                    <MovieComponent moviePosition="880" isSeen={false} movieName="Zire darakhatan zeyton (1994)" csfdLink="http://www.csfd.cz/film/36130-zire-darakhatan-zeyton/" imdbLink=""/>
                    <MovieComponent moviePosition="881" isSeen={false} movieName="Heavenly Creatures (1994)" csfdLink="http://www.csfd.cz/film/4710-heavenly-creatures/" imdbLink=""/>
                    <MovieComponent moviePosition="882" isSeen={false} movieName="Caro diario (1994)" csfdLink="http://www.csfd.cz/film/40918-dear-diary-caro-diario/" imdbLink=""/>
                    <MovieComponent moviePosition="883" isSeen={false} movieName="Muriel&#8217;s Wedding (1994)" csfdLink="http://www.csfd.cz/film/35344-muriels-wedding/" imdbLink=""/>
                    <MovieComponent moviePosition="884" isSeen={false} movieName="Riget (1994)" csfdLink="http://www.csfd.cz/film/71924-riget/" imdbLink=""/>
                    <MovieComponent moviePosition="885" isSeen={false} movieName="Babe (1995)" csfdLink="http://www.csfd.cz/film/16622-babe/" imdbLink=""/>
                    <MovieComponent moviePosition="886" isSeen={false} movieName="Deseret (1995)" csfdLink="http://www.csfd.cz/film/225252-deseret/" imdbLink=""/>
                    <MovieComponent moviePosition="887" isSeen={false} movieName="Braveheart (1995)" csfdLink="http://www.csfd.cz/film/3297-braveheart/" imdbLink=""/>
                    <MovieComponent moviePosition="888" isSeen={false} movieName="Safe (1995)" csfdLink="http://www.csfd.cz/film/17690-safe/" imdbLink=""/>
                    <MovieComponent moviePosition="889" isSeen={false} movieName="Toy Story (1995)" csfdLink="http://www.csfd.cz/film/18184-toy-story-pribeh-hracek-toy-story/" imdbLink=""/>
                    <MovieComponent moviePosition="890" isSeen={false} movieName="Casino (1995)" csfdLink="http://www.csfd.cz/film/8242-casino/" imdbLink=""/>
                    <MovieComponent moviePosition="891" isSeen={true} movieName="Heat (1995)" csfdLink="http://www.csfd.cz/film/6444-heat/" imdbLink=""/>
                    <MovieComponent moviePosition="892" isSeen={false} movieName="Kjaerlighetens kjotere (1995)" csfdLink="http://www.csfd.cz/film/15982-zero-kelvin-kj-and-230rlighetens-kj-and-248tere/" imdbLink=""/>
                    <MovieComponent moviePosition="893" isSeen={false} movieName="Clueless (1995)" csfdLink="http://www.csfd.cz/film/17952-clueless/" imdbLink=""/>
                    <MovieComponent moviePosition="894" isSeen={false} movieName="Smoke (1995)" csfdLink="http://www.csfd.cz/film/13948-smoke/" imdbLink=""/>
                    <MovieComponent moviePosition="895" isSeen={false} movieName="Badkonake sefid (1995)" csfdLink="http://www.csfd.cz/film/42479-badkonake-sefid/" imdbLink=""/>
                    <MovieComponent moviePosition="896" isSeen={true} movieName="Se7en (1995)" csfdLink="http://www.csfd.cz/film/2671-sedm-se7en/" imdbLink=""/>
                    <MovieComponent moviePosition="897" isSeen={false} movieName="Underground (1995)" csfdLink="http://www.csfd.cz/film/5459-underground/" imdbLink=""/>
                    <MovieComponent moviePosition="898" isSeen={false} movieName="Dilwale Dulhania Le Jayenge (1995)" csfdLink="http://www.csfd.cz/film/107196-dilwale-dulhania-le-jayenge/" imdbLink=""/>
                    <MovieComponent moviePosition="899" isSeen={false} movieName="Xich Lo (1995)" csfdLink="http://www.csfd.cz/film/83408-xich-lo/" imdbLink=""/>
                    <MovieComponent moviePosition="900" isSeen={false} movieName="The Usual Suspects (1995)" csfdLink="http://www.csfd.cz/film/8411-usual-suspects-the/" imdbLink=""/>
                    <MovieComponent moviePosition="901" isSeen={false} movieName="Dead Man (1995)" csfdLink="http://www.csfd.cz/film/4742-dead-man/" imdbLink=""/>
                    <MovieComponent moviePosition="902" isSeen={true} movieName="Fargo (1996)" csfdLink="http://www.csfd.cz/film/1606-fargo/" imdbLink=""/>
                    <MovieComponent moviePosition="903" isSeen={false} movieName="Trois vies &amp; une seule mort (1996)" csfdLink="http://www.csfd.cz/film/25634-trois-vies-and-une-seule-mort/" imdbLink=""/>
                    <MovieComponent moviePosition="904" isSeen={false} movieName="Shine (1996)" csfdLink="http://www.csfd.cz/film/4327-shine/" imdbLink=""/>
                    <MovieComponent moviePosition="905" isSeen={false} movieName="Breaking the Waves (1996)" csfdLink="http://www.csfd.cz/film/9385-breaking-the-waves/" imdbLink=""/>
                    <MovieComponent moviePosition="906" isSeen={true} movieName="Independence Day (1996)" csfdLink="http://www.csfd.cz/film/2572-independence-day/" imdbLink=""/>
                    <MovieComponent moviePosition="907" isSeen={false} movieName="Secrets &amp; Liess (1996)" csfdLink="http://www.csfd.cz/film/5835-secrets-and-lies/" imdbLink=""/>
                    <MovieComponent moviePosition="908" isSeen={false} movieName="Gabbeh (1996)" csfdLink="http://www.csfd.cz/film/70924-gabbeh/" imdbLink=""/>
                    <MovieComponent moviePosition="909" isSeen={false} movieName="Lone Star (1996)" csfdLink="http://www.csfd.cz/film/8047-lone-star/" imdbLink=""/>
                    <MovieComponent moviePosition="910" isSeen={true} movieName="Trainspotting (1996)" csfdLink="http://www.csfd.cz/film/901-trainspotting/" imdbLink=""/>
                    <MovieComponent moviePosition="911" isSeen={true} movieName="Scream (1996)" csfdLink="http://www.csfd.cz/film/1763-vriskot-scream/" imdbLink=""/>
                    <MovieComponent moviePosition="912" isSeen={true} movieName="The English Patient (1996)" csfdLink="http://www.csfd.cz/film/11918-english-patient-the/" imdbLink=""/>
                    <MovieComponent moviePosition="913" isSeen={false} movieName="Cheun gwong tsa sit (1997)" csfdLink="http://www.csfd.cz/film/37589-happy-together-cheun-gwong-tsa-sit/" imdbLink=""/>
                    <MovieComponent moviePosition="914" isSeen={true} movieName="Mononoke-hime (1997)" csfdLink="http://www.csfd.cz/film/22205-princezna-mononoke-mononokehime/" imdbLink=""/>
                    <MovieComponent moviePosition="915" isSeen={false} movieName="L.A. Confidential (1997)" csfdLink="http://www.csfd.cz/film/4123-la-confidential/" imdbLink=""/>
                    <MovieComponent moviePosition="916" isSeen={false} movieName="The Butcher Boy (1997)" csfdLink="http://www.csfd.cz/film/4876-butcher-boy-the/" imdbLink=""/>
                    <MovieComponent moviePosition="917" isSeen={false} movieName="The Ice Storm (1997)" csfdLink="http://www.csfd.cz/film/5729-ice-storm-the/" imdbLink=""/>
                    <MovieComponent moviePosition="918" isSeen={false} movieName="Boogie Nights (1997)" csfdLink="http://www.csfd.cz/film/155-boogie-nights/" imdbLink=""/>
                    <MovieComponent moviePosition="919" isSeen={false} movieName="Deconstructing Harry (1997)" csfdLink="http://www.csfd.cz/film/34-deconstructing-harry/" imdbLink=""/>
                    <MovieComponent moviePosition="920" isSeen={false} movieName="The Sweet Hereafter (1997)" csfdLink="http://www.csfd.cz/film/13319-sweet-hereafter-the/" imdbLink=""/>
                    <MovieComponent moviePosition="921" isSeen={false} movieName="Fast, Cheap and Out of Control (1997)" csfdLink="http://www.csfd.cz/film/130745-fast-cheap-and-out-of-control/" imdbLink=""/>
                    <MovieComponent moviePosition="922" isSeen={false} movieName="Funny Games (1997)" csfdLink="http://www.csfd.cz/film/33745-funny-games/" imdbLink=""/>
                    <MovieComponent moviePosition="923" isSeen={false} movieName="Ta&#8217;m e guilass (1997)" csfdLink="http://www.csfd.cz/film/36125-taste-of-cherry-tam-e-guilass/" imdbLink=""/>
                    <MovieComponent moviePosition="924" isSeen={false} movieName="Abre los ojos (1997)" csfdLink="http://www.csfd.cz/film/17060-open-your-eyes-abre-los-ojos/" imdbLink=""/>
                    <MovieComponent moviePosition="925" isSeen={false} movieName="Mat i syn (1997)" csfdLink="http://www.csfd.cz/film/36246-mother-and-son-mat-i-syn/" imdbLink=""/>
                    <MovieComponent moviePosition="926" isSeen={true} movieName="Titanic (1997)" csfdLink="http://www.csfd.cz/film/1250-titanic/" imdbLink=""/>
                    <MovieComponent moviePosition="927" isSeen={true} movieName="Tetsuo (1998)" csfdLink="http://www.csfd.cz/film/9138-tetsuo/" imdbLink=""/>
                    <MovieComponent moviePosition="928" isSeen={false} movieName="Festen (1998)" csfdLink="http://www.csfd.cz/film/35432-celebration-festen/" imdbLink=""/>
                    <MovieComponent moviePosition="929" isSeen={true} movieName="Saving Private Ryan (1998)" csfdLink="http://www.csfd.cz/film/8652-saving-private-ryan/" imdbLink=""/>
                    <MovieComponent moviePosition="930" isSeen={false} movieName="Buffalo 66 (1998)" csfdLink="http://www.csfd.cz/film/17389-buffalo-66/" imdbLink=""/>
                    <MovieComponent moviePosition="931" isSeen={true} movieName="Rushmore (1998)" csfdLink="http://www.csfd.cz/film/17754-rushmore/" imdbLink=""/>
                    <MovieComponent moviePosition="932" isSeen={false} movieName="Lola rennt (1998)" csfdLink="http://www.csfd.cz/film/32198-run-lola-run-lola-rennt/" imdbLink=""/>
                    <MovieComponent moviePosition="933" isSeen={false} movieName="Idioterne (1998)" csfdLink="http://www.csfd.cz/film/38127-idioterne/" imdbLink=""/>
                    <MovieComponent moviePosition="934" isSeen={true} movieName="Pi (1998)" csfdLink="http://www.csfd.cz/film/35661-pi/" imdbLink=""/>
                    <MovieComponent moviePosition="935" isSeen={true} movieName="Happiness (1998)" csfdLink="http://www.csfd.cz/film/8575-happiness/" imdbLink=""/>
                    <MovieComponent moviePosition="936" isSeen={true} movieName="The Thin Red Line (1998)" csfdLink="http://www.csfd.cz/film/6342-thin-red-line-the/" imdbLink=""/>
                    <MovieComponent moviePosition="937" isSeen={true} movieName="There&#8217;s Something About Mary (1998)" csfdLink="http://www.csfd.cz/film/2594-thebrres-something-about-mary/" imdbLink=""/>
                    <MovieComponent moviePosition="938" isSeen={false} movieName="Sombre (1998)" csfdLink="http:///www.csfd.cz/film/77307-sombre/" data-wplink-url-error="true" imdbLink=""/>
                    <MovieComponent moviePosition="939" isSeen={true} movieName="Ringu (1998)" csfdLink="http://www.csfd.cz/film/42164-ring-ringu/" imdbLink=""/>
                    <MovieComponent moviePosition="940" isSeen={false} movieName="Gohatto (1999)" csfdLink="http://www.csfd.cz/film/37332-gohatto/" imdbLink=""/>
                    <MovieComponent moviePosition="941" isSeen={false} movieName="Magnolia (1999)" csfdLink="http://www.csfd.cz/film/159-magnolia/" imdbLink=""/>
                    <MovieComponent moviePosition="942" isSeen={false} movieName="Beau Travail (1999)" csfdLink="http://www.csfd.cz/film/69873-beau-travail/" imdbLink=""/>
                    <MovieComponent moviePosition="943" isSeen={true} movieName="The Blair Witch Project (1999)" csfdLink="http://www.csfd.cz/film/6869-blair-witch-project-the/" imdbLink=""/>
                    <MovieComponent moviePosition="944" isSeen={true} movieName="Three Kings (1999)" csfdLink="http://www.csfd.cz/film/11500-three-kings/" imdbLink=""/>
                    <MovieComponent moviePosition="945" isSeen={false} movieName="Rosetta (1999)" csfdLink="http://www.csfd.cz/film/109990-rosetta/" imdbLink=""/>
                    <MovieComponent moviePosition="946" isSeen={false} movieName="Todo sobre mi madre (1999)" csfdLink="http://www.csfd.cz/film/85-all-about-my-mother-todo-sobre-mi-madre/" imdbLink=""/>
                    <MovieComponent moviePosition="947" isSeen={false} movieName="Le Temps Retrouve (1999)" csfdLink="http://www.csfd.cz/film/14569-temps-retrouve-le/" imdbLink=""/>
                    <MovieComponent moviePosition="948" isSeen={false} movieName="Fight Club (1999)" csfdLink="http://www.csfd.cz/film/2667-fight-clubrb/" imdbLink=""/>
                    <MovieComponent moviePosition="949" isSeen={true} movieName="Being John Malkovich (1999)" csfdLink="http://www.csfd.cz/film/4871-being-john-malkovich/" imdbLink=""/>
                    <MovieComponent moviePosition="950" isSeen={true} movieName="American Beauty (1999)" csfdLink="http://www.csfd.cz/film/6654-american-beauty/" imdbLink=""/>
                    <MovieComponent moviePosition="951" isSeen={false} movieName="Bad ma ra khahad bord (1999)" csfdLink="http://www.csfd.cz/film/36106-wind-will-carry-us-the-bad-ma-ra-khahad-bord/" imdbLink=""/>
                    <MovieComponent moviePosition="952" isSeen={true} movieName="The Matrix (1999)" csfdLink="http://www.csfd.cz/film/9499-matrix-the/" imdbLink=""/>
                    <MovieComponent moviePosition="953" isSeen={false} movieName="The Sixth Sense (1999)" csfdLink="http://www.csfd.cz/film/8364-sixth-sense-the/" imdbLink=""/>
                    <MovieComponent moviePosition="954" isSeen={false} movieName="Les Glaneurs et la /a/abrbrglaneuse (2000)" csfdLink="http://www.csfd.cz/film/71843-glaneurs-et-la-glaneuse-les/" imdbLink=""/>
                    <MovieComponent moviePosition="955" isSeen={false} movieName="Nueve Reinas (2000)" csfdLink="http://www.csfd.cz/film/69356-nueve-reinas/" imdbLink=""/>
                    <MovieComponent moviePosition="956" isSeen={false} movieName="La Captive (2000)" csfdLink="http://www.csfd.cz/film/48816-captive-la/" imdbLink=""/>
                    <MovieComponent moviePosition="957" isSeen={false} movieName="Dut Yeung Nin Wa (2000)" csfdLink="http://www.csfd.cz/film/37590-chungking-express-chongqing-senlin/" imdbLink=""/>
                    <MovieComponent moviePosition="958" isSeen={false} movieName="Ali Zaoua, prince de la rue (2000)" csfdLink="http://www.csfd.cz/film/209140-ali-zaoua-princeCSFD-de-la-rue/" imdbLink=""/>
                    <MovieComponent moviePosition="959" isSeen={false} movieName="Gladiator (2000)" csfdLink="http://www.csfd.cz/film/8271-gladiator/" imdbLink=""/>
                    <MovieComponent moviePosition="960" isSeen={false} movieName="Kippur (2000)" csfdLink="http://www.csfd.cz/film/35936-kippur/" imdbLink=""/>
                    <MovieComponent moviePosition="961" isSeen={false} movieName="Yi Yi (2000)" csfdLink="http://www.csfd.cz/film/124448-yi-yi/" imdbLink=""/>
                    <MovieComponent moviePosition="962" isSeen={true} movieName="Requiem for a Dream (2000)" csfdLink="http://www.csfd.cz/film/14317-requiem-for-a-dream/" imdbLink=""/>
                    <MovieComponent moviePosition="963" isSeen={false} movieName="Amores Perros (2000)" csfdLink="http://www.csfd.cz/film/35429-amores-perros/" imdbLink=""/>
                    <MovieComponent moviePosition="964" isSeen={true} movieName="Meet the Parents (2000)" csfdLink="http://www.csfd.cz/film/11981-meet-the-parents/" imdbLink=""/>
                    <MovieComponent moviePosition="965" isSeen={false} movieName="Signs &amp; Wonders (2000)" csfdLink="http://www.csfd.cz/film/15997-signs-and-wonders/" imdbLink=""/>
                    <MovieComponent moviePosition="966" isSeen={false} movieName="Wo hu cang long (2000)" csfdLink="http://www.csfd.cz/film/5733-crouching-tiger-hidden-dragon-wo-hu-cang-long/" imdbLink=""/>
                    <MovieComponent moviePosition="967" isSeen={false} movieName="Traffic (2000)" csfdLink="http://www.csfd.cz/film/8569-traffic/" imdbLink=""/>
                    <MovieComponent moviePosition="968" isSeen={false} movieName="Dancer in the Dark (2000)" csfdLink="http://www.csfd.cz/film/9389-dancer-in-the-dark/" imdbLink=""/>
                    <MovieComponent moviePosition="969" isSeen={true} movieName="Memento (2000)" csfdLink="http://www.csfd.cz/film/6995-memento/" imdbLink=""/>
                    <MovieComponent moviePosition="970" isSeen={false} movieName="Safar e Ghandehar (2001)" csfdLink="http://www.csfd.cz/film/70931-kandahar-safar-e-ghandehar/" imdbLink=""/>
                    <MovieComponent moviePosition="971" isSeen={false} movieName="Ni neibian jidian (2001)" csfdLink="http://www.csfd.cz/film/119131-ni-neibian-jidian/" imdbLink=""/>
                    <MovieComponent moviePosition="972" isSeen={false} movieName="Y Tu Mama Tambien (2001)" csfdLink="http://www.csfd.cz/film/29555-y-tu-mama-tambien/" imdbLink=""/>
                    <MovieComponent moviePosition="973" isSeen={false} movieName="Le Fabuleux destin d&#8217;Amélie Poulain (2001)" csfdLink="http://www.csfd.cz/film/29221-amelie-z-montmartru-fabuleux-destin-damelie-poulain-le/" imdbLink=""/>
                    <MovieComponent moviePosition="974" isSeen={true} movieName="Sen to Chihiro no kamikakushi (2001)" csfdLink="http://www.csfd.cz/film/42136-spirited-away-sen-to-chihiro-no-kamikakushi/" imdbLink=""/>
                    <MovieComponent moviePosition="975" isSeen={false} movieName="La Pianiste (2001)" csfdLink="http://www.csfd.cz/film/33749-pianiste-la/" imdbLink=""/>
                    <MovieComponent moviePosition="976" isSeen={false} movieName="La Stanza del figlio (2001)" csfdLink="http://www.csfd.cz/film/71096-sons-room-the-stanza-del-figlio-la/" imdbLink=""/>
                    <MovieComponent moviePosition="977" isSeen={true} movieName="Ničija zemlja (2001)" csfdLink="http://www.csfd.cz/film/37535-zeme-nikoho-nicija-zemlja/" imdbLink=""/>
                    <MovieComponent moviePosition="978" isSeen={true} movieName="Moulin Rouge! (2001)" csfdLink="http://www.csfd.cz/film/6175-moulin-rouge/" imdbLink=""/>
                    <MovieComponent moviePosition="979" isSeen={false} movieName="Monsoon Wedding (2001)" csfdLink="http://www.csfd.cz/film/37289-monsoon-wedding/" imdbLink=""/>
                    <MovieComponent moviePosition="980" isSeen={false} movieName="Lantana (2001)" csfdLink="http://www.csfd.cz/film/22330-lantana/" imdbLink=""/>
                    <MovieComponent moviePosition="981" isSeen={false} movieName="A Ma Soeur! (2001)" csfdLink="http://www.csfd.cz/film/69461-and-192-ma-soeur/" imdbLink=""/>
                    <MovieComponent moviePosition="982" isSeen={true} movieName="Mulholland Dr. (2001)" csfdLink="http://www.csfd.cz/film/6256-mulholland-drive-mulholland-dr/" imdbLink=""/>
                    <MovieComponent moviePosition="983" isSeen={false} movieName="The Royal Tenenbaums (2001)" csfdLink="http://www.csfd.cz/film/16276-royal-tenenbaums-the/" imdbLink=""/>
                    <MovieComponent moviePosition="984" isSeen={true} movieName="The Lord of the Rings: The Fellowship of the Ring (2001)" csfdLink="http://www.csfd.cz/film/4711-lord-of-the-rings-the-fellowship-of-the-ring-the/" imdbLink=""/>
                    <MovieComponent moviePosition="985" isSeen={true} movieName="The Pianist (2002)" csfdLink="http://www.csfd.cz/film/7355-pianist-the/" imdbLink=""/>
                    <MovieComponent moviePosition="986" isSeen={false} movieName="Gangs of New York (2002)" csfdLink="http://www.csfd.cz/film/8245-gangs-of-new-york/" imdbLink=""/>
                    <MovieComponent moviePosition="987" isSeen={true} movieName="Cidade de Deus (2002)" csfdLink="http://www.csfd.cz/film/88625-cidade-de-deus/" imdbLink=""/>
                    <MovieComponent moviePosition="988" isSeen={false} movieName="Ying Xiong (2002)" csfdLink="http://www.csfd.cz/film/38117-hero-ying-xiong/" imdbLink=""/>
                    <MovieComponent moviePosition="989" isSeen={false} movieName="Hable con ella (2002)" csfdLink="http://www.csfd.cz/film/69-talk-to-her-hable-con-ella/" imdbLink=""/>
                    <MovieComponent moviePosition="990" isSeen={false} movieName="Russian Ark (2002)" csfdLink="http://www.csfd.cz/film/136288-russian-ark/" imdbLink=""/>
                    <MovieComponent moviePosition="991" isSeen={true} movieName="The Lord of the Rings: The Two Towers (2002)" csfdLink="http://www.csfd.cz/film/4713-lord-of-the-rings-the-two-towers-the/" imdbLink=""/>
                    <MovieComponent moviePosition="992" isSeen={false} movieName="Les Invasions barbares (2003)" csfdLink="http://www.csfd.cz/film/103453-invasions-barbares-les/" imdbLink=""/>
                    <MovieComponent moviePosition="993" isSeen={false} movieName="Oldeuboi (2003)" csfdLink="http://www.csfd.cz/film/168893-oldboy-oldeuboi/" imdbLink=""/>
                    <MovieComponent moviePosition="994" isSeen={false} movieName="Kill Bill: Vol. 1 (2003)" csfdLink="http://www.csfd.cz/film/43483-kill-bill-kill-bill-vol-1/" imdbLink=""/>
                    <MovieComponent moviePosition="995" isSeen={true} movieName="Good Bye Lenin! (2003)" csfdLink="http://www.csfd.cz/film/111733-good-bye-lenin/" imdbLink=""/>
                    <MovieComponent moviePosition="996" isSeen={true} movieName="The Lord of the Rings: The Return of the King (2003)" csfdLink="http://www.csfd.cz/film/4712-lord-of-the-rings-the-return-of-the-king-the/" imdbLink=""/>
                    <MovieComponent moviePosition="997" isSeen={true} movieName="Fahrenheit 9/11 (2004)" csfdLink="http://www.csfd.cz/film/116848-fahrenheit-911/" imdbLink=""/>
                    <MovieComponent moviePosition="998" isSeen={true} movieName="The Passion of the Christ (2004)" csfdLink="http://www.csfd.cz/film/70199-passion-of-the-christ-the/" imdbLink=""/>
                    <MovieComponent moviePosition="999" isSeen={false} movieName="Collateral (2004)" csfdLink="http://www.csfd.cz/film/134254-collateral/" imdbLink=""/>
                    <MovieComponent moviePosition="1000" isSeen={false} movieName="The Aviator (2004)" csfdLink="http://www.csfd.cz/film/71530-aviator-the/" imdbLink=""/>
                    <MovieComponent moviePosition="1001" isSeen={false} movieName="Million Dollar Baby (2004)" csfdLink="http://www.csfd.cz/film/182363-million-dollar-baby/" imdbLink=""/>

                    <PageBlockHeaderThree header="Unofficial extra 2004—2008:"/>
                    <PageParagraph content={
                        <Fragment>
                            This is a little bit of extra, in case you need even more movies to watch.
                        </Fragment>
                    } />

                    <MovieComponent moviePosition="1002" isSeen={false} movieName="Crash (2004)" csfdLink="http://www.csfd.cz/film/134171-crash/" imdbLink=""/>
                    <MovieComponent moviePosition="1003" isSeen={false} movieName="Batman Begins (2005)" csfdLink="http://www.csfd.cz/film/136224-batman-zacina/" imdbLink=""/>
                    <MovieComponent moviePosition="1004" isSeen={true} movieName="Sin City (2005)" csfdLink="http://www.csfd.cz/film/178904-sin-city-mesto-hrichu/" imdbLink=""/>
                    <MovieComponent moviePosition="1005" isSeen={false} movieName="Corpse Bride (2005)" csfdLink="http://www.csfd.cz/film/194882-mrtva-nevesta-tima-burtona/" imdbLink=""/>
                    <MovieComponent moviePosition="1006" isSeen={true} movieName="Jarhead (2005)" csfdLink="http://www.csfd.cz/film/197354-marinak/" imdbLink=""/>
                    <MovieComponent moviePosition="1007" isSeen={false} movieName="United 93 (2006)" csfdLink="http://www.csfd.cz/film/221284-let-cislo-93/" imdbLink=""/>
                    <MovieComponent moviePosition="1008" isSeen={false} movieName="El labyrinto del Fauno (2006)" csfdLink="http://www.csfd.cz/film/221638-faunuv-labyrint/" imdbLink=""/>
                    <MovieComponent moviePosition="1009" isSeen={false} movieName="The Prestige (2006)" csfdLink="http://www.csfd.cz/film/223160-dokonaly-trik/" imdbLink=""/>
                    <MovieComponent moviePosition="1010" isSeen={true} movieName="Children of Men (2006)" csfdLink="http://www.csfd.cz/film/42954-potomci-lidi/" imdbLink=""/>
                    <MovieComponent moviePosition="1011" isSeen={false} movieName="American Gangster (2007)" csfdLink="http://www.csfd.cz/film/224384-americky-gangster/" imdbLink=""/>
                    <MovieComponent moviePosition="1012" isSeen={true} movieName="Sunshine (2007)" csfdLink="http://www.csfd.cz/film/222329-sunshine/" imdbLink=""/>
                    <MovieComponent moviePosition="1013" isSeen={false} movieName="Earth (2007)" csfdLink="http://www.csfd.cz/film/228566-earth/" imdbLink=""/>
                    <MovieComponent moviePosition="1014" isSeen={false} movieName="No Country for Old Men (2007)" csfdLink="http://www.csfd.cz/film/226762-tahle-zeme-neni-pro-stary/" imdbLink=""/>
                    <MovieComponent moviePosition="1015" isSeen={false} movieName="The Dark Knight (2008)" csfdLink="http://www.csfd.cz/film/223734-temny-rytir/" imdbLink=""/>
                    <MovieComponent moviePosition="1016" isSeen={false} movieName="The Curious Case of Benjamin Button (2008)" csfdLink="http://www.csfd.cz/film/221714-podivuhodny-pripad-benjamina-buttona/" imdbLink=""/>
                    <MovieComponent moviePosition="1017" isSeen={true} movieName="Gran Torino (2008)" csfdLink="http://www.csfd.cz/film/240479-gran-torino/" imdbLink=""/>
                    <MovieComponent moviePosition="1018" isSeen={false} movieName="Slumdog Millionaire (2008)" csfdLink="http://www.csfd.cz/film/234131-milionar-z-chatrce/" imdbLink=""/>
                    <MovieComponent moviePosition="1019" isSeen={false} movieName="Changeling (2008)" csfdLink="http://www.csfd.cz/film/230480-vymena/" imdbLink=""/>
                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
};

export default Movies;
