import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import TextCentered from "../../components/PageComponents/TextComponents/text-centered";
import PageWrapper from "../../components/PageWrapper";

const IssendaiPerfektniManazer = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Issendai – Jak být perfektní manažer'}}/>
            
            <PageContent content={
                <Fragment>
                    
                    <PageBlockQuote quote="http://www.issendai.com/psychology/how-to-be-a-perfect-manager.html" author="Originál v angličtině" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Několik měsíců po napsání původní eseje o Nemocných systémech nastoupila původní autorka do nové práce, která se ukázala být permanentním, hnisavým a do morku kosti prohnilým Nemocným systémem. Rady na následujících řádcích nemají s jejími zkušenostmi samozřejmě nic společného.
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Jak být perfektní manažer" />
                    
                    <PageParagraph content={
                        <Fragment>
                            Jste manažer a děláte sakra dobrou práci. Skvělou práci. Vlastně perfektní práci, ale bohužel jen málo lidí si toho všimne. Dokonce to někdy vypadá, že se vaši zaměstnanci snaží o to, aby některé jejich chyby vypadaly jako kdyby měly něco společného s vaším rozhodováním; například, že jejich drobné problémy ohledně work-life balance jsou důsledkem vašich rozhodnutí. Nenechte se tím ale stáhnout dolů. Tady je několik tipů k tomu, jak se bránit, pokud se vaši zaměstnanci snaží hodit jejich vlastní problémy na vás:
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            <strong>Pamatujte, že pokud vám to nedává smysl, tak to tak není</strong>. Vaši zaměstnanci vám říkají, že vaše rozhodnutí jim ztěžují práci. Vám ale práci nijak neztěžují a pokud se představíte na jejich místě, tak si neumíte představit, proč by neměli pracovat stejně jako vy. Vyslýchejte je ohledně toho, proč nemohou pracovat tak, jako vy. Nezapomeňte přidat pár lehkých narážek ohledně toho, že pokud nemají stejné pracovní návyky jako vy, tak jsou lehkomyslní a líní. Pokud se vaše práce od té jejich jakkoliv liší &#8211; například máte asistenta, který za vás vyřizuje veškerou administrativu &#8211; tak jim připomeňte, že jste taky byli v jejich pozici a víte, jaké to je. Pokud to, co tvrdí, vám stále nedává žádný smysl, tak jim dejte lehce najevo, že jsou líní (a lháři) a poté se z konverzace vyvlékněte tak rychle, jak to jenom jde. S iracionálními lidmi nelze diskutovat &#8211; takže jakákoliv snaha o diskuzi s nimi je ztrátou času.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            <strong>Kontrolujte tok konverzace</strong>. Zaměstnanci vám často řeknou věci jejich vlastním způsobem &#8211; konkrétně nesouvislým, disorganizovaným a ignorantským způsobem. Například vám Gregory řekne, že Bob udělal tuhle věc, potom Judy udělala tuhle věc a Bob jako odpověď udělal další věc &#8211; a nevšimněte si, že vám bylo právě řečeno to, že Bob vyplnil předávací protokoly zcela špatně. Zastavte konverzaci a nechte si popsat všechny detaily ohledně toho, co všech Bob špatně vyplnil. Možná vám bude zaměstnanec odporovat a snažit se pokračovat v konverzaci, ale on nerozumí tomu, kam ta konverzace má správně směřovat. Musíte vědět, co všechno Bob zkazil, v těch nejmenších detailech. Poté, co víte o problému naprosto vše, může Gregory pokračovat v konverzaci, aby se dostal k tomu, o čem si myslí, že je důležité vám sdělit.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Pokud bude pokračovat, tak si můžete povšimnout toho, že jeho vyprávění je nesouvislé a některé části chybí. Řekněte, aby si Gregory urovnal myšlenky a začal znovu. Řekne vám, že Bob udělal tohle, potom Judy udělala tamtu věc a potom Bob vyplnil špatně předávací protokol &#8211; takže teď je perfektní čas na to, konverzaci okamžitě pozastavit a zjistit si více informací o tom, co vše Bob špatně vyplnil. Potom může Gregory zase pokračovat v tom, co vám chce sdělit. To vám znovu nebude dávat žádný smysl &#8211; je to jako kdyby vám neřekl, co tomu vše předcházelo. Tak mu řekněte, ať vám to řekne celé ještě jednou.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Postupem času si Gregory uvědomí, že jeho vyprávěčské umění není zrovna nejlepší a rovnou skočí k tomu, co si myslí, že je nejdůležitější bod konverzace: &#8220;Judy zpronevěřuje firemní zdroje.&#8221; Zpronevěřuje?! Vaše nejlepší přítelkyně Judy?! To není možné! Jaký máš důkaz? Bude se ho snažit vysvětlit. Ale v jeho vyprávění je nemožné se vyznat. Je jasné, že si to obvinění vymýšlí. Už nikdy se s ním nebudete bavit a rozhodně ho už nikdy nebudete poslouchat.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            <strong>Trochu si zanadávejte</strong>. Řekněme, že pracujete někde, kde je extrémně striktní dress code a divize, pod kterou spadáte, vás občas překvapí nečekanými inspekcemi a tvrdými tresty za sebemenší provinění. Až o tom jednou bude řeč &#8211; a ona bude &#8211; máte dvě možnosti:
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            <em>Možnost 1</em>: &#8220;Jo, dress code je příliš striktní. Mluvíme o tom s Korporátem, ale zakladatel prostě tvrdě trvá na formálním oblečení na pracovišti, takže jsme s ním pořád nebyli schopní pohnout. Pokud tě někdo kontroluje, tak se prosím zkus usmívat a strpět to, je to něco, na čem pořád pracujeme. A jasně, neboj, zápis za nošení špatných bot během Svátku práce nijak neovlivní tvoji šanci na povýšení, o to se postarám.&#8221;
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Možnost 2: &#8220;Znepříjemňuje to život nám všem. Netýká se to jenom na tebe. Věděla jsi, že když jsem byla těhotná, tak jsem byla málem vyhozená a dali mě zpátky do zkušební doby za to, že jsem měla jednou zmačkaného motýlka? To taky nebylo fér, že jo? Musíme se s tím prostě smířit. A ještě k tomu jsem byla těhotná. Jsi těhotná? Nejsi. Tak nemáš dostatečně dobrý důvod k tomu, abys měla zmačkaného motýlka. Tohle prostě musíš strpět, pokud tu chceš pracovat. Pokud si myslíš, že můžeš nosit roztrhané minisukně a crop top, tak sis vybrala špatný obor. Pokud se chceš oblékat takhle, tak budeš muset se svojí kariérou pokračovat někde jinde. Jasné? [Ignorujte vše, co vám vaše zaměstnankyně říká o tom, že se nechce do práce oblékat jako šlapka; jde jí o to, aby se uvolnil dress code, takže tajně sní o druhém extrému. A ignorujte vše, co vám zaměstnankyně říká o tom, že nejde o problém s motýlkem.] Dobře. Tak si to pamatuj. A nezapomeň mít v pořádku motýlka. Budu tě sledovat.&#8221;
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Správná odpověď je jasná.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Tak, teď když jste si zanadávali, tak to často opakujte. Mluvte o tom pokaždé, když se začne mluvit o dress codu. Mluvte o tom pokaždé, když se začne mluvit o čemkoliv, co by s ním mohlo i jen mírně souviset. Mluvte o tom pokaždé, když je řeč o oblečení, i když nejste v práci. Bude to znamenat, že o tom budete mluvit několikrát denně, díky čemuž se reakce vašich zaměstnanců na dress code stanou velkým problémem, což znamená, že většina problémů ve vaší práci se týká dress codu, takže když se vyskytne nějaký problém, který vypadá, že s ním nesouvisí, tak se tím nenechte zmást. Učitě s tím souvisí, takže se zase začněte vztekat ihned, kdykoliv vám dá zaměstnanec sebemenší záminku.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Pokaždé když máte při konverzaci s nějakým zaměstnancem záchvat hněvu, tak zaměstnanci přiřaďte pomyslný černý puntík. Čím více černých puntíků zaměstnanec má, tím dříve byste měli u konverzace s ním vybuchnout. Někteří z nich budou mít postupně tolik černých puntíků, že je nejlepší vybuchnout ihned, když s nimi začnete mluvit. Možná si je budete muset občas i cíleně najít, abyste jim to všechno zopakovali. Po nějakém čase jej ale stejně budete muset vyhodit pro dobro vašeho týmu. Ve své práci nemůžete strpět zaměstnankyně, které se oblékají jako šlapky, bez ohledu na to, jak mají dobře upravené motýlky.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Možná zvažujete změnu tématu, o kterém budete mluvit během svého záchvatu vzteku. To by byla chyba. Mějte několik různých vět na začátek a konec vašeho výbuchu hněvu, ať nezníte jako robot, ale prostředek pokaždé držte pokud možno naprosto stejný. Je nutné, aby zaměstnanci rozumněli tomuto: &#8220;Nehádáš se s rozbitou gramofonovou deskou a nehádáš se se mnou.&#8221;
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Tahle metoda se může zdát pokřivená, ale funguje skvěle. Postupem času si všimnete, že vaši zaměstnanci k vám přicházejí s čím dál menším počtem problémů. Vaše záchvaty vzteku také vyřešily všechny problémy na vaší pobočce!
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            <strong>Buďte proaktivní s varováním</strong>. Na vašich zaměstnancích vám přeci záleží. Nechcete, aby dělali chyby, které by jim mohly poškodit jejich kariéru. Ujistěte se, že si jsou vědomi nebezpečí, které leží před nimi a pomozte jim vyhýbat se jim.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Vy: Pippo, jako uznání vaší vynikající práce vás povyšujeme na asistenta manažera.<br></br>
                            Pippa: Ach můj bože! Moc děkuju!<br></br>
                            Vy: Jen si pamatujte, že pokud toho na vás bude moc, tak o vaši pozici měli zájem ještě další čtyři spolupracovníci.<br></br>
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Gregory: Páni, tahle kancelář je skvělá. Koukej na ten výhled! A máme tu i plazmovou televizi! Dokonce třetí největší televizi v celé budově!<br></br>
                            Vy: Doufám, že se ti kancelář bude líbit, protože pokud ne, tak tu je tucet dalších lidí, kteří by pro ni byli ochotni zabíjet, protože mají v kanceláři jenom malou televizi.
                        </Fragment>
                    } />
                    
                    <PageParagraph content={
                        <Fragment>
                            Nenechávejte si varování jenom pro velké okamžiky v životě vašich zaměstnanců. Pokud je lehce popíchnete v jakékoliv konverzaci, tak budete držet své zaměstnance v neustálé pohotovosti.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Buďte efektivní</strong>. Pokud k vám přijde zaměstnanec, aby s vámi mluvil o problémech, které nyní má, tak využijte příležitosti k tomu, pohovořit s ním o problémech, které máte vy s ním. Chcete, aby vaši zaměstnanci viděli, že si jste vědomi všech problémů na pracovišti, aktivně hledáte jejich řešení a jste ochotni komunikovat. Díky tomu se budou cítit bezpečně.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            <strong>Držte se scénáře</strong>. Skvělí manažeři, stejně jako skvělí umělci, se drží scénářů. Vaši zaměstnanci možná nejsou tak skvělí jako vy, takže nejednají podle žádného scénáře. To je v pořádku. Vy se ale svého scénáře držte a lidé, kteří vás sledují, budou vědět, že děláte správnou věc. Například jeden z vašich zaměstnanců udělá chybu v reportu, která by se nestala, kdyby zkontroloval databázi:
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Vy: A kontroluješ databázi pokaždé, když píšeš report?<br></br>
                            On: Obvykle ano, ale zrovna včera mě něco rozptýlilo a zapomněl jsem.<br></br>
                            CO MĚL SPRÁVNĚ ŘÍCT: Ano, pokaždé!<br></br>
                            Vy: Jo, tak tomu nevěřím, protože kdybys kontroloval pokaždé databázi, neudělal bys tuhle chybu.<br></br>
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Vidíte? Jenom se držte scénáře. Ten vás už navede na správnou cestu.
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Pokud budete postupovat podle těchto jednoduchý tipů, tak&#8230; ale samozřejmě, že vy už podle těchto tipů postupujete. Vlastně už podle nich postupujete několik let, i když jste se o nich právě dozvěděli. Tak to prostě bývá, když jste perfektní. Tak si užijte svůj postup v kariérním žebříčku a nechť jsou všichni vaši zaměstnanci stejně dokonalí jako vy!
                        </Fragment>
                    } />

                </Fragment>}/>
            <Footer />
        </PageWrapper>
    )
}

export default IssendaiPerfektniManazer;