import React from "react";
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import TextCentered from "../../components/PageComponents/TextComponents/text-centered";
import PageBulletList from "../../components/PageComponents/PageListComponents/page-bullet-list";
import PageNumberedList from "../../components/PageComponents/PageListComponents/page-numbered-list";
import PageWrapper from "../../components/PageWrapper";

const OckovaniVsHolocaust = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{ header: '6 způsobů, jak se povinné očkování liší od holocaustu' }} />

            <PageContent content={
                <Fragment>
                    
                    <PageBlockQuote quote="https://www.theshovel.com.au/2021/10/14/anti-vaxxers-holocaust-guide/" author="Originál v angličtině" />

                    <PageParagraph content={
                        <Fragment>
                            Jednoduchá příručka pro odpůrce očkování.
                        </Fragment>
                    } />

                    <PageBreak />

                    <PageNumberedList content={
                        <Fragment>
                            <li>
                                <strong>Lidé, kteří prožili holokaust, byli nevinnými oběťmi; vy jste pouze sobečtí zmrdi.</strong> Je to velice drobný rozdíl, ale čím více to začnete zkoumat, tím zjevnější to je. Na jednu stranu tu máte nevinné lidi, kteří byli cíleně napadáni kvůli jejich náboženství nebo rase, a na druhou stranu tu máte pitomce, kteří jsou posedlí sami sebou a tvrdí, že jsou utlačovaní tím, že si nemohou zajít na kávu do jejich místní kavárny.
                            </li>
                            <li>
                                <strong>Během holokaustu byli systematicky vyvražďováni židé. Anti-vaxxeři během této epidemie nemohou jít do Kmartu.</strong> Možná se na první pohled zdá, že jsou tyto dvě věci srovnatelné, ale ve skutečnosti jsou rozdíly mezi nimi kurevsky monumentální. Drobná nepříjemnost, za kterou si můžete vy sami, versus doslovný rozsudek smrti pro miliony lidí.
                            </li>
                            <li>
                                <strong>Oběti holokaustu nemohly svůj osud nijak ovlivnit. Vy se můžete rozhodovat, jak chcete.</strong> Náš výzkum zjistil, že holokaust nenabízel možnost odmítnout účast. Nikdo neodpověděl na zaklepání dveří slovy jako: &#8220;Myslím, že to nějak přečkám, protože Pete Evans říkal, že bych neměl příliš věřit vědě.&#8221; Naopak, přesně tak, jak neustále opakujete znovu, a znovu, a pořád dokola, můžete si dělat cokoliv jenom chcete.
                            </li>
                            <li>
                                <strong>Během holokaustu se vyvražďovali lidé; vakcíny životy zachraňují.</strong> Na první pohled může být složité všimnout si rozdílu. Pokud se ale na to podíváte trochu více zblízka, můžete si všimnout, že tyto dvě věci jsou PŘESNĚ KURVA OPAČNÉ. Doslova by už nemohly být více rozdílné.
                            </li>
                            <li>
                                <strong>Oběti holokaustu musely jít do koncentračního tábora. Od vás se očekává, že půjdete dobrovolně do improvizovaného očkovacího centra.</strong> Cesta do tábora smrti bez možnosti diskuze, versus krátká cesta do očkovacího centra, kde dostanete lízátko zdarma. Prozkoumali jsme tyto dvě možnosti hlouběji a možná budete překvapeni zjištěním, že tyto věci nejsou ani vzdáleně podobné.
                            </li>
                            <li>
                                <strong>Židé pod nacistickou okupací v Evropě byli nuceni nosit žluté hvězdy; vy si zkoušíte cosplay.</strong> Cosplayování jako válečný vězeň může vypadat jako zábavný způsob, jakým získat více lajků na Instagramu. Ve skutečnosti je to ale kurevsky groteskní.
                            </li>
                        </Fragment>
                    } />

                </Fragment>} />
            <Footer />
        </PageWrapper>
    )
}

export default OckovaniVsHolocaust;