import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

const MenuTextButton = ({linkTo, currentPage, childPages, buttonLabel, buttonText, isHidden}) => {
    return (
        <div>
            {childPages.includes(currentPage) ? (
                <Link to={linkTo}>
                    <button class="w-full p-2 lg:mt-1 lg:mx-2 flex items-center rounded-md lg:px-4 duration-300 cursor-pointer hover:bg-white hover:bg-opacity-20 text-white  border-transparent border-2">
                        <span class="text-[15px] ml-0.5 mr-2 text-white font-bold">▾</span>
                        <span class="hidden sm:inline-block bg-red-500 font-bold text-white py-1 px-2 text-xs rounded ml-0">{buttonLabel}</span>
                        <span class="truncate text-[15px] ml-2 text-white font-bold">{buttonText}</span>
                    </button>
                </Link>
            ) : (
                <Link to={linkTo}>
                    <button class="w-full p-2 lg:mt-1 lg:mx-2 flex items-center rounded-md lg:px-4 duration-300 cursor-pointer hover:bg-white hover:bg-opacity-20 text-white  border-transparent border-2">
                        <span class="text-[15px] ml-0.5 mr-2 text-gray-300 font-bold">▸</span>
                        <span class="hidden sm:inline-block bg-red-500 font-bold text-white py-1 px-2 text-xs rounded ml-0">{buttonLabel}</span>
                        <span class="truncate text-[15px] ml-2 text-gray-300 font-bold">{buttonText}</span>
                    </button>
                </Link>
            )}
        </div>
    )
}

export default MenuTextButton