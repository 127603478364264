import React from 'react';
import PageHeader from '../../components/PageHeader';
import PageContent from "../../components/PageContent";
import Footer from '../../components/Footer';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import { Fragment } from "react";
import PageBlockHeaderTwo from "../../components/PageComponents/PageBlockHeaderTwo";
import PageBlockHeaderThree from "../../components/PageComponents/PageBlockHeaderThree";
import PageBlockQuote from "../../components/PageComponents/PageBlockQuote";
import PageParagraph from "../../components/PageComponents/PageParagraph";
import PageBreak from "../../components/PageComponents/PageBreak";
import PageWrapper from '../../components/PageWrapper';

const MyCv = ({full_display}) => {
    return (
        <PageWrapper full_display={full_display}>
            <PageHeader text={{header: 'Curriculum vitae'}}/>
            
            <PageContent content={
                <Fragment>

                    <PageBlockHeaderTwo header="Professional experience"/>

                    <PageBlockHeaderThree header="Supernova"/>

                    <PageParagraph content={
                        <Fragment>
                            Position: QA Test Lead
                            <PageBreak />
                            Duration: 05/2022 — present
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Position: Quality Assurance
                            <PageBreak />
                            Duration: 07/2019 — 05/2022
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="eBrána"/>

                    <PageParagraph content={
                        <Fragment>
                            Position: Automation Test Engineer + Integration Specialist for half a year.
                            <PageBreak />
                            Duration: 08/2017 — 07/2019
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Unicorn"/>

                    <PageParagraph content={
                        <Fragment>
                            Position: Developer (2nd line support).
                            <PageBreak />
                            Duration: 09/2016 — 08/2017
                        </Fragment>
                    } />

                    <PageBlockHeaderTwo header="Education"/>

                    <PageBlockHeaderThree header="Master's — Univerzita Hradec Králové"/>
                    
                    <PageParagraph content={
                        <Fragment>
                            Study branch: Information Management
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Studied: 2017—2019
                            <PageBreak />
                            Degree: Ing.
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="Bachelor's — Univerzita Hradec Králové"/>

                    <PageParagraph content={
                        <Fragment>
                            Study branch: Information Management
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Studied: 2013—2017
                            <PageBreak />
                            Degree: Bc.
                        </Fragment>
                    } />

                    <PageBlockHeaderThree header="High school — SOŠ a SOU Hradební 1029"/>
                    
                    <PageParagraph content={
                        <Fragment>
                            Study branch: Electronic computer systems with a focus on information technology (yes, that's the actual name of it!)
                        </Fragment>
                    } />

                    <PageParagraph content={
                        <Fragment>
                            Studied: 2009—2013
                            <PageBreak />
                            Graduation: Matura with honors
                        </Fragment>
                    } />

                </Fragment>}/>
            
            <Footer />
        </PageWrapper>
    )
};

export default MyCv;