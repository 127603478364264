import React from "react";

const PageWrapper = ({ full_display, children }) => {
  return full_display ? (
    <div className="flex h-screen w-3/5 lg:w-full flex-col">{children}</div>
  ) : (
    <div className="flex h-screen w-full lg:w-full flex-col">{children}</div>
  );
};

export default PageWrapper;