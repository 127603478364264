import React from "react";

function MovieComponent({moviePosition, movieName, isSeen, csfdLink, imdbLink}){
    return(
        <p class="py-2"><b>{moviePosition}</b>.{" "} 
        {isSeen ? (
            <del>
              {movieName}
            </del>
          ) : (
            movieName
          )}
        {" "} — {" "} 
        <a class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800" href={csfdLink} target="_blank">CSFD</a>
        { (imdbLink.length === 0) ? (
          <a class="hidden text-black bg-yellow-300 hover:bg-yellow-400 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-2 py-.5 mr-2 mb-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 focus:outline-none dark:focus:ring-yellow-800" href={imdbLink} target="_blank">IMDB</a>
        ) : (
          <a class="text-black bg-yellow-300 hover:bg-yellow-400 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-2 py-.5 mr-2 mb-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 focus:outline-none dark:focus:ring-yellow-800" href={imdbLink} target="_blank">IMDB</a>
        )}
        </p>
    );
}

export default MovieComponent