import logo from './logo.svg';
import './App.css';
import React, { useState } from "react";
import StyledForm from './styles';
import LeftMenu from './components/LeftMenu';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';

import Home from './pages/MainPages';
import MyCv from './pages/MainPages/cv';
import Projects from './pages/MainPages/projects';

import Movies from './pages/MainPages/movies';
import GameIdeas from './pages/MainPages/game-ideas';
import Hardware from './pages/MainPages/hardware';

import IssendaiAOpusteniRodice from './pages/IssendaiEstranged/issendai-a-opusteni-rodice';
import IssendaiBKulturaFor from './pages/IssendaiEstranged/issendai-b-kultura-for';
import IssendaiCDomnenky from './pages/IssendaiEstranged/issendai-c-domnenky';
import IssendaiDProcDetiOpousteji from './pages/IssendaiEstranged/issendai-d-proc-deti-opousteji';
import IssendaiEPoznamky from './pages/IssendaiEstranged/issendai-e-poznamky';

import IssendaiAJakDonutit from './pages/IssendaiSickSystems/issendai-a-jak-donutit';
import IssendaiBNaCestePryc from './pages/IssendaiSickSystems/issendai-b-na-ceste-pryc';
import IssendaiCCoVasDrzi from './pages/IssendaiSickSystems/issendai-c-co-vas-drzi';

import IssendaiPerfektniManazer from './pages/Translations/issendai-perfektni-manazer';
import NavodNaZhubnuti from './pages/Translations/navod-na-zhubnuti';
import StojimZaNic from './pages/Translations/stojim-za-nic';
import NaKaloriichZalezi from './pages/Translations/na-kaloriich-zalezi';
import Priony from './pages/Translations/priony';
import KoureniNejlepsiVec from './pages/Translations/koureni-je-nejlepsi';
import SedmFaziCovidu from './pages/Translations/sedm-fazi-covidu';
import OckovaniVsHolocaust from './pages/Translations/ockovani-vs-holokaust';
import PravoNaNazor from './pages/Translations/nemate-pravo-na-vlastni-nazor';
import Software from './pages/MainPages/software';


function App() {

  const [menu_displayed, set_menu_displayed] = useState(true);

  const switch_menu = () => {
      set_menu_displayed(!menu_displayed);
  };  

  return (
    <div class="bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] from-blue-700 via-blue-800 to-gray-900 xl:pl-16 xl:pr-4">
      <script src="node_modules\flowbite\plugin-windicss.js"></script>

      <button className="md:hidden fixed top-0 right-0 m-4 py-2 px-4 hover:bg-white hover:bg-opacity-20 text-white border-white border-transparent border-2 rounded" onClick={switch_menu}>
        {menu_displayed ? "▸" : "▾"}
      </button> 

      <div class="center flex h-screen">
        <Router>
          {menu_displayed && ( 
            <LeftMenu />
            )}
            <Routes>
              <Route exact path='/' element={<Home full_display={menu_displayed}/>} />
              <Route path="/cv" element={<MyCv full_display={menu_displayed}/>} />
              <Route path="/projects" element={<Projects full_display={menu_displayed}/>} />
              <Route path="/hardware" element={<Hardware full_display={menu_displayed}/>} />
              <Route path="/software" element={<Software full_display={menu_displayed}/>} />
              <Route path="/movies" element={<Movies full_display={menu_displayed}/>} />
              <Route path="/game-ideas" element={<GameIdeas full_display={menu_displayed}/>} />
              
              <Route path="/issendai-opusteni-rodice" element={<IssendaiAOpusteniRodice full_display={menu_displayed}/>} />
              <Route path="/issendai-kultura-for" element={<IssendaiBKulturaFor full_display={menu_displayed}/>} />
              <Route path="/issendai-domnenky" element={<IssendaiCDomnenky full_display={menu_displayed}/>} />
              <Route path="/issendai-proc-deti-opousteji" element={<IssendaiDProcDetiOpousteji full_display={menu_displayed}/>} />
              <Route path="/issendai-poznamky" element={<IssendaiEPoznamky full_display={menu_displayed}/>} />

              <Route path="/issendai-jak-donutit" element={<IssendaiAJakDonutit full_display={menu_displayed}/>} />
              <Route path="/issendai-na-ceste-pryc" element={<IssendaiBNaCestePryc full_display={menu_displayed}/>} />
              <Route path="/issendai-co-vas-drzi" element={<IssendaiCCoVasDrzi full_display={menu_displayed}/>} />

              <Route path="/issendai-jak-byt-perfektni-manazer" element={<IssendaiPerfektniManazer full_display={menu_displayed}/>} />
              <Route path="/navod-na-zhubnuti" element={<NavodNaZhubnuti full_display={menu_displayed}/>} />
              <Route path="/na-kaloriich-zalezi" element={<NaKaloriichZalezi full_display={menu_displayed}/>} />
              <Route path="/stojim-za-nic" element={<StojimZaNic full_display={menu_displayed}/>} />
              <Route path="/priony" element={<Priony full_display={menu_displayed}/>} />
              <Route path="/koureni-je-nejlepsi-vec-na-svete" element={<KoureniNejlepsiVec full_display={menu_displayed}/>} />
              <Route path="/sedm-fazi-covidu" element={<SedmFaziCovidu full_display={menu_displayed}/>} />
              <Route path="/jak-se-lisi-ockovani-od-holokaustu" element={<OckovaniVsHolocaust full_display={menu_displayed}/>} />
              <Route path="/nemate-pravo-na-svuj-nazor" element={<PravoNaNazor full_display={menu_displayed}/>} />
            </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;